@import '../../../assets/scss/variables';
@import '../../../assets/scss/responsive';

%cardStyle {
  background-color: rgba($fff-brand-new-primary, .04);
  border: 1px solid rgba($fff-brand-new-primary, .1);
  padding: 1rem 1rem 0;
  margin-bottom: 1rem;
}

%fffLabels {
  width: fit-content;
  padding: 0 0.75rem 0 0.625rem;
  position: absolute;
  z-index: 1;
  margin: 0;
  top: 10px;
  background: white;
  left: 1.5rem;
  border-radius: 20px;
  font-weight: normal;
  font-family: $fff-font-sans;
  font-weight: 500;
  line-height: 11px;
  text-transform: unset;

  &::after {
    content: '';
    width: 100%;
    height: 2px;
    display: block;
    background-color: #fff;
  }
}

%fffInputs {
  width: 100%;
  display: flex;
  align-items: center;
  line-height: 1.5;
  padding: 5px 12px;
  padding-left: 2rem !important;
  color: $fff-brand-new-dark !important;
  border: 2px solid rgba($fff-brand-grey, 0.24);
  margin-bottom: 0;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &.disabled {
    background-color: #EFEFEF;
  }

  strong {
    line-height: 48px;
  }

  &:disabled {
    background-color: #EFEFEF;
  }

  &:focus {
    border: 2px solid $fff-brand-new-secondary;
  }

  &:hover {
    border: 2px solid $fff-brand-new-secondary;
  }
}

.foodbox-container {
  padding: 0;

  @extend .container !optional;
    margin-right: 0;
    max-width: unset;
}

@media (min-width: 576px) {
  .foodbox-container {
    padding-left: 0px;
  }
}

@media (min-width: 768px) {
  .foodbox-container {
    padding-left: 0px;
  }
}

@include breakpoint(tab-landscape-up) {
  .foodbox-container {
    padding-left: calc((100vw - 960px) / 2);
    padding-left: calc((100dvw - 960px) / 2);
  }
}

@include breakpoint(desktop-up) {
  .foodbox-container {
    padding-left: calc((100vw - 1140px) / 2);
    padding-left: calc((100dvw - 1140px) / 2);
  }
}

@media (min-width: 1600px) {
  .foodbox-container {
    padding-left: calc((100vw - 1540px) / 2);
    padding-left: calc((100dvw - 1540px) / 2);
  }
}

// Payment Form

.paymentCard {
  @extend %cardStyle;

  .fffInputInline {
    padding-bottom: 16px;

    label {
      @extend %fffLabels;
      left: 2.5rem;
      top: -5px;
    }
  
    .frameContainer {
      padding: 9px 12px;
      padding-left: 1.9rem !important;
      height: 40px;
      min-height: 40px !important;
      border: 2px solid rgba($fff-brand-grey, 0.24);

      &:hover {
        border: 2px solid $fff-brand-new-secondary;
          transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      }

      &:focus {
        border: 2px solid $fff-brand-new-secondary;
      }
    }
  }

  .formGroup {
    position: relative;
    padding-top: 16px;

    label {
      @extend %fffLabels;
    }

    input,
    .input-element {
      @extend %fffInputs;
    }
  }

  .countryDropdown {
    position: relative;
    padding-top: 16px;

    label {
      @extend %fffLabels;
    }
  }
}

// Order Acknowledgement - Referral Code Banner

.referral {
  h4 {
    font-size: 20px;
  }

  .code {
    background-color: $fff-brand-foodbox-primary;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    border-radius: 2px;
    color: $fff-brand-new-light;

    span {
      font-size: 20px;
      font-weight: 600;
      font-family: $fff-font-sans;
    }

    i {
      line-height: 1.2;
      cursor: pointer;
    }
  }
}

// Checkbox Input

.defaultCheckbox {
  height: auto;
  width: fit-content;
  margin-bottom: 0.625rem;
  position: relative;
  display: flex !important;
  flex-direction: row-reverse;
  cursor: pointer;

  p {
    margin: 0;
    padding: 0;
    padding-left: 12px;
  }

  input {
    position: absolute;
    opacity: 0;
    left: 17px;
    top: -2px;
    height: 18px;
    width: 18px;
    cursor: pointer;
  }

  span {
    height: 18px;
    width: 18px;
    border: 1px solid rgba($fff-brand-grey, 0.24);
    display: block;
    border-radius: 4px;

    &::after {
      width: 6px;
      height: 10px;
      top: 2px;
      left: 5px;
    }
  }

  &:hover input~span {
    background-color: $fff-brand-new-fill;
  }

  input:checked~span {
    background-color: white;
  }

  span:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked~span:after {
    display: block;
  }

  span:after {
    width: 6px;
    height: 10px;
    top: 3px;
    left: 6px;
    border: 1px solid $fff-brand-new-primary;
    border-width: 0px 2px 2px 0px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

// Global Confirm Dialog Buttons
.primaryButton {
  background-color: $fff-brand-foodbox-primary !important;
  border-color: $fff-brand-foodbox-primary !important;
  
  &:hover {
    background-color: #72233f !important;
    border-color: #72233f !important;
  }
}