@import './responsive';

//collapsible

.accordion-main {
  .card {
    background-color: transparent;
    border: none;
    display: block;
    padding: 0;
    margin-bottom: 6px;

    &:hover {
      .card-header {
        background-color: #92A14B;
      }
    }

    .card-header {
      border: 0;
      background-color: $fff-brand-new-fill;
      border-radius: 0px;
      border: none;
      padding: 0;
      transition: background-color 0.24s ease-in-out;

      button {
        padding: 20px 30px 20px 30px;
        width: 100%;
        height: auto;
        justify-content: space-between;
        font-family: $fff-font-sans;
        font-weight: 700;
        font-size: 20px;
        color: black;
        text-align: left;
        border: none;
        line-height: 1.4;

        &::after {
          content: url('../img/icons/fff-chevron.svg');
          display: block;
          height: 24px;
          width: 24px;
          opacity: 0.4;
          transform: rotate(0deg);
          transition: transform 0.24s ease-in-out;
        }

        &:hover,
        &:focus {
          text-decoration: none;
        }

        &[aria-expanded='true'] {
          &::after {
            transform: rotate(180deg);
          }
        }
      }
    }

    .card-body {
      margin-top: 2px;
      background-color: transparent;
      padding: 20px 30px 20px 30px;
      color: $fff-brand-grey;
      font-family: $fff-font-body-light;
      font-size: 16px;
      line-height: 1.5;

      p {
        margin-bottom: 1rem;
      }
    }
  }
}

// header row
.headr-row {
  .page-header-static {
    position: relative;

    .img {
      width: 100%;
      height: 250px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top center;
      
      @media screen and (min-width: 1200px) {
        background-position: center center;
        height: 300px;
      }
    }

    &.bg-top-minus-sm {
      .img {
        background-position: top center;
        
        @include breakpoint(desktop-up) {
          background-position: top -50px center;
        }
      }
    }

    &.bg-top-minus-md {
      .img {
        background-position: top center;
        @include breakpoint(desktop-up) {
          background-position: top -140px center;
        }
        
        @include breakpoint(tab-up) {
          background-position: top -50px center;
        }
        
        @include breakpoint(tab-landscape-up) {
          background-position: top -80px center;
        }
      }
    }

    &.bg-top-minus-lg {
      .img {
        background-position: top center;
        @include breakpoint(desktop-up) {
          background-position: top -200px center;
        }

        @include breakpoint(tab-up) {
          background-position: top -60px center;
        }

        @include breakpoint(tab-landscape-up) {
          background-position: top -100px center;
        }
      }
    }

    &.aboutPageHeader {
      margin-bottom: 75px;

      @media all and (min-width: 1024px) {
        margin-bottom: 135px;
      }

      .img {
        background-position: top center;

        @media all and (min-width: 1024px) {
          background-position: top -50px center;
        }
      }
    }

    .header-title {
      height: auto;
      text-align: center;
      padding-top: 30px;
      padding-bottom: 40px;
      @media all and (min-width: 992px) {
        padding-top: 40px;
        padding-bottom: 50px;
      }

      h1 {
        font-size: 40px;
        line-height: 1.3;
        font-family: $fff-font-serif;
        font-weight: 400;
        margin-bottom: 15px;
        i {
          font-family: $fff-font-title-light;
          font-weight: 300;
        }
        @media all and (min-width: 992px) {
          font-size: 50px;
        }
      }

      h3 {
        font-size: 22px;
        margin-bottom: 0px;
        line-height: 1.4;
        font-family: $fff-font-sans;
        font-weight: bold;
        @media all and (min-width: 992px) {
          font-size: 27px;
        }
      }

      button {
        margin-top: 15px;
        
        @include breakpoint(desktop-up) {
          margin-top: 30px;
        }
      }
    }
  }

  .header-description {
    position: relative;
    // background-color: $fff-brand-light-green;
    margin: 0 auto 60px;

    @include media-breakpoint-for(mobile) {
      padding: 0px 15px;
      margin-bottom: 40px;
    }

    @include media-breakpoint-for(tab) {
      padding: 0px 15px;
      margin-bottom: 40px;
    }

    @include media-breakpoint-for(tab-landscape) {
      padding: 0px 15px;
      margin-bottom: 40px;
    }

    &.ambassador-intro {
      margin: -145px auto 45px;

      @include media-breakpoint-for(mobile) {
        margin: -50px auto 30px;
      }

      @include media-breakpoint-for(tab) {
        margin: -50px auto 30px;
      }

      @include media-breakpoint-for(tab-landscape) {
        margin: -50px auto 30px;
      }

      .description-inside {
        padding: 60px 0px 0px;

        @include media-breakpoint-for(mobile) {
          padding: 30px 0px 0px;
        }

        @include media-breakpoint-for(tab) {
          padding: 30px 0px 0px;
        }

        @include media-breakpoint-for(tab-landscape) {
          padding: 30px 0px 0px;
        }

        h3 {
          font-size: 42px;
          font-family: 'DINPro-Bold', sans-serif;

          @include media-breakpoint-for(mobile) {
            font-size: 28px;
          }

          @include media-breakpoint-for(tab) {
            font-size: 28px;
          }

          @include media-breakpoint-for(tab-landscape) {
            font-size: 28px;
          }
        }
      }
    }

    .description-inside {
      display: block;
      padding: 0px 0px;
      margin: 0 auto;
      text-align: center;

      @include media-breakpoint-for(mobile) {
        margin: 0px;
      }

      @include media-breakpoint-for(tab) {
        margin: 0px;
      }

      @include media-breakpoint-for(tab-landscape) {
        margin: 0px;
      }

      p {
        margin-bottom: 30px;
      }

      button {
        margin: 0px 8px;

        @include media-breakpoint-for(mobile) {
          width: 100%;
          margin: 0px;

          &:first-of-type {
            margin-bottom: 15px;
          }
        }

        @include media-breakpoint-for(tab) {
          width: 100%;
          margin: 0px;

          &:first-of-type {
            margin-bottom: 15px;
          }
        }

        @include media-breakpoint-for(tab-landscape) {
          width: 100%;
          margin: 0px;

          &:first-of-type {
            margin-bottom: 15px;
          }
        }
      }
    }
  }
}

.section-main {
  position: relative;

  &.full-width-green-bg {
    background-color: $fff-brand-new-fill;
    padding: 60px 0px;

    @include media-breakpoint-for(mobile) {
      padding: 15px 0px;
    }

    @include media-breakpoint-for(tab) {
      padding: 15px 0px;
    }

    @include media-breakpoint-for(tab-landscape) {
      padding: 15px 0px;
    }
  }

  &.margin-bottom-xlg {
    @include media-breakpoint-for(mobile) {
      margin-bottom: 30px;
    }

    @include media-breakpoint-for(tab) {
      margin-bottom: 30px;
    }

    @include media-breakpoint-for(tab-landscape) {
      margin-bottom: 30px;
    }
  }

  &.margin-bottom-xxlg {
    @include media-breakpoint-for(mobile) {
      margin-bottom: 45px;
    }

    @include media-breakpoint-for(tab) {
      margin-bottom: 45px;
    }

    @include media-breakpoint-for(tab-landscape) {
      margin-bottom: 45px;
    }
  }

  p {
    font-size: 16px;

    a {
      color: $fff-brand-new-primary;
      border-top: 2px solid transparent;
      border-bottom: 2px solid $fff-brand-new-primary;
      cursor: pointer;
      text-decoration: none;
      -webkit-transition: 0.2s;
      transition: 0.2s;

      &:hover {
        color: $fff-brand-green;
        border-bottom-color: rgba(6, 109, 56, 0);
        background: rgba(6, 109, 56, 0.18);
      }
    }
  }

  h1,
  h2 {
    padding-top: 0px;
    font-size: 42px;
    margin-bottom: 40px;
    position: relative;
    z-index: 1;
    font-family: $fff-font-sans;
    font-weight: 700;
    padding-left: 15px;
    padding-right: 15px;

    @include media-breakpoint-for(mobile) {
      font-size: 28px;
      margin-bottom: 30px;
    }

    @include media-breakpoint-for(tab) {
      font-size: 28px;
      margin-bottom: 30px;
    }

    @include media-breakpoint-for(tab-landscape) {
      font-size: 28px;
      margin-bottom: 30px;
    }

    &.leaf-img-txt {
      background-image: url('../../assets/img/fff-leaf-s.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 114px;

      @include media-breakpoint-for(mobile) {
        background-size: 100px;
      }

      @include media-breakpoint-for(tab) {
        background-size: 100px;
      }

      @include media-breakpoint-for(tab-landscape) {
        background-size: 100px;
      }
    }

    &.no-bottom-margin {
      margin-bottom: 0px;
    }
  }

  h3 {
    padding-top: 0px;
    font-size: 28px;
    margin-bottom: 40px;
    line-height: initial;
    color: $fff-brand-grey;
    font-family: $fff-font-sans;
    font-weight: 700;

    @include media-breakpoint-for(mobile) {
      padding-top: 0px;
      font-size: 22px;
      margin-bottom: 30px;
    }

    @include media-breakpoint-for(tab) {
      padding-top: 0px;
      font-size: 22px;
      margin-bottom: 30px;
    }

    @include media-breakpoint-for(tab-landscape) {
      padding-top: 0px;
      font-size: 22px;
      margin-bottom: 30px;
    }
  }

  .container {
    @include media-breakpoint-for(mobile) {
      padding: 0px;
    }

    @include media-breakpoint-for(tab) {
      padding: 0px;
    }

    @include media-breakpoint-for(tab-landscape) {
      padding: 0px;
    }
  }

  .paragraph-row {
    margin: 0px;

    &.top-padding {
      padding-top: 30px;

      @include media-breakpoint-for(mobile) {
        padding-top: 40px;
      }

      @include media-breakpoint-for(tab) {
        padding-top: 40px;
      }

      @include media-breakpoint-for(tab-landscape) {
        padding-top: 40px;
      }
    }

    &.top-padding-md {
      padding-top: 30px;

      @include media-breakpoint-for(mobile) {
        padding-top: 20px;
      }

      @include media-breakpoint-for(tab) {
        padding-top: 20px;
      }

      @include media-breakpoint-for(tab-landscape) {
        padding-top: 20px;
      }
    }

    &.mint-bg-m {
      margin-bottom: 60px;

      @include media-breakpoint-for(mobile) {
        background-color: $fff-brand-new-fill;
        padding: 30px 15px;
        margin-bottom: 0;
      }

      @include media-breakpoint-for(tab) {
        background-color: $fff-brand-new-fill;
        padding: 30px 15px;
        margin-bottom: 0;
      }

      @include media-breakpoint-for(tab-landscape) {
        background-color: $fff-brand-new-fill;
        padding: 30px 15px;
        margin-bottom: 0;
      }
    }

    &.white-bg-m {
      margin-bottom: 60px;

      @include media-breakpoint-for(mobile) {
        background-color: #ffffff;
        padding: 30px 15px;
        margin-bottom: 0;
      }

      @include media-breakpoint-for(tab) {
        background-color: #ffffff;
        padding: 30px 15px;
        margin-bottom: 0;
      }

      @include media-breakpoint-for(tab-landscape) {
        background-color: #ffffff;
        padding: 30px 15px;
        margin-bottom: 0;
      }
    }

    &.top-padding-lg {
      padding-top: 50px;
    }

    .paragraph-content {
      margin: 0 auto;
      padding: 0px;

      @include media-breakpoint-for(mobile) {
        padding: 0px 15px;
      }

      @include media-breakpoint-for(tab) {
        padding: 0px 15px;
        max-width: 100%;
        flex: 0 0 100%;
      }

      @include media-breakpoint-for(tab-landscape) {
        padding: 0px 15px;
        max-width: 100%;
        flex: 0 0 100%;
      }

      p {
        margin-bottom: 0px;
        text-align: center;
        font-family: $fff-font-sans;

        &.put-it-into-a-box {
          padding: 30px;
          background-color: $fff-brand-light-green;
          color: $fff-brand-black-text;
          font-weight: bold;
          margin-top: 15px;
        }
      }
    }
  }

  // how we work secton

  &.order-process-circle-images {
    .steps-row-odd {
      &:last-of-type {
        @include media-breakpoint-for(mobile) {
          &::after {
            display: none;
          }
        }
      }

      .img-col {
        @include media-breakpoint-for(tab) {
          height: 380px !important;
        }

        &::before {
          right: 15px !important;
        }

        @include media-breakpoint-for(mobile) {
          &::before {
            right: 50px !important;
          }
        }

        @include media-breakpoint-for(tab) {
          &::before {
            right: 230px !important;
          }
        }

        @media only screen and (max-device-width: 1366px) and (min-device-width: 1024px) {
          &::before {
            right: 34% !important;
          }
        }

        .img-container {
          background-size: contain !important;
          background-position: right;

          @include media-breakpoint-for(mobile) {
            background-position: center !important;
          }

          @include media-breakpoint-for(tab) {
            background-position: center !important;
          }
        }
      }
    }

    .steps-row-even {
      .img-col {
        @include media-breakpoint-for(tab) {
          height: 380px !important;
        }

        &::before {
          left: 15px !important;
        }

        @include media-breakpoint-for(mobile) {
          &::before {
            left: 50px !important;
          }
        }

        @include media-breakpoint-for(tab) {
          &::before {
            left: 230px !important;
          }
        }

        @media only screen and (max-device-width: 1366px) and (min-device-width: 1024px) {
          &::before {
            left: 34% !important;
          }
        }

        .img-container {
          background-size: contain !important;
          background-position: left;

          @include media-breakpoint-for(mobile) {
            background-position: center !important;
          }

          @include media-breakpoint-for(tab) {
            background-position: center !important;
          }
        }
      }
    }
  }

  .steps-row-odd {
    position: relative;
    margin: -55px 0px 0px;

    @include media-breakpoint-for(mobile) {
      margin: 0px 0px 0px;

      &::after {
        content: '';
        width: 26px;
        border-bottom: 2px dashed $fff-brand-yellow;
        position: absolute;
        bottom: -18px;
        height: 5px;
        z-index: 1;
        left: 50%;
        -ms-transform: translate(-50%, 0%) rotate(90deg);
        -webkit-transform: translate(-50%, 0%) rotate(90deg);
        transform: translate(-50%, 0%) rotate(90deg);
      }

      &:first-of-type {
        .steps-row-inside {
          padding-top: 0px;
        }
      }
    }

    @include media-breakpoint-for(tab) {
      margin: 0px 0px 0px;

      &::after {
        content: '';
        width: 26px;
        border-bottom: 2px dashed $fff-brand-yellow;
        position: absolute;
        bottom: -18px;
        height: 5px;
        z-index: 1;
        left: 50%;
        -ms-transform: translate(-50%, 0%) rotate(90deg);
        -webkit-transform: translate(-50%, 0%) rotate(90deg);
        transform: translate(-50%, 0%) rotate(90deg);
      }

      &:first-of-type {
        .steps-row-inside {
          padding-top: 0px;
        }
      }
    }

    @include media-breakpoint-for(tab-landscape) {
      margin: 0px 0px 0px;

      &::after {
        content: '';
        width: 26px;
        border-bottom: 2px dashed $fff-brand-yellow;
        position: absolute;
        bottom: -18px;
        height: 5px;
        z-index: 1;
        left: 50%;
        -ms-transform: translate(-50%, 0%) rotate(90deg);
        -webkit-transform: translate(-50%, 0%) rotate(90deg);
        transform: translate(-50%, 0%) rotate(90deg);
      }

      &:first-of-type {
        .steps-row-inside {
          padding-top: 0px;
        }
      }
    }

    &:first-of-type {
      margin: 0px;
    }

    .dotted-row {
      background-repeat: no-repeat;
      background-position: top center;
      padding-bottom: 124px;
      background-image: url('../img/dotted-line-r-green.svg');
      position: relative;
      top: -30px;

      @include media-breakpoint-for(mobile) {
        display: none;
      }

      @include media-breakpoint-for(tab) {
        display: none;
      }

      @include media-breakpoint-for(tab-landscape) {
        display: none;
      }
    }

    .steps-row-inside {
      position: relative;
      padding: 30px 0px;

      @include media-breakpoint-for(mobile) {
        padding: 30px 0px 0px;
      }

      @include media-breakpoint-for(tab) {
        padding: 30px 0px 0px;
      }

      @include media-breakpoint-for(tab-landscape) {
        padding: 30px 0px 0px;
      }

      .img-col {
        text-align: left;
        display: inline-block;
        height: 100%;
        position: absolute;
        top: 0px;
        right: 8.33333%;
        padding: 0px 0px 0px 15px;

        @include media-breakpoint-for(mobile) {
          display: block;
          padding: 0px 30px;
          position: relative;
          top: unset;
          display: block;
          right: unset;
          height: 300px;
          width: 100%;
        }

        @include media-breakpoint-for(tab) {
          display: block;
          padding: 0px 30px;
          position: relative;
          top: unset;
          display: block;
          right: unset;
          height: 500px;
          width: 100%;
          max-width: 100%;
          flex: 0 0 100%;
        }

        @include media-breakpoint-for(tab-landscape) {
          display: block;
          padding: 0px 30px;
          position: relative;
          top: unset;
          display: block;
          right: unset;
          height: 500px;
          width: 100%;
          max-width: 100%;
          flex: 0 0 100%;
        }

        &::before {
          content: '';
          background-image: url('../img/fff-leaf-r-green.svg');
          background-repeat: no-repeat;
          position: absolute;
          height: 30px;
          width: 64px;
          z-index: 1;
          bottom: 60px;
          right: -30px;
          background-size: contain;

          @include media-breakpoint-for(mobile) {
            height: 20px;
            width: 45px;
            bottom: 60px;
            right: 12px;
          }

          @include media-breakpoint-for(tab) {
            height: 30px;
            width: 64px;
            bottom: 60px;
            right: 0px;
          }

          @include media-breakpoint-for(tab-landscape) {
            height: 30px;
            width: 64px;
            bottom: 60px;
            right: 0px;
          }
        }

        .img-container {
          position: relative;
          background-size: cover;
          background-repeat: no-repeat;
          width: 100%;
          height: 100%;

          @include media-breakpoint-for(mobile) {
            top: 0px;
            -webkit-transform: none;
            transform: none;
            height: 100%;
            max-height: none;
            width: 100%;
          }

          @include media-breakpoint-for(tab) {
            top: 0px;
            -webkit-transform: none;
            transform: none;
            height: 100%;
            max-height: none;
            width: 100%;
          }

          @include media-breakpoint-for(tab-landscape) {
            top: 0px;
            -webkit-transform: none;
            transform: none;
            height: 100%;
            max-height: none;
            width: 100%;
          }
        }
      }

      .green-box {
        height: auto;
        background-color: $fff-brand-new-fill;
        margin: 0px;

        @include media-breakpoint-for(mobile) {
          height: auto;
          padding: 30px 0px;
          width: 100%;
        }

        @include media-breakpoint-for(tab) {
          height: auto;
          padding: 30px 0px;
          width: 100%;
        }

        @include media-breakpoint-for(tab-landscape) {
          height: auto;
          padding: 30px 0px;
          width: 100%;
        }

        .green-box-inside {
          margin: 0 auto;
          padding: 0px;

          @include media-breakpoint-for(mobile) {
            margin: 0px;
            padding: 0px 15px;
          }

          @include media-breakpoint-for(tab) {
            margin: 0px;
            padding: 0px 15px;
            max-width: 100%;
            flex: 0 0 100%;
          }

          @include media-breakpoint-for(tab-landscape) {
            margin: 0px;
            padding: 0px 15px;
            max-width: 100%;
            flex: 0 0 100%;
          }

          .text-col {
            text-align: center;
            position: relative;
            margin-bottom: 0px;
            display: inline-block;
            padding: 30px 15px 30px 0px;
            float: left;

            @include media-breakpoint-for(mobile) {
              display: block;
              text-align: center;
              padding: 0px;
            }

            @include media-breakpoint-for(tab) {
              display: block;
              text-align: center;
              padding: 0px;
              max-width: 100%;
              flex: 0 0 100%;
            }

            @include media-breakpoint-for(tab-landscape) {
              display: block;
              text-align: center;
              padding: 0px;
              max-width: 100%;
              flex: 0 0 100%;
            }

            .number-circle {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              text-align: center;
              font-size: 28px;
              font-family: $fff-font-sans;
              font-weight: 700;
              color: white;
              background-color: $fff-brand-new-primary;
              margin: 0 auto;
              display: block;
              margin-bottom: 24px;
              line-height: 40px;

              @include media-breakpoint-for(mobile) {
                width: 36px;
                height: 36px;
                font-size: 22px;
                line-height: 36px;
              }

              @include media-breakpoint-for(tab) {
                width: 36px;
                height: 36px;
                font-size: 22px;
                line-height: 36px;
              }

              @include media-breakpoint-for(tab-landscape) {
                width: 36px;
                height: 36px;
                font-size: 22px;
                line-height: 36px;
              }
            }

            h2 {
              font-size: 20px;
              color: black;
              font-family: $fff-font-sans;
              font-weight: 400;
              margin-bottom: 12px;

              @include media-breakpoint-for(mobile) {
                font-size: 18px;
              }

              @include media-breakpoint-for(tab) {
                font-size: 18px;
              }

              @include media-breakpoint-for(tab-landscape) {
                font-size: 18px;
              }
            }

            p {
              font-size: 16px;
              text-align: center;
              font-family: $fff-font-sans;

              @include media-breakpoint-for(mobile) {
                font-size: 14px;
              }

              @include media-breakpoint-for(tab) {
                font-size: 14px;
              }

              @include media-breakpoint-for(tab-landscape) {
                font-size: 14px;
              }
            }

            a {
              color: $fff-brand-green;
            }
          }
        }
      }
    }
  }

  .steps-row-even {
    position: relative;
    margin: -55px 0px 0px;

    @include media-breakpoint-for(mobile) {
      margin: 0px 0px 0px;

      &::after {
        content: '';
        width: 26px;
        border-bottom: 2px dashed $fff-brand-yellow;
        position: absolute;
        bottom: -18px;
        height: 5px;
        z-index: 1;
        left: 50%;
        -ms-transform: rotate(90deg) rotate(90deg);
        -webkit-transform: translate(-50%, 0%) rotate(90deg);
        transform: translate(-50%, 0%) rotate(90deg);
      }

      &:last-of-type {
        &::after {
          display: none;
        }
      }
    }

    @include media-breakpoint-for(tab) {
      margin: 0px 0px 0px;

      &::after {
        content: '';
        width: 26px;
        border-bottom: 2px dashed $fff-brand-yellow;
        position: absolute;
        bottom: -18px;
        height: 5px;
        z-index: 1;
        left: 50%;
        -ms-transform: rotate(90deg) rotate(90deg);
        -webkit-transform: translate(-50%, 0%) rotate(90deg);
        transform: translate(-50%, 0%) rotate(90deg);
      }

      &:last-of-type {
        &::after {
          display: none;
        }
      }
    }

    @include media-breakpoint-for(tab-landscape) {
      margin: 0px 0px 0px;

      &::after {
        content: '';
        width: 26px;
        border-bottom: 2px dashed $fff-brand-yellow;
        position: absolute;
        bottom: -18px;
        height: 5px;
        z-index: 1;
        left: 50%;
        -ms-transform: rotate(90deg) rotate(90deg);
        -webkit-transform: translate(-50%, 0%) rotate(90deg);
        transform: translate(-50%, 0%) rotate(90deg);
      }

      &:last-of-type {
        &::after {
          display: none;
        }
      }
    }

    &:first-of-type {
      margin: 0px;
    }

    &:last-of-type {
      .dotted-row {
        display: none;
      }
    }

    .dotted-row {
      background-repeat: no-repeat;
      background-position: top center;
      padding-bottom: 124px;
      background-image: url('../img/dotted-line-r-green.svg');
      -webkit-transform: rotateY(180deg);
      transform: rotateY(180deg);
      position: relative;
      top: -30px;

      @include media-breakpoint-for(mobile) {
        display: none;
      }

      @include media-breakpoint-for(tab) {
        display: none;
      }

      @include media-breakpoint-for(tab-landscape) {
        display: none;
      }
    }

    .steps-row-inside {
      position: relative;
      padding: 30px 0px;

      @include media-breakpoint-for(mobile) {
        padding: 30px 0px 0px;
      }

      @include media-breakpoint-for(tab) {
        padding: 30px 0px 0px;
      }

      @include media-breakpoint-for(tab-landscape) {
        padding: 30px 0px 0px;
      }

      .img-col {
        text-align: left;
        display: inline-block;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 8.33333%;
        padding: 0px 15px 0px 0px;

        @include media-breakpoint-for(mobile) {
          display: block;
          padding: 0px 30px;
          position: relative;
          top: unset;
          display: block;
          left: unset;
          height: 300px;
          width: 100%;
        }

        @include media-breakpoint-for(tab) {
          display: block;
          padding: 0px 30px;
          position: relative;
          top: unset;
          display: block;
          left: unset;
          height: 500px;
          width: 100%;
          max-width: 100%;
          flex: 0 0 100%;
        }

        @include media-breakpoint-for(tab-landscape) {
          display: block;
          padding: 0px 30px;
          position: relative;
          top: unset;
          display: block;
          left: unset;
          height: 500px;
          width: 100%;
          max-width: 100%;
          flex: 0 0 100%;
        }

        &::before {
          content: '';
          background-image: url('../img/fff-leaf-r-green.svg');
          background-repeat: no-repeat;
          position: absolute;
          height: 30px;
          width: 64px;
          z-index: 1;
          top: 60px;
          left: -30px;
          background-size: contain;

          @include media-breakpoint-for(mobile) {
            height: 20px;
            width: 45px;
            top: 52px;
            left: 12px;
          }

          @include media-breakpoint-for(tab) {
            height: 30px;
            width: 64px;
            top: 52px;
            left: 0px;
          }

          @include media-breakpoint-for(tab-landscape) {
            height: 30px;
            width: 64px;
            top: 52px;
            left: 0px;
          }
        }

        .img-container {
          position: relative;
          background-size: cover;
          background-repeat: no-repeat;
          width: 100%;
          height: 100%;

          @include media-breakpoint-for(mobile) {
            top: 0px;
            -webkit-transform: none;
            transform: none;
            height: 100%;
            max-height: none;
            width: 100%;
          }

          @include media-breakpoint-for(tab) {
            top: 0px;
            -webkit-transform: none;
            transform: none;
            height: 100%;
            max-height: none;
            width: 100%;
          }

          @include media-breakpoint-for(tab-landscape) {
            top: 0px;
            -webkit-transform: none;
            transform: none;
            height: 100%;
            max-height: none;
            width: 100%;
          }
        }
      }

      .green-box {
        height: auto;
        background-color: $fff-brand-new-fill;
        margin: 0px;

        @include media-breakpoint-for(mobile) {
          height: auto;
          padding: 30px 0px;
          width: 100%;
        }

        @include media-breakpoint-for(tab) {
          height: auto;
          padding: 30px 0px;
          width: 100%;
        }

        @include media-breakpoint-for(tab-landscape) {
          height: auto;
          padding: 30px 0px;
          width: 100%;
        }

        .green-box-inside {
          margin: 0 auto;
          padding: 0px;

          @include media-breakpoint-for(mobile) {
            margin: 0px;
            padding: 0px 15px;
          }

          @include media-breakpoint-for(tab) {
            margin: 0px;
            padding: 0px 15px;
            max-width: 100%;
            flex: 0 0 100%;
          }

          @include media-breakpoint-for(tab-landscape) {
            margin: 0px;
            padding: 0px 15px;
            max-width: 100%;
            flex: 0 0 100%;
          }

          .text-col {
            text-align: center;
            position: relative;
            margin-bottom: 0px;
            display: inline-block;
            padding: 30px 0px 30px 15px;
            float: right;

            @include media-breakpoint-for(mobile) {
              display: block;
              text-align: center;
              padding: 0px;
            }

            @include media-breakpoint-for(tab) {
              display: block;
              text-align: center;
              padding: 0px;
              max-width: 100%;
              flex: 0 0 100%;
            }

            @include media-breakpoint-for(tab-landscape) {
              display: block;
              text-align: center;
              padding: 0px;
              max-width: 100%;
              flex: 0 0 100%;
            }

            .number-circle {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              text-align: center;
              font-size: 28px;
              font-family: $fff-font-sans;
              font-weight: 700;
              color: white;
              background-color: $fff-brand-new-primary;
              margin: 0 auto;
              display: block;
              margin-bottom: 24px;
              line-height: 40px;

              @include media-breakpoint-for(mobile) {
                width: 36px;
                height: 36px;
                font-size: 22px;
                line-height: 36px;
              }

              @include media-breakpoint-for(tab) {
                width: 36px;
                height: 36px;
                font-size: 22px;
                line-height: 36px;
              }

              @include media-breakpoint-for(tab-landscape) {
                width: 36px;
                height: 36px;
                font-size: 22px;
                line-height: 36px;
              }
            }

            h2 {
              font-size: 20px;
              color: black;
              font-family: $fff-font-sans;
              font-weight: 400;
              margin-bottom: 12px;

              @include media-breakpoint-for(mobile) {
                font-size: 18px;
              }

              @include media-breakpoint-for(tab) {
                font-size: 18px;
              }

              @include media-breakpoint-for(tab-landscape) {
                font-size: 18px;
              }
            }

            p {
              font-size: 16px;
              text-align: center;
              font-family: $fff-font-sans;

              @include media-breakpoint-for(mobile) {
                font-size: 14px;
              }

              @include media-breakpoint-for(tab) {
                font-size: 14px;
              }

              @include media-breakpoint-for(tab-landscape) {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }

  // Static Testimonials section
  .three-col-row {
    display: flex;
    flex-wrap: wrap;
    row-gap: 27px;
  }

  .col-three-row {
    &.hide-mobile {
      @media screen and (max-width: 1024px) {
        display: none;
      }
    }

    &.justify-center {
      justify-content: center;
    }

    @media screen and (max-width: 1024px) {
      margin: 0;
    }

    .col-md2 {
      @media screen and (max-width: 1024px) {
        display: none;
      }
    }

    .col-md-4 {
      margin-top: 30px;

      &:nth-child(1) {
        margin-top: 0px;
      }

      &:nth-child(2),
      &:nth-child(3) {        
        margin-top: 30px;

        @include breakpoint(desktop-up) {
          margin-top: 0px;
        }
      }

      @media screen and (max-width: 1024px) {
        margin-top: 30px;
        max-width: 100%;
        flex: 0 0 100%;
      }
    }

    .three-col {

      &.adding-flex {
        display: flex;
        flex-direction: column;
        height: 100%;

        .item-text {
          flex-grow: 1;
          @include breakpoint(tab-landscape-up) {
            min-height: calc(100% - 300px);
          }
          @include breakpoint(tab-up) {
            padding-bottom: calc(30px + 87px) !important;
          }

          .text-bottom {
            @include breakpoint(tab-up) {
              position: absolute;
              bottom: 30px;
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
      }

      &.yellow-leaf-left {
        &::after {
          content: '';
          position: absolute;
          top: 0px;
          left: 15px;
          right: 15px;
          background-color: white;
          height: 285px;

          @include breakpoint(tab-up) {
            height: 655px;
          }

          @include breakpoint(desktop-up) {
            height: 270px;
          }
        }

        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          top: 0;
          left: 15px;
          right: 15px;
          background-color: $fff-brand-new-fill;
        }

        .item-image {
          padding: 0px;
          position: relative;
          width: 100%;
          z-index: 1;
          height: 300px;
          background-color: white;

          @include breakpoint(tab-up) {
            height: 690px;
          }

          @include breakpoint(desktop-up) {
            height: 300px;
          }

          &::before {
            background-image: url('../../assets/img/fff-leaf-ml.svg');
            background-repeat: no-repeat;
            background-position: bottom left;
            -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
            position: absolute;
            z-index: 1;
            bottom: -12px;
            left: 36px;
            background-size: contain;
            background-position: center;
            width: 40px;
            height: 22px;
            
            @include breakpoint(desktop-up) {
              width: 50px;
              height: 28px;
            }
          }

          .item-img-container {
            height: 100%;
            width: 100%;
            background-size: cover;
            background-position: top center;
            background-repeat: no-repeat;
          }
        }

        .item-text {
          height: auto;
          z-index: 1;
          position: relative;
          padding: 15px 15px 15px;
          
          @include breakpoint(desktop-up) {
            padding: 30px 30px 30px;
          }

          .description {
            font-size: 14px;
            text-align: center;

            p {
              font-size: 14px;

              &:last-of-type {
                margin-bottom: 0px;
              }
            }
          }

          .text-bottom {
            margin-top: 15px;
            text-align: center;

            p {
              margin-bottom: 0px;
              strong {
              font-family: $fff-font-sans;
              font-weight: bold;
              }
            }
          }
        }
      }

      &.bottom-line {
        @include breakpoint(tab-landscape-up) {
          margin-top: 60px;
        }

        &::after {
          content: '';
          position: absolute;
          top: 0px;
          left: 15px;
          right: 15px;
          background-color: white;
          height: 315px;
          @include breakpoint(desktop-up) {
            height: 270px;
          }
        }

        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          top: 0;
          left: 15px;
          right: 15px;
          background-color: $fff-brand-new-fill;
        }

        .item-image {
          padding: 0px 30px;
          position: relative;
          height: 300px;
          width: 100%;
          z-index: 1;
          background-color: white;

          &::before {
            content: '';
            position: absolute;
            bottom: 0;
            background-position: center;
            border-bottom: 1px solid #000;
            height: 2px;
            left: 15px;
            right: 15px;
            @include breakpoint(desktop-up) {
              left: 30px;
              right: 30px;
            }
          }

          .item-img-container {
            height: 100%;
            width: 100%;
            background-size: contain;
            background-position: top center;
            background-repeat: no-repeat;
          }
        }

        .item-text {
          margin-top: -30px;
          height: auto;
          z-index: 1;
          position: relative;
          margin-bottom: 0px;
          padding: 40px 15px 15px;
          
          @include breakpoint(desktop-up) {
            margin-bottom: auto;
            padding: 40px 30px 30px;
          }

          .description {
            font-size: 14px;
            text-align: center;
            @include breakpoint(tab-up) {
              text-align: left;
            }

            p {
              font-size: 14px;

              &:last-of-type {
                margin-bottom: 0px;
              }
            }
          }

          .text-top {
            margin-bottom: 15px;
            text-align: center;
            @include breakpoint(tab-up) {
              text-align: left;
            }

            &::before {
              background-image: url('../../assets/img/fff-leaf-s.svg');
              background-repeat: no-repeat;
              background-position: bottom left;
              position: absolute;
              -webkit-transform: rotate(132deg);
              transform: rotate(132deg);
              width: 75px;
              height: 40px;
              background-size: contain;
              padding-top: 10px;
              left: 10px;
              
              @include breakpoint(desktop-up) {
                left: 15px;
              }
            }

            h2 {
              font-family: $fff-font-sans;
              font-weight: 700;
              color: black;
              font-size: 20px;
              padding-left: 20px;
              margin-bottom: 3px;
              z-index: 1;
              position: relative;
            }

            h3 {
              font-family: $fff-font-sans;
              font-weight: 700;
              color: $fff-brand-new-primary;
              font-size: 16px;
              padding-left: 20px;
              margin-bottom: 0px;
              z-index: 1;
              position: relative;
            }
          }
        }
      }

      &.green-leaf-left {
        &::after {
          content: '';
          position: absolute;
          height: 270px;
          top: 0px;
          left: 15px;
          right: 15px;
          background-color: white;

          @include media-breakpoint-for(mobile) {
            height: 270px;
          }

          @include media-breakpoint-for(tab) {
            height: 630px;
          }

          @include media-breakpoint-for(tab-landscape) {
            height: 630px;
          }
        }

        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          top: 0;
          left: 15px;
          right: 15px;
          background-color: rgba($fff-brand-dark-grey, 0.08);
        }

        .item-image {
          padding: 0px 30px;
          position: relative;
          height: 300px;
          width: 100%;
          z-index: 1;

          @include media-breakpoint-for(mobile) {
            padding: 0px 30px;
          }

          @include media-breakpoint-for(tab) {
            padding: 0px 30px;
            height: 690px;
          }

          @include media-breakpoint-for(tab-landscape) {
            padding: 0px 30px;
            height: 690px;
          }

          &::before {
            content: '';
            background-image: url('../../assets/img/fff-leaf-ml-green.svg');
            background-repeat: no-repeat;
            background-position: bottom left;
            -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
            position: absolute;
            width: 50px;
            height: 28px;
            z-index: 1;
            bottom: -12px;
            left: 36px;
            background-size: contain;
            background-position: center;

            @include media-breakpoint-for(mobile) {
              width: 35px;
              height: 16px;
            }

            @include media-breakpoint-for(tab) {
              width: 35px;
              height: 16px;
            }

            @include media-breakpoint-for(tab-landscape) {
              width: 35px;
              height: 16px;
            }
          }

          .item-img-container {
            height: 100%;
            width: 100%;
            background-size: cover;
            background-position: top center;
            background-repeat: no-repeat;
          }
        }

        .item-text {
          margin-top: -30px;
          height: auto;
          text-align: center;
          position: relative;
          z-index: 1;
          padding: 60px 15px 30px;
          
          @include breakpoint(desktop-up) {
            padding: 60px 30px 30px;
          }

          .description {
            font-size: 14px;
            text-align: center;
            @include breakpoint(tab-up) {
              text-align: left;
            }

            p {
              font-size: 14px;

              &:last-of-type {
                margin-bottom: 0px;
              }
            }
          }

          .text-top {
            margin-bottom: 15px;
            text-align: center;

            h2 {
              font-family: 'DINPro-Bold', sans-serif;
              color: black;
              font-weight: 300;
              font-size: 20px;
              margin-bottom: 3px;
              position: relative;
            }

            h3 {
              font-family: 'Soleil-Bold', sans-serif;
              color: $fff-brand-green;
              font-weight: 300;
              font-size: 16px;
              margin-bottom: 0px;
              position: relative;
            }
          }

          .text-bottom {
            p {
              font-family: "Soleil-Bold", sans-serif;

              &.color-green {
                min-height: 48px;

                @include media-breakpoint-for(mobile) {
                  min-height: unset;
                }

                @include media-breakpoint-for(tab) {
                  min-height: unset;
                }

                @include media-breakpoint-for(tab-landscape) {
                  min-height: unset;
                }
              }
            }
          }
        }
      }

      &.yellow-leaf-right-full-img {
        &::after {
          content: '';
          position: absolute;
          height: 270px;
          top: 0px;
          left: 15px;
          right: 15px;
          background-color: white;

          @include media-breakpoint-for(mobile) {
            height: 300px;
          }

          @include media-breakpoint-for(tab) {
            height: 300px;
          }

          @include media-breakpoint-for(tab-landscape) {
            height: 300px;
          }
        }

        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          top: 0;
          left: 15px;
          right: 15px;
          background-color: $fff-brand-new-fill;
        }

        .item-image {
          padding: 0px;
          position: relative;
          height: 300px;
          width: 100%;
          z-index: 1;

          &::before {
            background-image: url('../../assets/img/fff-leaf-s.svg');
            background-repeat: no-repeat;
            background-position: bottom left;
            -webkit-transform: rotate(132deg);
            transform: rotate(132deg);
            position: absolute;
            width: 50px;
            height: 28px;
            z-index: 1;
            bottom: -12px;
            right: 0px;
            background-size: contain;
            background-position: center;

            @include media-breakpoint-for(mobile) {
              width: 40px;
              height: 22px;
            }

            @include media-breakpoint-for(tab) {
              width: 40px;
              height: 22px;
            }

            @include media-breakpoint-for(tab-landscape) {
              width: 40px;
              height: 22px;
            }
          }

          .item-img-container {
            height: 100%;
            width: 100%;
            background-size: cover;
            background-position: top center;
            background-repeat: no-repeat;
          }
        }

        .item-text {
          padding: 30px;
          height: auto;
          text-align: center;
          position: relative;
          z-index: 1;

          @include media-breakpoint-for(mobile) {
            padding: 15px;
          }

          @include media-breakpoint-for(tab) {
            padding: 15px;
          }

          @include media-breakpoint-for(tab-landscape) {
            padding: 15px;
          }

          .description {
            font-size: 14px;
            text-align: center;

            p {
              font-size: 14px;
              text-align: center;

              &:last-of-type {
                margin-bottom: 0px;
              }
            }
          }

          .text-top {
            margin-bottom: 15px;
            text-align: center;

            h2 {
              font-family: $fff-font-sans;
              color: black;
              font-weight: 700;
              font-size: 20px;
              margin-bottom: 3px;
              position: relative;
            }

            h3 {
              font-family: 'Soleil-Bold', sans-serif;
              color: $fff-brand-green;
              font-weight: 300;
              font-size: 16px;
              margin-bottom: 0px;
              position: relative;
            }
          }
        }
      }

      &.green-leaf-right-yellow-bg {
        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          top: 0;
          left: 15px;
          right: 15px;
          background-color: $fff-brand-new-fill;

          @include media-breakpoint-for(mobile) {
            display: none;
          }

          @include media-breakpoint-for(tab) {
            display: none;
          }

          @include media-breakpoint-for(tab-landscape) {
            display: none;
          }
        }

        h2 {
          padding: 30px 40px;
          margin-bottom: 0px;
          font-family: $fff-font-sans;
          color: black;
          font-weight: 700;
          font-size: 28px;
          z-index: 1;
          position: relative;

          @include media-breakpoint-for(mobile) {
            font-size: 22px;
            padding: 15px;
          }

          @include media-breakpoint-for(tab) {
            font-size: 22px;
            padding: 15px;
          }

          @include media-breakpoint-for(tab-landscape) {
            font-size: 22px;
            padding: 15px;
          }
        }

        .item-image {
          padding: 0;
          position: relative;
          height: auto;
          width: 100%;
          z-index: 1;

          @include media-breakpoint-for(mobile) {
            padding: 0px 0px 15px;
          }

          @include media-breakpoint-for(tab) {
            padding: 0px 0px 15px;
          }

          @include media-breakpoint-for(tab-landscape) {
            padding: 0px 0px 15px;
          }

          .item-img-container {
            height: 160px;
            width: 160px;
            background-size: cover;
            background-position: top center;
            background-repeat: no-repeat;
            margin: 0 auto;
          }
        }

        .item-text {
          padding: 30px;
          height: auto;
          text-align: center;
          z-index: 1;
          position: relative;

          @include media-breakpoint-for(mobile) {
            padding: 15px;
            background-color: $fff-brand-new-fill;
            z-index: unset;
          }

          @include media-breakpoint-for(tab) {
            padding: 15px;
            background-color: $fff-brand-new-fill;
            z-index: unset;
          }

          @include media-breakpoint-for(tab-landscape) {
            padding: 15px;
            background-color: $fff-brand-new-fill;
            z-index: unset;
          }

          h2 {
            background: transparent;
          }

          .description {
            font-size: 14px;
            text-align: left;

            p {
              font-size: 14px;
              text-align: left;

              &:last-of-type {
                margin-bottom: 0px;
              }
            }

            ul {
              padding-left: 0px;
              margin-top: 15px;

              li {
                list-style: none;
                font-family: $fff-font-sans;
                background-image: url('../../assets/img/fff-bullet-r-green.svg');
                background-repeat: no-repeat;
                background-position: left top;
                background-size: 22px;
                padding-left: 30px;
                font-size: 16px;
                font-weight: 400;
                color: $fff-brand-grey;
                margin-bottom: 8px;
                text-align: left;

                @include media-breakpoint-for(mobile) {
                  background-position: left 0px top 0;
                }

                @include media-breakpoint-for(tab) {
                  background-position: left 0px top 0;
                }

                @include media-breakpoint-for(tab-landscape) {
                  background-position: left 0px top 0;
                }

                &:last-of-type {
                  margin-bottom: 0px;
                }
              }
            }
          }

          .text-top {
            margin-bottom: 15px;
            text-align: center;

            h2 {
              font-family: $fff-font-sans;
              color: black;
              font-weight: 700;
              font-size: 20px;
              margin-bottom: 3px;
              position: relative;
              padding: 0px;

              @include media-breakpoint-for(mobile) {
                font-size: 18px;
              }

              @include media-breakpoint-for(tab) {
                font-size: 18px;
              }

              @include media-breakpoint-for(tab-landscape) {
                font-size: 18px;
              }
            }

            h3 {
              font-family: 'Soleil-Bold', sans-serif;
              color: $fff-brand-green;
              font-weight: 300;
              font-size: 16px;
              margin-bottom: 0px;
              position: relative;
            }
          }
        }
      }

      &.yellow-leaf-right {
        margin: 0px;

        @include media-breakpoint-for(mobile) {
          margin-bottom: 0px;
        }

        @include media-breakpoint-for(tab) {
          margin-bottom: 0px;
        }

        @include media-breakpoint-for(tab-landscape) {
          margin-bottom: 0px;
        }

        .item-image {
          padding: 30px;
          position: relative;
          height: 220px;
          width: 100%;

          @include media-breakpoint-for(mobile) {
            padding: 0px 30px 15px;
          }

          @include media-breakpoint-for(tab) {
            padding: 0px 30px 15px;
          }

          @include media-breakpoint-for(tab-landscape) {
            padding: 0px 30px 15px;
          }

          .item-img-container {
            height: 100%;
            width: 100%;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
          }
        }

        .item-text {
          height: auto;
          text-align: center;
          padding: 15px;
          
          @include breakpoint(desktop-up) {
            padding: 30px;
          }

          .description {
            font-size: 14px;
            text-align: center;
            @include breakpoint(tab-up) {
              text-align: left;
            }
            p {
              font-size: 14px;
              text-align: left;

              &:last-of-type {
                margin-bottom: 0px;
              }
            }
          }

          .text-top {
            margin-bottom: 15px;
            text-align: center;

            h2 {
              font-family: $fff-font-sans;
              color: black;
              font-weight: 700;
              font-size: 20px;
              margin-bottom: 3px;
              position: relative;
            }

            h3 {
              font-family: 'Soleil-Bold', sans-serif;
              color: $fff-brand-green;
              font-weight: 300;
              font-size: 16px;
              margin-bottom: 0px;
              position: relative;
            }
          }
        }
      }
    }
  }

  .read-more {
    color: $fff-brand-green;
    font-size: 16px;
    display: block;
    text-align: center;
    text-decoration: none;
    margin-top: 40px;

    @media (max-width: 900px) {
      margin-bottom: 2rem;
    }
  }

  .col-full-row {
    margin: 0;

    >div {
      @include media-breakpoint-for(tab) {
        max-width: 100%;
        flex: 0 0 100%;
      }

      @include media-breakpoint-for(tab-landscape) {
        max-width: 100%;
        flex: 0 0 100%;
      }
    }

    .full-col {
      &.green-leaf-full-width {
        .item-image-auto {
          padding: 0px;
          position: relative;
          height: 247px;
          width: 100%;

          @include media-breakpoint-for(mobile) {
            padding: 0px 30px;
            height: 86px;
          }

          @include media-breakpoint-for(tab) {
            padding: 0px 30px;
            height: 86px;
          }

          @include media-breakpoint-for(tab-landscape) {
            padding: 0px 30px;
            height: 86px;
          }

          &::before {
            background-image: url('../../assets/img/fff-leaf-ml-green.svg');
            background-repeat: no-repeat;
            -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
            position: absolute;
            width: 50px;
            height: 28px;
            z-index: 1;
            bottom: -12px;
            left: 36px;
            background-size: contain;
            background-position: center;

            @include media-breakpoint-for(mobile) {
              width: 35px;
              height: 16px;
            }

            @include media-breakpoint-for(tab) {
              width: 35px;
              height: 16px;
            }

            @include media-breakpoint-for(tab-landscape) {
              width: 35px;
              height: 16px;
            }
          }

          .item-img-container {
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            position: absolute;
            top: 0px;
            bottom: 0px;
            left: 30px;
            right: 30px;
            background-color: white;
          }
        }

        .item-text {
          margin-top: -30px;
          padding: 60px 30px 30px;
          background-color: $fff-brand-new-fill;
          height: auto;
          text-align: left;

          @include media-breakpoint-for(mobile) {
            padding: 60px 15px 15px;
          }

          @include media-breakpoint-for(tab) {
            padding: 60px 15px 15px;
          }

          @include media-breakpoint-for(tab-landscape) {
            padding: 60px 15px 15px;
          }

          .description {
            font-size: 14px;
            text-align: left;

            p {
              font-size: 14px;

              &:last-of-type {
                margin-bottom: 0px;
              }
            }
          }
        }
      }
    }
  }

  .request-box-row {
    margin: 0px;
    padding: 60px 0px;

    .request-box-inside {
      margin: 0 auto;

      @include media-breakpoint-for(mobile) {
        display: grid;
        text-align: center;
      }

      @media screen and (max-width: 1200px) {
        padding: 30px;
      }

      a {
        @media screen and (max-width: 1200px) {
          width: 100%;
          display: block;
          margin: 0 auto !important;

          button {
            width: 100%;
            display: block;
          }
        }
      }

      button {
        @media screen and (max-width: 1200px) {
          width: 100%;
          display: block;

          &:first-of-type {
            margin-bottom: 15px;
          }
        }
      }
    }
  }

  .request-box-inside {

    @include media-breakpoint-for(mobile) {
      display: grid;
      text-align: center;
      row-gap: 1rem;
    }
  }

  .image-grid-layout {
    margin: 0px;
    position: relative;

    .img-lg-right {
      padding: 0px;
      height: 315px;
      
      @include breakpoint(desktop-up) {
        height: 715px;
      }
    }

    .img-md-left {
      height: 300px;
      padding: 0px;

      &.bottom-margin {
        margin-bottom: 115px;
      }
    }

    .image {
      padding: 0;
      width: 100%;
      height: 100%;
      display: inline-block;
      background-size: cover;
    }

    .middle-line {
      margin: 0px;
      background-color: $fff-brand-yellow;
      height: 120px;
      position: absolute;
      top: 300px;
      left: 0px;
      right: 0px;
      vertical-align: middle;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  // FAQ section
  .faq-menu {
    display: block;

    ul {
      padding-left: 0px;

      li {
        display: inline-block;
        font-family: 'Soleil-Bold', sans-serif;
        color: rgba($fff-brand-grey, 0.5);

        a {
          color: $fff-brand-new-primary;
          font-family: $fff-font-sans;
          font-weight: 700;

          &:hover {
            text-decoration: none;
          }
        }

        &::after {
          content: '';
          width: 5px;
          height: 5px;
          display: inline-block;
          background-color: rgba($fff-brand-grey, 0.16);
          margin: 1px 10px;
          border-radius: 50%;
        }

        &:first-child,
        &:last-child {
          padding-right: 10px;

          &::after {
            display: none;
          }
        }
      }
    }
  }

  // Contact section
  .contact-row {
    @include media-breakpoint-for(mobile) {
      margin: 0px;
    }

    @include media-breakpoint-for(tab) {
      margin: 0px;
    }

    @include media-breakpoint-for(tab-landscape) {
      margin: 0px;
    }

    .col-md-4 {
      @include media-breakpoint-for(tab) {
        max-width: 100%;
        flex: 0 0 100%;
      }

      @include media-breakpoint-for(tab-landscape) {
        max-width: 100%;
        flex: 0 0 100%;
      }
    }

    .contact-detail-col {
      height: calc(100% - 30px);
      padding: 30px;
      background-color: $fff-brand-new-fill;
      margin-bottom: 30px;

      @include media-breakpoint-for(mobile) {
        margin-bottom: 15px;
        height: auto;
      }

      @include media-breakpoint-for(tab) {
        margin-bottom: 15px;
        height: auto;
      }

      @include media-breakpoint-for(tab-landscape) {
        margin-bottom: 15px;
        height: auto;
      }

      h2 {
        margin-bottom: 20px;
        font-size: 20px;
        color: black;
        display: block;
        font-family: $fff-font-sans;
        font-weight: 700;
      }

      a {
        color: $fff-brand-new-primary;
        font-size: 16px;
        display: block;
        font-family: $fff-font-sans;
        margin-bottom: 10px;

        &:last-of-type {
          margin-bottom: 0px;
        }
      }
    }
    .social-media {
      a {
        display: inline-block;
        font-size: 24px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: $fff-brand-new-light;
        margin: 0 5px;
        line-height: 45px;
        text-align: center;

        &:hover {
          box-shadow: 0px 4px 8px rgba($fff-brand-green, 0.16);
        }
      }
    }
  }

  // about section
  &.pricing-page-three-column {
    p {
      font-size: 14px;
    }

    .container {
      @include media-breakpoint-for(mobile) {
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }

    .how-it-box {
      img {
        max-height: 130px !important;
      }
    }
  }

  &.about-us-three-columns {
    .container {
      @include media-breakpoint-for(mobile) {
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }

    .how-it-box {
      p {
        padding-right: 15px;
        padding-left: 15px;
      }
    }
  }

  .img-overlap-row {
    margin: 0px;
    position: relative;
    background-color: $fff-brand-new-fill;
    padding: 60px;

    @include media-breakpoint-for(mobile) {
      padding: 0px;

      h2 {
        margin-bottom: 15px;
      }
    }

    @include media-breakpoint-for(tab) {
      padding: 0px;

      h2 {
        margin-bottom: 15px;
      }
    }

    @include media-breakpoint-for(tab-landscape) {
      padding: 0px;

      h2 {
        margin-bottom: 15px;
      }
    }

    .img-col {
      padding: 0px;
      height: 300px;

      @include media-breakpoint-for(mobile) {
        margin: 0px;
        height: 360px;
      }

      @include media-breakpoint-for(tab) {
        margin: 0px;
        height: 650px;
        max-width: 100%;
        flex: 0 0 100%;
      }

      @include media-breakpoint-for(tab-landscape) {
        margin: 0px;
        height: 650px;
        max-width: 100%;
        flex: 0 0 100%;
      }

      .image {
        width: 100%;
        height: 100%;
        position: absolute;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        // top: 50%;
        // left: 0%;
        // -webkit-transform: translate(0%, -50%);
        // transform: translate(0%, -50%);
        top: 50%;
        left: 50%;
        -webkit-transform: translate(0%, -50%);
        transform: translate(-50%, -50%);
        z-index: 1;

        @include media-breakpoint-for(mobile) {
          position: relative;
          top: 0px;
          left: 0px;
          transform: unset;
          width: 100%;
          height: 100%;
          display: block;
          z-index: 1;
        }

        @include media-breakpoint-for(tab) {
          position: relative;
          top: 0px;
          left: 0px;
          transform: unset;
          width: 100%;
          height: 100%;
          display: block;
          z-index: 1;
        }

        @include media-breakpoint-for(tab-landscape) {
          position: relative;
          top: 0px;
          left: 0px;
          transform: unset;
          width: 100%;
          height: 100%;
          display: block;
          z-index: 1;
        }
      }
    }

    .description-col {
      @include media-breakpoint-for(mobile) {
        padding: 30px 15px;
      }

      @include media-breakpoint-for(tab) {
        padding: 30px 15px;
        max-width: 100%;
        flex: 0 0 100%;
      }

      @include media-breakpoint-for(tab-landscape) {
        padding: 30px 15px;
        max-width: 100%;
        flex: 0 0 100%;
      }

      .description-col-inside {
        position: absolute;
        top: 50%;
        left: 0%;
        -webkit-transform: translate(0%, -50%);
        transform: translate(0%, -50%);
        margin-left: 60px;

        @include media-breakpoint-for(mobile) {
          position: relative;
          top: unset;
          left: unset;
          -webkit-transform: unset;
          transform: unset;
          margin-left: 0px;
        }

        @include media-breakpoint-for(tab) {
          position: relative;
          top: unset;
          left: unset;
          -webkit-transform: unset;
          transform: unset;
          margin-left: 0px;
        }

        @include media-breakpoint-for(tab-landscape) {
          position: relative;
          top: unset;
          left: unset;
          -webkit-transform: unset;
          transform: unset;
          margin-left: 0px;
        }

        h2 {
          margin-bottom: 15px;
        }

        p {
          font-size: 16px;
          text-align: center;
          color: $fff-brand-dark-grey;

          &:last-of-type {
            margin-bottom: 0px;
          }
        }

        ol {
          margin-top: 20px;
          padding-left: 15px;

          li {
            font-size: 16px;
            text-align: left;
            color: $fff-brand-dark-grey;
            margin-bottom: 10px;

            &:last-of-type {
              margin-bottom: 0px;
            }
          }
        }
      }
    }
  }

  .img-overlap-grid-row {
    margin: 0;
    position: relative;

    @include media-breakpoint-for(mobile) {
      margin-bottom: 30px;

      &:last-of-type {
        margin-bottom: 0px;
      }
    }

    @include media-breakpoint-for(tab) {
      margin-bottom: 30px;

      &:last-of-type {
        margin-bottom: 0px;
      }
    }

    @include media-breakpoint-for(tab-landscape) {
      margin-bottom: 30px;

      &:last-of-type {
        margin-bottom: 0px;
      }
    }

    &:first-of-type {
      .grid-col-container {
        padding-top: 0;
      }
    }

    &:last-of-type {
      .img-col {
        margin-top: 0px;
      }

      .grid-col-container {
        padding-top: 0px;
        padding-bottom: 0;
      }
    }

    .img-col {
      margin: 60px 0px;
      padding: 0px;
      background-color: $fff-brand-new-fill;
      position: relative;

      @include media-breakpoint-for(mobile) {
        margin: 0px;
      }

      @include media-breakpoint-for(tab) {
        margin: 0px;
        max-width: 100%;
        flex: 0 0 100%;
      }

      @include media-breakpoint-for(tab-landscape) {
        margin: 0px;
        max-width: 100%;
        flex: 0 0 100%;
      }

      .middle-box {
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        position: relative;
        text-align: center;
        padding: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include media-breakpoint-for(mobile) {
          padding: 15px;
        }

        @include media-breakpoint-for(tab) {
          padding: 15px;
        }

        @include media-breakpoint-for(tab-landscape) {
          padding: 15px;
        }

        .image {
          display: block;
          margin: -20px auto 0;
          max-height: 130px;
        }

        h2 {
          font-size: 28px;
          color: black;
          margin-bottom: 0;
        }
      }
    }

    .grid-col-container {
      padding: 60px 30px;

      h3 {
        text-align: center;
        margin-bottom: 20px;
      }

      @include media-breakpoint-for(mobile) {
        padding: 30px 15px;
      }

      @include media-breakpoint-for(tab) {
        padding: 30px 15px;
        max-width: 100%;
        flex: 0 0 100%;
      }

      @include media-breakpoint-for(tab-landscape) {
        padding: 30px 15px;
        max-width: 100%;
        flex: 0 0 100%;
      }

      .grid-row {
        margin: 0px 0px 1px;

        .yellow-bg-col {
          background: $fff-brand-new-secondary;
          font-size: 28px;
          color: white;
          font-family: $fff-font-sans;
          font-weight: 700;
          padding: 14px;
          text-align: center;
          vertical-align: center;
        }

        .green-bg-col {
          background: $fff-brand-new-primary;
          font-size: 28px;
          color: white;
          font-family: $fff-font-sans;
          font-weight: 700;
          padding: 14px;
          text-align: center;
        }

        .shaded-yellow-bg-col {
          background: $fff-brand-new-fill;
          font-size: 16px;
          color: $fff-brand-new-secondary;
          font-family: $fff-font-sans;
          padding: 20px 30px;
          text-align: left;
        }

        .shaded-green-bg-col {
          background: $fff-brand-new-fill;
          font-size: 16px;
          color: $fff-brand-new-primary;
          font-family: $fff-font-sans;
          padding: 20px 30px;
          text-align: left;
        }
      }
    }
  }

  //change background to white

  .section-main {
    &>.white-detail-box-row:first-child {
      .light-green-box {
        margin-top: 30px !important;
      }
    }
  }

  .white-detail-box-row {
    .light-green-box {
      background-color: white !important;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
      padding-top: 15px !important;
      padding-bottom: 15px !important;
      margin: 20px 0 !important;

      @include media-breakpoint-for(mobile) {
        margin: 0px !important;
      }

      p {
        margin-bottom: 0;
      }

      &::before {
        width: 60px !important;
        top: -3px !important;

        @include media-breakpoint-for(mobile) {
          width: 31px !important;
        }
      }
    }

    &::before {
      content: none !important;
    }
  }

  // light-green-box
  .light-green-box-row {
    margin: 0;
    position: relative;

    @include media-breakpoint-for(mobile) {
      margin-bottom: 30px;
    }

    @include media-breakpoint-for(tab) {
      margin-bottom: 30px;
    }

    @include media-breakpoint-for(tab-landscape) {
      margin-bottom: 30px;
    }

    &.leaf-left {
      .light-green-box {
        &::before {
          content: '';
          background-image: url('../../assets/img/fff-leaf-r-green.svg');
          background-repeat: no-repeat;
          background-position: top left;
          -webkit-transform: rotate(-50deg);
          transform: rotate(-50deg);
          position: absolute;
          width: 75px;
          height: 35px;
          z-index: 1;
          top: 5px;
          left: 5px;
          background-size: contain;

          @include media-breakpoint-for(mobile) {
            width: 31px;
            height: 14px;
            top: -5px;
            left: 17px;
          }

          @include media-breakpoint-for(tab) {
            width: 31px;
            height: 14px;
            top: -5px;
            left: 17px;
          }

          @include media-breakpoint-for(tab-landscape) {
            width: 31px;
            height: 14px;
            top: -5px;
            left: 17px;
          }
        }
      }

      @include media-breakpoint-for(mobile) {
        &::after {
          content: '';
          width: 30px;
          border-bottom: 2px dashed rgba($fff-brand-grey, 0.18);
          position: absolute;
          bottom: -15px;
          height: 3px;
          z-index: 1;
          -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
          left: 22px;
        }

        &:last-of-type {
          &::after {
            display: none;
          }
        }
      }

      @include media-breakpoint-for(tab) {
        &::after {
          content: '';
          width: 30px;
          border-bottom: 2px dashed rgba($fff-brand-grey, 0.18);
          position: absolute;
          bottom: -15px;
          height: 3px;
          z-index: 1;
          -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
          left: 22px;
        }

        &:last-of-type {
          &::after {
            display: none;
          }
        }
      }

      @include media-breakpoint-for(tab-landscape) {
        &::after {
          content: '';
          width: 30px;
          border-bottom: 2px dashed rgba($fff-brand-grey, 0.18);
          position: absolute;
          bottom: -15px;
          height: 3px;
          z-index: 1;
          -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
          left: 22px;
        }

        &:last-of-type {
          &::after {
            display: none;
          }
        }
      }
    }

    &.leaf-right {
      margin-bottom: 30px;

      .light-green-box {
        @include media-breakpoint-for(mobile) {
          margin: 0px 30px;
          padding: 30px;
        }

        @include media-breakpoint-for(tab) {
          margin: 0px 30px;
          padding: 30px;
          flex: unset;
        }

        @include media-breakpoint-for(tab-landscape) {
          margin: 0px 30px;
          padding: 30px;
          flex: unset;
        }

        &::before {
          content: '';
          background-image: url('../../assets/img/fff-leaf-r-green.svg');
          background-repeat: no-repeat;
          background-position: top left;
          position: absolute;
          width: 65px;
          height: 30px;
          z-index: 1;
          bottom: 30px;
          right: -30px;
          background-size: contain;

          @include media-breakpoint-for(mobile) {
            width: 35px;
            height: 18px;
            bottom: 30px;
            right: -15px;
          }

          @include media-breakpoint-for(tab) {
            width: 35px;
            height: 18px;
            bottom: 30px;
            right: -15px;
          }

          @include media-breakpoint-for(tab-landscape) {
            width: 35px;
            height: 18px;
            bottom: 30px;
            right: -15px;
          }
        }
      }
    }

    &.block-1 {
      &::before {
        content: '';
        -webkit-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
        background-repeat: repeat-y;
        background-position: top right -5px;
        background-size: contain;
        background-image: url(../../assets/img/Dotted_Line-02.svg);
        position: relative;

        @include media-breakpoint-for(mobile) {
          display: none;
        }

        @include media-breakpoint-for(tab) {
          display: none;
        }

        @include media-breakpoint-for(tab-landscape) {
          display: none;
        }
      }

      .light-green-box {
        margin: 30px 0px;

        @include media-breakpoint-for(mobile) {
          margin: 0px;
        }

        @include media-breakpoint-for(tab) {
          margin: 0px;
        }

        @include media-breakpoint-for(tab-landscape) {
          margin: 0px;
        }
      }
    }

    &.block-2 {
      &::before {
        content: '';
        -webkit-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
        position: relative;

        @include media-breakpoint-for(mobile) {
          display: none;
        }

        @include media-breakpoint-for(tab) {
          display: none;
        }

        @include media-breakpoint-for(tab-landscape) {
          display: none;
        }
      }
    }

    .light-green-box {
      background-color: $fff-brand-new-fill;
      padding: 30px 30px 30px 90px;

      @include media-breakpoint-for(mobile) {
        padding: 30px 30px 30px 60px;
      }

      @include media-breakpoint-for(tab) {
        padding: 30px 30px 30px 60px;
        max-width: 100%;
        flex: 0 0 100%;
      }

      @include media-breakpoint-for(tab-landscape) {
        padding: 30px 30px 30px 60px;
        max-width: 100%;
        flex: 0 0 100%;
      }

      h3 {
        font-family: $fff-font-sans;
        font-size: 20px;
        margin-bottom: 10px;
        color: black;
      }

      p {
        font-family: 'SoleilLt', sans-serif;
        color: $fff-brand-grey;
        font-size: 16px;
      }

      ul {
        padding-left: 0px;

        li {
          list-style: none;
          font-family: $fff-font-sans;
          background-image: url('../../assets/img/fff-bullet-r-green.svg');
          background-repeat: no-repeat;
          background-position: left;
          background-size: 22px;
          padding-left: 30px;
          font-size: 16px;
          font-weight: 400;
          color: $fff-brand-grey;
          margin-bottom: 8px;
          text-align: left;

          @include media-breakpoint-for(mobile) {
            background-position: left 0px top 0;
          }

          @include media-breakpoint-for(tab) {
            background-position: left 0px top 0;
          }

          @include media-breakpoint-for(tab-landscape) {
            background-position: left 0px top 0;
          }
        }
      }
    }
  }

  .testimonial-slide-row {
    @include media-breakpoint-for(mobile) {
      position: relative;
      margin-top: 50px;
      margin-left: 0px;
      margin-right: 0px;
      margin-bottom: 0px;
    }

    @include media-breakpoint-for(tab) {
      position: relative;
      margin-top: 50px;
      margin-left: 0px;
      margin-right: 0px;
      margin-bottom: 0px;
    }

    @include media-breakpoint-for(tab-landscape) {
      position: relative;
      margin-top: 50px;
      margin-left: 0px;
      margin-right: 0px;
      margin-bottom: 0px;
    }

    &.supplier-m {
      @include media-breakpoint-for(mobile) {
        .slider {
          .carousel {
            &.slide {
              .carousel-inner {
                .carousel-item {
                  .carousel-image {
                    padding: 0px;

                    .slider-image {
                      background-size: cover;
                    }
                  }

                  .carousel-grey-row {
                    position: relative;
                    padding: 0px 0px 30px;
                    height: 195px;

                    .col-md-1 {
                      display: none;
                    }

                    &::before {
                      background-image: url('../../assets/img/fff-leaf-s.svg');
                      background-repeat: no-repeat;
                      background-position: top left;
                      -webkit-transform: rotate(-50deg);
                      transform: rotate(-50deg);
                      position: absolute;
                      z-index: 1;
                      background-size: contain;
                      width: 50px;
                      height: 28px;
                      top: -15px;
                      left: unset;
                      right: 0px;
                    }

                    .text-center {
                      color: black;
                      padding-top: 15px;
                      margin-bottom: 0px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      @include media-breakpoint-for(tab) {
        .slider {
          .carousel {
            &.slide {
              .carousel-inner {
                .carousel-item {
                  .carousel-image {
                    padding: 0px;

                    .slider-image {
                      background-size: cover;
                      height: 490px;
                    }
                  }

                  .carousel-grey-row {
                    position: relative;
                    padding: 0px 0px 30px;
                    height: 130px;

                    .col-md-1 {
                      display: none;
                    }

                    &::before {
                      background-image: url('../../assets/img/fff-leaf-s.svg');
                      background-repeat: no-repeat;
                      background-position: top left;
                      -webkit-transform: rotate(-50deg);
                      transform: rotate(-50deg);
                      position: absolute;
                      z-index: 1;
                      background-size: contain;
                      width: 50px;
                      height: 28px;
                      top: -15px;
                      left: unset;
                      right: 0px;
                    }

                    .text-center {
                      color: black;
                      padding-top: 15px;
                      margin-bottom: 0px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      @include media-breakpoint-for(tab-landscape) {
        .slider {
          .carousel {
            &.slide {
              .carousel-inner {
                .carousel-item {
                  .carousel-image {
                    padding: 0px;

                    .slider-image {
                      background-size: cover;
                      height: 490px;
                    }
                  }

                  .carousel-grey-row {
                    position: relative;
                    padding: 0px 0px 30px;
                    height: 130px;

                    .col-md-1 {
                      display: none;
                    }

                    &::before {
                      background-image: url('../../assets/img/fff-leaf-s.svg');
                      background-repeat: no-repeat;
                      background-position: top left;
                      -webkit-transform: rotate(-50deg);
                      transform: rotate(-50deg);
                      position: absolute;
                      z-index: 1;
                      background-size: contain;
                      width: 50px;
                      height: 28px;
                      top: -15px;
                      left: unset;
                      right: 0px;
                    }

                    .text-center {
                      color: black;
                      padding-top: 15px;
                      margin-bottom: 0px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .slider {
      display: block;
      height: 100%;
      margin: 0 auto;
      padding: 0px;
      background-color: $fff-brand-new-fill;
      @include breakpoint(tab-up) {
        max-width: 100%;
        flex: 0 0 100%;
      }
      @include breakpoint(desktop-up) {
        display: none;
      }

      .carousel {
        height: 100%;

        &.slide {
          height: 100%;

          .carousel-indicators {
            left: unset;
            right: unset;
            bottom: 0px;
            margin: 0;
            -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
            width: 100%;
            bottom: 10px;

            >li {
              border-radius: 50%;
              border: 2px solid #ffffff;
              background-color: transparent;
              width: 8px;
              height: 8px;

              &.active {
                background-color: rgba($fff-brand-grey, 0.36);
              }
            }

            &.customIndicators {
              left: unset;
              right: unset;
              margin: 0;
              position: absolute;
              left: 25%;
              transform: translate(-25%, 0%);
              z-index: 15;
              display: -webkit-flex;
              display: flex;
              -webkit-justify-content: center;
              justify-content: center;
              list-style: none;
              bottom: 24px;
              -webkit-flex: 0 0 100%;
              flex: 0 0 100%;
              max-width: 100%;
              width: 100%;

              li {
                width: 8px;
                height: 8px;
              }
            }
          }

          .carousel-control {
            display: none;

            .carousel-control-prev {
              color: $fff-brand-grey;
              opacity: 1;
              top: -9px;
              background-image: url(../../assets/img/icons/arrow_left-grey.svg);
              background-repeat: no-repeat;
              background-position: center;
              display: block;
              background-size: 15px;
              width: 20px;
              height: 30px;
              margin-right: 5px;
              opacity: 0.3;
              z-index: 10000;

              span {
                display: none;
              }
            }

            .carousel-control-next {
              color: $fff-brand-grey;
              opacity: 1;
              top: -9px;
              background-image: url(../../assets/img/icons/arrow_right-grey.svg);
              background-repeat: no-repeat;
              background-position: center;
              display: block;
              background-size: 15px;
              width: 20px;
              height: 30px;
              margin-left: 5px;
              opacity: 0.3;
              z-index: 10000;

              span {
                display: none;
              }
            }
          }

          .carousel-inner {
            overflow: visible;

            .carousel-item {
              padding: 0px;

              .carousel-grey-row {
                margin: 0px;
                background-color: transparent;
                padding: 16px 16px 24px;
                height: auto;
                @include breakpoint(tab-up) {
                  height: 228px;
                }
                @include breakpoint(tab-landscape) {
                  height: 220px;
                }

                .col-md-1 {
                  display: none;
                }

                &::before {
                  background-image: url('../../assets/img/fff-leaf-ml.svg');
                  background-repeat: no-repeat;
                  background-position: top left;
                  -webkit-transform: rotate(90deg);
                  transform: rotate(90deg);
                  position: absolute;
                  z-index: 1;
                  background-size: contain;
                  width: 35px;
                  height: 18px;
                  top: -5px;
                  left: 5px;
                }

                .carousel-captions {
                  text-align: center;
                  float: left;
                  position: relative;
                  margin-bottom: 10px;
                  padding: 5px 32px 0px;
                  @include breakpoint(tab-up) {
                    max-width: 100%;
                    flex: 0 0 100%;
                  }

                  .text-center {
                    margin-bottom: 10px;
                    height: auto;
                  }

                  p {
                    font-family: $fff-font-body-light;
                    color: #000000;
                    margin-bottom: 5px;
                  }

                  h5 {
                    font-family: $fff-font-body-light;
                    color: $fff-brand-green;
                    margin-bottom: 24px;
                  }
                }
              }

              .carousel-image {
                display: inline-block;
                top: 0px;
                height: 100%;
                right: 0px;
                text-align: center;
                position: relative;
                @include breakpoint(tab-up) {
                  max-width: 100%;
                  flex: 0 0 100%;
                }

                .slider-image {
                  position: relative;
                  top: 0;
                  -webkit-transform: none;
                  -ms-transform: none;
                  transform: none;
                  background-size: contain;
                  background-repeat: no-repeat;
                  width: 100%;
                  height: 200px;
                  display: block;
                  max-height: unset;
                  margin: -20px auto 0px;
                  background-position: center;
                  @include breakpoint(tab-up) {
                    height: 320px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

//From 2019.05.20
.carousel-inner {
  .carousel-item {
    opacity: 0;
    transition: opacity 2s ease-in-out;
  }

  .active {
    opacity: 1;
  }
}

.green-box-inside {
  p {
    word-break: inherit;
  }
}

.header-slider-preferences {
  .col {
    width: 33%;
    height: 45px;
    display: inline-block;

    img {
      width: 25%;
      height: 60%;
      overflow: hidden;
    }

    span {
      width: 100%;
      display: inline-block;
      color: black;
    }
  }
}

.faq-items {
  margin: 0;

  p {
    word-break: inherit;
  }
}

.on-the-menu-tab-items {
  .food-img-row {
    // height: 290px;

    @include media-breakpoint-for(mobile) {
      margin: 0px;
      height: auto;
    }

    @include media-breakpoint-for(tab) {
      margin: 0px;
      height: auto;
    }

    @include media-breakpoint-for(tab-landscape) {
      margin: 0px;
      height: auto;
    }

    .tab-default {
      @include media-breakpoint-for(mobile) {
        display: none;
      }

      @include media-breakpoint-for(tab) {
        display: none;
        max-width: 100%;
        flex: 0 0 100%;
      }

      @include media-breakpoint-for(tab-landscape) {
        display: none;
        max-width: 100%;
        flex: 0 0 100%;
      }

      .tag {
        font-family: $fff-font-sans;
        font-weight: 700;
        color: $fff-brand-new-primary;
        font-size: 18px;
        padding: 5px 16px;
        display: block;
        text-align: center;
        z-index: 1;

        @include media-breakpoint-for(mobile) {
          top: 11px;
          left: 10px;
          display: none;
          font-size: 16px;
        }

        @include media-breakpoint-for(tab) {
          top: 11px;
          left: 10px;
          display: none;
          font-size: 16px;
        }

        @include media-breakpoint-for(tab-landscape) {
          top: 11px;
          left: 10px;
          display: none;
          font-size: 16px;
        }
      }

      .image-wrap {
        width: 100%;
        margin: 0px;
        height: 290px;
        overflow: hidden;
        cursor: pointer;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        -webkit-transition: 0.2s ease;
        transition: 0.2s ease;
        opacity: 0.6;

        @include media-breakpoint-for(mobile) {
          height: auto;
        }

        @include media-breakpoint-for(tab) {
          height: auto;
        }

        @include media-breakpoint-for(tab-landscape) {
          height: auto;
        }
      }

      &.active-tab {
        display: block !important;

        .image-wrap {
          opacity: 1;

          @include media-breakpoint-for(mobile) {
            height: 290px;
          }

          @include media-breakpoint-for(tab) {
            height: 805px;
          }

          @include media-breakpoint-for(tab-landscape) {
            height: 800px;
          }

          &::before {
            background-image: url(../../assets/img/fff-leaf-s.svg);
            background-repeat: no-repeat;
            background-position: top left;
            -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
            position: absolute;
            width: 32px;
            height: 15px;
            z-index: 1;
            bottom: -8.5px;
            right: 30px;
            background-size: contain;
            opacity: 0.8;

            @include media-breakpoint-for(mobile) {
              display: none;
            }

            @include media-breakpoint-for(tab) {
              display: none;
            }

            @include media-breakpoint-for(tab-landscape) {
              display: none;
            }
          }
        }
      }
    }

    .mobile-tab-menu {
      display: none;

      @include media-breakpoint-for(mobile) {
        display: block;
        width: 100%;

        ul {
          display: inline-flex;
          width: 100%;
          padding: 0px 15px;
          text-align: center;
          margin: 0px;
          height: 85px;

          li {
            display: inline-flex;
            width: 100%;
            height: 70px;
            padding: 15px;
            font-family: 'Soleil-Bold', sans-serif;
            background-color: $fff-brand-new-secondary;
            color: $fff-brand-new-fill;
            margin-right: 1px;
            font-size: 14px;
            vertical-align: middle;
            -webkit-transition: height 0.1s;
            transition: height 0.1s;

            &:last-child {
              margin-right: 0 !important;
            }

            &.active-tab {
              background-color: $fff-brand-new-secondary;
              position: relative;
              z-index: 1;

              &::before {
                content: '';
                position: absolute;
                width: 100%;
                max-width: 90px;
                z-index: 1;
                bottom: -16px;
                right: 0;
                left: 0;
                margin: 0 auto;
                opacity: 1;
                border-left: 42px solid transparent;
                border-right: 42px solid transparent;
                border-top: 17px solid $fff-brand-new-secondary;
              }
            }
          }
        }
      }

      @include media-breakpoint-for(tab) {
        display: block;
        width: 100%;

        ul {
          display: inline-flex;
          width: 100%;
          padding: 0px 15px;
          text-align: center;
          margin: 0px;
          height: 85px;

          li {
            display: block;
            width: 100%;
            height: 70px;
            padding: 15px;
            font-family: 'Soleil-Bold', sans-serif;
            background-color: $fff-brand-new-secondary;
            color: $fff-brand-new-fill;
            margin-right: 1px;
            font-size: 14px;
            vertical-align: middle;
            -webkit-transition: height 0.1s;
            transition: height 0.1s;

            &:last-child {
              margin-right: 0 !important;
            }

            &.active-tab {
              background-color: $fff-brand-new-secondary;
              position: relative;
              z-index: 1;

              &::before {
                content: '';
                content: '';
                position: absolute;
                width: 100%;
                max-width: 90px;
                z-index: 1;
                bottom: -16px;
                right: 0;
                left: 0;
                margin: 0 auto;
                opacity: 1;
                border-left: 45px solid transparent;
                border-right: 45px solid transparent;
                border-top: 18px solid $fff-brand-new-secondary;
              }
            }
          }
        }
      }

      @include media-breakpoint-for(tab-landscape) {
        display: block;
        width: 100%;

        ul {
          display: inline-flex;
          width: 100%;
          padding: 0px 15px;
          text-align: center;
          margin: 0px;
          height: 85px;

          li {
            display: block;
            width: 100%;
            height: 70px;
            padding: 15px;
            font-family: 'Soleil-Bold', sans-serif;
            background-color: $fff-brand-new-secondary;
            color: $fff-brand-new-fill;
            margin-right: 1px;
            font-size: 14px;
            vertical-align: middle;
            -webkit-transition: height 0.1s;
            transition: height 0.1s;

            &:last-child {
              margin-right: 0 !important;
            }

            &.active-tab {
              background-color: $fff-brand-new-secondary;
              position: relative;
              z-index: 1;

              &::before {
                content: '';
                position: absolute;
                width: 100%;
                max-width: 90px;
                z-index: 1;
                bottom: -16px;
                right: 0;
                left: 0;
                margin: 0 auto;
                opacity: 1;
                border-left: 45px solid transparent;
                border-right: 45px solid transparent;
                border-top: 18px solid $fff-brand-new-secondary;
              }
            }
          }
        }
      }
    }
  }

  .tab-view {
    height: auto;
    display: inline-block;
    background-color: $fff-brand-new-fill;
    padding: 30px 0px;
    margin: 0;
    width: 100%;

    .active-tab {
      display: block !important;
    }

    .tab-view-content {
      margin: 0 auto;
      display: none;
      text-align: center;

      @include media-breakpoint-for(tab) {
        max-width: 100%;
        flex: 0 0 100%;
      }

      @include media-breakpoint-for(tab-landscape) {
        max-width: 100%;
        flex: 0 0 100%;
      }

      .nutrition-preferences {
        margin: 30px auto 30px;

        ul {
          padding: 0;
          text-align: center;

          li {
            display: inline-block;
            width: 85px;
            height: auto;
            margin: 0 15px;
            text-align: center;
            font-family: $fff-font-sans;

            @include media-breakpoint-for(mobile) {
              margin-bottom: 15px;
            }

            @include media-breakpoint-for(tab) {
              margin-bottom: 15px;
            }

            @include media-breakpoint-for(tab-landscape) {
              margin-bottom: 15px;
            }

            img {
              width: 50%;
              display: block;
              margin: 0 auto;
            }
          }
        }
      }
    }

    .btn-more-about {
      width: 200px;
      box-shadow: none;
      color: $fff-brand-new-secondary;
      border: 2px solid $fff-brand-new-secondary;
      background-color: #ffffff;
      height: 35px;
      line-height: 29px;
      font-size: 13px;
      border-radius: 30px;
      font-family: 'Soleil-Bold', sans-serif;
      text-align: center;
      margin: 0 auto;
      display: block;
      font-weight: 300;

      &:not(:disabled):not(.disabled):active,
      &:focus,
      &:hover {
        background: $fff-brand-new-fill;
      }

      &:focus {
        color: $fff-brand-green;
        border-color: rgba($fff-brand-green, 0.18);
        background-color: #ffffff;
      }
    }
  }
}

.image-section {
  width: 100%;
  height: 500px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0px;
  position: relative;
  margin: 0px;

  @include media-breakpoint-for(mobile) {
    background-position: bottom 0px right 0px;
    height: 300px;
    margin-top: 230px;
  }

  @include media-breakpoint-for(tab) {
    background-position: bottom 0px right 0px;
    height: 450px;
    margin-top: 195px;
  }

  @include media-breakpoint-for(tab-landscape) {
    background-position: bottom 0px right 0px;
    height: 450px;
    margin-top: 195px;
  }

  .col-md-1 {
    @include media-breakpoint-for(mobile) {
      display: none;
    }

    @include media-breakpoint-for(tab) {
      display: none;
    }

    @include media-breakpoint-for(tab-landscape) {
      display: none;
    }
  }

  .col-md-4 {
    @include media-breakpoint-for(tab) {
      max-width: 100%;
      flex: 0 0 100%;
    }

    @include media-breakpoint-for(tab-landscape) {
      max-width: 100%;
      flex: 0 0 100%;
    }
  }

  .leaft-text-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    display: block;

    @include media-breakpoint-for(mobile) {
      position: relative;
      top: unset;
      left: unset;
      transform: unset;
      width: 100%;
      display: block;
      margin-top: -224px;
    }

    @include media-breakpoint-for(tab) {
      position: relative;
      top: unset;
      left: unset;
      transform: unset;
      width: 100%;
      display: block;
      margin-top: -165px;
    }

    @include media-breakpoint-for(tab-landscape) {
      position: relative;
      top: unset;
      left: unset;
      transform: unset;
      width: 100%;
      display: block;
      margin-top: -165px;
    }

    &::before {
      background-image: url('../../assets/img/fff-leaf-l.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 300px;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%) rotate(-50deg);
      transform: translate(-50%, -50%) rotate(-50deg);
      width: 300px;
      height: 70px;

      @include media-breakpoint-for(mobile) {
        width: 150px;
        background-size: 150px;
      }

      @include media-breakpoint-for(tab) {
        width: 150px;
        background-size: 150px;
      }

      @include media-breakpoint-for(tab-landscape) {
        width: 150px;
        background-size: 150px;
      }
    }

    .text-wrapper {
      position: relative;
      width: 100%;
      display: block;

      h3 {
        font-family: $fff-font-sans;
        font-size: 28px;
        color: $fff-brand-grey;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        display: block;
        z-index: 1;
        font-weight: 300;
        margin-bottom: 0px;

        @include media-breakpoint-for(mobile) {
          position: relative;
          top: unset;
          left: unset;
          transform: unset;
          width: 100%;
          display: block;
          font-size: 22px;
          text-align: center;
          background-color: transparent;
          padding: 15px;
        }

        @include media-breakpoint-for(tab) {
          position: relative;
          top: unset;
          left: unset;
          transform: unset;
          width: 100%;
          display: block;
          font-size: 22px;
          text-align: center;
          background-color: transparent;
          padding: 15px;
        }

        @include media-breakpoint-for(tab-landscape) {
          position: relative;
          top: unset;
          left: unset;
          transform: unset;
          width: 100%;
          display: block;
          font-size: 22px;
          text-align: center;
          background-color: transparent;
          padding: 15px;
        }

        strong {
          font-family: 'Soleil-Bold', sans-serif;
        }
      }
    }
  }
}

.leaf-img {
  background-image: url('../../assets/img/fff-leaf-l.svg');
  background-repeat: no-repeat;
}

.detail-box-data-items {
  width: 95%;
  height: auto;
  padding: 20px 100px;
  display: inline-block;
  background-color: #f2fff9;
  margin-bottom: 25px;

  h1 {
    font-weight: bold;
    font-size: 16px;
    margin: 0;
    font-family: 'SoleilLt', sans-serif;
  }
}

.gallery {
  position: relative;

  .prev-next {
    width: 30px;
    height: 30px;
    font-size: 30px;
    position: absolute;
    display: block;
    top: 50%;
    -webkit-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
    border-radius: 50%;
    background: rgba(white, 0.5);
    cursor: pointer;

    &.prev {
      left: -60px;

      @include media-breakpoint-for(mobile) {
        left: 5px;
      }

      @include media-breakpoint-for(tab) {
        left: 5px;
      }

      @include media-breakpoint-for(tab-landscape) {
        left: 5px;
      }

      i {
        transform: rotate(90deg);
      }
    }

    &.next {
      right: -60px;

      @include media-breakpoint-for(mobile) {
        right: 5px;
      }

      @include media-breakpoint-for(tab) {
        right: 5px;
      }

      @include media-breakpoint-for(tab-landscape) {
        right: 5px;
      }

      i {
        transform: rotate(-90deg);
      }
    }

    i {
      display: block;
      color: rgba($fff-brand-dark-grey, 0.36);
    }
  }

  li {
    cursor: pointer;

    .overlay {
      display: block;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      position: absolute;
    }

    &:hover {
      .overlay {
        background-color: rgba(0, 0, 0, 0.4);
      }

      .inner-description {
        display: block;
        color: white;
        text-align: center;

        span {
          display: block;
          font-size: 18px !important;
          margin-bottom: 15px;
        }
      }
    }
  }

  img {
    width: 100%;
    height: 100%;
    padding: 1px;
  }

  .logo-carousel {
    position: relative;
     @media screen and (max-width: 1200px) {
       margin: 0px 15px;
     }

    .brand-logos {
      .alice-carousel {
        ul {
          li {
            width: auto;
            margin: 0;
            padding: 7.5px;

            .image-wrap-main .img-wrap {
              background-size: calc(100% - 30px);
            }
          }
        }
      }
    }

    .alice-carousel {
      ul {
        li {
          height: 164px;
          padding: 0;
          width: 100%;
          margin: 0px;
          
          @include breakpoint(tab-up) {
            width: 164px;
            margin: 7.5px;
          }

          &:hover {
            .overlay {
              background-color: transparent;
            }
          }

          .top-title {
            display: none;
          }

          .image-wrap-main {
            width: 100%;

            .img-wrap {
              width: 100%;
              height: 100%;
              margin: 0px;
              overflow: hidden;
              cursor: pointer;
              background-position: center;
              background-size: contain;
              background-repeat: no-repeat;
              -webkit-transition: 0.2s ease;
              transition: 0.2s ease;
              position: absolute;
            }
          }

          h3 {
            display: none;
          }

          .bottom-description {
            display: none;
          }
        }
      }
    }

    .prev-next {
      &.prev {
        @include media-breakpoint-for(mobile) {
          left: 0px;
        }

        @include media-breakpoint-for(tab) {
          left: 0px;
        }

        @include media-breakpoint-for(tab-landscape) {
          left: 0px;
        }
      }

      &.next {
        @include media-breakpoint-for(mobile) {
          right: 0px;
        }

        @include media-breakpoint-for(tab) {
          right: 0px;
        }

        @include media-breakpoint-for(tab-landscape) {
          right: 0px;
        }
      }
    }
  }

  .bottom-desc-carousel {
    position: relative;

    .alice-carousel {
      ul {
        padding-bottom: 25%;
        justify-content: space-between;

        @include media-breakpoint-for(mobile) {
          padding-bottom: 0%;
        }

        @include media-breakpoint-for(tab) {
          padding-bottom: 0%;
        }

        @include media-breakpoint-for(tab-landscape) {
          padding-bottom: 0%;
        }

        li {
          width: 25% !important;
          height: 0;
          padding: 0;
          position: relative;
          margin-bottom: 60px;
          cursor: default;

          @include media-breakpoint-for(mobile) {
            width: 100% !important;
            padding-bottom: 100%;
          }

          @include media-breakpoint-for(tab) {
            width: 100% !important;
            padding-bottom: 100%;
          }

          @include media-breakpoint-for(tab-landscape) {
            width: 100% !important;
            padding-bottom: 100%;
          }

          .top-title {
            display: none;
          }

          .image-wrap-main {
            width: 100%;
            padding-bottom: 100%;
            position: relative;

            .img-wrap {
              position: absolute;
              top: 0px;
              left: 1px;
              right: 0px;
              bottom: 0px;
              background-size: cover;
              background-position: center;

              @include media-breakpoint-for(mobile) {
                left: 0px;
              }

              @include media-breakpoint-for(tab) {
                left: 0px;
              }

              @include media-breakpoint-for(tab-landscape) {
                left: 0px;
              }
            }
          }

          h3 {
            display: none;
          }

          .inner-description {
            opacity: 0;
            z-index: 1;
            background-color: rgba(0, 0, 0, 0.69);
            font-size: 14px;
            padding: 20px 15px;
            position: absolute;
            left: 0px;
            right: 0px;
            top: 0px;
            bottom: 0px;

            p {
              color: white;
              position: relative;
              top: 50%;
              left: 50%;
              -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
              text-align: center;
              font-size: 14px;
              padding: 0px 30px;
            }

            &:hover {
              opacity: 1;
              z-index: 1;
            }
          }

          .bottom-description {
            // background-color: rgba($fff-brand-grey, 0.08);
            background-color: $fff-brand-yellow;
            padding: 20px 15px;
            position: relative;
            max-height: 94px;
            border-left: 1px solid #fff;

            p {
              font-size: 14px;
              margin-bottom: 0px;
            }

            &::before {
              content: '';
              background-image: url(../../assets/img/fff-leaf-s-grey.svg);
              background-repeat: no-repeat;
              background-position: center;
              -webkit-transform: rotate(-180deg);
              transform: rotate(-180deg);
              position: absolute;
              width: 32px;
              height: 15px;
              z-index: 1;
              top: -6px;
              left: 15px;
              background-size: contain;
              opacity: 0.36;
            }
          }
        }
      }
    }

    .prev-next {
      display: none;

      @include media-breakpoint-for(mobile) {
        display: block;
        top: 43%;
        -webkit-transform: translate(0%, -43%);
        transform: translate(0%, -43%);

        i {
          color: $fff-brand-yellow;
        }
      }

      @include media-breakpoint-for(tab) {
        display: block;
        top: 43%;
        -webkit-transform: translate(0%, -43%);
        transform: translate(0%, -43%);

        i {
          color: $fff-brand-yellow;
        }
      }

      @include media-breakpoint-for(tab-landscape) {
        display: block;
        top: 43%;
        -webkit-transform: translate(0%, -43%);
        transform: translate(0%, -43%);

        i {
          color: $fff-brand-yellow;
        }
      }
    }
  }

  .inner-description-carousel {
    position: relative;

    .alice-carousel {
      ul {
        padding-bottom: 25%;
        justify-content: space-between;

        @include media-breakpoint-for(mobile) {
          padding-bottom: 0%;
        }

        @include media-breakpoint-for(tab) {
          padding-bottom: 0%;
        }

        @include media-breakpoint-for(tab-landscape) {
          padding-bottom: 0%;
        }

        li {
          width: 25% !important;
          height: 0;
          padding: 0;
          position: relative;

          @include media-breakpoint-for(mobile) {
            width: 100% !important;
            padding-bottom: 100%;
          }

          @include media-breakpoint-for(tab) {
            width: 100% !important;
            padding-bottom: 100%;
          }

          @include media-breakpoint-for(tab-landscape) {
            width: 100% !important;
            padding-bottom: 100%;
          }

          .top-title {
            display: none;
          }

          .image-wrap-main {
            width: 100%;
            padding-bottom: 100%;
            position: relative;

            .img-wrap {
              position: absolute;
              top: 0px;
              left: 1px;
              right: 0px;
              bottom: 0px;
              background-size: cover;
              background-position: center;

              @include media-breakpoint-for(mobile) {
                left: 0px;
              }

              @include media-breakpoint-for(tab) {
                left: 0px;
              }

              @include media-breakpoint-for(tab-landscape) {
                left: 0px;
              }
            }
          }

          h3 {
            display: none;
          }

          .inner-description {
            opacity: 0;
            z-index: 1;
            background-color: rgba(0, 0, 0, 0.69);
            font-size: 14px;
            padding: 20px 15px;
            position: absolute;
            left: 0px;
            right: 0px;
            top: 0px;
            bottom: 0px;

            p {
              color: white;
              position: relative;
              top: 50%;
              left: 50%;
              -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
              text-align: center;
              font-size: 14px;
            }

            &:hover {
              opacity: 1;
              z-index: 1;
            }
          }

          .bottom-description {
            display: none;
          }
        }
      }
    }
  }

  .top-title-carousel {
    position: relative;

    .alice-carousel {
      ul {
        padding-bottom: 25%;
        justify-content: space-between;

        @include media-breakpoint-for(mobile) {
          padding-bottom: 0%;
        }

        @include media-breakpoint-for(tab) {
          padding-bottom: 0%;
        }

        @include media-breakpoint-for(tab-landscape) {
          padding-bottom: 0%;
        }

        li {
          width: 25% !important;
          height: 0;
          padding: 0;
          position: relative;

          @include media-breakpoint-for(mobile) {
            width: 100% !important;
            padding-bottom: 100%;
          }

          @include media-breakpoint-for(tab) {
            width: 100% !important;
            padding-bottom: 100%;
          }

          @include media-breakpoint-for(tab-landscape) {
            width: 100% !important;
            padding-bottom: 100%;
          }

          .top-title {
            text-align: center;
            font-size: 22px;
            font-family: 'DINPro-Bold', sans-serif;
            color: black;
          }

          .image-wrap-main {
            width: 100%;
            padding-bottom: 100%;
            position: relative;

            .img-wrap {
              position: absolute;
              top: 0px;
              left: 1px;
              right: 0px;
              bottom: 0px;
              background-size: cover;
              background-position: center;

              @include media-breakpoint-for(mobile) {
                left: 0px;
              }

              @include media-breakpoint-for(tab) {
                left: 0px;
              }

              @include media-breakpoint-for(tab-landscape) {
                left: 0px;
              }
            }
          }

          h3 {
            display: none;
          }

          .inner-description {
            opacity: 0;
            z-index: 1;
            background-color: rgba(0, 0, 0, 0.69);
            font-size: 14px;
            padding: 20px 15px;
            position: absolute;
            left: 0px;
            right: 0px;
            top: 0px;
            bottom: 0px;

            p {
              color: white;
              position: relative;
              top: 50%;
              left: 50%;
              -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
              text-align: center;
              font-size: 14px;
            }

            &:hover {
              opacity: 0;
              z-index: 1;
            }
          }

          .bottom-description {
            display: none;
          }
        }
      }
    }
  }
}

.slider-range-box {
  background-color: rgba($fff-brand-dark-grey, 0.08);
  padding-top: 30px;
  margin: 0px;

  .slider-range-bar-wrap {
    display: inline-block;
    width: 100%;
    margin: 0 auto 55px;

    @include media-breakpoint-for(mobile) {
      margin: 0px 0px 40px;
    }

    @include media-breakpoint-for(tab) {
      margin: 0px 0px 40px;
      max-width: 100%;
      flex: 0 0 100%;
    }

    @include media-breakpoint-for(tab-landscape) {
      margin: 0px 0px 40px;
      max-width: 100%;
      flex: 0 0 100%;
    }

    h3 {
      font-family: 'SoleilLt', sans-serif;
      font-size: 20px;
      font-weight: 400;
      text-align: center;
      padding: 0px 0px 40px;
    }
  }
}

.pricing-row {
  margin: 0px;

  .no-padding-left {
    padding-left: 0px;

    @include media-breakpoint-for(mobile) {
      padding: 0px;
    }

    @include media-breakpoint-for(tab) {
      padding: 0px;
      max-width: 100%;
      flex: 0 0 100%;
    }

    @include media-breakpoint-for(tab-landscape) {
      padding: 0px;
      max-width: 100%;
      flex: 0 0 100%;
    }
  }

  .no-padding-right {
    padding-right: 0px;

    @include media-breakpoint-for(mobile) {
      padding: 0px;
    }

    @include media-breakpoint-for(tab) {
      padding: 0px;
      max-width: 100%;
      flex: 0 0 100%;
    }

    @include media-breakpoint-for(tab-landscape) {
      padding: 0px;
      max-width: 100%;
      flex: 0 0 100%;
    }
  }

  .pricing-table-data {
    margin: 0px 0px 15px;
    border-bottom: 1px solid rgba($fff-brand-grey, 0.12);
    padding-bottom: 15px;

    .col {
      background-color: rgba(66, 69, 70, 0.08);
      width: 14%;
      padding: 0;
      float: left;

      ul {
        padding: 0;
        text-transform: lowercase;

        li {
          height: 65px;
          overflow: hidden;
          text-align: center;
          list-style-type: none;
          padding: 10px;
          border: 0.2px solid #f2fff9;
          display: grid;
          align-items: center;

          @include media-breakpoint-for(mobile) {
            padding: 5px;
            font-size: 12px;
            height: 50px;
          }

          @include media-breakpoint-for(tab) {
            padding: 5px;
            font-size: 12px;
            height: 50px;
          }

          @include media-breakpoint-for(tab-landscape) {
            padding: 5px;
            font-size: 12px;
            height: 50px;
          }

          strong {
            font-weight: bold;
          }

          &.top-title {
            text-transform: lowercase;
            display: block;

            @include media-breakpoint-for(mobile) {
              height: 50px;
              display: grid;
              align-items: center;
              text-align: center;
              width: 100%;
              padding: 5px 1px;

              br {
                display: none;
              }
            }

            @include media-breakpoint-for(tab) {
              height: 50px;
              display: grid;
              align-items: center;
              text-align: center;
              width: 100%;
              padding: 5px 1px;

              br {
                display: none;
              }
            }

            @include media-breakpoint-for(tab-landscape) {
              height: 50px;
              display: grid;
              align-items: center;
              text-align: center;
              width: 100%;
              padding: 5px 1px;

              br {
                display: none;
              }
            }
          }

          &.left-title {
            display: block;

            @include media-breakpoint-for(mobile) {
              height: 50px;
              display: flex;
              align-items: center;
              text-align: center;
            }

            @include media-breakpoint-for(tab) {
              height: 50px;
              display: flex;
              align-items: center;
              text-align: center;
            }

            @include media-breakpoint-for(tab-landscape) {
              height: 50px;
              display: flex;
              align-items: center;
              text-align: center;
            }
          }
        }
      }
    }

    .active-col {
      background-color: #f5ec3f !important;

      ul {
        li {
          font-weight: bold;

          &:first-child {
            font-weight: normal;
          }
        }
      }
    }
  }

  .pricing-table {
    width: 100%;
    height: auto;
    background-color: #f2fff9;
    padding: 20px 30px 30px;

    @include media-breakpoint-for(mobile) {
      padding: 30px 15px;
    }

    @include media-breakpoint-for(tab) {
      padding: 30px 15px;
    }

    @include media-breakpoint-for(tab-landscape) {
      padding: 30px 15px;
    }

    h2 {
      font-family: 'DINPro-Bold', sans-serif;
      font-size: 20px;
      margin-bottom: 30px;
      text-align: center;
      color: black;
    }

    table {
      background-color: rgba(66, 69, 70, 0.1);

      p {
        margin: 0;
      }
    }

    .table-bordered {
      border: none;
    }
  }

  .pricing-right-col {
    width: 100%;
    height: 100%;
    background-color: #f2fff9;
    padding: 80px 20px;
    text-align: center;
    position: relative;

    @include media-breakpoint-for(mobile) {
      margin-left: 0px;
      padding: 30px 15px;
      background-color: #fff;
    }

    @include media-breakpoint-for(tab) {
      margin-left: 0px;
      padding: 30px 15px;
      background-color: #fff;
    }

    @include media-breakpoint-for(tab-landscape) {
      margin-left: 0px;
      padding: 30px 15px;
      background-color: #fff;
    }

    h3 {
      font-family: 'DINPro-Bold', sans-serif;
      font-size: 20px;
      margin-bottom: 0px;
      color: $fff-brand-dark-grey;
    }

    h2 {
      font-size: 34px;
      font-family: 'DINPro-Bold', sans-serif;
      margin-bottom: 0px;
      color: black;
    }

    .leaf-txt {
      margin-top: 4px;
      background-image: url('../../assets/img/fff-leaf-s.svg');
      background-repeat: no-repeat;
      background-size: 150px;
      background-position: top 5px center;
    }

    .text-wrap {
      position: absolute;
      bottom: 0px;
      left: 30px;
      right: 30px;
      display: block;
      font-size: 14px;
      text-align: center;

      @include media-breakpoint-for(mobile) {
        position: relative;
        left: 0px;
        right: 0px;
        margin: 30px 0px 0px;
      }

      @include media-breakpoint-for(tab) {
        position: relative;
        left: 0px;
        right: 0px;
        margin: 30px 0px 0px;
      }

      @include media-breakpoint-for(tab-landscape) {
        position: relative;
        left: 0px;
        right: 0px;
        margin: 30px 0px 0px;
      }
    }
  }
}

.adjust-about-us-banner {
  .img {
    background-position: 0px -50px !important;

    @include media-breakpoint-for(mobile) {
      background-position: center !important;
    }
  }
}

//Adding all the new styles here so we can later get rid of the unused ones above.
//TODO: Revisit the styles above and clear unused scss rules.

.new-static-styles {
  .main-banner {
    .banner-image {
      width: 100%;
      height: 200px;
      background-size: cover;
      background-position: center top;

      @media screen and (min-width: 992px) {
        height: 354px;
      }
    }

    .banner-title {
      padding-top: 30px;
      padding-bottom: 30px;

      @media screen and (min-width: 992px) {
        padding-top: 40px;
        padding-bottom: 50px;
      }

      h1 {
        font-size: 40px;
        line-height: 1;
        margin-bottom: 15px;
        font-family: $fff-font-serif;
        font-weight: 400;

        @media screen and (min-width: 992px) {
          font-size: 50px;
        }

        i {
          font-family: $fff-font-title-light;
          font-weight: 300;
        }
      }

      h3 {
        font-size: 22px;
        margin-bottom: 0px;
        line-height: 1;
        font-family: $fff-font-sans;

        @media screen and (min-width: 992px) {
          font-size: 27px;
        }
      }
    }
  }

  .static-box {
    box-shadow: 0px 0px 9px 1px rgba($color: #000000, $alpha: .16);
    background-color: white;
    padding: 30px 40px;

    h2 {
      text-align: center;
      font-size: 30px;
      font-family: $fff-font-sans;
      font-weight: 700;
      line-height: 1;
      margin-bottom: 30px;

      @media screen and (min-width: 992px) {
        font-size: 36px;
      }
    }
  }

  section {
    padding-top: 40px;
    padding-bottom: 40px;

    &+section {
      padding-top: 0px;
    }
  }

  h3.page-subtitle {
    font-size: 22px;
    font-family: $fff-font-sans;
    font-weight: 700;
    text-align: center;
    margin-bottom: 15px;

    @media screen and (min-width: 992px) {
      font-size: 27px;
    }
  }

  //styles related to pricing page
  &.new-price-plan {
    .new-macro-calculator {
      box-shadow: 0px 0px 9px 1px rgba($color: #000000, $alpha: .16);
      padding: 24px;

      .separator {
        border-top: 1px solid rgba(0, 0, 0, 0.125);
        width: 100%;
        height: 1px;
      }

      .meal-calculation {
        font-family: $fff-font-sans;
        font-weight: 700;
      }

      .macro-calculator-button {
        width: 100%;
        max-width: 150px;
      }

      h3.nutrition-calculation {
        font-size: 32px;
        text-align: center;
        font-family: $fff-font-title-light;
        font-weight: 100;
        margin-top: 2rem;

        b {
          font-family: $fff-font-title-bold;
        }
      }

      .multi-input-datepicker {
        &:hover {
          [class*='control'] {
            border-color: $fff-brand-green;
          }
        }

        [class*='control'] {
          border-color: rgba(0, 0, 0, 0.125);
          height: 44px;
          transition: border-color 0.24s ease;
        }

        span[class*='indicatorSeparator'] {
          display: none;
        }

        [class*="placeholder"] {
          height: 24px;
          line-height: 26px;
          font-weight: bold;
        }

        [class*="singleValue"] {
          height: 24px;
          line-height: 26px;
          font-weight: bold;
        }
      }

      .input-group {
        .genderButton {
          border-color: rgba(0, 0, 0, 0.125);
          height: 44px;
          font-family: $fff-font-body-bold;
          transition: border-color 0.24s ease;
          color: $fff-brand-grey;
          line-height: 44px;
          display: inline-block;
          font-weight: 400;

          &:hover {
            border-color: $fff-brand-green;
            z-index: 2;
            background-color: white;
          }

          &.male {
            margin-right: -2px;
          }

          &.btn-primary {
            background-color: rgba($fff-brand-dark-green, .06);
            border-color: $fff-brand-green;
            z-index: 2;
          }
        }

        input {
          &[type="number"] {
            border-color: rgba(0, 0, 0, 0.125);
            border-radius: 4px !important;
            height: 44px;
            font-weight: bold;
            transition: border-color 0.24s ease;

            &:hover {
              border-color: $fff-brand-green;
            }
          }
        }
      }
    }

    .price-plans-section {
      .plan-card {
        display: flex;
        flex-direction: column;
        height: 100%;

        @media screen and (max-width: 600px) {
          height: auto;
          margin-bottom: 16px;
        }

        .plan-image {
          background-color: $fff-brand-new-fill;
          height: 345px;
          background-size: cover;
          background-position: center;
        }

        .plan-content {
          padding: 0px 24px 24px;
          background-color: $fff-brand-new-fill;
          box-shadow: 0px 0px 9px 1px rgba($color: #000000, $alpha: .16);
          flex-grow: 1;

          @media screen and (min-width: 992px) {
            padding: 0px 40px 30px;
          }

          .plan-title {
            padding: 30px 0px 25px;
            min-height: 81px;

            h2 {
              font-size: 36px;
              line-height: 1;
              margin-bottom: 10px;
              font-family: $fff-font-sans;
              font-weight: 700;
            }

            p {
              font-size: 18px;
              font-family: $fff-font-sans;
              margin-bottom: 0;
            }
          }

          .plan-price {
            margin-top: 30px;

            p {
              font-size: 18px;
              font-family: $fff-font-body-light;
              color: $fff-brand-grey;
              font-weight: bold;
              margin-bottom: 0;

              span {
                font-family: $fff-font-sans;
                font-weight: 700;
                font-size: 36px;
                color: $fff-brand-black-text;
                line-height: 1;
                display: inline-block;

                &::first-letter {
                  font-size: 80%;
                }
              }

            }
            small {
              font-size: 15px;
              display: block;
              margin-top: 0.5rem;
            }
          }

          .plan-description {
            ul {
              display: grid;
              grid: auto / auto;
              grid-auto-flow: row;
              gap: 15px;
              padding-left: 0px;

              li {
                list-style: none;
                display: grid;
                grid: auto / 24px auto;
                gap: 9px;
                font-size: 18px;
                font-family: $fff-font-sans;
                line-height: 1.4;
                align-items: center;

                @media screen and (min-width: 764px) {
                  grid: auto / 24px auto;
                }
              }
            }
          }

          .plan-options {
            padding-top: 35px;

            label {
              font-size: 18px;
              font-family: $fff-font-sans;
              font-weight: 700;
              color: $fff-brand-black-text;
              margin-bottom: 0;
              text-transform: unset;
            }

            .price-plan-options {
              max-width: 95px;
              margin-left: auto;

              &__single-value {
                font-size: 18px;
                font-weight: bold;
                margin-top: 1px;
              }

              &__value-container--has-value {
                justify-content: center;
              }

              &__option {
                font-size: 18px;
                font-weight: bold;
              }

              &__indicator-separator {
                display: none;
              }
            }
          }
        }
      }

    }

    .extra-box {
      display: grid;
      grid: 45px / 60% 35px auto;
      gap: 15px;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;

      @include breakpoint(tab-up) {
        grid: auto / max-content 35px auto;
        justify-content: center;
      }

      @include breakpoint(desktop-up) {
        grid: auto / max-content 35px auto;
        justify-content: center;
        max-width: 230px;
        margin-bottom: 0px;
      }

      h3 {
        font-size: 18px;
        font-family: $fff-font-sans;
        font-weight: 700;
        margin-bottom: 0;
      }

      img {
        max-width: 35px;
        max-height: 45px;
        object-fit: scale-down;
        object-position: center;
        display: block;
        margin: 0 auto;
      }

      .extra-price {
        font-size: 24px;
        font-family: $fff-font-sans;
        font-weight: 700;
        line-height: 1;
        margin-bottom: 0;

        &::first-letter {
          font-size: 80%;
        }
      }
    }

    .bragging {
      padding-top: 40px;

      .brag-box {
        background-color: $fff-brand-new-light;
        padding: 1.5rem 40px;

        @media screen and (min-width: 992px) {
          padding: 1.5rem 40px 1rem;
        }
      }

      h2 {
        display: flex;
        font-size: 27px;
        margin-bottom: 0px;
        font-family: $fff-font-serif;
        flex-direction: column;
        text-align: center;
        justify-content: center;

        @media screen and (min-width: 992px) {
          align-items: center;
          height: 100px;
          font-size: 32px;
          flex-direction: row;
          text-align: left;
          justify-content: flex-start;
          max-width: 1368px;
          margin: 0 auto;
        }

        &>span:first-of-type {
          font-size: 80px;
          line-height: 1.2;
          color: $fff-brand-new-accent;
          display: block;

          @media screen and (min-width: 992px) {
            margin-right: 20px;
            font-size: 100px;
            line-height: 60px;
            height: 80px;
            display: inline-flex;
          }
        }

        &>span:last-of-type {
          @media screen and (min-width: 992px) {
            line-height: 44px;
            display: inline-flex;
            align-items: flex-start;
            height: 100px;
          }
        }
      }
    }

    .macro-calculator-trigger {
      background-color: $fff-brand-new-primary;
      color: $fff-brand-new-fill;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 9px 15px;

      i.material-icons {
        margin-left: 9px;
        font-size: 36px;
      }

      span {
        font-size: 27px;
        font-family: $fff-font-sans;
        font-weight: 700;

        i {
          font-family: $fff-font-title-light;
          font-weight: 300;
        }
      }
    }

    .food-carousel {
      padding-top: 10px;

      &-card {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          width: 100%;
          @include breakpoint(tab-landscape-up) {
            height: 350px;
          }
          object-fit: cover;
          object-position: center;
        }

        &__image {
          height: 350px;
          background-size: cover;
          background-position: center;
          width: 100%;
        }

        &__description {
          padding-top: 9px;

          p {
            font-size: 18px;
            text-align: center;
            font-family: $fff-font-sans;
            font-weight: bold;
          }
        }
      }
    }
  }
}

// TODO: Move below styles inside order form

.new-macro-calculator {
  .multi-input-datepicker {
    font-family: $fff-font-sans;
    &:hover {
      .order_form_control {
        border-color: $fff-brand-new-secondary;
      }
    }
    
    .order-form {
      &__control {
        border: 2px solid rgba(0, 0, 0, 0.125);
        height: 44px;
        transition: border-color 0.24s ease;

        &--is-focused {
          border: 2px solid $fff-brand-new-secondary;
          box-shadow: none;
        }
      }

      &__indicator-separator {
        display: none;
      }

      &__single-value {
        height: 24px;
        line-height: 26px;
        font-weight: 600;
        color: $fff-brand-grey;
      }
      &__option {
        &--is-focused {
          background-color: $fff-brand-new-fill;
        }
        &--is-selected {
          background-color: $fff-brand-new-secondary;
        }
        &:active {
          background-color: $fff-brand-new-fill;
        }
      }
    }

    [class*="placeholder"] {
      height: 24px;
      line-height: 26px;
      font-weight: 600;
      color: $fff-brand-grey;
    }
  }

  .input-group {
    .genderButton {
      border-color: rgba(0, 0, 0, 0.125);
      height: 44px;
      font-family: $fff-font-sans;
      transition: border-color 0.24s ease;
      color: $fff-brand-grey;
      line-height: 44px;
      display: inline-block;
      font-weight: 600;

      &:hover {
        border-color: $fff-brand-new-secondary;
        z-index: 2;
        background-color: white;
      }

      &.male {
        margin-right: -2px;
      }

      &.btn-primary {
        background-color: $fff-brand-new-fill;
        border-color: $fff-brand-new-secondary;
        z-index: 2;
      }
    }

    input {
      &[type="number"] {
        border-color: rgba(0, 0, 0, 0.125);
        border-radius: 4px !important;
        height: 44px;
        font-weight: 600;
        transition: border-color 0.24s ease;
        font-family: $fff-font-sans;

        &:hover {
          border-color: $fff-brand-new-secondary;
        }
      }
    }
  }
}

.delivery-details, .meal-details, .payment-details, .new-macro-calculator, .checkout-address-input {
  .form-control {
    min-height: 44px;
    border-radius: 6px;
    &:hover {
      border-color: $fff-brand-new-secondary;
    }
  }
  .order-form {
    &__control {
      border: 2px solid rgba(0, 0, 0, 0.125);
      height: 44px;
      transition: border-color 0.24s ease;
      border-radius: 6px;

      &--is-focused {
        border: 2px solid $fff-brand-new-secondary;
        box-shadow: none;
        &:hover {
          border: 2px solid $fff-brand-new-secondary;
        }
      }

      &:hover {
        border-color: $fff-brand-new-secondary;
      }
    }

    &__menu {
      z-index: 4;
    }

    &__indicator-separator {
      display: none;
    }

    &__single-value {
      height: 24px;
      line-height: 26px;
      font-weight: 600;
      color: $fff-brand-grey;
    }
    &__option {
      &--is-focused {
        background-color: $fff-brand-new-fill;
      }
      &--is-selected {
        background-color: $fff-brand-new-secondary;
      }
      &:active {
        background-color: $fff-brand-new-fill !important;
      }
    }
  }
}

.order-form-calendar {
  input.p-inputtext {
    background-color: white;
    &:hover {
      border: 2px solid $fff-brand-new-secondary !important;
    }
    &:focus {
      border: 2px solid $fff-brand-new-secondary !important;
    }
  }
  .pi-calendar {
    color: $fff-brand-new-primary;
    font-size: 22px;
  }
  .p-datepicker {
    font-family: $fff-font-sans;
    table {
      td {
        span {
          &.p-highlight {
            background-color: rgba($fff-brand-new-secondary, 0.12);
            color: $fff-brand-black-text;
            font-weight: 600;
          }
          &:not(.p-disabled) {
            cursor: pointer;
            font-weight: 500;
          }
        }
      }
    }
  }
}

.on-the-menu-calc-section{
  padding-top: 0 !important;

  @media (min-width: 900px) {
    padding-bottom: 60px !important;
  }

}