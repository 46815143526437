.adjust-banner {
  .img {
    background-position: 0px -75px !important;
    @media screen and (min-width: 1900px) {
      background-position: 0px -140px !important; 
    }
    @include media-breakpoint-for (mobile) {
      background-position: center !important;
    }
  }
}

.impact-circle-row {
  @include media-breakpoint-for (mobile) {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .impact-circle-wrapper {
    display: flex;
    justify-content: center;
  
    .impact-circle {
      width: 300px;
      height: 300px;
      border-radius: 50%;
      background-color: $fff-brand-new-fill;
      padding: 50px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      margin-bottom: 1rem;
      margin-top: 1rem;
  
      img {
        width: 100px;
        height: 100px;
      }
  
      h3 {
        font-size: 20px;
        color: black;
        font-family: $fff-font-sans;
        font-weight: 700;
        text-align: center;
        margin-top: 1rem;
        margin-bottom: 0;
      }
    }
  }
}


.quote {
  h3,p {color: $fff-brand-new-primary !important;}
  p {
    font-family: $fff-font-sans;
  }
  h3{
    line-height: 1.5 !important;
    margin-bottom: 1rem !important;
  }
}

.tackling-obesity {
  padding-bottom: 40px;
  p {
    margin-bottom: 1rem !important;
  }
}

.magazine-content-wrapper {
  padding: 30px 30px 40px;
  p {
    a {
      color: $fff-brand-new-primary !important;
      text-decoration: none !important;
      border: none !important;
    }
  }
  .hero-img {
    margin: 10px 0;
    background-size: calc(100% - 2rem);
    background-position: center;
    background-repeat: no-repeat;
    min-height: 200px;
    @media screen and (min-width: 1900px) {
      min-height: 450px;
    }
  }
}

.caption-with-image {
  display: flex;
  align-items: center;
  @include media-breakpoint-for (mobile) {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .text-content {
    text-align: center;
    padding: 40px 30px;
  }
  .image-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    @include media-breakpoint-for (mobile) {
      padding: 0;
      order: 1;
    }
    &.single {
      &>div {
        max-width: 90%;
      }
    }
    &>div {
      flex-grow: 1;
      max-width: 50%;
      width: 100%;
      padding: 1rem;
      img {
        width: 100%;
        overflow: hidden;
      }
    }
  }
}

p, div{
  &.anchor-without-border {
    a {
      color: $fff-brand-new-primary !important;
      text-decoration: none !important;
      border: none !important;
    }
  }
}
