.spinner-panel {
  z-index: 10000;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.spinner {
  position: fixed;
  display: block;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);    
  z-index: 10000;
}
.local-spinner-panel {
  z-index: 10000;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-center-spinner {
  position: fixed;
  display: block;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}
