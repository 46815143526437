$fff-brand-yellow: #F7F536;
$fff-brand-yellow-light: #FFF3CD;
$fff-brand-green: #036E38;
$fff-brand-medium-green:#28A745;
$fff-brand-dark-green:#014925;
$fff-brand-shaded-green: #D4EDDA;
$fff_brand_yellow_green: #E8EBDF;
$fff-brand_light_mint_green: #E7ECDE;
$fff_brand_grey_green: #F6F7F6;
$fff-brand-light-green: #F2fff9;
$fff-brand-pastel-green: #E7EBDF;
$fff-brand-blue: #007BFF;
$fff-brand-dark-blue: #004085;
$fff-brand-shaded-blue: #CCE5FF;
$fff-brand-brown:#856404;
$fff-brand-shadded-brown:#FFF3CD;
$fff-brand-red:#DC3545;
$fff-brand-dark-red:#721C24;
$fff-brand-shadded-red:#F8D7DA;
$fff-brand-grey: #54565C;
$fff-brand-dark-grey:#424546;
$fff-brand-grey-light: #EFEFF0;
$fff-brand-grey-lighter: #EBEBEB;
$fff-brand-light-grey: #DFDFDF;
$fff-brand-light-yellow: #F8F4C6;
$fff-brand-dark-purple: #151b4f;
$fff-brand-logo-background: #dddede;
$fff-brand-black-text: #212529;
$facebook-blue: #4267B2;
$fff-brand-supplement: #fff3dc;

$fff-font-body-bold : 'Soleil-Bold', sans-serif;
$fff-font-body-light : 'SoleilLt', sans-serif;
$fff-font-title-bold : 'DINPro-Bold', sans-serif;
$fff-font-title-light : 'DINPro-Light', sans-serif;

$fff-font-serif : 'DM Serif Display', serif;
$fff-font-sans : 'Poppins', sans-serif;

$fff-brand-new-primary : #003A1F;
$fff-brand-new-secondary : #6E810F;
$fff-brand-new-accent : #C24504;
$fff-brand-new-fill : #E8ECDE;
$fff-brand-new-dark : #000000;
$fff-brand-new-light : #ffffff;

$fff-brand-foodbox-primary :#66102E;


$theme-colors: (
  'primary': $fff-brand-green
);
  
$nav-background: #ffffff;
