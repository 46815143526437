@import './responsive';

.cal-col {
  color: $fff-brand-grey;
  font-family: 'Soleil-Bold', sans-serif;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  padding: 10px;

  .value {
    float: right;
    margin-right: -17px;
  }

  strong {
    font-size: 20px;
    font-family: 'DINPro-Bold', sans-serif;
  }
}

.inline-grid {
  padding: 10px 15px;
  display: flex;
  width: 100%;

  @include media-breakpoint-for(mobile) {
    display: flex;
    flex-wrap: wrap;
  }

  @include media-breakpoint-for(tab) {
    display: flex;
    flex-wrap: wrap;
  }

  @include media-breakpoint-for(tab-landscape) {
    display: flex;
    flex-wrap: wrap;
  }

  &.light-green {
    background-color: $fff-brand-light-green;
    margin-bottom: 4px;

    .grid-col-right {
      button {
        box-shadow: none !important;
        // background-color: transparent !important;
        // border: 0px  !important;
        // padding: 5px 0px 5px 12px !important;
      }

      img {
        width: auto !important;
        height: 20px !important;
        margin: 0px !important;
      }
    }
  }

  .grid-col-left {
    display: inline-flex;
    flex-grow: 2;
    max-width: 100%;

    @include media-breakpoint-for(mobile) {
      flex-basis: 0;
      display: inline-block;
    }

    @include media-breakpoint-for(tab) {
      flex-basis: 0;
      display: inline-block;
    }

    @include media-breakpoint-for(tab-landscape) {
      flex-basis: 0;
      display: inline-block;
    }

    .left-col-block {
      padding-right: 10px;
      display: inline-block;

      &:last-of-type {
        margin-bottom: 0px;
      }

      @include media-breakpoint-for(mobile) {
        max-width: 100%;
        display: block;
        width: 100%;
        margin-bottom: 10px;
      }

      @include media-breakpoint-for(tab) {
        max-width: 100%;
        display: block;
        width: 100%;
        margin-bottom: 10px;
      }

      @include media-breakpoint-for(tab-landscape) {
        max-width: 100%;
        display: block;
        width: 100%;
        margin-bottom: 10px;
      }

      img {
        width: auto !important;
        height: 20px !important;
        margin: 0px !important;

        &.grid-img-md {
          width: 60px !important;
          height: 60px !important;
          background-color: rgba($fff-brand-grey, 0.08);
          background-size: contain;
          padding: 9px;
        }
      }

      p {
        margin-bottom: 0px;
        line-height: 30px;
      }

      &.fixed-label {
        max-width: 100px;
        width: 100px;

        @media (min-width: 1600px) {
          max-width: 200px;
          width: 200px;
        }

        @include media-breakpoint-for(mobile) {
          max-width: 100%;
          display: block;
          width: 100%;
          margin-bottom: 10px;
        }

        @include media-breakpoint-for(tab) {
          max-width: 100%;
          display: block;
          width: 100%;
          margin-bottom: 10px;
        }

        @include media-breakpoint-for(tab-landscape) {
          max-width: 100%;
          display: block;
          width: 100%;
          margin-bottom: 10px;
        }

        .text-grey {
          color: rgba($fff-brand-grey, 0.8);
          font-weight: 800;
        }

      }

      &.fixed-width-md {
        max-width: 30%;
        width: 30%;

        @include media-breakpoint-for(mobile) {
          max-width: 100%;
          display: block;
          width: 100%;
          margin-bottom: 10px;
        }

        @include media-breakpoint-for(tab) {
          max-width: 100%;
          display: block;
          width: 100%;
          margin-bottom: 10px;
        }

        @include media-breakpoint-for(tab-landscape) {
          max-width: 100%;
          display: block;
          width: 100%;
          margin-bottom: 10px;
        }
      }

      &.fixed-width-lg {
        max-width: 50%;
        width: 50%;

        @include media-breakpoint-for(mobile) {
          max-width: 100%;
          display: block;
          width: 100%;
          margin-bottom: 10px;
        }

        @include media-breakpoint-for(tab) {
          max-width: 100%;
          display: block;
          width: 100%;
          margin-bottom: 10px;
        }

        @include media-breakpoint-for(tab-landscape) {
          max-width: 100%;
          display: block;
          width: 100%;
          margin-bottom: 10px;
        }
      }

    }
  }

  .grid-col-right {
    display: inline-block;

    @include media-breakpoint-for(mobile) {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }

    @include media-breakpoint-for(tab) {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }

    @include media-breakpoint-for(tab-landscape) {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }

    >div {
      float: right;
    }

    button {
      float: right;
      margin-left: 10px;
      box-shadow: none !important;
      white-space: nowrap;

      i {
        font-weight: 600;
        font-size: 16px;
      }

      &:last-of-type {
        margin-left: 0px;

        @include media-breakpoint-for(mobile) {
          margin: 2px 0px;
        }

        @include media-breakpoint-for(tab) {
          margin: 2px 0px;
        }

        @include media-breakpoint-for(tab-landscape) {
          margin: 2px 0px;
        }
      }
    }

    .checkbox-default {
      width: auto;
      float: right;

      p {
        white-space: nowrap;
      }
    }

    img {
      float: right;
    }
  }

}