@import "./responsive";

.main-header {
  height: 64px;
  border-radius: 0px 0px 8px 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  background: #ffffff;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 100;

  &.order-wizard {
    height: 46px;

    .title {
      text-align: -webkit-center;
      display: block;
      line-height: 3;
      font-family: "DINPro-Bold";
      font-size: 17px;
    }

    .logo-icon {
      height: 46px;
      padding-top: 3px;
    }

    .close {
      height: 46px;
    }
  }

  .custom-navbar {
    height: 64px;
    width: 100%;
    padding: 0px;
    float: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    @include media-breakpoint-for(mobile) {
      width: 100%;
    }

    @include media-breakpoint-for(tab) {
      width: 100%;
    }

    @include media-breakpoint-for(tab-landscape) {
      width: 100%;
    }

    .navbar-brand {
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      margin-right: 0px;
      @media screen and (min-width: 1200px) {
        position: relative;
        left: auto;
        top: auto;
        transform: none;
        margin-right: 1rem;
      }

      .logo {
        justify-content: center;

        img {
          height: 25px;
          @media screen and (min-width: 1200px) {
            height: 40px;
          }
        }
      }
    }

    .navbar-toggler {
      @include media-breakpoint-for(desktop-down) {
        color: $fff-brand-green;
        border: 0;
        padding: 0;
        display: block;

        .navbar-toggler-icon {
          display: none;
        }

        i {
          color: $fff-brand-green;
        }
      }

      @include media-breakpoint-for(tab) {
        position: absolute;
        left: 0px;
        color: $fff-brand-green;
        border: 0;
        padding: 0;
        display: block;

        .navbar-toggler-icon {
          display: none;
        }

        i {
          color: $fff-brand-green;
        }
      }

      @include media-breakpoint-for(tab-landscape) {
        position: absolute;
        left: 0px;
        color: $fff-brand-green;
        border: 0;
        padding: 0;
        display: block;

        .navbar-toggler-icon {
          display: none;
        }

        i {
          color: $fff-brand-green;
        }
      }
    }

    .navbar-collapse {
      @media screen and (max-width: 1200px) {
        background-color: white;
        padding: 15px;
        text-align: left;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
        position: fixed;
        left: 0px;
        top: 59px;
        height: calc(100vh - 60px);
        overflow-y: auto;
        overflow-x: hidden;
      }

      ul {
        width: 100%;

        @media screen and (max-width: 1200px) {
          margin: 0px 15px 15px !important; // TODO: re-enable search bar after fix
          padding-bottom: 15px;
          border-bottom: 1px solid rgba($fff-brand-grey, 0.72);
          display: block;
          width: auto;
        }

        li {
          @media screen and (max-width: 1200px) {
            padding: 10px 0px;
            margin-bottom: 5px;
          }

          &.nav-item {
            height: auto;
            @media screen and (min-width: 1200px) {
              height: 64px;
            }

            button {
              height: 30px;
            }

            a {
              font-family: $fff-font-sans;
              font-weight: bold;
              height: 100%;
              -webkit-align-items: center;
              align-items: center;
              display: inline-flex;
              font-size: 12px;
              padding: 0px;
              color: rgba($fff-brand-grey, 0.72);
              background-image: linear-gradient($fff-brand-new-accent, $fff-brand-new-accent);
              background-position:16px calc(100% - 20px);
              background-repeat: no-repeat;
              background-size: 0% 1px;
              white-space: nowrap;
              
              &:hover,
              &:focus,
              &:active {
                background-size: calc(100% - 32px) 1.5px;
              }
              
              @media screen and (min-width: 1200px) {
                color: black;
                font-size: 14px;
                padding: 0px 14px;
              }

              &.active {
                color: $fff-brand-green !important;
              }
            }

            &.dropdown {
              .on-the-menu {
                position: fixed;
                left: 0px;
                right: 0px;
                top: 0px;
                margin-top: 54px;
                width: 100%;
                background-color: white;

                .list-items-wrapper {
                  display: flex;
                  justify-content: center;
                  padding-top: 16px;
                }

                .list-items-wrapper li:nth-child(1) .products-title {
                  color: $fff-brand-foodbox-primary;
                }
                
                .list-items-wrapper li:nth-child(2) .products-title {
                  color: $fff-brand-new-primary;
                }
                
                .list-items-wrapper li:nth-child(3) .products-title {
                  color: $fff-brand-new-secondary;
                }

                @include media-breakpoint-for(mobile) {
                  ul {
                    display: none;
                  }
                }

                @include media-breakpoint-for(tab) {
                  ul {
                    display: none;
                  }
                }

                @include media-breakpoint-for(tab-landscape) {
                  ul {
                    display: none;
                  }
                }

                @include breakpoint (desktop-up) {
                  height: 255px;
                }

                @include media-breakpoint-for(desktop-down) {
                  display: none;
                }

                ul {
                  background-color: white;
                  text-align: center;
                  margin: 0px;
                  padding: 0px 10px;

                  li {
                    display: inline-block;
                    float: none;
                    margin: 10px;
                    vertical-align: top;
                    width: 300px;
                    max-width: 300px;

                    .new-products-item {
                      display: flex;
                      align-items: start;
                      padding: 18px 20px;
                      border-radius: 8px;
                      background-image: unset;
                      box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
                    }

                    .products-title-wrapper {
                      display: flex;
                      align-items: center;
                      padding-bottom: 16px;
                    }
                  }
                }

                .products-img {
                  width: 50px;
                  height: 50px;
                }

                .products-title {
                  font-size: 24px;
                  color: $fff-brand-new-primary;
                  text-align: start;
                  margin-bottom: 0;
                  padding-left: 8px;
                }

                .products-description {
                  margin-bottom: 0;
                  padding: 0 14px;
                  text-wrap: auto;
                  text-align: start;
                }
              }
            }
          }
        }
      }
    }
  }

  .footer-links {
    display: none;

    @include media-breakpoint-for(desktop-down) {
      display: block;
      margin: 0px 15px;

      a {
        width: 50%;
        display: inline-block;
        font-family: "SoleilLt", sans-serif;
        font-size: 14px;
        margin-bottom: 10px;
        color: $fff-brand-green;
        padding: 5px 0;
      }

      .footer-icon {
        border-top: 1px solid rgba($fff-brand-grey, 0.72);
        padding-top: 15px;
        margin-top: 5px;
        width: 100%;
        color: rgba($fff-brand-grey, 0.72);

        a {
          width: 30px;
          height: 30px;
          padding: 8px 0px 8px;
          font-size: 15px;
          line-height: 17px;
          border-radius: 50%;
          background: #fff;
          margin-right: 15px;
          text-align: center;
          color: $fff-brand-green;
          background-color: rgba($fff-brand-grey, 0.16);
        }
      }
    }

    @include media-breakpoint-for(tab) {
      display: block;
      margin: 0px 15px;

      a {
        width: 50%;
        display: inline-block;
        font-family: "SoleilLt", sans-serif;
        font-size: 14px;
        margin-bottom: 10px;
        color: $fff-brand-green;
        padding: 5px 0;
      }

      .footer-icon {
        border-top: 1px solid rgba($fff-brand-grey, 0.72);
        padding-top: 15px;
        margin-top: 5px;
        width: 100%;
        color: rgba($fff-brand-grey, 0.72);

        a {
          width: 30px;
          height: 30px;
          padding: 8px 0px 8px;
          font-size: 15px;
          line-height: 17px;
          border-radius: 50%;
          background: #fff;
          margin-right: 15px;
          text-align: center;
          color: $fff-brand-green;
          float: left;
          background-color: rgba($fff-brand-grey, 0.16);
        }
      }
    }

    @include media-breakpoint-for(tab-landscape) {
      display: block;
      margin: 0px 15px;

      a {
        width: 50%;
        display: inline-block;
        font-family: "SoleilLt", sans-serif;
        font-size: 14px;
        margin-bottom: 10px;
        color: $fff-brand-green;
        padding: 5px 0;
      }

      .footer-icon {
        border-top: 1px solid rgba($fff-brand-grey, 0.72);
        padding-top: 15px;
        margin-top: 5px;
        width: 100%;
        color: rgba($fff-brand-grey, 0.72);

        a {
          width: 30px;
          height: 30px;
          padding: 8px 0px 8px;
          font-size: 15px;
          line-height: 17px;
          border-radius: 50%;
          background: #fff;
          margin-right: 15px;
          text-align: center;
          color: $fff-brand-green;
          float: left;
          background-color: rgba($fff-brand-grey, 0.16);
        }
      }
    }
  }

  .top-nav-right {
    position: absolute;
    right: 0px;
    width: auto;
    display: block;

    @media screen and (min-width: 1200px) {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative;
    }

    // search desktop button
    .search-desktop {
      width: auto;
      align-items: center;
      display: inline-block;

      @include media-breakpoint-for(mobile) {
        display: none;
      }

      @include media-breakpoint-for(tab) {
        display: none;
      }

      @include media-breakpoint-for(tab-landscape) {
        display: none;
      }

      .search-desktop-btn {
        border: 0;
        background-color: transparent;
        outline: 0;
        position: relative;
        box-shadow: none;
        padding: 0;
        height: 64px;
        text-align: right;
        float: right;

        &:not(:disabled):not(.disabled):active,
        &:hover,
        &:focus {
          background-color: transparent;
          box-shadow: none;
          outline: 0;
        }

        @include media-breakpoint-for(mobile) {
          display: none;
        }

        @include media-breakpoint-for(tab) {
          display: none;
        }

        @include media-breakpoint-for(tab-landscape) {
          display: none;
        }

        i {
          font-size: 40px;
          font-weight: 300;
          margin-bottom: -5px;
          -ms-transform: rotate(90deg);
          -webkit-transform: rotate(90deg);
          transform: rotate(90deg);

          &::before {
            color: rgba($fff-brand-grey, 0.72);
          }

          &.icon-fff-close {
            font-size: 30px;
            margin-bottom: 0px;
          }
        }
      }
    }

    .user-info {
      height: 64px;
      padding: 0px;
      width: auto;
      float: right;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      align-items: center;
      -webkit-justify-content: flex-end;
      justify-content: flex-end;
      position: relative;
      margin-left: 20px;

      @include media-breakpoint-for(mobile) {
        margin-right: 0;
      }

      @include media-breakpoint-for(tab) {
        margin-right: 0;
      }

      @include media-breakpoint-for(tab-landscape) {
        margin-right: 0;
      }

      .btn {
        height: 40px;
        padding-left: 20px;
        padding-right: 20px;
        box-shadow: none;
        line-height: 17px;
        white-space: nowrap;
        border-color: rgba(0,0,0,.125);
        color: #fff;

        @include media-breakpoint-for(mobile) {
          font-size: 14px;
          padding: 0px 20px;
          height: 30px;
          display: inline-flex;
          line-height: 30px;
        }

        @include media-breakpoint-for(tab) {
          font-size: 14px;
          padding: 0px 20px;
          height: 30px;
          display: inline-flex;
          line-height: 30px;
        }

        @include media-breakpoint-for(tab-landscape) {
          font-size: 14px;
          padding: 0px 20px;
          height: 30px;
          display: inline-flex;
          line-height: 30px;
        }

        .icon-fff-user {
          font-weight: bold;
          vertical-align: middle;

          @include media-breakpoint-for(mobile) {
            line-height: 17px;
          }

          @include media-breakpoint-for(tab) {
            line-height: 17px;
          }

          @include media-breakpoint-for(tab-landscape) {
            line-height: 17px;
          }

          &::before {
            font-size: 20px;
          }
        }

        &.log-btn {
          display: none;
          @media screen and (min-width: 1200px) {
            display: block; 
          }
        }

        &.acc-btn {
          padding-left: 0.75rem;
          padding-right: 0.75rem;
          color: white;
          @media screen and (min-width: 1200px) {
            margin-left: 10px;
            padding-left: 20px;
            padding-right: 20px;
          }
        }

        .material-icons {
          font-size: 26px;
          color: rgba($fff-brand-grey, 0.7);
        }
      }

      .btn-secondary {
        border-color: $fff-brand-new-secondary;
        color: $fff-brand-new-secondary;

        &:hover, &:active, &:focus {
          border-color: $fff-brand-new-secondary;
          background-color: $fff-brand-new-fill;
        }
      }

      .btn-primary {
        border-color: $fff-brand-new-primary;
        color: $fff-brand-new-secondary;

      }

      .acc-dropdown {
        position: absolute;
        background-color: white;
        padding: 15px;
        text-align: left;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
        width: 250px;
        border-radius: 0px 0px 8px 8px;

        @include media-breakpoint-for(mobile) {
          width: 100%;
          position: absolute;
        }

        @include media-breakpoint-for(tab) {
          width: 100%;
          position: absolute;
        }

        @include media-breakpoint-for(tab-landscape) {
          width: 250px;
          position: absolute;
        }

        a {
          display: block;
          width: 100%;
          cursor: pointer;
          text-decoration: none;
          padding: 5px 0px;
          color: rgba($fff-brand-grey, 0.72);

          &:hover {
            color: $fff-brand-grey;
          }
        }
      }
    }
  }

  .fff-search {
    position: absolute;
    right: 0px;
    left: 0;
    top: 0;
    height: 64px;
    padding: 0px;
    align-items: center;
    background-color: transparent;
    transition: opacity 0.2s ease-in-out;
    -moz-transition: opacity 0.2s ease-in-out;
    -webkit-transition: opacity 0.2s ease-in-out;
    opacity: 0;

    &.hide {
      visibility: hidden;
    }

    &.show {
      opacity: 1;
      display: block;
      visibility: visible;

      > div {
        .gsc-control-cse {
          .gsc-control-wrapper-cse {
            form {
              margin-bottom: 0px;

              .gsc-search-box {
                margin-bottom: 0px;

                .gsc-search-button {
                  transform: translateX(-150px);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }

    > div {
      width: 100%;

      .gsc-control-cse {
        padding: 0;
        border: 0px;

        .gsc-control-wrapper-cse {
          margin: 0px;
          height: 64px;

          form {
            height: 100%;

            .gsc-search-box {
              height: 100%;
              margin: 0px;

              .gsc-input {
                padding-right: 0px;

                .gsc-input-box {
                  border: 2px solid rgba($fff-brand-green, 0.16);
                  padding-left: 45px;
                  border-radius: 20px;

                  &:not(:disabled):not(.disabled):active,
                  &:hover,
                  &:active,
                  &:focus {
                    border: 2px solid $fff-brand-green;
                  }

                  .gsst_a {
                    padding: 1px 15px;

                    .gscb_a {
                      color: rgba($fff-brand-green, 0.16);
                      font-size: 22px;
                      display: block;

                      &:hover {
                        color: $fff-brand-green;
                      }
                    }
                  }
                }
              }

              .gsc-search-button {
                position: absolute;
                top: 0px;
                left: 150px;
                right: unset;
                padding: 0px;
                width: 50px;
                align-items: center;
                height: 64px;
                background-color: transparent;
                border-color: transparent;
                transition: all 0.3s linear;
                -moz-transition: all 0.3s linear;
                -webkit-transition: all 0.3s linear;
                opacity: 0;

                &:not(:disabled):not(.disabled):active,
                &:hover,
                &:active,
                &:focus {
                  box-shadow: none !important;
                }

                svg {
                  color: rgba($fff-brand-grey, 0.72);
                  width: 20px;
                  height: 20px;
                  margin-top: 0px;
                  -webkit-transform: rotate(90deg);
                  transform: rotate(90deg);
                }
              }
            }
          }

          .gsc-results-wrapper-visible {
            height: 500px;
            overflow-y: auto;
            background-color: white;
            padding: 0px 15px;
          }
        }
      }
    }
  }

  .desktop-gsearch {
    display: block;
    position: absolute;
    top: 0px;
    left: 175px;
    right: 65px;
    z-index: 1;

    @include media-breakpoint-for(mobile) {
      display: none;
    }

    @include media-breakpoint-for(tab) {
      display: none;
    }

    @include media-breakpoint-for(tab-landscape) {
      display: none;
    }
  }

  .m-gsearch {
    display: none;

    @include media-breakpoint-for(mobile) {
      display: block;

      .fff-search {
        left: 0px;
        right: 0px;

        &.show {
          > div {
            .gsc-control-cse {
              .gsc-control-wrapper-cse {
                form {
                  .gsc-search-box {
                    .gsc-search-button {
                      transform: translateX(0px);
                    }
                  }
                }
              }
            }
          }
        }

        > div {
          .gsc-control-cse {
            background-color: rgba($fff-brand-grey, 0.16);
            padding: 16px;

            .gsc-control-wrapper-cse {
              height: auto;

              form {
                .gsc-search-box {
                  .gsc-input {
                    .gsc-input-box {
                      border: 0px;
                      padding: 0px 10px 0px 35px;
                      height: 30px;
                      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);

                      &:not(:disabled):not(.disabled):active,
                      &:hover,
                      &:active,
                      &:focus {
                        border: 0px;
                      }

                      td {
                        padding: 0px;
                      }

                      .gsst_a {
                        width: 18px;
                        height: 18px;
                        border-radius: 50%;
                        align-items: center;
                        text-align: center;
                        display: flex;
                        background-color: rgba($fff-brand-grey, 0.16);
                        margin-left: 4px;
                        padding: 01px 4px;

                        .gscb_a {
                          color: rgba($fff-brand-green, 0.16);
                          font-size: 18px;
                          display: block;
                          line-height: 18px;
                          text-align: center;

                          &:hover {
                          }
                        }
                      }

                      input {
                        font-size: 14px;
                      }
                    }
                  }

                  .gsc-search-button {
                    left: 5px;
                    -webkit-transition: unset;
                    opacity: 1;
                    height: 61px;

                    svg {
                      width: 15px;
                      height: 15px;
                    }
                  }
                }
              }

              .gsc-results-wrapper-visible {
                overflow-y: auto;
                background-color: white;
                padding: 0px 15px;
                height: auto;
                position: fixed;
                bottom: 0px;
                top: 105px;
                left: 30px;
                right: 30px;
              }
            }
          }
        }

        .gsc-results-wrapper-overlay {
          top: 7%;
        }
      }
    }

    @include media-breakpoint-for(tab) {
      display: block;

      .fff-search {
        left: 0px;
        right: 0px;

        &.show {
          > div {
            .gsc-control-cse {
              .gsc-control-wrapper-cse {
                form {
                  .gsc-search-box {
                    .gsc-search-button {
                      transform: translateX(0px);
                    }
                  }
                }
              }
            }
          }
        }

        > div {
          .gsc-control-cse {
            background-color: rgba($fff-brand-grey, 0.16);
            padding: 16px;

            .gsc-control-wrapper-cse {
              height: auto;

              form {
                .gsc-search-box {
                  .gsc-input {
                    .gsc-input-box {
                      border: 0px;
                      padding: 0px 10px 0px 35px;
                      height: 30px;
                      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);

                      &:not(:disabled):not(.disabled):active,
                      &:hover,
                      &:active,
                      &:focus {
                        border: 0px;
                      }

                      td {
                        padding: 0px;
                      }

                      .gsst_a {
                        width: 18px;
                        height: 18px;
                        border-radius: 50%;
                        align-items: center;
                        text-align: center;
                        display: flex;
                        background-color: rgba($fff-brand-grey, 0.16);
                        margin-left: 4px;
                        padding: 01px 4px;

                        .gscb_a {
                          color: rgba($fff-brand-green, 0.16);
                          font-size: 18px;
                          display: block;
                          line-height: 18px;
                          text-align: center;

                          &:hover {
                          }
                        }
                      }

                      input {
                        font-size: 14px;
                      }
                    }
                  }

                  .gsc-search-button {
                    left: 5px;
                    -webkit-transition: unset;
                    opacity: 1;
                    height: 61px;

                    svg {
                      width: 15px;
                      height: 15px;
                    }
                  }
                }
              }

              .gsc-results-wrapper-visible {
                overflow-y: auto;
                background-color: white;
                padding: 0px 15px;
                height: auto;
                position: fixed;
                bottom: 0px;
                top: 105px;
                left: 30px;
                right: 30px;
              }
            }
          }
        }

        .gsc-results-wrapper-overlay {
          top: 7%;
        }
      }
    }

    @include media-breakpoint-for(tab-landscape) {
      display: block;

      .fff-search {
        left: 0px;
        right: 0px;

        &.show {
          > div {
            .gsc-control-cse {
              .gsc-control-wrapper-cse {
                form {
                  .gsc-search-box {
                    .gsc-search-button {
                      transform: translateX(0px);
                    }
                  }
                }
              }
            }
          }
        }

        > div {
          .gsc-control-cse {
            background-color: rgba($fff-brand-grey, 0.16);
            padding: 16px;

            .gsc-control-wrapper-cse {
              height: auto;

              form {
                .gsc-search-box {
                  .gsc-input {
                    .gsc-input-box {
                      border: 0px;
                      padding: 0px 10px 0px 35px;
                      height: 30px;
                      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);

                      &:not(:disabled):not(.disabled):active,
                      &:hover,
                      &:active,
                      &:focus {
                        border: 0px;
                      }

                      td {
                        padding: 0px;
                      }

                      .gsst_a {
                        width: 18px;
                        height: 18px;
                        border-radius: 50%;
                        align-items: center;
                        text-align: center;
                        display: flex;
                        background-color: rgba($fff-brand-grey, 0.16);
                        margin-left: 4px;
                        padding: 01px 4px;

                        .gscb_a {
                          color: rgba($fff-brand-green, 0.16);
                          font-size: 18px;
                          display: block;
                          line-height: 18px;
                          text-align: center;

                          &:hover {
                          }
                        }
                      }

                      input {
                        font-size: 14px;
                      }
                    }
                  }

                  .gsc-search-button {
                    left: 5px;
                    -webkit-transition: unset;
                    opacity: 1;
                    height: 61px;

                    svg {
                      width: 15px;
                      height: 15px;
                    }
                  }
                }
              }

              .gsc-results-wrapper-visible {
                overflow-y: auto;
                background-color: white;
                padding: 0px 15px;
                height: auto;
                position: fixed;
                bottom: 0px;
                top: 105px;
                left: 30px;
                right: 30px;
              }
            }
          }
        }

        .gsc-results-wrapper-overlay {
          top: 7%;
        }
      }
    }
  }

  .logo-center {
    height: 64px;
    text-align: center;
    padding-top: 10px;

    img {
      height: 40px;
    }
  }

  .logo-icon {
    height: 64px;
    text-align: center;
    padding-top: 10px;

    img {
      height: 40px;
    }
  }

  .close {
    position: absolute;
    right: 0;
    top: 0;
    height: 64px;

    &:not(:disabled):not(.disabled):active,
    &:hover,
    &:focus {
      background-color: transparent;
    }
  }

  .user-info {
    justify-content: flex-end;

    a {
      color: $fff-brand-green;
      font-weight: 600;

      @include media-breakpoint-for(mobile) {
        font-size: 12px;
      }

      @include media-breakpoint-for(tab) {
        font-size: 12px;
      }

      @include media-breakpoint-for(tab-landscape) {
        font-size: 12px;
      }
    }
  }
}

.navbar-expand-md .navbar-collapse {
  @include media-breakpoint-for(desktop-down) {
    display: block !important;
  }

  @include media-breakpoint-for(tab) {
    display: block !important;
  }

  @include media-breakpoint-for(tab-landscape) {
    display: block !important;
  }
}

.collapse:not(.show) {
  @include media-breakpoint-for(desktop-down) {
    display: none !important;
  }

  @include media-breakpoint-for(tab) {
    display: none !important;
  }

  @include media-breakpoint-for(tab-landscape) {
    display: none !important;
  }
}

.welcome-header {
  display: flex !important;
  flex-basis: auto;
  flex-grow: 1;
  align-items: center;

  @include media-breakpoint-for(mobile) {
    display: none;
  }

  p {
    color: black;
    font-family: "Soleil-Bold", sans-serif;
    font-size: 22px;
    margin: 0 auto;
    flex-direction: row;
    display: flex;
  }
}

.nav-login-item{
  @include breakpoint(desktop-up) {
    display: none;
  }
}