.admin-bg-white{
  padding: 15px;
  margin: 0px;
  background-color: white;
  box-shadow: 0px 1px 4px rgba($fff-brand-grey, 0.08);
  h2{ 
   font-size: 18px;
   font-weight: 600;
   margin-bottom: 15px;
   margin-top: 0px;
   font-family: 'DINPro-Bold', sans-serif ;
   display: inline-block;
 }
 .order-title {
  font-family: 'Soleil-Bold', sans-serif;
  text-transform: uppercase;
  font-size: 12px;
  color: rgba($fff-brand-grey, 0.72);
  border-bottom: 1px solid rgba($fff-brand-grey, 0.16);
  padding: 0px;
  margin-bottom: 0px;
  margin: 0px 4px;
  &:first-of-type{
    margin-left: 0px;
  }
}
.order-value {
font-family: 'SoleilLt', sans-serif;
font-size: 16px;
padding: 7px 0px 0px 0px;
margin: 0px 4px;
&.enlarged-text{
  font-size: 24px;
}
&:first-of-type{
  margin-left: 0px;
}
}
.order-text {
  font-family: 'SoleilLt', sans-serif;
  font-size: 14px;
  padding: 7px 0px 0px 0px;
  margin: 0px 4px;
  &:first-of-type{
    margin-left: 0px;
  }
  }
.finance-table{
  margin: 15px 0px 30px 0px;
  &.add-border-top{
    .row{
      &:first-child{
        border-top: 1px solid rgba($color:$fff-brand-grey, $alpha: .08);
      }
    }
  }
  &.remove-border-bottom{
    .row{
      &:last-child{
        box-shadow: none;
      }
    }
  }
  .row{
    box-shadow: 0px 1px 0px rgba($fff-brand-grey, 0.08);
    align-items: center;
    height: 25px;
    line-height: unset;
    flex-wrap: unset;
    div[class^="col"]{
      padding-top: 0px;
      padding-bottom: 0px;
      text-align: right;
      color: $fff-brand-grey;
      font-size: 12px;
      height: auto;
      line-height: unset;
      &:first-of-type{
        text-align: left;
      }
      b{
        font-family: 'Soleil-Bold', sans-serif;
        font-weight: 400;
      }
    }
    .text-center{    
      padding-top: 1rem;
      padding-bottom: 1rem;
      color: $fff-brand-grey;
      font-size: 14px;
      .pipeline{
        width: 1px;
        height: 100%;
        background-color: rgba($fff-brand-grey,0.18);
        display: inline-block;
      }
    }
  }
}
  &.margin-bottom-lg{
    margin-bottom: 30px;
  }
  &.margin-bottom-md{
    margin-bottom: 15px;
  }
  &.order-finance-chart{
    canvas{
      max-height: 600px !important;
      height: auto !important;
    }
  }
  &.order-finance-analytics{
    .custom-date-filter{
      button{
        border-radius: 0; 
        box-shadow: none !important; 
        padding: 0 15px;
        border-right: 0;
      }
      button:first-child{
        border-radius: 5px 0 0 5px;
      }
      button:last-child{
        border-radius: 0 5px 5px 0;
        border-right: 2px solid $fff-brand-new-secondary;
      }
      .btn-active{
        background-color: #d1e5da !important;
      }
    }
    .col-md-2{
      flex: 0 0 20.66667%; 
      max-width: 20.66667%;
    }
    .double-date{
      .calendar-wrap{
        .p-calendar{
          display: inline-block !important;
          width: 50%;   
          .p-inputtext{
            background-image: url(../img/icons/fff-chevron.svg);
            background-repeat: no-repeat;
            background-position: 95% center;
            cursor: pointer;   
            &:hover, &:focus{
              border-color: $fff-brand-green !important;
            }
          }
          &:first-of-type{
            .p-inputtext{
              transition: none !important;
              border-top-right-radius: 0 !important;
              border-bottom-right-radius: 0 !important;
              border-right-color: transparent;              
              &:hover, &:focus{
                border-color: $fff-brand-green !important;
              }
               }
          }
          &:last-of-type{
            .p-inputtext{
              border-top-left-radius: 0 !important;
              border-bottom-left-radius: 0 !important;
            }
          }
        }
        &.date-range{
          .p-calendar {
            width: 100%;
            @include breakpoint(desktop-up){
              width: 100%;
            }
          }
          &:last-of-type{
            .p-inputtext{
              background-position: 98% center;
              padding-right: 34px;
              border-radius: 5px !important;
              border-right-color: rgba(3, 110, 56, 0.18);
            }
          }
        }
      }
    }
    .vs{
      height: 100%;
      display: inline-block;
      line-height: 4.5;
      font-size: 16px;
    }
  }
  &.delivery-analytics{
    .vs-separator{
      margin-top: 40px;
      height: 40px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 600;
    }
    .box{
      padding: 0px;
    }
  }
  &.deferred-revenue{
    height: 100%;
    .btn{
      box-shadow: none;
    }
  }
  &.revenue-chart{
    height: 100%;
  }
  .dailly-delivery-values{
    padding: 0 15px;
    box-shadow: none !important;
    margin-bottom: 30px;
    .card-value{
      padding-bottom: 0 !important;
    }
  }
}

.admin-tab-content{
  background-color: white;
  box-shadow: 0px 1px 4px rgba($fff-brand-grey, 0.08);
  .mainTabs{
    margin: 15px 0px 0px 0px;
  }
  .tab-content{
    padding: 12px 16px;
    margin: 0px;
    background-color: white;
    &.no-padding{
      padding: 0px;
    }
  }
  .inline-grid{
    &.light-green{
      padding: 10px 15px;
    }
  }
}
.search-filter-panel {
  margin-top: 0px;
  margin-bottom: 0px;
  .input-group {
    display: flex;
    margin-bottom: 0px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    .date-picker-customer-list {
      width: 200px;
      input {
        border-radius: 5px 0 0 5px !important;
      }
    }
    .form-control {
      display: inline-block;
      width: 200px;
      height: 40px;
      border-radius: 0 !important;
      margin-bottom: 0px;
      border-right-width: 0px;
      border: 2px solid rgba(3, 110, 56, 0.18);
      &:not(:disabled):not(.disabled):active,
      &:focus{
        border-right-width: 2px;
        border-color: $fff-brand-new-secondary;}
    }
    .input-group-append {
      display: flex;
      margin-left: 0px !important;
      .p-calendar{
          .p-inputtext{
            border-radius: 0px !important;
            min-width: 110px;
            border-right: 2px solid transparent;
            &:active,
            &:focus{
              border-right: 2px solid $fff-brand-green;
              z-index: 1;
            }
          }
      }
      .p-dropdown{
        transition: none !important;
      }
      .custom-p-dropdown{
       // min-width: 230px;
        border-radius: 0px !important;
        .p-inputtext{
          border-radius: 0px !important;
          border-right-width: 0px;
          &:not(:disabled):not(.disabled):active,
          &:focus{
            border-right-width: 2px;}
        }
    }
    .selectBox{
      width: 200px;
      .fff_ {
        &_control {
          border-radius: 0px !important;
        }
      }
    }
      button{
        border-radius: 0px 5px 5px 0px;
        height: 40px;
        padding: 0px 15px;
        box-shadow: none;
        white-space: nowrap;
        border-left: 0px;
        &:not(:disabled):not(.disabled):active,
        &:focus,
        &:hover {
          box-shadow: none;
        }
      }
    }
  }
}
.addnew{
  text-align: right;
  position: absolute;
  right: 15px;
  top: 23px;
  .btn{
    margin-left: 10px;
    &:first-of-type{
      margin-left: 0px;
    }
  }
}
.seasonal-discount-top-row{
  margin: 0px;
  .checkbox-col{
    padding-top: 5px;
  > label{
    margin-bottom: 0px;
  }
  }}
  
.custom-p-dropdown{
  &.p-dropdown{ 
    padding: 0rem;
    border: 2px solid rgba($fff-brand-green, 0.18);
    height: 40px;
    line-height: 2.4;
    outline: 0px;
    box-shadow: none !important;  
    width: 100%;
    &:not(:disabled):not(.disabled):active,
    &:focus,
    &:hover {
      outline: 0px !important;
      box-shadow: none !important;  
      border: 2px solid rgba(3, 110, 56, 0.18);
      border-color: rgba(3, 110, 56, 0.18) !important;
    }
    &.p-focus{
      outline: 0px !important;
      box-shadow: none !important;  
      border: 2px solid $fff-brand-green;
      border-color: $fff-brand-green !important;
    }
    .p-inputtext{
      height: auto;
      outline: 0px;
      box-shadow: none;
      font-size: 14px;
      padding-right: 3rem;
      font-family: 'SoleilLt', sans-serif;
      text-transform: capitalize;
      min-height: unset;
      &:not(:disabled):not(.disabled):active,
      &:focus,
      &:hover {
        outline: 0px;
        box-shadow: none;  
        border:0;
      }
    }
    .p-dropdown-trigger{
      outline: 0px;
      width: 3rem;
      height: 35px;
      color:rgba($fff-brand-green, 0.18);
      &:not(:disabled):not(.disabled):active,
      &:focus,
      &:hover {
        color: $fff-brand-green;
      }
    }
    .p-dropdown-panel{
      .p-dropdown-items-wrapper{
        .p-dropdown-items{
          .p-dropdown-item{
            padding: 0em 0.857em;
            &.p-highlight{
              background-color: $fff-brand-green;
            }
            &:not(:disabled):not(.disabled):active,
            &:focus,
            &:hover {
              background: rgba($fff-brand-green, 0.16);
              color: black;
            }
          }
        } }
    } 
  }
}
.button-wrapper-daily-deliveries {
  display: flex;
  flex-wrap: wrap;
  row-gap: 12px;
}
.daily-deliveries-meal-day-code {
  color: $fff-brand-new-primary;
  font-size: 22px;
  line-height: 1;
  font-weight: bold;
  padding: 0.625rem;
}
.daily-deliveries{  
 .p-calendar {
  .p-inputtext{
    border-radius: 4px 0px 0px 4px !important;
    font-weight: bold;
    color: $fff-brand-new-secondary;
    border: 2px solid $fff-brand-new-secondary;
    border-color: $fff-brand-new-secondary !important;
  }
 } 
 .btn-active{
  background-color: #d1e5da !important;
}
.dashboard-tabs{
  text-align: right;
  .btn{
border-radius: 0px;
box-shadow: none;
border-right: 0px;
padding: 0px 15px;
&:first-of-type{
  border-left: 0px;
}
&:last-of-type{
  border-radius: 0px 4px 4px 0px;
  border-right: 2px solid $fff-brand-new-secondary;
}
  }
}
}

.button-wrapper{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  .btn{
    box-shadow: none;
  }
}

.recipe-form{
  margin: 0px;
  .light-bg{
padding: 15px;
margin-bottom: 15px;
  }
  h4{
    margin-bottom: 20px;
    font-size: 20px;
    text-align: center;
  }
  .checkbox-default{
    p{
      width: 100%;
      color:$fff-brand-grey;
      font-size: 12px;
      text-transform: uppercase;
      margin-bottom: 2px;
      font-weight: 400;
      font-family: 'Soleil-Bold', sans-serif;
    }
  }
  .light-bg{
    background-color: rgba($fff-brand-grey, 0.08);
  }
}

.template-copy-status{
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color:#cce5ff;
  border: 2px solid #b8daff;
  border-radius: 5px!important;
  padding-top: 15px;
  text-align: center;

  p{
    color: #004085;
    padding: 0 15px;
  }

  &.in-progress{
    background-color:#fff3cd;
    border: 2px solid #ffeeba;

    p{
      color:#856404 ;
    }
  }
}

.partnership-sales-calendar {
  .p-datepicker {
    .p-datepicker-header {
      .p-datepicker-prev,
      .p-datepicker-next {
        padding: 0;
        width: 0;
      }

      .p-datepicker-title {
        width: 100%;
        text-align: center;
        select {
          width: 100%;
          text-align: center;
          cursor: pointer;
          font-weight: 600;
          border: 1px solid rgba(0, 0, 0, 0.125);
          padding: 5px 10px;
          border-radius: 3px;
          appearance: none;

          &:hover,
          &:focus,
          &:active {
            border: 1px solid $fff-brand-new-secondary;
          }
          
          option {
            text-align: start;
            padding: 10px 0;
          }
        }
      }
    }
  }

  .p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
    background: #036E38 !important;
  }
}