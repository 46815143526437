@import "./responsive";

// header row
.frozen-headr-row {

  .page-header-frozen {
    width: 100%;
    position: relative;
    padding-top: 40px;
    padding-bottom: 40px;

    .frozen-header-title {
      height: auto;
      padding: 0px;
      background-color: #dddede;
      text-align: center;

      &::before {
        content: "";
        background-image: url("../../assets/img/fff-leaf-ml.svg");
        background-repeat: no-repeat;
        background-position: top left;
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        position: absolute;
        width: 45px;
        height: 22px;
        z-index: 1;
        top: -10px;
        left: 16px;
        background-size: contain;

        @include media-breakpoint-for(mobile) {
          left: 15px;
          top: -11px;
          width: 43px;
          height: 20px;
        }

        @include media-breakpoint-for(tab) {
          left: 15px;
          top: -11px;
          width: 43px;
          height: 20px;
        }

        @include media-breakpoint-for(tab-landscape) {
          left: 15px;
          top: -11px;
          width: 43px;
          height: 20px;
        }
      }

      h1 {
        margin: 0;
        font-size: 42px;
        font-family: "DINPro-Bold", sans-serif;
        margin: 0px;
        line-height: initial;

        @include media-breakpoint-for(mobile) {
          font-size: 28px;
          line-height: 30px;
        }

        @include media-breakpoint-for(tab) {
          font-size: 28px;
          line-height: 30px;
        }

        @include media-breakpoint-for(tab-landscape) {
          font-size: 28px;
          line-height: 30px;
        }
      }

      h2 {
        margin: 16px 0px 0px;
        font-size: 28px;
        font-family: "DINPro-Bold", sans-serif;
        margin: 0px;
        color: $fff-brand-dark-grey;

        @include media-breakpoint-for(mobile) {
          font-size: 22px;
          line-height: 30px;
        }

        @include media-breakpoint-for(tab) {
          font-size: 22px;
          line-height: 30px;
        }

        @include media-breakpoint-for(tab-landscape) {
          font-size: 22px;
          line-height: 30px;
        }
      }

      button {
        margin-top: 30px;

        @include media-breakpoint-for(mobile) {
          margin-top: 15px;
        }

        @include media-breakpoint-for(tab) {
          margin-top: 15px;
        }

        @include media-breakpoint-for(tab-landscape) {
          margin-top: 15px;
        }
      }
    }

    .fff-logo-frozen {
      margin: 0 auto;
      width: 100%;
      height: 180px;
      background-image: url(../img/fff-shop-logo.png);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      display: block;

      @include media-breakpoint-for(mobile) {
        height: 125px;
      }

      @include media-breakpoint-for(tab) {
        height: 125px;
      }

      @include media-breakpoint-for(tab-landscape) {
        height: 125px;
      }
    }
  }

  .header-description {
    position: relative;
    // background-color: $fff-brand-light-green;
    margin: 0 auto 60px;

    @include media-breakpoint-for(mobile) {
      padding: 0px 15px;
      margin-bottom: 40px;
    }

    @include media-breakpoint-for(tab) {
      padding: 0px 15px;
      margin-bottom: 40px;
    }

    @include media-breakpoint-for(tab-landscape) {
      padding: 0px 15px;
      margin-bottom: 40px;
    }

    .description-inside {
      display: block;
      padding: 0px 0px;
      margin: 0 auto;
      text-align: center;

      @include media-breakpoint-for(mobile) {
        margin: 0px;
      }

      @include media-breakpoint-for(tab) {
        margin: 0px;
      }

      @include media-breakpoint-for(tab-landscape) {
        margin: 0px;
      }

      p {
        margin-bottom: 30px;
      }

      button {
        margin: 0px 8px;

        @include media-breakpoint-for(mobile) {
          width: 100%;
          margin: 0px;

          &:first-of-type {
            margin-bottom: 15px;
          }
        }

        @include media-breakpoint-for(tab) {
          width: 100%;
          margin: 0px;

          &:first-of-type {
            margin-bottom: 15px;
          }
        }

        @include media-breakpoint-for(tab-landscape) {
          width: 100%;
          margin: 0px;

          &:first-of-type {
            margin-bottom: 15px;
          }
        }
      }
    }
  }
}

.frozen-section-main {
  position: relative;

  &.margin-bottom-xlg {
    @include media-breakpoint-for(mobile) {
      margin-bottom: 30px;
    }

    @include media-breakpoint-for(tab) {
      margin-bottom: 30px;
    }

    @include media-breakpoint-for(tab-landscape) {
      margin-bottom: 30px;
    }
  }

  &.margin-bottom-lg {
    @include media-breakpoint-for(mobile) {
      margin-bottom: 15px;
    }

    @include media-breakpoint-for(tab) {
      margin-bottom: 15px;
    }

    @include media-breakpoint-for(tab-landscape) {
      margin-bottom: 15px;
    }
  }

  &.margin-top-xlg {
    @include media-breakpoint-for(mobile) {
      margin-bottom: 30px;
    }

    @include media-breakpoint-for(tab) {
      margin-bottom: 30px;
    }

    @include media-breakpoint-for(tab-landscape) {
      margin-bottom: 30px;
    }
  }

  p {
    font-size: 14px;
    text-align: center;

    a {
      color: $fff-brand-green;
      border-top: 2px solid transparent;
      border-bottom: 2px solid rgba(6, 109, 56, 0.5);
      cursor: pointer;
      text-decoration: none;
      -webkit-transition: 0.2s;
      transition: 0.2s;

      &:hover {
        color: $fff-brand-green;
        border-bottom-color: rgba(6, 109, 56, 0);
        background: rgba(6, 109, 56, 0.18);
      }
    }
  }

  &:first-child {
    h2 {
      margin-top: 0px;
    }
  }

  h1,
  h2 {
    padding-top: 0px;
    font-size: 38px;
    margin-bottom: 20px;
    margin-top: 40px;
    position: relative;
    z-index: 1;
    color: $fff-brand-new-fill;
    font-family: $fff-font-sans;
    font-weight: 700;
    background-color: $fff-brand-new-primary;

    @include media-breakpoint-for(mobile) {
      font-size: 28px;
      margin-bottom: 30px;
    }

    @include media-breakpoint-for(tab) {
      font-size: 28px;
      margin-bottom: 30px;
    }

    @include media-breakpoint-for(tab-landscape) {
      font-size: 28px;
      margin-bottom: 30px;
    }

    &.leaf-img-txt {
      background-image: url("../../assets/img/fff-leaf-s.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 114px;

      @include media-breakpoint-for(mobile) {
        background-size: 100px;
      }

      @include media-breakpoint-for(tab) {
        background-size: 100px;
      }

      @include media-breakpoint-for(tab-landscape) {
        background-size: 100px;
      }
    }

    &.no-bottom-margin {
      margin-bottom: 0px;
    }
  }

  h3 {
    padding-top: 0px;
    font-size: 35px;
    margin-bottom: 40px;
    line-height: initial;
    color: black;
    font-family: $fff-font-sans;
    font-weight: 700;

    @include media-breakpoint-for(mobile) {
      padding-top: 0px;
      font-size: 22px;
      margin-bottom: 30px;
    }

    @include media-breakpoint-for(tab) {
      padding-top: 0px;
      font-size: 22px;
      margin-bottom: 30px;
    }

    @include media-breakpoint-for(tab-landscape) {
      padding-top: 0px;
      font-size: 22px;
      margin-bottom: 30px;
    }
  }

  .container {
    @include media-breakpoint-for(mobile) {
      padding: 0px;
    }

    @include media-breakpoint-for(tab) {
      padding: 0px;
    }

    @include media-breakpoint-for(tab-landscape) {
      padding: 0px;
    }
  }

  .frozen-paragraph-row {
    margin: 0px;

    &.top-padding {
      padding-top: 30px;

      @include media-breakpoint-for(mobile) {
        padding-top: 40px;
      }

      @include media-breakpoint-for(tab) {
        padding-top: 40px;
      }

      @include media-breakpoint-for(tab-landscape) {
        padding-top: 40px;
      }
    }

    &.top-padding-md {
      padding-top: 30px;

      @include media-breakpoint-for(mobile) {
        padding-top: 20px;
      }

      @include media-breakpoint-for(tab) {
        padding-top: 20px;
      }

      @include media-breakpoint-for(tab-landscape) {
        padding-top: 20px;
      }
    }

    &.margin-bottom-md {
      margin-bottom: 40px;

      @include media-breakpoint-for(mobile) {
        margin-bottom: 20px;
      }

      @include media-breakpoint-for(tab) {
        margin-bottom: 20px;
      }

      @include media-breakpoint-for(tab-landscape) {
        margin-bottom: 20px;
      }
    }

    &.mint-bg-m {
      margin-bottom: 60px;

      @include media-breakpoint-for(mobile) {
        background-color: #f2fff9;
        padding: 30px 15px;
        margin-bottom: 0;
      }

      @include media-breakpoint-for(tab) {
        background-color: #f2fff9;
        padding: 30px 15px;
        margin-bottom: 0;
      }

      @include media-breakpoint-for(tab-landscape) {
        background-color: #f2fff9;
        padding: 30px 15px;
        margin-bottom: 0;
      }
    }

    &.top-padding-lg {
      padding-top: 50px;
    }

    .frozen-paragraph-content {
      margin: 0 auto;
      padding: 0px;

      @include media-breakpoint-for(mobile) {
        padding: 0px 15px;
      }

      @include media-breakpoint-for(tab) {
        padding: 0px 15px;
        max-width: 100%;
        flex: 0 0 100%;
      }

      @include media-breakpoint-for(tab-landscape) {
        padding: 0px 15px;
        max-width: 100%;
        flex: 0 0 100%;
      }

      p {
        margin-bottom: 0px;
        text-align: center;
      }
    }
  }
}

.inventory-card-container {
  margin: 0;

  >div {
    margin-bottom: 30px;

    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      top: 0;
      left: 15px;
      right: 15px;
      background-color: $fff-brand-new-fill;
    }
  }

  .frozen-card {
    padding: 0px 0px 83px;
    border: 0px;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;

    .frozen-img {
      width: 100%;
      margin: 0px 0 20px;
      height: 260px;
      cursor: pointer;
      background-position: center top;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: #f8f8f8;

      .soldout {
        height: auto;
        top: 2em;
        left: -6em;
        color: #fff;
        display: block;
        position: absolute;
        text-align: center;
        text-decoration: none;
        letter-spacing: 0.06em;
        background-color: #a00;
        padding: 0.5em 5em 0.6em 5em;
        text-shadow: 0 0 0.75em #444;
        box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5);
        font: bold 16px/1.2em Arial, Sans-Serif;
        -webkit-text-shadow: 0 0 0.75em #444;
        -webkit-box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5);
        -webkit-transform: rotate(-45deg) scale(0.75, 1);
        z-index: 10;

        &:before {
          content: "";
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          position: absolute;
          margin: -0.3em -5em;
          transform: scale(0.7);
          -webkit-transform: scale(0.7);
          border: 2px rgba(255, 255, 255, 0.7) dashed;
        }
      }
    }

    .price-old {
      text-decoration: line-through;
      text-decoration-color: red;
      color: rgba(0, 0, 0, 0.5);
    }

    a {
      color: black;
      text-decoration: underline !important;
    }

    h3 {
      font-size: 20px;
      margin-bottom: 15px;
      line-height: normal;
      line-height: initial;
      color: black;
      text-align: center;
      font-family: $fff-font-sans;
      font-weight: 700;
      position: relative;
      padding: 0px 15px;
      overflow: hidden;

      .default-tooltip {
        font-size: 15px;
        margin-left: 6px;
        vertical-align: -1px;
      }
    }

    p {
      text-align: center;
      padding: 0px 15px;
      overflow: hidden;
      position: relative;

      br {
        display: none;
      }

      strong {
        display: block;
        margin-top: 0.5rem;
      }
    }

    h4 {
      text-align: center;
      position: absolute;
      width: 100%;
      bottom: 62px;
      margin-bottom: 0;
    }

    .btn-group {
      text-align: center;
      position: absolute;
      width: 100%;
      bottom: 15px;
    }
  }

  .five-col {
    flex: 0 0 20%;
    max-width: 20%;

    @include media-breakpoint-for(mobile) {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .frozen-card .frozen-img {
      height: 247.5px;

      @include media-breakpoint-for(mobile) {
        height: 260px;
      }
    }
  }
}

.react-tiny-popover-container {
  @include media-breakpoint-for(mobile) {
    left: 10px !important;
    right: 10px !important;
  }
}

.frozen-tooltip {
  background-color: white;
  border: 1px solid $fff-brand-green;
  border-radius: 3px;
  padding: 8px;
  width: 300px;

  @include media-breakpoint-for(mobile) {
    width: 100%;
    right: 0px;
    left: 0;
  }
}

.frozen-section-main {
  &.how-it-works {
    .how-it-box {
      img {
        max-height: 112px;
        min-height: 112px;
      }

      p {
        font-size: 14px;
        font-family: $fff-font-sans;
      }

      h4 {
        font-family: $fff-font-sans;
        font-weight: bold;
        margin-top: 1.5rem;
        margin-bottom: 15px;
        font-size: 18px;

        @include media-breakpoint-for(tab-landscape) {
          font-size: 20px;
        }
      }

      margin-bottom: 1rem;

      @include media-breakpoint-for(tab) {
        margin-bottom: 0;
      }
    }
  }
}