@mixin media-breakpoint-for($device) {
  @if $device == mobile {
    @media (max-width: 900px) {
      @content;
    }
  }  @else if $device == tab {
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
      @content;
    }
  } @else if $device == tab-landscape {
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
      @content;
    }
  } @else if $device == ipad-pro-portrait {
    @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
      @content;
    }
  } @else if $device == desktop-down {
    @media only screen and (max-width: 1200px) {
      @content;
    }
  }
}

@mixin breakpoint($device) {
  @if $device == small-mobile {
    @media (max-width: 370px) {
      @content;
    }
  }
  @if $device == mobile {
    @media (max-width: 575.98px) {
      @content;
    }
  }
  @if $device == tablet {
    @media (max-width: 991.98px) {
      @content;
    }
  }
  @if $device == mobile-landscape {
    @media (max-width: 767.98px) {
      @content;
    }
  } @else if $device == tab-up {
    @media (min-width: 768px) {
      @content;
    }
  } @else if $device == tab-down {
    @media (max-width: 768px) {
      @content;
    }
  } @else if $device == tab-landscape-up {
    @media (min-width: 992px) {
      @content;
    }
  } @else if $device == desktop-up {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $device == large-display-up {
    @media (min-width: 1800px) {
      @content;
    }
  }
}