@import "./responsive";

.login-offset {
  @include media-breakpoint-for(mobile) {
    margin: 0px;
    max-width: 100%;
    flex: 0 0 100%;
  }
  @include media-breakpoint-for(tab) {
    margin: 0px auto;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  @include media-breakpoint-for(tab-landscape) {
    margin: 0px auto;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
}
.login-wrapper {
  margin: 2rem auto 0;
  padding: 1rem;
  background-color: white;
  box-shadow: 0px 3px 80px rgba($color: #000000, $alpha: 0.1);
  &.no-margin {
    margin: 0px;
  }
  > div {
    border: 0px;
    margin-bottom: 0px;
    .login-checkbox {
      margin: 20px 0px 0px 0px;
      .checkbox-default {
        margin-bottom: 0px;
      }
    }

    .login-btn {
      width: 100%;
      margin-bottom: 15px;
      margin-top: 20px;

      //TODO: Remove this extra class and change button color globally
      &.add-new-color {
        background-color: $fff-brand-new-primary;
        border-color: $fff-brand-new-primary;
      }
    }

    .forgot-password-link {
      color: $fff-brand-new-primary;
    }

    .lockout-message{
      background-color:$fff-brand-shadded-red;
      border: 2px solid $fff-brand-shadded-red;
      border-radius: 5px !important;
      padding-top: 15px;
    }
  }

  .form-group {
    label {
      font-family: $fff-font-sans;
      font-weight: 600;
    }
  }
}

.login-title {
  font-weight: 700;
  font-size: 2rem;
  text-align: center;
  color: $fff-brand-new-primary;
  margin: 2rem auto 0;
  font-family: $fff-font-sans;
}

//social login button styles override

.social-logins {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  .google-login {
    width: 100%;
    font-family: $fff-font-sans !important;
    font-weight: 500 !important;
    border-radius: 24px !important;
    height: 35px;
    overflow: hidden;
    position: relative;
    display: flex !important;
    align-items: center;
    box-shadow: 0px 4px 8px rgba($fff-brand-green, 0.18) !important;
    transition: background-color 0.35s ease;
    &:hover {
      background-color: darken($color: #ffffff, $amount: 5) !important;
    }
    div {
      display: flex;
      position: absolute;
      left: 10px;
      top: 0;
      align-items: center;
      height: 100%;
      padding: 0 !important;
      background-color: transparent !important;
    }
    span {
      font-size: 14px;
      min-width: 100%;
      text-align: center;
      display: inline-block;
      line-height: 1;
      padding-left: 10px !important;
      font-family: $fff-font-sans !important;
      font-weight: 600 !important;
    }
  }

  .fff-google-login {
    width: 100%;
    font-family: $fff-font-sans !important;
    font-weight: 600 !important;
    border-radius: 24px !important;
    height: 35px;
    overflow: hidden;
    position: relative;
    display: flex !important;
    align-items: center;
    box-shadow: 0px 4px 8px rgba($fff-brand-green, 0.18) !important;
    transition: background-color 0.35s ease;
    border: none;
    color: rgba(0,0,0,0.54);
    &:hover {
      background-color: darken($color: #ffffff, $amount: 5) !important;
      color: rgba(0,0,0,0.54) !important;  
    }
    &:active, &:focus {
      background-color: inherit;
      color: rgba(0,0,0,0.54) !important;  
    }
    &::before {
      content: '';
      width: 20px;
      height: 20px;
      position: absolute;
      left: 10px;
      background-image: url('../img/icons/google-icon.svg');
      background-repeat: no-repeat;
      background-size: 100%;
    }
  }

  .facebook-login {
    height: 35px;
    width: 100%;
    border: none;
    border-radius: 24px;
    font-family: $fff-font-sans;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    box-shadow: 0px 4px 8px rgba($fff-brand-green, 0.18);
    color: white;
    background-color: $facebook-blue;
    background-image: url(../img/icons/fff-social-logins-fb.svg);
    background-size: 19px;
    background-repeat: no-repeat;
    background-position: 10px center;
    transition: background-color 0.35s ease;
    &:hover {
      background-color: darken($color: $facebook-blue, $amount: 10);
      color: white;
      background-image: url(../img/icons/fff-social-logins-fb.svg);
      background-size: 19px;
      background-repeat: no-repeat;
      background-position: 10px center;
      transition: background-color 0.35s ease;
    }
    &:focus, &:active {
      background-color: darken($color: $facebook-blue, $amount: 10) !important;
      color: white !important;
      background-image: url(../img/icons/fff-social-logins-fb.svg) !important;
      background-size: 19px !important;
      background-repeat: no-repeat !important;
      background-position: 10px center !important;
      transition: background-color 0.35s ease !important;
    }
  }

  .apple-login-button {
    height: 35px;
    width: 100%;
    border: none;
    border-radius: 24px;
    font-family: $fff-font-sans;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    box-shadow: 0px 4px 8px rgba($fff-brand-green, 0.18);
    color: white;
    background-color: lighten($color: #000000, $amount: 10);
    background-image: url(../img/icons/fff-social-logins-apple.svg);
    background-size: 19px;
    background-repeat: no-repeat;
    background-position: 10px center;
    transition: background-color 0.35s ease;
    &:hover {
      background-color: black;
      background-image: url(../img/icons/fff-social-logins-apple.svg);
      background-size: 19px;
      background-repeat: no-repeat;
      background-position: 10px center;
      color: white;
    }
    &:focus, &:active {
      background-color: black !important;
      background-image: url(../img/icons/fff-social-logins-apple.svg) !important;
      background-size: 19px !important;
      background-repeat: no-repeat !important;
      background-position: 10px center !important;
      color: white !important;
    }
  }

  #appleid-signin {
    height: 35px;
    width: 100%;
    border: none;
    border-radius: 24px;
    font-family: $fff-font-sans;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    box-shadow: 0px 4px 8px rgba($fff-brand-green, 0.18);
    color: white;
    background-color: lighten($color: #000000, $amount: 10);
    background-image: url(../img/icons/fff-social-logins-apple.svg);
    background-size: 19px;
    background-repeat: no-repeat;
    background-position: 10px center;
    transition: background-color 0.35s ease;
    cursor: pointer;
    &:hover {
      background-color: black;
    }
    img {
      display: none;
    }
    &::before {
      content: "Sign in with Apple";
      color: white;
      display: inline-block;
      line-height: 35px;
    }
  }
}

// 2FA Related Styles

.tfa-modal {
  .modal{
    &-dialog {
      max-width: 400px !important;
    }

    &-body {
      padding-left: 32px !important;
      padding-right: 32px !important;
      margin-bottom: 0px !important;
    }
  }
}

%tfa-circle {
  border-radius: 50%;
  
  display: flex;
  justify-content: center;
  align-items: center;
  color: $fff-brand-grey;
}

.tfa-instructions {
  p {
    margin-bottom: 0.5rem;
  }
}

.tfa-qr {
  width: 230px;
  height: 230px;
  margin: 0 auto;
  background-color: rgba($color: $fff-brand-grey, $alpha: .028);
  @extend %tfa-circle;
}

.tfa-icon {
  width: 60px;
  height: 60px;
  background-color: rgba($color: $fff-brand-grey, $alpha: .1);
  @extend %tfa-circle;
}

.tfa-title {
  font-weight: 400;
  font-size: 16px;
  display: block;
  margin: 8px auto 24px;
  text-align: left;
  color: $fff-brand-grey;
  text-align: center;
  font-family: $fff-font-body-bold;
  text-align: center;
  max-width: 75%;
}
