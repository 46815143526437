@font-face {
    font-family: "Soleil-Bold";
    src: url("../fonts/soleil/386747_0_0.eot")
          format("eot"),
        url("../fonts/soleil/386747_0_0.woff2")
          format("woff2"),
        url("../fonts/soleil/386747_0_0.woff") 
          format("woff"),
        url("../fonts/soleil/386747_0_0.ttf")
          format("truetype"),
        url("../fonts/soleil/Soleil_bold.otf") 
          format("woff");
  }
  @font-face {
    font-family: "SoleilLt";
    src: url("../fonts/soleil/386747_1_0.eot")
          format("eot"),
        url("../fonts/soleil/386747_1_0.woff2")
          format("woff2"),
        url("../fonts/soleil/386747_1_0.woff") 
          format("woff"),
        url("../fonts/soleil/386747_1_0.ttf")
          format("truetype"),
        url("../fonts/soleil/Soleil_light.otf") 
          format("woff");
  }