@import './responsive';

.preference-container {
  justify-content: space-between;
  flex-wrap: wrap;
  display: flex;
  &.justify-center{
    justify-content: center;
    margin-right: -1.5%;
    margin-left: -1.5%;
    
    & > .card {
      margin-left: 1.5%;
      margin-right: 1.5%;
    }
  }
  .card {
    border: 2px solid rgba($fff-brand-green, 0.18);
    padding: 0;
    color: rgba($fff-brand-grey, 0.1);
    width: 30%;
    height: 0;
    padding-bottom: 30%;
    margin-bottom: 3%;
    &.selected {
      border: 2px solid $fff-brand-green;
      color: black;
    }
    .card-body {
      padding: 0rem;    
      height: 0;
      padding-bottom: 100%;
      
      .card-title {
        margin-bottom: 0px;    
        height: 0;
        padding-bottom: 100%;
        .card-container {
          display: inline-block;
          position: relative;
          cursor: pointer;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          text-align: center;
          float: left;
          padding-top: 0px !important;
          padding-left: 0px;
          margin-bottom: 0rem;
          height: 0;
          padding-bottom: 100%;
          .img-container {
            display: block;
            width: 90px;
            height: 90px;
            padding: 10px;
            margin-right: 0rem;
            background-size: cover;
            border-radius: 50%;
            background-color: rgba($fff-brand-grey, 0.1);
            margin: 0;
            position: absolute;
            top: 25%;
            left: 50%;
            transform: translate(-50%, -25%);

            @include media-breakpoint-for(mobile) {
              width: 60px;
              height: 60px;
            }
            @include media-breakpoint-for(tab) {
              width: 90px;
              height: 90px;
            }
            @include media-breakpoint-for(tab-landscape) {
              width: 90px;
              height: 90px;
            }
            .img {
              width: 70%;
              height: 70%;
              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;
              margin: 0px;
              position: absolute;
              top: 50%;
              left: 50%;
              -webkit-transform: translate(-50%, -25%);
              transform: translate(-50%, -50%);
            }
          }
          span {
            display: block;
            vertical-align: middle;
            width: 100%;
            padding: 0.3rem 0;
            position: absolute;
            bottom: 15px;
            margin-left: 0px;
     
            @include media-breakpoint-for(mobile) {
              bottom: 0px;
            }
            @include media-breakpoint-for(tab) {
              bottom: 15px;
            }
            @include media-breakpoint-for(tab-landscape) {
              bottom: 15px;
            }
            h1 {
              font-weight: 400;
              font-size: 14px;
              display: block;
              margin-top: 0rem;
              margin-bottom: 0;
              color: $fff-brand-grey;
              text-align: center;
              font-family: 'SoleilLt', sans-serif;
              @include media-breakpoint-for(mobile) {
                font-size: 10px;
              }
              @include media-breakpoint-for(tab) {
                font-size: 15px;
              }
              @include media-breakpoint-for(tab-landscape) {
                font-size: 15px;
              }
            }
          }
          input {
            position: absolute;
            opacity: 0;
          }
          .checkmark {
            display: none;
          }
        }
      }
    }
  }
  .option-row{
    text-align: center;
    display: block;
    width: 100%;
    margin-bottom: 3%;
    h2{
      font-family: 'Soleil-Bold', sans-serif;
      font-size: 24px;
    margin-bottom: 0px;}
  }
  .one-column {
    justify-content: space-between;
    flex-wrap: wrap;
    display: flex;
    width: 100%;
    .card {
      color: rgba($fff-brand-grey, 0.1);
      width: 100%;
      height: 0;
      padding-bottom: 32%;
      &.selected {
        border: 2px solid $fff-brand-green;
        color: black;
      }
      .card-body {
        height: 0;
        padding-bottom: 32%;
        .card-title {
          margin-bottom: 0px;
          height: 0;
          padding-bottom: 32%;
          .card-container {
            width: 100%;
            text-align: center;
            height: 0;
            padding-bottom: 32%;
            }
        }
      }
    }
  }
  .two-column {
    justify-content: space-between;
    flex-wrap: wrap;
    display: flex;
    width: 100%;
    padding: 0 10%;
    margin-bottom: 10px;
    .card {
      color: rgba($fff-brand-grey, 0.1);
      width: 45%;
      height: 0;
      padding-bottom: 45%;
      &.selected {
        border: 2px solid $fff-brand-green;
        color: black;
      }
      .card-body {
        height: 0;
        padding-bottom: 100%;
        .card-title {
          margin-bottom: 0px;
          height: 0;
          padding-bottom: 100%;
          .card-container {
            width: 100%;
            text-align: center;
            height: 0;
            padding-bottom: 100%;
            .checkmark {
              background-color: white;
            }
            &:hover input ~ .checkmark {
              background-color: white;
            }
            input:checked ~ .checkmark {
              background-color: white;
            }
          }
        }
      }
    }
  }
}
