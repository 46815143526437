@import "./responsive";
@import "./variables";
@import "/src/modules/food-box/order-form/food-box-layout.module.scss";

.menu-filter-button-wrapper {
  position: relative;
  width: 100%;

  .filter-count {
    background-color: $fff-brand-foodbox-primary;
    color: $fff-brand-new-light;
    position: absolute;
    top: -4px;
    right: -4px;
    height: 17px;
    width: 17px;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    text-align: center;
    font-size: 12px;

    @include media-breakpoint-for(mobile) {
      top: 11.5px;
      right: 16px;
    }
  }

  .menu-filter-button {
    flex-direction: row;
    padding: 10px 24px 10px 24px;
    width: 225px;
    align-items: center;
    justify-content: center;
    border-color: #6E8124;
    cursor: pointer;

    &:hover {
      background-color: $fff_brand_yellow_green;
    }

    @include breakpoint(tablet) {
      width: 100%;
    }

    .filter-label {
      font-family: $fff-font-sans;
      font-weight: 500;
      font-size: 14px;
      color: #6E8124;
      margin-left: 8px;
      cursor: pointer;
    }
  }
}

.menu-filter-wrapper {
  flex-direction: row;
  display: flex;
  width: 100%;
  gap: 8px;
  margin-inline: 0;

  @include media-breakpoint-for(mobile) {
    display: none;
  }

  .menu-filter-chip {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 6px 12px 6px 12px;
    height: 38px;
    border-radius: 20px;
    border: 1px solid #DFDFDF;
    margin: 0;
    width: fit-content;
    gap: 8px;
    cursor: pointer;

    .filter-label {
      text-align: center;
      font-size: 14px;
      font-family: $fff-font-sans;
      font-weight: 400;
      color: #212529;
      line-height: 20px;
      letter-spacing: 0.1px;
      pointer-events: none;

      span {
        font-weight: 500;
        color: $fff-brand-new-secondary;
      }
    }

    .close-icon {
      position: relative;
      display: inline-block;
      cursor: pointer;
    }

    &:hover {
      background-color: $fff-brand-new-fill;
    }
  }

  .menu-filter-reset-btn {
    height: 38px;
    padding: 0px 8px;
    text-align: center;
    font-family: $fff-font-sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    color: #6E8124;
    background: none;
    border: none;
  }
}

.margin-inline-correction {
  margin-inline: 0;
}

// drawer animation 
@keyframes slideIn {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(100%);
  }
}

.drawer-underlay {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 50;
}

.drawer {
  position: fixed;
  top: 64px;
  right: 0;
  height: 100%;
  transition: transform 0.3s ease-in-out;
  z-index: 20;

  &.open {
    transform: translateX(0);
  }

  &.closed {
    transform: translateX(100%);
  }

  &.animate {
    &.open {
      animation: slideIn 0.3s forwards;
    }

    &.closed {
      animation: slideOut 0.3s forwards;
    }
  }
}

.menu-filter-drawer {
  height: calc(100vh - 64px); // for older devices
  height: calc(100dvh - 64px);
  z-index: 101;
  background: $fff-brand-new-light;
  box-shadow: 0px 3px 6.3px 0px rgba(0, 0, 0, 0.16);

  @media (max-width: 767.98px) {
    width: 100vw !important;
  }

  @include breakpoint (tablet) {
    width: 50vw;
  }

  .menu-header {
    padding: 16px;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);

    .menu-filter-header {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding-inline: 0;
      align-items: center;

      .menu-filter-title {
        font-weight: 600;
        font-size: 16px;
        font-family: $fff-font-sans;
        color: $fff-brand-black-text;
        margin-left: 8px;
      }
    }

    .close-icon {
      position: relative;
      display: inline-block;
      cursor: pointer;
    }
  }

  .menu-content {
    flex-direction: column;
    padding-inline: 16px;
    height: calc(100vh - 124px);
    height: calc(100dvh - 124px);
    overflow-x: hidden;
    overflow-y: scroll;
    scrollbar-width: thin;

    .filter-preference-container {

      @include breakpoint(mobile-landscape) {
        min-height: calc(100vh - 200px);
        min-height: calc(100dvh - 200px);
        overflow-x: hidden;
        overflow-y: scroll;
        padding-bottom: 72px;
        scrollbar-width: none;
        -ms-overflow-style: none;
      }

      .menu-filter-header {
        color: $fff-brand-foodbox-primary;
        font-size: 16px;
        font-weight: 700;
        font-family: $fff-font-sans;
        padding-top: 16px;
        margin-bottom: 0;
      }

      .menu-filter-icon {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }

      .menu-filter-title {
        color: $fff-brand-new-dark;
        font-size: 14px;
        font-weight: 500;
        font-family: $fff-font-sans;
        margin-inline: 0;
        padding: 16px 0;
      }

      .menu-filter-options {
        padding-left: 24px;
        padding-right: 24px;
      }
    }

    .mobile-button-container {
      display: none;

      @media screen and (max-width: 375px) {
        box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
      }

      @include media-breakpoint-for(mobile) {
        display: flex;
        padding: 16px 0;
        gap: 16px;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0;
        background-color: $fff-brand-new-light;
        width: 100%;

        .menu-filter-reset-btn {
          height: 40px;
          width: 172px;
          padding: 0px 8px;
          text-align: center;
          font-family: $fff-font-sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          color: #6E8124;
          background: none;
          border: none;
        }

        .apply-button {
          width: 172px;
          background-color: $fff-brand-foodbox-primary;
          border-color: $fff-brand-foodbox-primary;

          &:active {
            background-color: #72233f !important;
            border-color: #72233f !important;
          }
        }

        .see-results-button {
          display: none;

          @include breakpoint(mobile-landscape) {
            display: block;
          }
        }
      }
    }
  }
}

.postcode-selector {
  height: 50px;
  width: 240px;
  padding-top: 10px;

  >div {
    >div {
      border: 2px solid rgba(0, 0, 0, 0.12);
    }
  }
}

.postcode-input {
  @include breakpoint(tablet) {
    width: 100%;
  }

  .postcode-change {
    display: flex;
    position: relative;
    min-width: 240px;

    p {
      margin-bottom: 0px;
      position: absolute;
      top: -10px;
      left: 15px;
      padding: 0px 10px 0px 10px;
      background-color: #ffffff;
      color: #8F8F93;
      z-index: 1;
    }

    >input {
      cursor: pointer;
      padding-left: 22px;

      @include breakpoint(tablet) {
        width: 100%;
      }

      &:hover {
        border-color: #6E8124;
      }
    }

    .postcode-change-button {
      position: absolute;
      right: 5px;
      top: 8px;
      color: #6E8124;
      background: transparent;
      border: none;
      font-size: 14px;
      font-weight: 500;
      cursor: default;
      pointer-events: none;
    }
  }
}

.change-postcode-modal {
  .modal-content {
    min-height: unset !important;
    border-radius: 20px !important;
  }
}

.postcode-modal,
.getstarted-postcode-modal {

  top: 15%;
  transform: translateY(-15%) !important;

  @media (min-width: 767.98px) {
    max-width: 500px;
  }

  @include breakpoint(mobile-landscape) {
    max-width: 100%;
  }

  .modal-content {
    border-radius: 20px;
    border: none;
    min-height: 314px;

    @include breakpoint(mobile) {
      border-radius: 0;
    }
  }


  .modal-body {
    padding: 0 !important;
    margin-bottom: 0 !important;
    overflow: unset !important;

    .postcode-modal-banner-section {
      display: flex;
      height: 226px;
      padding: 24px;
      flex-direction: column;
      border-radius: 20px 20px 0 0;
      background-image: url('../img/fff-foodbox-postcode-modal-banner-image.svg');

      .postcode-modal-header {
        font-size: 25px;
        font-weight: 700;
        color: $fff-brand-black-text;
        margin-bottom: 2px;
      }

      .postcode-modal-para {
        font-size: 14px;
        font-weight: 400;
        color: $fff-brand-black-text;
      }

    }

    .postcode-modal-footer-section {
      padding: 24px;
    }

    .postcode-modal-mobile-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100vh;
      height: 100dvh;

      .postcode-modal-header-section-mobile {
        justify-content: center;
        align-items: center;
        padding: 16px;

        .postcode-modal-header {
          font-size: 20px;
          font-weight: 700;
          color: $fff-brand-black-text;
          text-align: center;
        }

        .postcode-modal-para {
          font-size: 13px;
          font-weight: 400;
          color: $fff-brand-black-text;
          text-align: center;
          padding-bottom: 16px;
          margin-bottom: 0;
        }
      }

      .postcode-modal-banner-section-mobile {
        display: flex;
        height: 500px;
        padding: 16px;
        flex-direction: column;
        border-radius: 0.3rem 0.3rem 0 0;
        background-image: url('../img/fff-foodbox-postcode-modal-banner-image-mobile.svg');
        background-size: cover;
        background-position: right;

        @media (max-width: 375px) {
          height: 385px;
        }

        @include breakpoint(mobile) {
          height: 425px;
        }

      }

      .postcode-modal-footer-section-mobile {
        display: flex;
        flex-direction: column;
        padding: 16px;
        gap: 16px;
        padding-bottom: 40px;

        .submit-button {
          background-color: $fff-brand-foodbox-primary;
          border-color: $fff-brand-foodbox-primary;

          &:active {
            background-color: #72233f !important;
            border-color: #72233f !important;
          }
        }

        .back-button {
          box-shadow: none;
        }
      }
    }
  }
}

.getstarted-postcode-modal {
  @media (max-width: 767.9px) {
    height: 100vh; // for older devices
    height: 100dvh;
    margin: auto;
  }

  .modal-content,
  .modal-body {
    @media (max-width: 767.9px) {
      height: 100vh; // for older devices
      height: 100dvh;
    }
  }
}

.address-input {
  @include breakpoint(tablet) {
    width: 100%;
  }

  .address-change {
    display: flex;
    position: relative;
    min-width: 240px;

    p {
      margin-bottom: 0px;
      position: absolute;
      top: -10px;
      left: 15px;
      padding: 0px 10px 0px 10px;
      background-color: #ffffff;
      color: #8F8F93;
      z-index: 1;
    }

    >input {
      cursor: pointer;
      padding-left: 22px;

      @include breakpoint(tablet) {
        width: 100%;
      }

      &:hover {
        border-color: #6E8124;
      }
    }

    .address-change-button {
      position: absolute;
      right: 5px;
      top: 8px;
      color: #6E8124;
      background: white;
      border: none;
      font-size: 14px;
      font-weight: 500;
      pointer-events: none;
    }
  }
}

.address-modal {
  top: 15%;
  transform: translateY(-15%) !important;

  @media (max-width: 767.98px) {
    height: 100dvh;
    margin: auto;

    .modal-content {
      height: 100dvh;
    }
  }

  @media (min-width: 767.98px) {
    max-width: 500px;
  }

  @include breakpoint(mobile-landscape) {
    max-width: 100%;
  }

  .modal-content {
    border-radius: 16px;
  }

  .modal-body {
    padding: 0 !important;
    margin-bottom: 0 !important;
    overflow: unset !important;

    @media (max-width: 767.98px) {
      max-height: 100dvh !important;
      overflow-y: auto !important;
      display: flex;
      flex-direction: column;
    }

    @media (min-width: 768px) {
      max-height: unset !important;
    }

    .address-modal-banner-section {
      display: flex;
      height: 226px;
      padding: 16px;
      flex-direction: column;
      border-radius: 16px 16px 0 0;
      background-image: url('../img/fff-foodbox-postcode-modal-banner-image.svg');

      .address-modal-header {
        font-size: 20px;
        font-weight: 700;
        color: $fff-brand-black-text;
      }

      .address-modal-para {
        font-size: 13px;
        font-weight: 400;
        color: $fff-brand-black-text;
      }

    }

    .address-modal-footer-section {
      display: grid;
      gap: 10px;
      padding: 16px;

      @media (max-width: 767.98px) {
        align-content: space-between;
        flex: 1 1 auto;
      }

      .margin-correction {
        margin-inline: 0;
      }

      .address-heading-row {
        flex-direction: row;
        justify-content: space-between;
        display: flex;

        .address-label {
          font-family: $fff-font-sans;
          font-size: 14px;
          font-weight: 600;
          color: $fff-brand-black-text;
          margin-bottom: 0;

          @media (max-width: 767.98px) {
            padding-bottom: 16px;
          }
        }

        .address-add-new-label {
          font-family: $fff-font-sans;
          font-size: 14px;
          font-weight: 500;
          color: $fff-brand-new-secondary;
          cursor: pointer;
        }
      }

      .address-form-content {
        flex-direction: column;

        .address-label {
          padding-bottom: 16px;
        }
      }

      .address-add-new-label-mobile {
        display: none;

        @media (max-width: 767.98px) {
          display: block;
          font-family: $fff-font-sans;
          font-size: 14px;
          font-weight: 500;
          color: $fff-brand-new-secondary;
          cursor: pointer;
          text-align: center;
          padding: 16px 0;
        }
      }

      .address-mobile-buttons {
        display: none;

        @media (max-width: 767.98px) {
          display: flex;
          flex-direction: column;
          gap: 16px;

          .save-button {
            font-family: $fff-font-sans !important;
            background-color: $fff-brand-foodbox-primary;
            border-color: $fff-brand-foodbox-primary;
            color: $fff-brand-new-light;

            &:active,
            &:hover {
              background-color: #72233f !important;
              border-color: #72233f !important;
              color: $fff-brand-new-light !important;
            }
          }
        }
      }

      .address-heading-mobile {
        @media (max-width: 767.98px) {
          display: none;
        }
      }

      .address-dropdown-option {
        .address-dropdown-display-label {
          font-family: $fff-font-sans;
          font-size: 13px;
          font-weight: 600;
          color: unset;
          margin-bottom: 0;
          line-height: 1.5;
        }

        .address-dropdown-full-label {
          font-family: $fff-font-sans;
          font-size: 13px;
          font-weight: 400;
          color: unset;
          margin-bottom: 0;
          line-height: 1.5;
          white-space: normal;
          padding-bottom: 5px;
        }
      }

      .address-dropdown-selected-option {
        align-items: center;
        justify-content: center;
        font-family: $fff-font-sans;
        font-size: 14px;
        font-weight: 400;
        color: $fff-brand-new-dark;
        white-space: normal;
      }

      .fff-input-inline {
        width: 100%;
        position: relative;
        margin-bottom: 16px;

        label {
          @extend %fffLabels;
          top: -4px
        }

        input,
        .input-element {
          @extend %fffInputs;

          &:disabled {
            &:hover {
              border: 2px solid rgba(84, 86, 92, 0.24);
            }
          }
        }

        .address-box {
          padding: 1rem 2rem;
        }

        .fff {
          &__control {
            padding: 0;
            border: 2px solid rgba($fff-brand-grey, 0.24) !important;
            cursor: unset;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

            &--is-focused,
            &:hover {
              border: 2px solid $fff-brand-new-secondary !important;
            }
          }

          &__placeholder {
            padding-left: 2rem;
            color: #C1C2C4;
          }

          &__input {
            padding-left: 0rem;
          }
        }

        textarea {
          padding: 1rem 1rem 1rem 2rem;
          border: 2px solid rgba(84, 86, 92, 0.24);
          transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

          &:focus {
            border: 2px solid $fff-brand-new-secondary;
          }

          &:hover {
            border: 2px solid $fff-brand-new-secondary;
          }

          &:disabled {
            &:hover {
              border: 2px solid rgba(84, 86, 92, 0.24);
            }
          }
        }
      }

      .validation-error-outline.selectBox div[class*="control"] {
        border: 2px solid #DC3545 !important;
      }

      .fff-field-group {
        width: 100%;
        position: relative;

        label {
          @extend %fffLabels;
          top: -4px
        }

        input,
        .input-element {
          @extend %fffInputs;
          padding-left: 0px !important;
        }
      }

      .address-button-container {
        display: flex;
        justify-content: end;

        @media (max-width: 767.98px) {
          display: flex;
          flex-direction: column-reverse;
          gap: 16px;

          .cancel-button {
            margin-right: 0 !important;
          }

          .save-button {
            width: 100% !important;
          }
        }

        .cancel-button {
          font-family: $fff-font-sans !important;
          margin-right: 10px;
        }

        .save-button {
          font-family: $fff-font-sans !important;
          background-color: $fff-brand-foodbox-primary;
          border-color: $fff-brand-foodbox-primary;
          color: $fff-brand-new-light;
          width: 84px;

          &:active,
          &:hover {
            background-color: #72233f !important;
            border-color: #72233f !important;
            color: $fff-brand-new-light !important;
          }
        }
      }
    }
  }
}

.address-change-modal {
  .modal-content {
    border-radius: 0.3rem;
  }
}

.address-modal-mobile-container {
  @media (max-width: 767.98px) {
    height: 100dvh;
    display: grid;
    grid-template-rows: 1fr 1fr;

    .address-modal-mobile-banner {
      display: flex;
      height: 500px;
      padding: 16px;
      flex-direction: column;
      border-radius: 0.3rem 0.3rem 0 0;
      background-image: url('../img/fff-foodbox-postcode-modal-banner-image-mobile.svg');
      background-size: cover;
      background-position: center;

      @media (max-width: 375px) {
        height: 230px;
      }

      @include breakpoint(mobile) {
        height: 330px;
      }
    }

    .address-modal-header-section-mobile {
      justify-content: center;
      align-items: center;
      padding: 16px;

      .address-modal-mobile-header {
        font-size: 20px;
        font-weight: 700;
        color: $fff-brand-black-text;
        text-align: center;
        padding-top: 16px;
      }

      .address-modal-mobile-para {
        font-size: 13px;
        font-weight: 400;
        color: $fff-brand-black-text;
        text-align: center;
        margin-bottom: 0;
      }
    }

  }
}

.dropdown-wrapper {
  position: relative;
  height: 50px;
  width: 240px;

  @include breakpoint(tablet) {
    width: 100%;
  }

  .dropdown-label {
    position: absolute;
    top: 0px;
    left: 15px;
    padding: 0px 10px 0px 10px;
    background-color: $fff-brand-new-light;
    color: #8F8F93;
    z-index: 11;
    text-transform: unset;
    width: 140px;
    font-family: $fff-font-sans;
    font-size: 14px;
    margin-bottom: 0;
  }

  .dropdown {
    top: 10px;
    position: relative;
    width: 240px;
    height: 40px;
    border-radius: 4px !important;

    &.p-dropdown {
      @include breakpoint(tablet) {
        width: 100%;
      }

      .p-dropdown-panel {
        .p-dropdown-items-wrapper {
          .p-dropdown-items {
            .p-dropdown-item {
              color: $fff-brand-black-text;
              font-family: $fff-font-sans;

              &.p-highlight {
                background-color: $fff-brand-new-primary;
                color: $fff-brand-new-light;
              }

              &:not(:disabled):not(.disabled):active,
              &:focus,
              &:hover {
                background: $fff-brand-new-fill;
                color: $fff-brand-black-text;
              }
            }
          }
        }
      }
    }

    .p-dropdown-label {
      height: 100%;
      min-height: 100%;
      color: $fff-brand-black-text;
      font-family: $fff-font-sans;
      font-size: 14px;
      padding-left: 24px;
    }
  }
}

.delivery-day-tab {
  width: 100%;
  position: sticky;
  top: -2px;
  z-index: 1;
  background-color: $fff-brand-new-light;

  .delivery-day-tab-filler {
    width: 100%;
    border-bottom: 1px solid #DEE2E6;
    flex: 1;
    background-color: $fff-brand-new-light;
  }

  .normal {
    background-color: $fff-brand-new-light !important;
    color: $fff-brand-grey !important;
    font-family: $fff-font-sans;
    font-weight: normal;
    border-width: 0px 0px 1px 0px;
    border-radius: 0px 0px 0px 0px;
    border-color: #DEE2E6 !important;
    box-shadow: none !important;

    &:hover {
      background-color: $fff_brand_grey_green !important;
      border-color: #DEE2E6 !important;
      color: $fff-brand-new-dark !important;
      box-shadow: none !important;
    }

    &:focus {
      background-color: $fff_brand_grey_green;
      border-color: #DEE2E6;
      color: $fff-brand-new-dark;
      box-shadow: none;
    }
  }

  .active {
    border-width: 1px 1px 0px 1px;
    border-radius: 4px 4px 0px 0px;
    border-color: #DEE2E6;
    background-color: $fff_brand_grey_green;
    box-shadow: none;
    color: $fff-brand-new-primary;
  }

  .btn-secondary:not(:disabled):not(.disabled).active {
    background-color: $fff_brand_grey_green;
    color: $fff-brand-foodbox-primary;
    border-color: #DEE2E6;
    box-shadow: none;
  }
}

.menu-catalogue {
  background-color: $fff_brand_grey_green;
  border-width: 1px;
  border-color: $fff-brand-new-dark;
  padding: 16px;
  border: 1px solid #DEE2E6;
  border-top: none;
  width: 100%;
  min-height: calc(100% - 40px);
  display: flex;
  justify-content: center;

  // Padding bottom when there are error labels on the collapsed order summary
  @media screen and (max-width: 375px) {
    padding-bottom: calc(157px + var(--menu-bottom-spacing)) !important;
  }

  @include breakpoint(mobile-landscape) {
    padding-bottom: calc(187px + var(--menu-bottom-spacing));
  }

  .unavailable-day-error-wrapper {
    display: inline-flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    color: $fff-brand-grey;
    padding: 0;
    gap: 4px;
  }
}

.unavailable-menu-catalogue {
  flex: 1;
  min-height: calc(100% - 40px);
  justify-content: center;
  align-items: start;
  background-color: $fff_brand_grey_green;
}

.skeletal-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 8px;
  background-color: $fff_brand_grey_green;
  min-height: calc(100vh - 282px); // for older devices
  min-height: calc(100dvh - 282px);
}

.menu-section {
  margin-bottom: 16px;
  max-width: unset;

  .menu-section-header {
    flex-direction: row;
    align-items: center;
    height: 50px;
    margin-bottom: 16px;

    .menu-section-image {
      height: 35px;
      width: 35px;
      margin-right: 8px;
    }

    .menu-section-title {
      height: 50px;
      color: $fff-brand-new-primary;

      .menu-section-title-text {
        height: 50px;
        line-height: 50px;
        font-family: $fff-font-sans;
        font-weight: 500;
        font-size: 24px;
        color: $fff-brand-foodbox-primary;
      }
    }
  }

  .menu-section-content {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 8px;

    @include media-breakpoint-for(mobile) {
      justify-content: center;
      gap: 16px;
    }
  }
}

.menu-card {
  border-radius: 20px;
  border: 1.5px solid #dee2e6;
  background-color: $fff-brand-new-light;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  min-width: 300px;
  overflow: hidden;

  @include media-breakpoint-for(mobile) {
    width: 100%;
    max-width: unset;
  }

  .menu-card-content {
    padding: 16px 12px 4px 12px;
    min-height: calc(100% - (64px + 37px));
    box-shadow: unset;
    display: flex;
    flex-direction: column;
    align-items: start;
    flex: 1 1;

    @include media-breakpoint-for(mobile) {
      padding: 16px 12px 0 12px;
    }

    .menu-card-content-mobile-top {
      display: grid;
      grid-template-columns: 35% 65%;
      width: 100%;
      margin-bottom: 8px;

      .menu-card-image {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        padding-right: 5px;
      }

      .menu-card-details {
        display: flex;
        flex-direction: column;
      }

      .menu-card-title {
        padding-left: 0;
        width: 84%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        flex: unset;
      }

      .menu-card-item-text {
        flex: 1 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .nutrition-text {
        margin: 0;
        font-size: 12px;

        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          margin-bottom: 8px;

          @media (max-width: 375px) {
            font-size: 12px !important;
          }

          @include breakpoint(mobile-landscape) {
            font-size: 12.5px;
          }

          span {
            font-size: 14px;
            font-weight: 400;
            color: #DFDFDF;
            margin: 0 5px;
          }
        }
      }
    }

    .menu-card-title {
      text-align: start;
      font-family: $fff-font-sans;
      font-size: 18px;
      line-height: 21px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 91%;
      margin-inline: 0px;
      padding-left: 5px;
      height: 42px;

      b {
        font-weight: 600;
      }
    }

    .menu-card-image {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 8px;
      margin-bottom: 8px;
      width: 100%;
      cursor: pointer;

      .item-image {
        height: 124px;
        width: 124px;
        object-fit: cover;

        @include media-breakpoint-for(mobile) {
          height: 110px;
          width: 110px;
        }
      }
    }

    .menu-card-info {
      margin-inline: 0;
      width: 100%;

      .item-content {
        padding: 0px;
      }

      .menu-card-item-text {
        font-family: $fff-font-sans;

        .total-calories-text {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
        }

        .nutrition-text {
          margin-bottom: 0;

          p {
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            margin-bottom: 8px;

            span {
              font-size: 14px;
              font-weight: 400;
              color: #DFDFDF;
              margin: 0 5px;
            }
          }
        }
      }

      .menu-card-variation-wrapper {
        display: grid;
        gap: 8px;
        justify-content: start;
        grid-template-columns: 100%;

        .menu-card-variation {
          align-items: center;
          justify-content: start;
          width: 100%;
          margin-inline: 0px;
        }
      }

      .menu-card-variant-group {
        padding: 0;
        display: flex;

        .variant-button {
          border-radius: 0;
          border-width: 1px;
          border-color: #DFDFDF;
          color: $fff-brand-new-dark;
          font-family: $fff-font-sans;
          font-weight: normal;
          margin-right: 0px;
          box-shadow: none;
          flex: 1;
          height: 36px;

          &:hover {
            background-color: $fff-brand-new-light;
            border-color: #6E8124;
          }

          &:first-child {
            border-radius: 20px 0 0 20px;
          }

          &:last-child {
            border-radius: 0 20px 20px 0;
          }

          &:only-child {
            border-radius: 20px;
          }

          &:focus {
            border-color: #6E8124;
          }

          &.active {
            background-color: $fff_brand_yellow_green;
            border-color: #6E8124;
          }
        }
      }

      .menu-card-dietary-pref-group {
        display: flex;
        gap: 4px;
        width: 100%;

        .variant-button {
          border-width: 1px;
          border-color: #DFDFDF;
          color: $fff-brand-new-dark;
          font-family: $fff-font-sans;
          font-weight: normal;
          box-shadow: none;
          font-size: 12.5px;
          height: 36px;
          white-space: nowrap;
          padding: 0 8px;
          flex: 1 1;
          max-width: 50%;

          &:hover {
            background-color: $fff-brand-new-light;
            border-color: #6E8124;
          }

          &:focus {
            border-color: #DFDFDF;
            background-color: $fff-brand-new-light;
          }

          &.active {
            background-color: $fff_brand_yellow_green;
            border-color: #6E8124;
          }
        }
      }
    }

  }

  .menu-extra-card-content {
    padding: 16px 12px 0 12px;

    .menu-card-content-mobile-top {
      margin-bottom: 0;
    }

    .menu-card-item-text {
      justify-content: flex-start !important;
      padding-top: 8px;
    }
  }

  .menu-card-footer {
    display: flex;
    padding: 12px;
    border-radius: 0px 0px 20px 20px;
    justify-content: space-between;

    .quantity-label {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      background-color: $fff-brand-new-light;
      align-items: center;
      justify-content: center;
      display: flex;
      color: $fff-brand-foodbox-primary;
      border: 1.5px solid $fff-brand-new-fill;

      b {
        font-size: 15px;
        font-weight: 600;
        line-height: 11px;
      }
    }

    .add-button {
      min-width: 122px;
      height: 40px;
      white-space: nowrap;
    }
  }

  .info-icon {
    position: absolute;
    top: 14px;
    right: 14px;
    height: 20px;
    width: 20px;
    cursor: pointer;
    fill: $fff_brand_yellow_green;

    @include media-breakpoint-for(mobile) {
      height: 30px;
      width: 30px;
    }

  }
}

.menu-card-info-pop {
  border-radius: 8px;

  .modal-content {
    width: 120%;
  }

  .modal-body {
    margin-bottom: 0 !important;
    height: 70vh; // for older devices
    height: 70dvh;
  }

  @include media-breakpoint-for(mobile) {
    margin: 0;
    transform: unset !important;
    top: 0;

    .modal-content {
      height: 100vh;
      width: 100vw;
    }

    .modal-body {
      max-height: unset !important;
    }
  }
}

.tab-icon-image {
  width: 20px;
  height: 20px;
}

.tab-icon-image-hide {
  display: none;
}

.tab-image-icon-left {
  margin-right: 8px;
}

.tab-image-icon-right {
  margin-left: 8px;
}

// To be changed
.remove {
  width: 25px;
  height: 25px;
  margin: 5px 5px;
  font-size: 10px;
  font-weight: 600;
  line-height: 11px;
  box-shadow: none;
  position: relative;
  border-width: 0;
  background-color: transparent;

  &::after {
    content: " ";
    position: absolute;
    background-image: url("../../assets/img/icons/fff-remove-item.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center bottom;
    top: 3px;
    bottom: 2.5px;
    left: 3px;
    right: 3px;
  }
}

body:has(.menu-view-scoll-hidden) {
  overflow: hidden;
  height: 100vh; // for older devices
  height: 100dvh;

  @media screen and (max-width: 767.9px) {
    overflow-y: auto;
  }
}

@media screen and (max-width: 767.9px) {
  body:has(.menu-catalogue) {
    overflow: hidden;
  }
}

.customer-portal-menu-items-carousal {
  display: flex;
  position: relative;

  .arrow-button {
    background: none;
    border: none;
    font-size: 30px;
    color: $fff-brand-foodbox-primary;

    &:hover {
      color: $fff-brand-new-secondary;
    }
  }

  @mixin arrow-button-container {
    position: relative;
    display: flex;
    align-items: center;
    width: 16px;
  }

  .arrow-button-container-right {
    @include arrow-button-container;

    button {
      position: absolute;
      left: 8px;
    }
  }

  .arrow-button-container-left {
    @include arrow-button-container;

    button {
      position: absolute;
      right: 8px;
    }
  }

  .alice-carousel {
    width: 95%;

    .alice-carousel__dots {
      margin: 16px 3px 0;
      gap: 12px;
      display: flex;
      justify-content: center;

      .alice-carousel__dots-item {
        background-color: unset;
        border: 1px solid $fff-brand-foodbox-primary;
        width: 10px;
        height: 10px;
        margin-right: 0;
      }
    }

    .alice-carousel__dots-item.__active {
      background-color: $fff-brand-foodbox-primary;
    }

    .alice-carousel__stage-item {
      text-align: center;
    }

    .alice-carousel__prev-btn {
      display: none;
    }

    .alice-carousel__next-btn {
      display: none;
    }

  }
}

.customer-portal-carousal-message {
  padding: 0 16px;
}

.splide {
  width: 100%;
  max-width: 100%;
  padding: 0 16px;

  &__slide {
    text-align: center;
  }

  &__pagination {
    display: flex;
    justify-content: center;
    margin-top: 8px !important;
    position: initial !important;

    &__page {
      width: 10px !important;
      height: 10px !important;
      border: 1px solid $fff-brand-foodbox-primary !important;
      background-color: transparent !important;
      margin: 0 4px !important;
      opacity: 1 !important;
      transition: background-color 0.3s ease, border-color 0.3s ease;
    }

    &__page.is-active {
      background-color: $fff-brand-foodbox-primary !important;
      border-color: $fff-brand-foodbox-primary !important;
    }
  }

  &__track {
    &__list {
      &__slide {
        text-align: center;
      }
    }
  }

  &__arrow {
    background-color: transparent !important;
    border: none;
    font-size: 16px;
    cursor: pointer;
    padding: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    transition: color 0.3s ease;
    pointer-events: all;
    opacity: 1 !important;

    &:disabled {
      opacity: 0.3 !important;
      cursor: default;
    }

    svg {
      fill: $fff-brand-foodbox-primary !important;
    }

    &--prev {
      left: 10px !important;
    }

    &--next {
      right: 10px !important;
    }
  }
}

.select-menu-header {

  h1 {
    color: $fff-brand-foodbox-primary !important;
  }
}

.select-transparent-btn {
  color: $fff-brand-new-secondary;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.1px;

  &:hover {
    color: $fff-brand-foodbox-primary;
  }
}

.admin-mapping-dropdown {
  .fff__menu {
    width: 135%;
    height: auto;
  }

  .fff__option {
    color: $fff-brand-new-dark;
    font-size: 13px;
  }
}

body {
  --header-height: 64px;
  --menu-bottom-spacing: 0px;
  --main-announcement-banner: 0px;

  &:has(.top-banner-active) {
    --header-height: 110px;

    @media screen and (max-width: 375px) {
      --header-height: 173px !important;
    }

    @include media-breakpoint-for(mobile) {
      --header-height: 152px;
    }
  }

  &:has(.error-wrapper-spacing) {
    @media screen and (max-width: 375px) {
      --menu-bottom-spacing: 130px !important;
    }

    @media screen and (max-width: 767.9px) {
      --menu-bottom-spacing: 85px;
    }
  }

  &:has(.announcement-banner) {
    --main-announcement-banner: 46px;

    @media screen and (max-width: 375px) {
      --main-announcement-banner: 110px !important;
    }

    @include media-breakpoint-for(mobile) {
      --main-announcement-banner: 88px;
    }

    // For iPad Mini and iPad Air
    @media only screen and (min-width: 768px) and (max-width: 820px) {
      --main-announcement-banner: 67px;
    }
  }
}

.foodbox-variation-details-form {
  padding-bottom: 50px;
}

.foodbox-variation-details-recipe-qty-table {
  width: 100%;
  margin-bottom: 10px;
}

.meal-variation-form-recipe-container {
  min-height: 500px;
}

.credit-card-container {
  .delivery-address-card-container {
    padding: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;

    @include breakpoint(tablet) {
      grid-template-columns: 1fr;
    }
  }

  .order-terms {
    margin-top: 16px;
    margin-bottom: 0;
  }

  .delivery-address-card-item {
    border: 1px solid rgba($fff-brand-new-dark, 0.12);
    padding-top: 1rem;
    height: 100%;
    border-radius: 3px;
    margin-bottom: 16px;

    .row {
      margin-inline: 0;
    }

    .col-6 {
      flex: 1 1 50%;
    }

    .card-number {
      display: flex;
      align-items: center;
      gap: 0.25rem;

      span {
        font-size: 16px;

      }

      .last-four-digits {
        font-size: 14px;
      }
    }

    &__info {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .display-name {
        font-weight: bold;
      }

      img {
        height: 25px;
      }

      .label-default {
        width: fit-content;
        background-color: $fff-brand-new-secondary;
        padding: 2px 8px;
        color: $fff-brand-new-light;
        border-radius: 2px;
        font-size: 12px;
      }
    }
  }
}

.customer-portal-cancel-subscription-modal {

  .modal-body {
    margin-bottom: 0 !important;
    overflow: visible !important;
    padding: 16px !important;
  }

}

.cancel-subscription-form-input-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 16px;

  .form-control:hover,
  .form-control:focus {
    border: 1px solid $fff_brand_new_secondary;
  }

  .form-control::placeholder {
    color: #808080;
  }

  .fff-dropdown-select {
    height: auto;
    line-height: 24px;

    .cancel-subscription {
      &__control {
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 5px;
        box-shadow: none;

        &:hover {
          border-color: $fff-brand-new-secondary;
        }

        &--is-focused {
          border-color: $fff-brand-new-secondary;
        }
      }

      &__menu {
        box-shadow: 0px 6px rgba(0, 0, 0, 0.16);
        border: 1px solid rgba(0, 0, 0, 0.125);
      }

      &__option {
        cursor: pointer;

        &--is-focused {
          background-color: $fff-brand-new-fill;
        }

        &--is-selected {
          background-color: $fff-brand-new-primary;
          color: white;

          &:active {
            background-color: $fff-brand-new-primary !important;
          }
        }

        &:active {
          background-color: $fff-brand-new-fill;
        }
      }

      &__dropdown-indicator {
        color: rgba(0, 0, 0, 0.125);
      }
    }
  }

  .cancel-subscription-input {
    border: 1px solid rgba(0, 0, 0, 0.125);
  }

  .cancel-btn {
    background-color: $fff-brand-foodbox-primary;
    border-color: $fff-brand-foodbox-primary;

    &:hover {
      background-color: $fff-brand-foodbox-primary;
      border-color: $fff-brand-foodbox-primary;
    }

    &:active {
      background-color: #72233f !important;
      border-color: #72233f !important;
    }
  }
}

.customer-portal-skip-week-popup {
  .modal-body {
    padding: 16px !important;
  }
}

.add-address-form {
  .selectBox {
    .fff__control {
      &:hover {
        border: 1px solid $fff-brand-new-secondary !important;
      }
    }
  }

  .validation-error-outline:not(.selectBox) {
    border: 1px solid #DC3545 !important;
  }

  textarea {

    &:focus,
    &:hover {
      border: 1px solid $fff-brand-new-secondary !important;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    &:disabled {
      &:hover {
        border: 1px solid rgba($fff-brand-grey, 0.24) !important;
      }
    }
  }
}

.manage-order-cancel-delivery-modal {
  .modal-body {
    overflow: visible !important;
  }
}

.react-select-dropdown {
  .select {
    &__control {
      border: 1px solid rgba(0, 0, 0, 0.125) !important;
      border-radius: 5px;
      box-shadow: none;

      &:hover {
        border-color: $fff-brand-new-secondary;
      }

      &--is-focused {
        border-color: $fff-brand-new-secondary;
      }
    }

    &__option {
      cursor: pointer;

      .address-dropdown-option {
        .address-dropdown-display-label {
          font-family: $fff-font-sans;
          font-size: 13px;
          font-weight: 600;
          color: unset;
          margin-bottom: 0;
          line-height: 1.5;
        }

        .address-dropdown-full-label {
          font-family: $fff-font-sans;
          font-size: 13px;
          font-weight: 400;
          color: unset;
          margin-bottom: 0;
          line-height: 1.5;
          white-space: normal;
          padding-bottom: 5px;
        }
      }

      .address-dropdown-selected-option {
        align-items: center;
        justify-content: center;
        font-family: $fff-font-sans;
        font-size: 14px;
        font-weight: 400;
        color: $fff-brand-new-dark;
        white-space: normal;
      }

      &:hover {
        background-color: $fff-brand-new-fill !important;
      }

      &--is-focused {
        background-color: $fff-brand-new-fill;
      }

      &--is-selected {
        background-color: $fff-brand-new-primary;
        color: white !important;

        &:active {
          background-color: $fff-brand-new-primary !important;
        }

        &:hover {
          background-color: $fff-brand-new-primary !important;
        }
      }

      &:active {
        background-color: $fff-brand-new-fill;
      }
    }
  }
}

.custom-macros-toggle {
  .form-group {
    @extend %toggleSwitch;
  }
}