@import "./responsive";

.otherDays {
  padding-top: 0px;
  line-height: 16px;
  height: 30px;
  color: $fff-brand-grey;
  cursor: pointer;

  @include media-breakpoint-for(mobile) {
    font-size: 12px;
    height: 25px;
    line-height: 11px;
  }

  @include media-breakpoint-for(tab) {
    font-size: 12px;
    height: 25px;
    line-height: 11px;
  }

  @include media-breakpoint-for(tab-landscape) {
    font-size: 12px;
    height: 25px;
    line-height: 11px;
  }

  &.curser-default {
    cursor: default !important;
  }
}

.holiday {
  background: rgba($fff-brand-grey, 0.16);
  color: $fff-brand-grey;
  border-radius: 50%;
  float: right;
  height: 24px;
  width: 24px;
  line-height: 24px;
  padding: 0px;
  margin: 1px 0px 3px 0px;
  text-align: center;
  font-size: 14px;

  @include media-breakpoint-for(mobile) {
    font-size: 12px;
    height: 20px;
    width: 20px;
    line-height: 20px;
    padding: 0px;
  }

  @include media-breakpoint-for(tab) {
    font-size: 12px;
    height: 20px;
    width: 20px;
    line-height: 20px;
    padding: 0px;
  }

  @include media-breakpoint-for(tab-landscape) {
    font-size: 12px;
    height: 20px;
    width: 20px;
    line-height: 20px;
    padding: 0px;
  }
}

.selectedDay {
  background: rgba($fff-brand-grey, 0.16);
  width: 100%;
  height: 100%;
  padding-right: 5px;
  padding-top: 5px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  @include media-breakpoint-for(mobile) {
    font-size: 12px;
  }

  @include media-breakpoint-for(tab) {
    font-size: 12px;
  }

  @include media-breakpoint-for(tab-landscape) {
    font-size: 12px;
  }
}



//sales target CRM calendar overrides

.sales-target-calendar {
  .p-inputtext {
    border-radius: 5px 0px 0px 5px !important;
    border-right: 0;
    &:hover {
      border-right: 0 !important;
    }
  }
}

// increasing the cell size of the calender-for-user-profile


.calendar-increased-cell-height {
  .p-datepicker table {
    td {
      & > a,
      > span {
        height: 47px;
        @media (min-width: 600px) {
          height: 62.31px;
        }
      }
    }
  }
}
 

.calender-for-user-profile {
  .p-datepicker table {
    td {
      & > a,
      > span {
        height: 47px;
        @media (min-width: 600px) {
          height: 52px;
          min-width: 60px;
        }
      }
    }
  }
}

.keep-old-calendar {
  &.enlarge-calender-for-desktop {
    .calender-for-user-profile {
      @media (min-width: 900px) {
        width: 80%;
      }
    }
  }
  .calender-for-user-profile {
    .p-datepicker table {
      td {
        & > a,
        > span {
          height: 45px;
          @media (min-width: 900px) {
            height: 47px;
          }
        }
      }
    }
  }
}

// Calendar UI refactor according to Emma's Design

%circle {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  @media screen and (min-width: 600px) {
    width: 27px;
    height: 27px;
  }
}

.delivery-date-icon {
  @extend %circle;
  color: white;
  background-color: $fff-brand-green;
}

.delivery-day {
  display: grid;
  height: 100%;
  align-items: end;
  cursor: pointer;
  grid-template: "blank date" 1fr
                 "delivery supplement" 22px / 20px auto;
  @media screen and (min-width: 400px) {
    grid-template: "blank date" 1fr
                 "delivery supplement" 22px / 22px auto;
  }
  @media screen and (min-width: 600px) {
    grid-template: "blank date" 1fr
                  "delivery supplement" 27px / 27px auto;
  }

  &.pending-delivery-paid-overlap {
    &::after {
      content: '';
      display: block;
      grid-area: delivery;
      z-index: 1;
      @extend %circle;
      background-color: $fff-brand-green;
      background-image: repeating-linear-gradient(
        45deg,
        rgba($fff-brand-grey, 0),
        rgba($fff-brand-grey, 0) 5px,
        rgba($fff-brand-black-text, 0.4) 5px,
        rgba($fff-brand-black-text, 0.4) 10px
      );
      transform: translateX(8px);
      border: 2px solid white;
    }
    .delivery {
      border: 2px solid white;
    }
  }

  &.pending-delivery-unpaid-overlap {
    &::after {
      content: '';
      display: block;
      grid-area: delivery;
      z-index: 1;
      @extend %circle;
      background-color: $fff-brand-yellow;
      background-image: repeating-linear-gradient(
        45deg,
        rgba($fff-brand-grey, 0),
        rgba($fff-brand-grey, 0) 5px,
        rgba($fff-brand-black-text, 0.4) 5px,
        rgba($fff-brand-black-text, 0.4) 10px
      );
      transform: translateX(8px);
      border: 2px solid white;
    }
    .delivery {
      border: 2px solid white;
    }
  }

  &.delivery-paid-overlap {
    &::after {
      content: '';
      display: block;
      grid-area: delivery;
      z-index: 1;
      @extend %circle;
      background-color: $fff-brand-green;
      transform: translateX(8px);
      border: 2px solid white;
    }
    .delivery {
      border: 2px solid white;
    }
  }

  &.disabled {
    .delivery {
      background: #eeeeee !important;
      color: $fff-brand-black-text !important;
    }
  }

  &.cursor-default {
    cursor: default;
  }

  .calendar-date {
    grid-area: date;
    color: $fff-brand-grey;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    width: 100%;
    height: 100%;
    text-align: right;
  }

  .delivery {
    @extend %circle;
    grid-area: delivery;
    z-index: 2;

    &-paid {
      color: white;
      background-color: $fff-brand-green;

      &.delivery-pending {
        background-image: repeating-linear-gradient(
          45deg,
          rgba($fff-brand-green, 0),
          rgba($fff-brand-green, 0) 5px,
          rgba($fff-brand-grey, 0.4) 5px,
          rgba($fff-brand-grey, 0.4) 10px
        );
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
      }
    }

    &-unpaid {
      color: $fff-brand-black-text;
      background-color: lighten($color: $fff-brand-yellow, $amount: 15);

      &.delivery-pending {
        background-image: repeating-linear-gradient(
          45deg,
          rgba($fff-brand-grey, 0),
          rgba($fff-brand-grey, 0) 5px,
          rgba($fff-brand-black-text, 0.5) 5px,
          rgba($fff-brand-black-text, 0.5) 10px
        );
      }
    }
    
    &-withheld {
      background-color: $fff-brand-red;
      color: white;
    }
  }

  .supplement{
    &-delivery {
      grid-area: supplement;
      @extend %circle;
      color: white;
      background-color: $fff-brand-grey;
      margin-left: -2px;
      border: 2px solid white;
      font-size: 70%;
      width: 18px;
      height: 18px;
      line-height: 16px;
      z-index: 3;
      @media screen and (min-width: 600px) {
        font-size: 80%;
        width: 20px;
        height: 20px;
        margin-left: -4px;
      }
    }

    &-withheld {
      grid-area: delivery;
      @extend %circle;
      color: white;
      background-color: $fff-brand-grey;
      background-image: repeating-linear-gradient(
          45deg,
          rgba($fff-brand-grey, 0),
          rgba($fff-brand-grey, 0) 5px,
          rgba($fff-brand-black-text, 0.4) 5px,
          rgba($fff-brand-black-text, 0.4) 10px
      );
    }
  }
}

.delivery-legend {
  &.align-to-start {
    ul {
      justify-content: flex-start;
      li {
        margin-right: 20px;
        @media (min-width: 600px) {
          margin-right: 35px;
        }
      }
    }
  }
  ul {
    margin: 10px 0px;
    padding: 0px 5px;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;

    li {
      list-style: none;
      margin: 0px 0px 10px;
      padding: 0px;
      font-size: 12px;
      line-height: 12px;
      font-family: "SoleilLt", sans-serif;
      display: flex;
      color: $fff-brand-grey;
      align-items: center;
      flex: 0 0 auto;
      max-width: 100%;
      width: auto;
      min-width: 123px;
      @media (min-width: 600px) {
        min-width: max-content;
      }

      span {
        min-width: 16px;
        min-height: 16px;
        border-radius: 50%;
        border: 1px solid rgba($fff-brand-grey, 0.16);
        margin-right: 8px;

        @media (min-width: 600px) {
          min-width: 20px;
          min-height: 20px;
        }

        &.withheld {
          background-color: $fff-brand-red;
        }

        &.paid {
          background-color: $fff-brand-green;
        }

        &.unpaid {
          background-color: lighten($color: $fff-brand-yellow, $amount: 15);
        }

        &.pending {
          background: repeating-linear-gradient(
            45deg,
            white,
            white 5px,
            rgba($fff-brand-grey, 0.08) 5px,
            rgba($fff-brand-grey, 0.08) 10px
          );
        }

        &.supplement {
          background-color: $fff-brand-grey;
        }

        &.unavailable {
          background-color: rgba($color: $fff-brand-grey, $alpha: .16);
        }

        &.paused {
          background: #eeeeee !important;
          color: $fff-brand-black-text !important;
        }
      }
    }
  }
}

.delivery-date-preview {
  @extend %circle;
  background-color: $fff-brand-green;
  color: white;
  margin: 0 auto;

  &.pending-class {
    background-image: repeating-linear-gradient(
      45deg,
      $fff-brand-green,
      $fff-brand-green 5px,
      rgba($fff-brand-black-text, 0.5) 5px,
      rgba($fff-brand-black-text, 0.5) 10px
    );
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.35);
  }
}
