@import "./responsive";

.user-profile-container {
  margin-left: 0px;
  margin-right: 0px;

  > div {
    padding-left: 0px;
    padding-right: 0px;
    @include media-breakpoint-for(tab) {
      max-width: 100%;
      flex: 0 0 100%;
    }
    @include media-breakpoint-for(tab-landscape) {
      max-width: 100%;
      flex: 0 0 100%;
    }
  }
  .green-bg-row {
    background-color: $fff-brand-light-green;
    position: relative;
    height: auto;
  }
  .green-gradienent {
    background-color: white; /* For browsers that do not support gradients */
    background-image: linear-gradient($fff-brand-light-green, white);
    background-image: -moz-linear-gradient(
      $fff-brand-light-green,
      white
    ); /* FF3.6-15 */
    background-image: -webkit-linear-gradient(
      $fff-brand-light-green,
      white
    ); /* Chrome10-25,Safari5.1-6 */
    background-image: linear-gradient(
      $fff-brand-light-green,
      white
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$fff-brand-light-green', endColorstr='white',GradientType=0 ); /* IE6-9 */
    background-repeat: no-repeat;
    background-size: 100% 300px;
  }
  .left-container-one {
    position: relative;
    // background-image: url('../img/fff-profile-green-bg.png');
    // background-repeat: no-repeat;
    // background-size: 70%;
    // background-position: right 20px top -70px;
    // height: 346px;
    // width: 100%;
    // background-image: linear-gradient(to right, $fff-brand-light-green 50%, transparent);
    //       &::before{
    //     content: '';
    //     position: absolute;
    //     right:0;
    //     top: 0;
    //     height: 303px;
    //     width: 100%;
    //     opacity: 0.6;
    //     background-image: linear-gradient(to right, $fff-brand-light-green 50%, transparent);

    //     @include media-breakpoint-for(mobile) {
    //         height: 140px;
    //       }
    //       @include media-breakpoint-for(tab) {
    //         height: 140px;
    //       }
    //       @include media-breakpoint-for(tab-landscape) {
    //         height: 140px;
    //       }
    // }

    @include media-breakpoint-for(mobile) {
      // background-size: 66%;
      // background-position: right 20px top 0px;
      padding-left: 0px;
      padding-right: 0px;
    }
    @include media-breakpoint-for(tab) {
      // background-size: 66%;
      // background-position: right 20px top 0px;
      padding-left: 0px;
      padding-right: 0px;
    }
    @include media-breakpoint-for(tab-landscape) {
      // background-size: 66%;
      // background-position: right 20px top 0px;
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  .left-container-two {
    position: relative;

    @include media-breakpoint-for(mobile) {
      padding-left: 0px;
      padding-right: 0px;
    }
    @include media-breakpoint-for(tab) {
      padding-left: 0px;
      padding-right: 0px;
    }
    @include media-breakpoint-for(tab-landscape) {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  .profile-header {
    margin-left: 0px;
    margin-right: 0px;
    padding-top: 40px;
    .profile-detail-wrap {
      display: -webkit-inline-flex;
      display: inline-flex;
      .profile-img-wrap {
        display: inline-block;
        width: 60px;
        height: 60px;
      }
      .text-wrap {
        padding-left: 12px;
        display: inline-block;
        h1 {
          font-size: 28px;
          font-weight: 600;
          color: black;
          margin-bottom: 0rem;
          margin-top: 0rem;
          font-family: "DINPro-Bold", sans-serif;
          display: block;
          width: 100%;
        }
        p {
          font-family: "SoleilLt", sans-serif;
          font-size: 16px;
          font-weight: 400;
          color: $fff-brand-grey;
          display: block;
          width: 100%;
          margin-bottom: 0rem;
        }
      }
    }
  }
  .widget {
    margin-left: 0px;
    margin-right: 0px;
    padding-top: 30px;
    padding-bottom: 28px;
    position: relative;
    @include media-breakpoint-for(mobile) {
      padding-bottom: 0px;
    }
    @include media-breakpoint-for(tab) {
      padding-bottom: 0px;
    }
    @include media-breakpoint-for(tab-landscape) {
      padding-bottom: 0px;
    }
    .customHeigh {
      height: auto;
      @include media-breakpoint-for(mobile) {
        //  height: unset;
      }
      @include media-breakpoint-for(tab) {
        // height: 100%;
      }
      @include media-breakpoint-for(tab-landscape) {
        // height:  100%;
      }
    }
    .widget-col {
      // background-color: white;
      // box-shadow: 0px 1px 4px rgba($fff-brand-grey, 0.08);
      padding: 0px;
      position: relative;
      border-radius: 4px;
      overflow: hidden;
      height: 100%;
      &.meal {
        // &::before{
        //     content: '';
        //     background-image: url('../img/fff-meal-yellow.svg');
        //     background-size: 60px;
        //     right: -111px;
        //     position: absolute;
        //     height: 495px;
        //     width: 83%;
        //     transform:          rotate(40deg);
        //     -ms-transform:      rotate(40deg);
        //     -moz-transform:     rotate(40deg);
        //     -webkit-transform:  rotate(40deg);
        //     -o-transform:       rotate(40deg);

        //     @include media-breakpoint-for(mobile) {
        //         right: 0px;
        //       }
        //       @include media-breakpoint-for(tab) {
        //         right: 0px;
        //       }
        //       @include media-breakpoint-for(tab-landscape) {
        //         right: 0px;
        //       }
        // }
      }
      &.token {
        // &::before{
        //     content: '';
        //     background-image: url('../img/fff-token-yellow.svg');
        //     background-size: 60px;
        //     right: -111px;
        //     position: absolute;
        //     height: 495px;
        //     width: 83%;
        //     transform:          rotate(40deg);
        //     -ms-transform:      rotate(40deg);
        //     -moz-transform:     rotate(40deg);
        //     -webkit-transform:  rotate(40deg);
        //     -o-transform:       rotate(40deg);

        //     @include media-breakpoint-for(mobile) {
        //         right: 0px;
        //       }
        //       @include media-breakpoint-for(tab) {
        //         right: 0px;
        //       }
        //       @include media-breakpoint-for(tab-landscape) {
        //         right: 0px;
        //       }
        // }
      }

      @include media-breakpoint-for(mobile) {
        margin-bottom: 0px;
      }
      @include media-breakpoint-for(tab) {
        margin-bottom: 20px;
      }
      @include media-breakpoint-for(tab-landscape) {
        margin-bottom: 20px;
      }
      .detail-wrap {
        display: -webkit-inline-flex;
        display: inline-flex;
        margin-top: 15px;
        width: 100%;
        .img-wrap {
          background-repeat: no-repeat;
          width: 60px;
          height: 60px;
          border-radius: 50%;
          background-color: rgba($fff-brand-grey, 0.1);
        }
        .text-wrap {
          display: block;
          padding-left: 12px;
          p {
            font-size: 16px;
            font-weight: 400;
            color: rgba($fff-brand-grey, 0.72);
            margin-bottom: 0rem;
            margin-top: 0rem;
            font-family: "DINPro-Bold", sans-serif;
            display: inline-block;

            @media (min-width: 1600px) {
              font-size: 18px;
            }
            .lg {
              font-size: 20px;

              @include media-breakpoint-for(mobile) {
                font-size: 18px;
              }
              @include media-breakpoint-for(tab) {
                font-size: 18px;
              }
              @include media-breakpoint-for(tab-landscape) {
                font-size: 18px;
              }
              @media (min-width: 1600px) {
                font-size: 22px;
              }
            }
            strong {
              color: $fff-brand-grey;
            }
          }

          a {
            color: $fff-brand-green;
            display: inline-block;
            font-weight: 600;
          }
          h1 {
            font-size: 28px;
            font-weight: 600;
            color: black;
            margin-bottom: 0rem;
            margin-top: 0rem;
            font-family: "DINPro-Bold", sans-serif;
            display: block;
            width: 100%;

            @include media-breakpoint-for(mobile) {
              font-size: 22px;
            }
            @include media-breakpoint-for(tab) {
              font-size: 22px;
            }
            @include media-breakpoint-for(tab-landscape) {
              font-size: 22px;
            }
            .sm {
              font-size: 18px;

              @include media-breakpoint-for(mobile) {
                font-size: 16px;
              }
              @include media-breakpoint-for(tab) {
                font-size: 16px;
              }
              @include media-breakpoint-for(tab-landscape) {
                font-size: 16px;
              }
            }
          }
        }
      }
      h2 {
        font-size: 18px;
        font-weight: 600;
        color: rgba($fff-brand-grey, 0.72);
        margin-bottom: 0rem;
        margin-top: 0rem;
        margin-right: 15px;
        font-family: "DINPro-Bold", sans-serif;
        display: inline-block;
        width: auto;
        @media (min-width: 1600px) {
          font-size: 20px;
        }
      }
      button {
        position: absolute;
        right: 0px;
        top: 0px;
        box-shadow: none;
      }
    }
  }
  .profile-carousel {
    margin: 40px 0px 40px;
    position: relative;
    @include media-breakpoint-for(mobile) {
      padding-top: 0px;
      margin: 15px 0px;
    }
    @include media-breakpoint-for(tab) {
      padding-top: 0px;
      margin: 15px 0px;
    }
    @include media-breakpoint-for(tab-landscape) {
      padding-top: 0px;
      margin: 15px 0px;
    }
    .customHeigh {
      height: 215px;
      // position: absolute !important;
      // top: 50%;
      // left: unset;
      // -webkit-transform: translate(0, -50%);
      // transform: translate(0, -50%);
      // display: block;
      // width: 100%;
      @include media-breakpoint-for(mobile) {
        height: 180px;
        position: relative !important;
        top: unset;
        -webkit-transform: unset;
        transform: unset;
      }
      @include media-breakpoint-for(tab) {
        height: 233px;
        // position: absolute !important;
        // top: 50%;
        // left: unset;
        // -webkit-transform: translate(0, -50%);
        // transform: translate(0, -50%);
      }
      @include media-breakpoint-for(tab-landscape) {
        height: 233px;
        // position: absolute;
        // top: 50%;
        // left: unset;
        // -webkit-transform: translate(0, -50%);
        // transform: translate(0, -50%);
      }
      @media (min-width: 1600px) {
        height: 194px;
      }
    }

    .carousel-col {
      padding: 0px;
      position: relative;
      border-radius: 4px;
      overflow: hidden;
      height: 100%;
      .carousel-slider-user-profile {
        height: 100%;
        > div {
          height: 100%;
        }
        .carousel-fade {
          height: 100%;
          .carousel-inner {
            height: 100%;
            // max-height: 115px;
            .carousel-item {
              height: 100%;
              .carousel-image {
                height: 100%;
                float: left;
                padding: 0px 0px 0px 30px;
                // flex: 0 0 30%;
                // max-width: 30%;
                @include media-breakpoint-for(mobile) {
                  width: 50%;
                }
                @include media-breakpoint-for(tab) {
                  width: 50%;
                }
                @include media-breakpoint-for(tab-landscape) {
                  width: 50%;
                }
                .slider-image {
                  width: 100%;
                  background-position: center center;
                  height: 100%;
                  background-size: contain;
                  background-repeat: no-repeat;
                }
              }
              .carousel-caption {
                bottom: unset;
                left: 0;
                float: left;
                position: relative;
                right: unset;
                padding: 0px 0px 0px 20px;
                height: 100%;
                // flex: 0 0 70%;
                // max-width: 70%;
                @include media-breakpoint-for(mobile) {
                  padding: 0px 0px 0px 10px;
                  width: 50%;
                }
                @include media-breakpoint-for(tab) {
                  padding: 0px 0px 0px 10px;
                  width: 50%;
                }
                @include media-breakpoint-for(tab-landscape) {
                  padding: 0px 0px 0px 10px;
                  width: 50%;
                }
                .text-left {
                  position: absolute;
                  top: 50%;
                  left: unset;
                  -webkit-transform: translate(0, -50%);
                  transform: translate(0, -50%);
                  display: block;
                  padding-right: 30px;
                  h1 {
                    font-size: 28px;
                    margin: 0px;
                    line-height: 30px;
                    @include media-breakpoint-for(mobile) {
                      font-size: 18px;
                      line-height: 20px;
                    }
                    @include media-breakpoint-for(tab) {
                      font-size: 18px;
                      line-height: 20px;
                    }
                    @include media-breakpoint-for(tab-landscape) {
                      font-size: 18px;
                      line-height: 20px;
                    }
                  }
                  p {
                    font-size: 16px;
                    margin-bottom: 0px;
                    margin-top: 5px;
                    @include media-breakpoint-for(mobile) {
                      font-size: 14px;
                    }
                    @include media-breakpoint-for(tab) {
                      font-size: 14px;
                    }
                    @include media-breakpoint-for(tab-landscape) {
                      font-size: 14px;
                    }
                  }
                }
                .carousel-cta-container {
                  .btn {
                    position: relative;
                    top: unset;
                    right: unset;
                    margin-top: 15px;
                    box-shadow: none;
                    @include media-breakpoint-for(mobile) {
                      margin-top: 5px;
                    }
                    @include media-breakpoint-for(tab) {
                      margin-top: 5px;
                    }
                    @include media-breakpoint-for(tab-landscape) {
                      margin-top: 5px;
                    }
                  }
                }
              }
            }
          }
          .carousel-control {
            width: 100%;
            height: auto;
            flex: 0 0 100%;
            max-width: 100%;
            position: absolute;
            top: 50%;
            left: unset;
            -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
            display: block;
            z-index: 1;
            span {
              display: inline-block;
              width: 20px;
              height: 20px;
              background-size: 8px;
              background-color: black;
              border-radius: 50%;
            }
            .carousel-control-prev {
              top: 0px;
              background-image: url(../../assets/img/icons/arrow-black-prev.svg);
              background-repeat: no-repeat;
              background-position: center;
              display: block;
              width: 30px;
              height: 30px;
              margin-left: 0;
              background-size: 28px;
              opacity: 1;
              span {
                display: none;
              }
            }
            .carousel-control-next {
              top: 0px;
              background-image: url(../../assets/img/icons/arrow-black-next.svg);
              background-repeat: no-repeat;
              background-position: center;
              display: block;
              width: 30px;
              height: 30px;
              margin-right: 0;
              background-size: 28px;
              opacity: 1;
              span {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  .tab-content {
    margin-left: 0px;
    margin-right: 0px;
    > .col-md-12 {
      @include media-breakpoint-for(mobile) {
        padding: 0px;
      }
      @include media-breakpoint-for(tab) {
        padding: 0px;
      }
      @include media-breakpoint-for(tab-landscape) {
        padding: 0px;
      }
    }
  }

  .right-container {
    background-color: rgb(238, 238, 238);
    padding: 10px;
    margin-left: 0px;
    margin-right: 0px;
    position: fixed;
    right: 0;
    bottom: 0px;
    top: 65px;

    ::-webkit-scrollbar {
      width: 2px;
    }

    ::-webkit-scrollbar-thumb {
      background: rgba($fff-brand-grey, 0.4);
    }

    @include media-breakpoint-for(mobile) {
      display: none;
    }
    @include media-breakpoint-for(tab) {
      display: none;
    }
    @include media-breakpoint-for(tab-landscape) {
      display: none;
    }
  }
}

.macro-cal-comparison {
  .add-right-border {
    @media screen and (min-width: 600px) {
      border-right: 1px solid $fff-brand-green;
    }
  }
  h3 {
    font-weight: bold;
    font-size: 18px;
    @media screen and (min-width: 900px) {
      font-size: 16px;
    }
  }
  .value {
    font-size: 30px;
    color: $fff-brand-black-text;
  }
  .allowance {
    font-size: 14px;
    font-weight: 600;
    color: $fff-brand-black-text;
    line-height: 1;
  }
  .macro-row {
    margin-top: 20px;
    &.increase-margin {
      @media screen and (min-width: 600px) {
        margin-top: 34px;
      }
    }
    p,
    span {
      font-size: 12px;
    }
  }
}

/*========================
  New User Profile Styles
=========================*/

%toggleSwitch {
  display: flex;
  justify-content: end;
  .p-inputswitch {
    width: 3.5em;
    height: 1.8em;
    color: $fff-brand-new-light;
    font-weight: bold;
    font-family: $fff-font-sans;

    &::before, &::after {
      content: '';
      width: 18px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      position: absolute;
      z-index: 1;
      cursor: pointer;
      transition: 0.3s color;
    }

    &::before {
      content: 'g';
      left: 4px;
      bottom: 2px;
      color: $fff-brand-new-light;
    }

    &::after {
      content: '%';
      right: 4px;
      color: $fff-brand-new-secondary;
    }

    .p-inputswitch-slider {
      background-color: $fff-brand-new-fill !important;

      &::before {
        width: 22px;
        height: 22px;
        background-color: $fff-brand-new-secondary;
        bottom: 0;
        top: 0;
        margin: auto;
        left: 0.15em;
      }
    }
  }

  .p-inputswitch-checked {
    .p-inputswitch-slider {
      &::before {
        transform: translateX(1.6em);
      }
    }

    &.p-inputswitch {
      &::before {
        color: $fff-brand-new-secondary;
      }
      &::after {
        color: $fff-brand-new-light;
      }
    }
  }

  .p-inputswitch-focus {
    .p-inputswitch-slider {
      box-shadow: none;
    }
  }
}

.user-profile {
  padding-top: 0.5rem;
  background-color: $fff-brand-new-light;
  min-height: calc(100vh - 348px);
  // height: calc(100vh - 65px);
  // overflow: hidden auto;

  .container {
    min-height: calc(100vh - 356px);
  }

  @include breakpoint(mobile) {
    padding-top: 1rem;
  }

  @media (min-width: 1300px) and (max-width: 1365px){
    .container {
     max-width: 1240px;
    }
   }

  @media (min-width: 1366px) and (max-width: 1600px){
   .container {
    max-width: 1300px;
   }
  }

  .place-order-content {
    max-width: 650px;
    margin: auto;
    position: absolute;
    display: inline-block;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;

    h4 {
      margin-bottom: 1rem;
    }
  }

  .sidebar {
    width: 350px;
    max-width: 350px;
    position: sticky;
    top: 72px;
    height: calc(100vh - 72px);
    &::before {
      content: '';
      width: 50vw;
      height: calc(100% + 40px);
      background-color: $fff-brand-new-fill;
      position: absolute;
      top: -40px;
      right: 0;
      z-index: 0;
    }

    &.sidebar-open {
      height: 200px;
    }

    @include media-breakpoint-for(desktop-down) {
      border-radius: 8px 8px 0 0;
      position: fixed;
      z-index: 999;
      width: 100%;
      max-width: 100%;
      height: auto;
      background-color: $fff-brand-new-fill;
      top: auto;
      bottom: -1px;
      left: 0;
      right: 0;
      margin: 0 auto;
      padding: 0;

      &::before {
        display: none;
      }
    }

    .mobile-nav-menu {
      display: flex;
      flex-direction: column-reverse;
      padding: 0 1rem;
      transition: 0.2s height;
      min-height: 0;
      height: 89px;
      overflow: hidden;

      &__expanded {
        height: 178px;
      }

      &__items {
        list-style-type: none;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1.25rem 0;
        margin: 0;
        &:not(:first-child) {
          border-bottom: 1px solid $fff-brand-new-light;
        }

        &.sub-menu {
          .mobile-nav-menu__item {
            flex: 1 1 25%;
          }
        }
      }

      &__item {
        display: flex;
        flex-direction: column;
        flex: 1 1 20%;
        justify-content: center;
        padding: 0 8px;
        text-align: center;
        max-width: 75px;

        a, .btn-more {
          display: flex;
          flex-direction: column;
          justify-content: center;
          font-size: 12px;
          font-weight: bold;
          line-height: 1.5;
          font-family: $fff-font-sans;
          color: $fff-brand-new-primary;
          font-weight: 500;
          gap: 0.125rem;
          span {
            margin-bottom: 0.25rem;
          }
        }

        a {
          &.active {
            color: $fff-brand-new-primary;
            position: relative;
            font-weight: bold;
            .fff-nav-icon {
              &::before {
                color: $fff-brand-new-light;
              }
              &::after {
                content: '';
                display: block;
                width: 50px;
                height: 36px;
                border-radius: 25px;
                background-color: rgba($fff-brand-new-primary, 1);
                position: absolute;
                z-index: -1;
                inset: 0;
                margin: 0 auto;
                top: -3px;
              }
            }
          }
        }

        .btn-more {
          outline: none;
          border: none;
          background: none;
          width: 100%;
          box-shadow: none !important;
          padding: 0;
          i {
            font-weight: bold;
            font-size: 22px;
            padding: 5px;
            transition: 0.2s all;
            &.arrow-up {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }

  .sidebar-inner {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-right: 2rem;
    position: sticky;
    top: 244px;
    height: calc(100% - 150px);
    ::-webkit-scrollbar {
      width: 4px;
    }

    ::-webkit-scrollbar-thumb {
      background: rgba($fff-brand-grey, 0.4);
      border-radius: 10px;
    }

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba($fff-brand-grey, 0.2);
      border-radius: 10px;
    }

    aside {
      min-width: 100px;
      flex-grow: 1;
      margin-bottom: 4.4rem;
      margin-left: -2rem;
      overflow-y: auto;

      // @media (orientation: landscape) and (max-height: 860px) {
      //   zoom: 80%;
      // }
    }

    .carousel-wrapper {
      flex-grow: 0;
      background-color: transparent;
      padding: 1rem 0;
      margin-bottom: 0rem;
      // border: 1px solid rgba($fff-brand-new-primary, .18);
      position: absolute;
      z-index: -1;
      min-height: 345px;
      width: 500px;
      bottom: 0;
      overflow: hidden;
      right: -1rem;

      &::after {
        content: '';
        background-image: url('../img/fingerprint-pattern-with-gradient.png');
        display: inline-block;
        background-size: contain;
        width: 100%;
        min-width: 500px;
        height: 513px;
        position: absolute;   
        right: 0;
        top: 0;
        background-repeat: no-repeat;
        z-index: -1;
      }
      .carousel-item {
        height: auto;
        display: none;
        flex-direction: column;
      }
      .carousel-image {
        display: none;
        flex-grow: 1;
        width: 100%;
        height: 150px;
        margin-bottom: 1rem;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: $fff-brand-new-light;
      }
      .carousel-content-wrapper {
        padding: 0 2rem;
        &::after {
          content: '';
        }
        .row {
          flex-direction: column;
        }
      }
      h3, p {
        color: $fff-brand-new-fill;
      }
      h3 {
        font-size: 1.75rem;
        line-height: 1;
        margin-bottom: 0.25rem;
      }
      p {
        margin-bottom: 0.75rem;
      }
      button {
        border: 0;
        width: 100%;
      }
    }
  }

  .sidebar-wrapper {
    height: 100%;
  }

  .sidebar-book-call {
    display: inherit;
    position: absolute;
    z-index: 10;
    bottom: 0;
    width: 100%;
    padding: 16px;
    padding-left: 0;
    align-items: center;

    .book-a-call-btn{
      background: #ffffff96;
    }
  }
  
  .customer-info-widget {
    height: 120px;
    font-family: $fff-font-sans;
    color: $fff-brand-new-primary;
    padding: 1.25rem 2rem 1.875rem 0;
    margin-bottom: 2rem;
    position: sticky;
    top: 72px;
    background-color: $fff-brand-new-fill;
    z-index: 1;

    &::before {
      content: '';
      width: 50vw;
      height: calc(100% + 100px);
      background-color: $fff-brand-new-fill;
      position: absolute;
      z-index: -1;
      right: -1rem;
      bottom: 0;
    }

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      position: absolute;
      right: 2rem;
      bottom: 0;
      background-color: #c8c8c8;
    }

    %text-overflow {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    h1 {
      @extend %text-overflow;
      font-family: $fff-font-sans;
      color: $fff-brand-new-primary;
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 0.5rem; 
    }
    .user-email {
      @extend %text-overflow;
      margin-bottom: 0.25rem;

    }
    .customer-id {
      @extend %text-overflow;

    }

    // @media (orientation: landscape) and (max-height: 860px) {
    //   zoom: 80%;
    // }
  }

  .sidebar-navigation-panel {
    ul {
      width: fit-content;
      padding: 0;

      li {
        list-style-type: none;
        display: block;


        &:not(:last-child) {
          margin-bottom: 0.5rem;
        }

        a {
          display: block;
          width: 100%;
          color: $fff-brand-new-primary;
          font-family: $fff-font-sans;
          font-size: 1rem;
          font-weight: bold;
          padding: 1rem 2rem;
          border-radius: 50px;
          // border: 5px solid $fff-brand-new-fill;
          
          &.active {
            background-color: $fff-brand-new-primary;
            color: $fff-brand-new-light;
            border-radius: 50px;
            &:hover {
              background-color: $fff-brand-new-primary;
              color: $fff-brand-new-light;
            }
          }
          &:hover {
            background-color: rgba($fff-brand-new-primary, .12);
            color: $fff-brand-new-primary;
          } 
        }
      }
      li:has(a.active) {
        position: sticky;
        top: 0;
        bottom: 0;
        // z-index: 99;
        // &::after {
        //   content: '';
        //   display: block;
        //   width: 75%;
        //   margin: 0 auto;
        //   height: 5px;
        //   border-radius: 0 0 50px 50px;
        //   background-color: $fff-brand-new-fill;
        // }
      }
    }
  }

  .content-area {
    width: 100%;
    background-color: $fff-brand-new-light;
    padding-left: 3rem;
    position: relative;
    z-index: 1;
    max-width: calc(100% - 350px);

    @include media-breakpoint-for(desktop-down) {
      max-width: 100%;
      padding-left: 15px;
    }

    // @media (orientation: landscape) and (max-height: 860px) {
    //   zoom: 80%;
    // }
  }

  .menu-header {
    height: 120px;
    margin-bottom: 1.5rem;
    position: sticky;
    top: 72px;
    background-color: $fff-brand-new-light;
    z-index: 10001;
    &::before {
      content: '';
      display: block;
      width: calc(100% + 40px);
      height: calc(100% + 40px);
      position: absolute;
      background-color:$fff-brand-new-light;
      top: -40px;
      left: -20px;
      z-index: -1;
      bottom: 1px;
    }

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      position: absolute;
      left: 0;
      bottom: 0;
      background-color: #c8c8c8;
    }

    @include breakpoint(mobile) {
      height: auto;
      padding-bottom: 1rem;
      position: relative;
      top: 0;

      .btn-wrapper {
        padding: 0;
        margin-top: 1rem;
        margin-bottom: 1rem;
        justify-content: start !important;
      }
    }

    .row {
      margin: 0;
    }

    .btn-wrapper {
      justify-content: end;

      a, button {
        flex: 1;
        width: 100%;
        max-width: 150px;
      }
    }

    h1, h2 {
      font-family: $fff-font-serif;
      font-weight: normal;
      color: $fff-brand-new-primary;
      margin-bottom: 0.5rem;
    }

    h1 {
      font-size: 2.5rem;
    }

    h4 {
      overflow: hidden;
      font-size: 14px;
      color: $fff-brand-new-primary;
      font-family: $fff-font-sans;
      margin-bottom: 0.5rem;
      line-height: 18px;
    }

    .detail-wrap {
      margin-top: 0;

      .text-wrap {
        padding: 0;
        color: $fff-brand-new-primary;
        font-weight: 700;
        line-height: 1.7;

        span, div {
          font-weight: normal;
        }

        .date {
          color: $fff-brand-new-accent;
        }
      }
    }

    .btn-reorder {
      width: 100%;
      max-width: 150px;
    }

    .account-credit-balance {
      padding: 0.25rem 0.625rem;
      border-radius: 4px;
      background-color: $fff-brand-new-secondary;
      color: $fff-brand-new-light;
    }
  }

  .content-box {
    padding: 1rem 0;
    box-shadow: 0px 1px 6px rgba($fff-brand-grey, 0.12);
    margin-bottom: 2rem;
    border-radius: 6px;

    h3 {
      font-size: 20px;
      @include breakpoint(mobile) {
        font-size: 18px;
      }
    }

    p {
      color: $fff-brand-new-dark;
    }

    .row {
      margin: 0;
    }

    .promo-infor-widget {
      padding: 0.625rem 1rem;
      border: 1px solid rgba($fff-brand-new-primary, 0.12);
      margin-bottom: 1rem;
      background-color: $fff-brand-new-fill;
      border-radius: 3px;
      .carousel-content-wrapper {
        .row {
          .promo-content {
            h3, p {
              color: $fff-brand-new-primary;
              margin-bottom: 0.5rem;
            }
            
            h3 {
              margin-bottom: 0;
            }
          }

          .promo-action {
            max-width: 156px;
            display: flex;
            align-items: center;
          }
        }
      }
    }

    .delivery-schedule-info-box-container {
      @include media-breakpoint-for(mobile) {
        // display: none; 
      }
      @include breakpoint(mobile) {
        display: none;
      }
    }

    .delivery-schedule-info-box {
      padding: 1rem;
      border-radius: 3px;
      background-color: $fff-brand-new-fill;
      position: relative;

      .info-widget-header {
        border-bottom: 1px solid rgba($fff-brand-new-primary, .16);
        margin-bottom: 1rem;
        display: flex;
        justify-content: space-between;
      }

      .delivery-location {
        margin-left: 0.5rem;
        font-size: 12px;
        margin: auto;
        margin-right: 2rem;
      }

      .delveiry-instruction {
        color: $fff-brand-new-dark;
        span {
          font-weight: bold;
        }
      }

      .animate-overlay {
        width: 100%;
        height: 100%;
        display: inline-block;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 999;
        background-color: rgba($fff-brand-new-light, 0.735);
      }

      h3 {
        font-size: 18px;
        font-family: $fff-font-sans;
        color: $fff-brand-new-accent;
      }

      .info-row {
        display: flex;
        margin-bottom: .5rem;
        align-items: center;

        &:last-child {
          margin-bottom: 1rem;
        }

        p {
          margin-bottom: 0.25rem;
        }

        .lable {
          min-width: 130px;
          font-weight: bold;
        }
      }

      .bag-count {
        width: 27px;
        height: 27px;
        background-color: $fff-brand-new-primary;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $fff-brand-new-light;
      }
    }

    .delivery-pattern-manager {
      padding: 2rem 1rem 0;

      .section-title-container {
        justify-content: space-between;
        align-items: center;
        margin: 0 0 .5rem;

        .btn {
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $fff-brand-new-primary;
          border: 0;
          box-shadow: none;
        }
      }

      .delivery-pattern-container {
        margin: 0;
        border-radius: 3px;
        border: 0;

        .info-row {
          align-items: center;
          margin: 0 0 1rem;
          gap: 0.5rem;

          .lable {
            margin-bottom: 0;
          }
        }

        .schedule-actions-row {
          margin: 0 -15px;

          @include breakpoint(mobile) {
            margin-top: 1rem;
            margin-bottom: 1rem;
            flex-direction: column;

            .btn-save-schedule-wrapper {
              margin-top: 1rem;
              button {
                width: 100%;
              }
            }
          }
        }

        .schedule-date-range {
          padding: 0rem;
          border: 1px solid rgba($fff-brand-new-dark, 0.12);
          height: 40px;
          min-width: 240px;
          line-height: 38px;
          outline: 0px;
          box-shadow: none;
          border-radius: 3px;

          @include breakpoint(mobile) {
            width: 100%;
          }          

          &:hover {
            border-color: $fff-brand-new-secondary;
          }

          &.p-focus {
            border-color: $fff-brand-new-secondary;
          }

          .p-dropdown-label {
            height: 100%;
            min-height: 100%;
            color: $fff-brand-new-accent;
          }

          .p-dropdown-item {

            &.p-highlight {
              background-color: $fff-brand-new-primary;
            }

            &:not(.p-highlight):hover {
              background-color: $fff-brand-new-fill;
            }
          }

          .p-dropdown-trigger {
            padding: 18px 20px;

            span {
              color: $fff-brand-new-dark;
              font-size: 14px;
            }
          }
        }

        .delivery-pattern {
          flex: 1 1 auto;
        }

        .btn-wrapper {
          flex: 0 0 auto;
          padding: 0 0 0 1rem;
          text-align: center;
          display: flex;
          justify-content: start;
          align-items: center;

          .btn {
            width: 32px;
            height: 32px;
            padding: 5px;
            border: 0;
            color: $fff-brand-new-primary;
            box-shadow: none;
            background: transparent;
            min-width: fit-content;

            &:hover {
              background-color: $fff-brand-new-fill;
            }
          }
        }

        .btn-save-schedule-wrapper {
          display: flex;
          justify-content: end;
          align-items: center;
          column-gap: 1rem;

          .btn {
            min-width: 150px;
            border-width: 1px;
          }

          @include breakpoint(mobile) {
            flex-direction: column;
            gap: 1rem;
          }
        }

        .delivery-days-wrapper {
          .delivery-day-item {
            border-radius: 0;

            .total-count {
              display: flex;
              justify-content: center;

              .total {
                width: 32px;
                height: 32px;
                max-width: 32px;
                display: flex;
                border-radius: 32px;
                align-items: center;
                justify-content: center;
                margin: 0 0.625rem;
                background-color: $fff-brand-new-primary;
                color: $fff-brand-new-light;
              }

              .btn-rounded {
                max-width: 26px;
                height: 26px
              }

              .pending-delivery {
                background-image: repeating-linear-gradient(
                  45deg,
                  rgba($fff-brand-green, 0),
                  rgba($fff-brand-green, 0) 6px,
                  rgba($fff-brand-new-light, 0.4) 6px,
                  rgba($fff-brand-new-light, 0.4) 12px
                );
              }
            }
          }
        }

        .custom-calendar-dropdown {
          margin: .5rem 0 0;

          .p-inputtext {
            border: 1px solid rgba($fff-brand-new-dark, 0.12);
            font-family: $fff-font-sans;
            font-weight: 700;
            color: $fff-brand-new-accent;
            border-radius: 3px !important;

            &:hover, &:focus {
              border: 1px solid $fff-brand-new-secondary !important;
            }
          }

          .p-datepicker {
            .p-datepicker-header {
              button {
                color: $fff-brand-new-dark;

                span {
                  font-size: 16px;
                }
              }
              .p-datepicker-title {
                color: $fff-brand-new-accent;
                font-weight: 700;
                font-family: $fff-font-sans;
              }
            }
            .p-datepicker-calendar {
              tr {
                th {
                  font-family: $fff-font-sans;
                  font-weight: 700;
                  color: $fff-brand-new-dark;
                }

                .p-highlight {
                  background-color: rgba($fff-brand-new-primary, .12);
                }
              }
            }
          }
        }
      }
    }

    &.account-info-view {
      .entry-row {
        margin-bottom: 1.25rem;
        
        .btn-edit {
          min-width: 28px;
        }

        .hide-in-mobile {
          display: none !important;

          @media (min-width: 575.98px) {
            display: flex !important;
          }
        }

        .hide-in-desktop {
          display: none !important;

          @media (max-width: 575.97px) {
            display: flex !important;
          }
        }

        @include breakpoint(mobile) {
          margin-bottom: 2rem;

          .col {
            &:first-child {
              flex-basis: 50%;
            }

            .btn-primary {
              width: 100%;
            }
          }

          &.personal-info {
            flex-direction: column;
            gap: 0.5rem;
          }
        }
      }

      .user-details {
        padding: 0 1rem;
        display: grid;
        grid-template-columns: calc(50% - 0.5rem) calc(50% - 0.5rem);
        column-gap: 1rem;

        .col {
          padding: 0;

          .gender-input {
            margin-bottom: 0 !important;
          }

          .fff-inline__value-container {
            display: flex;
            position: relative;
            align-items: center;
          }

          .fff-inline__single-value {
            margin-top: 6px;
            height: 48px;
            line-height: 2.5;
          }

          .fff-inline__control--is-focused {
            border-width: 1px;
          }

          .fff-input-inline .p-datepicker {
            position: absolute;
            top: inherit !important;
          }
        }

        .actions {
          grid-column-start: 2;
          grid-column-end: 2;
          grid-row-start: 5;
          grid-row-end: 5;

          .btn-wrapper {
            margin-top: 1rem;
            .btn {
              min-width: 150px;
            } 
          }
        }

        @include breakpoint(tab-down) {
          display: block;
        }
      }

      .input-group {
        margin-bottom: 0;
      }

      .entry-title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h3 {
          margin-bottom: 0;
          list-style: 1;
        }
      }

      .btn-md {
        min-width: 125px;
      }

      .btn-edit {
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $fff-brand-new-primary;
        border: 0;
        box-shadow: none;
      }

      .delivery-address-card-container {
        padding: 0 15px;
        gap: 1rem;

        @include media-breakpoint-for(mobile) {
          flex-direction: column;
        }

        .row {
          width: 100%;
        }

        >.col {
          padding: 0;
          flex: 1 1 calc(50% - 1rem);
          max-width: calc(50% - 0.5rem);

          @include media-breakpoint-for(mobile) {
            flex: 1 1 100%;
            max-width: 100%;
          }
        }
      }

      .delivery-address-card-item {
        border: 1px solid rgba($fff-brand-new-dark, 0.12);
        padding: 1rem 0;
        height: 100%;
        border-radius: 3px;

        .col-6 {
          flex: 1 1 50%;
        }

        .card-number {
          display: flex;
          align-items: center;
          gap: 0.25rem;

          span {
            font-size: 16px;
            
          }

          .last-four-digits {
            font-size: 14px;
          }
        }

        &__info {
          display: flex;
          align-items: center;
          gap: 0.5rem;

          .display-name {
            font-weight: bold;
          }

          img {
            height: 25px;
          }

          .label-default {
            width: fit-content;
            background-color: $fff-brand-new-secondary;
            padding: 2px 8px;
            color: $fff-brand-new-light;
            border-radius: 2px;
            font-size: 12px;
          }
        }

        .label-col {
          display: flex;
          align-items: center;
        }

        .btn-col {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          justify-content: end;
        }
      }

      .email-settings-container {
        .row {
          padding: 0 1rem;
        }
        .row:nth-child(2) {
          padding-top:10px;
        }

        .email-settigs-row {
          gap: 1rem;

          > .col {
            padding: 0;
          }

          .checkbox-default {
            margin-bottom: 0;
          }

          @include media-breakpoint-for(mobile) {
            flex-direction: column;
            gap: 0.5rem;
          }
        }
    }
    }
  }

  .manage-nutrition-view {
    .entry-row {
      margin-bottom: 0.5rem;

      h3 {
        margin-bottom: 0;
      }

      .form-group {
        margin-bottom: 0;
      }

      .btn-edit {
        width: 32px;
        height: 32px;
        border: 0;
        box-shadow: none;
        font-size: 16px;
        color: $fff-brand-new-primary;
      }
    }

    .toggle-switch {
      @extend %toggleSwitch;
    }

    .content-box {
      .meal-plan-wrapper {
        h4 {
          font-size: 16px;
        }

        .numberContainer {
          padding: 0;
        }

        .form-row {
          gap: 1rem;

          .col {
            margin-bottom: 0 !important;
          }

          .col-md {
            padding: 0;
            &:not(:last-child) {
              margin-bottom: 0 !important;
            }
          }

          .card {
            border: 1px solid rgba($fff-brand-new-dark, 0.125);
            border-radius: 4px;

            &.item-selected {
              border-color: $fff-brand-new-secondary;
            }
          }
        }

        .accordian-button {
          display: flex;
          align-items: center;

          .extras-count {
            width: 20px;
            height: 20px;
            font-size: 12px;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $fff-brand-new-secondary;
            color: $fff-brand-new-light;
            border-radius: 100%;
          }

          span {
            color: $fff-brand-new-dark;
            font-size: 14px;
          }
        }

        .slider-wrapper {
          background-color: rgba($fff-brand-new-primary, 0.04);
          border-radius: 4px;
          padding: 0.5rem;
          margin-bottom: 1rem;
        }

        .conclusion {
          background-color: rgba($fff-brand-new-primary, .04);
        }

        .col-12, .col {
          padding: 0;
        }

        .card-container {
            display: flex;
            gap: 1rem;
            
            @include breakpoint(mobile) {
              flex-direction: column;
            }

          .item-card {
            flex: 1;
            padding: 1rem;
            text-align: center;
            background-color: rgba($fff-brand-new-primary, .04);
  
            &__label {
              font-weight: bold;
              color: $fff-brand-new-primary;
            }
  
            &__value {
              font-size: 28px;
              color: $fff-brand-new-secondary;
              font-weight: bold;
            }

            &__extras {
              display: flex;
              gap: 0.5rem;
              justify-content: center;
              margin-top: 0.25rem;

              > div {
                position: relative;
              }

              &--icon {
                width: 34px;
                height: 34px;
                margin-top: 0.5rem;
              }

              &--count {
                width: 20px;
                height: 20px;
                border-radius: 100%;
                background-color: $fff-brand-new-secondary;
                color: $fff-brand-new-light;
                font-weight: bold;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                position: absolute;
                top: 0;
                right: 0;
              }
            }
          }
        }
      }
      
      .exclusions-wrapper {
        display: flex;
        gap: 0.5rem;

        .exclusion {
          width: fit-content;
          padding: 0.375rem 1rem;
          background-color: rgba($fff-brand-new-primary, .04);
          color: $fff-brand-new-dark;
          font-weight: bold;
          border-radius: 3px;
        }
      }

      .macro-row {
        padding: 0 1rem;
        gap: 1rem;

        .macro-card-group {
          display: flex;
          gap: 1rem;
          padding: 0;

          @include breakpoint(mobile) {
            flex-direction: column;
          }
        }

        .macro-card-contianer {
          padding: 0;
          flex: 1;
        }
      }
    }

    .macro-breakdown {
      box-shadow: none;
      border-radius: 0;
      padding: 0;

      .macro-row {
        padding: 0;
      }
      
      .macro-card {
        text-align: center;
        background-color: rgba($fff-brand-new-primary, 0.04);
        border-radius: 3px;
      }

      .macro-lable {
        font-weight: bold;
        color: $fff-brand-new-primary;
        margin-bottom: 0.25rem;
        padding-top: 0.625rem;
      }

      .macro-value {
        padding: 8px;
        color: $fff-brand-new-primary;
        padding-top: 0;
        border-radius: 0 0 3px 3px;
      }

      .macro-input-field-group {
        margin-bottom: 0.25rem;

        label {
          font-family: $fff-font-sans;
          color: $fff-brand-new-primary;
          text-align: center;
          font-size: 14px;
          margin-bottom: 0.25rem;

          &.validation-error-label {
            text-align: left;
            color: $fff-brand-red;
          }
        }

        input {
          border-radius: 4px !important;
          text-align: center;
          color: $fff-brand-new-primary;
          border-width: 1px !important;
        }

        .custom-addon {
          height: 28px;

          span {
            color: $fff-brand-new-primary;
          }
        }

        .macro-input-field-label {
          display: flex;
          justify-content: center;
          gap: 0.5rem;
        }
      }

      .macros-in-grams {
        display: block;
        color: $fff-brand-new-primary;
        font-weight: bold;
        text-align: center;
        font-size: 14px;
      }
      
      .info-text {
        font-size: 12px;
        font-weight: bold;
        margin: 1rem 0 0;
      }
    }

    .increase-height {
      height: auto;
    }

    .fixed-progress-bar-container {
      margin-top: 2rem;
      position: relative;

      .progress-bar-content {
        padding: 5px 0 0 !important;
        background-color: white;

        .progress-bar-label {
          padding: 0.75rem 1rem 1rem;
          background-color: $fff-brand-new-primary;
          color: $fff-brand-new-light;
          border-radius: 3px;
          @include breakpoint(mobile) {
            font-size: 12px;
          }
        }
        
        .progress {
          border-radius: 10px;
          margin: 0.5rem 0 0;
          .progress-bar {
            background-color: $fff-brand-new-secondary;

            &.yellow-progress-bar {
              background-color: $fff-brand-yellow;
            }

            &.grey-progress-bar {
              background-color: $fff-brand-new-fill;
            }
          }
        }
      }
    }
  }

  .order-management-view {
    margin-bottom: 2rem;

    .fff-dropdown-select {
      max-width: 325px;
      width: calc(25% - 5px);
      border: 1px solid rgba($fff-brand-new-dark, 0.125);
      border-radius: 3px;
      &:hover, &:focus {
        border: 1px solid $fff-brand-new-secondary;
      }

      .p-dropdown-trigger {
        border-radius: 3px;
      }

      @include media-breakpoint-for(mobile) {
        max-width: 100%;
        width: 100%;
      }
    }

    .my-order-content-wrapper {
      display: flex;
      flex-direction: row;
      margin: 0;

      > div {
        @include breakpoint(mobile) {
          padding: 0 !important;
          &:not(:last-child) {
            margin-bottom: 2rem;
          }
        }
      }

      .my-order-content-box {
        width: 100%;
        height: 100%;
        padding:0.625rem 1rem 1rem;
        box-shadow: 0px 1px 6px rgba($fff-brand-grey, 0.08);
        display: flex;
        flex-direction: column;
        border-radius: 3px;
        &:not(:last-child) {
          margin-bottom: 2rem;
        }

        .order-detail-item {
          &:last-child {
            flex: 1;
            overflow: hidden;
            border-radius: 3px;

            .order-detail-item__content {
              height: 100%;
              border-radius: 3px;
            }
          }
        }

        h3 {
          font-size: 18px;
          @include breakpoint(mobile) {
            font-size: 16px;
          }
        }

        p {
          color: $fff-brand-new-dark;
          margin-bottom: 1rem;
        }

        .calender-for-user-profile {
          border: 0;
          padding: 0;
          .top-row {
            display: none;
          }
          .p-datepicker-group-container {
            width: 100%;
          }
        }
      }
    }

    .order-detail-item {
      background-color: rgba($fff-brand-new-primary, .04);
      &:not(:last-child) {
        border-bottom: 3px solid $fff-brand-new-light;
      }
      &__title {
        text-align: center;
        padding:1rem 0.625rem 0.25rem;
        color: $fff-brand-new-primary;
        &.highlight {
          padding: 0.625rem;
          background-color: $fff-brand-new-primary;
          color: $fff-brand-new-fill;
          border-radius: 3px 3px 0 0;
          margin: 0 0  1rem;

        }
        h3 {
          font-size: 18px;
          margin-bottom: 0;
        }
      }
      
      &__content {
        padding: 0 1rem 1rem;
        table {
          width: 100%;
          tr {
            td {
              width: 50%;
              padding: 6px 0;
              vertical-align: baseline;
              &:first-child {
                font-weight: 700;
                text-transform: lowercase;
                &::first-letter {
                  text-transform: capitalize;
                }
                &::after {
                  content: ':';
                }
              }
            }
          }
        }
      }
    }
  }

  .daily-menu-view {
    margin-bottom: 2rem;
    .daily-menu-entry-row {
      margin-bottom: 1rem;
      align-items: center;
      .btn-col {
        display: flex;
        justify-content: right;
      }
      .p-calendar {
        max-width: 325px;
        .p-inputtext {
          border: 1px solid rgba($fff-brand-new-dark, 0.125);
          border-radius: 3px !important;
          color: $fff-brand-new-accent;
          font-weight: bold;
          &:hover, 
          &:focus {
            border: 1px solid $fff-brand-new-secondary !important;
          }
        }
        .p-button {
          span {
            color: $fff-brand-new-primary;
            font-size: 22px;
          }
        }
      }
    }

    .daily-menu-container {
      .tab-menu {
        display: flex;
        border-bottom: 1px solid $fff-brand-new-primary;
        button {
          max-width: 300px;
          outline: none;
          box-shadow: none !important;
          border: 0;
          flex: 1;
          border-radius: 0 25px 0 0;
          box-shadow: none;
          background-color: $fff-brand-new-fill;
          color: $fff-brand-new-primary;
          font-size: 18px;
          font-weight: 700;
          position: relative;
          transition: 0.3s all;
          border-top: 2px solid $fff-brand-new-light;
          border-right: 2px solid $fff-brand-new-light;
          &:not(:first-child) {
            &::before {
              content: '';
              width: 25px;
              height: 100%;
              position: absolute;
              z-index: -1;
              inset: 0;
              left: -24px;
              transition: 0.3s all;
              background-color: $fff-brand-new-fill;
            }
          }

          &:last-of-type {
            border-right: 0;
          }

          &.active {
            color: $fff-brand-new-fill;
            background-color: $fff-brand-new-primary;
            &::before {
              background-color: $fff-brand-new-primary;
            }
          }

          &:focus {
            border-color: $fff-brand-new-light;
          }

          @include breakpoint(mobile) {
            font-size: 13px;
          }
        }
      }

      %macro-lable {
        color: $fff-brand-new-primary;
        font-weight: 700;
        margin-bottom: 0.25rem;
        text-align: center;
      }

      %macro-value {
        padding: 0.625rem;
        background-color: $fff-brand-new-fill;
        color: $fff-brand-new-primary;
        text-align: center;
        font-size: 18px;
      }

      .content-box {
        margin-top: 1rem;
        .macro-row {
          padding: 0 1rem;
          gap: 1rem;

          .macro-card-group {
            display: flex;
            gap: 1rem;
            padding: 0;

            @include breakpoint(mobile) {
              flex-direction: column;
            }
          }

          .macro-card-contianer {
            padding: 0;
            flex: 1;
          }
        }
        .macro-card {
          background-color: $fff-brand-new-fill;
          .macro-lable {
            @extend %macro-lable;
            padding-top: 0.625rem;
          }
          .macro-value {
            @extend %macro-value;
            padding: 0 0.625rem 0.625rem;

            @include breakpoint(mobile) {
              font-size: 14px;
            }
          }
        }
      }

      .daily-menu-items {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
        margin-bottom: 2rem;

        @include media-breakpoint-for(mobile) {
          grid-template-columns: repeat(1, 1fr);
          gap: 2rem;
        }
      }

      .daily-menu-item-wrapper {
        padding: 0;
      }

      .daily-menu-item {
        box-shadow: 0px 1px 6px rgba($fff-brand-grey, 0.08);
        height: 100%;
        border-radius: 3px;
        .item-lable {
          font-size: 18px;
          color: $fff-brand-new-fill;
          background-color: $fff-brand-new-primary;
          font-weight: 700;
          padding: 0.625rem;
          text-align: center;
          border-radius: 3px 3px 0 0;

          @include breakpoint(mobile) {
            font-size: 16px;
          }
        }
        .daily-menu-item-content {
          padding: 1rem;
          border-radius: 0 0 3px 3px;
          .item-description {
            margin-bottom: 1rem;
          }
          .dv-star-rating {
            margin-bottom: 0;
          }
          .macros-accordian {
            display: flex;
            align-items: center;
            font-size: 12px;
            justify-content: end;
            position: relative;
            height: 10px;
            margin-bottom: 1rem;
            &::before {
              content: '';
              width: 100%;
              height: 1px;
              display: block;
              position: absolute;
              background-color: rgba($fff-brand-new-dark, 0.12);
            }
            > div {
              display: flex;
              align-items: center;
              padding-left: 0.5rem;
              color: $fff-brand-new-primary;
              background-color: $fff-brand-new-light;
              position: relative;
              z-index: 1;
              cursor: pointer;
            }
          }

          .macro-row {
            margin: 0;
            margin-top: 0.75rem;
            column-gap: 0.75rem;
            .macro-card {
              padding: 0;
              background-color: $fff-brand-new-fill;
              .macro-lable {
                @extend %macro-lable;
                font-size: 12px;
                padding-top: 0.625rem;
              
              }
              .macro-value {
                @extend %macro-value;
                font-size: 14px;
                padding-top: 0;
              }
            }
          }
        }
      }
    }
  }

  .referral-view {
    .referral-content {
      display: flex;
      flex-wrap: nowrap;
      .referral-promo-details-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1 1 auto;
        max-width: calc(100% - 430px);
        .referral-message {
          padding-bottom: 2rem;
          p {
            color: $fff-brand-new-dark;
          }
        }

        .referal-message-section{
          margin-bottom: 16px;

          .referal-title{
            font-size: 16px;
            font-weight: 700;
            margin-bottom: 0.25rem;
          }

          .refer-sub-title{
            color: $fff-brand-black-text;
          }

          .referal-cards-container{
            display: grid;
            gap: 8px;

            @media (min-width: 1601px) {
              grid-template-columns:  1fr 1fr;
              column-gap: 64px;
            }

            .referal-card{
              display: flex;

              .referal-number{
                font-weight: 700;
                font-size: 15px;
                color: $fff-brand-new-primary;
              }
              .referal-img{
                height: 70px;
              }

              .referal-text{
                color: $fff-brand-black-text;
                font-size: 13px;
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                justify-content: center;
                padding-left: 12px;

                @media (min-width: 1601px) {
                  justify-content: flex-start;
                  padding-top: 8px;
                }

              }

              .referal-text-info{
                padding-top: 0;

                h4{
                  margin-bottom: 4px;
                  font-size: 13.5px;
                }

                p{
                  margin: 0;
                  color: $fff-brand-black-text;
                  font-size: 13.5px;
                }
              }
            }
          }
        }
        .referrals-container {
          
        }
      }
      .referral-promo-image-container {
        max-width: fit-content;
        flex: 0 0 auto;
        .referral-promo-image {
          width: 100%;
          max-width: 400px;
          height: auto;
          img {
            margin: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-radius: 6px;
          }
        }
  
      }

      @media (max-width: 767px) {
        flex-direction: column-reverse; 
        gap: 1.5rem;

        > .col {
          flex: 0 0 100%;
          max-width: 100%;

          .referral-promo-image {
            max-width: 100%;
            height: 260px;

            img {
              object-fit: cover;
            }
          }

          .referral-message {
            padding-bottom: 0;
          }
        }
      }
    }
    .share-discount-code {
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
      &.secondary {
        .code {
          color: $fff-brand-new-secondary;
          border: 1px solid $fff-brand-new-secondary;
        }
      }
      .lable {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 0.25rem;
      }
      .code {
        border: 1px solid $fff-brand-new-primary;
        border-radius: 3px;
        padding: 8px 15px;
        font-size: 26px;
        text-transform: uppercase;
        font-weight: bold;
        color: $fff-brand-new-primary;
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        i {
          cursor: pointer;
        }
      }
    }
    .content-box {
      padding: 1.25rem 0.625rem 0.625rem;
      margin-top: 1.5rem;
      .content-box-inner {
        .entry-row {
          margin: 0 0 1.25rem;
          justify-content: space-between;
          h2 {
            font-weight: bold;
            font-size: 20px;
            margin: 0;
          }
        }
        .token-calculation {
          padding: 2.5rem 1rem;
          background-color: rgba($fff-brand-new-primary, 0.04);
          border-radius: 3px;

          .row {
            max-width: 750px;
            margin: 0 auto;
          }

          &__operator {
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            max-width: fit-content;
            font-size: 36px;
            color: $fff-brand-new-secondary;
          }

          &__item {
            background-color: $fff-brand-new-fill;
            color: $fff-brand-new-secondary;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 1.5rem;
            margin: auto;
            height: 100%;

            &.balance {
              background: $fff-brand-new-fill;
              color: $fff-brand-new-primary;
            }

            .lable {
              font-size: 14px;
              font-weight: bold;
              margin-bottom: 5px;
            }

            .value {
              font-size: 36px;
              line-height: 1;
              font-weight: bold;
            }
          }

          @media (max-width: 767px) {
            .col {
              padding: 0;
            }

            &__operator {
              font-size: 14px;
            }

            &__item {
              width: 85px;
              height: 85px;
              .lable {
                font-size: 12px;
              }
              .value {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }

  //Calendar Styles

  .delivery-calendar {
    @include breakpoint(mobile) {
      padding: 0.5rem;
    }
  }

  .calender-for-user-profile {
    padding: 1rem 1rem 0;
    border:1px solid rgba(66, 69, 70, 0.18);
    border-radius: 3px;
    @include breakpoint(mobile) {
      padding: 0.5rem;
    }

    .p-datepicker-group-container {
      width: 100%;
    }

    .p-datepicker-calendar-container {
      table {
        .popover {
          background-color: $fff-brand-new-light;
          border: 1px solid rgba($fff-brand-new-dark, 0.12);
          padding: 0.75rem 1rem 1rem;
          width: 230px;
          .entry-row {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid rgba($fff-brand-new-dark, 0.12);
            h1 {
              padding: 0 0 0.75rem;
              color: $fff-brand-new-accent;
              border-bottom: 0;
            }
            .btn {
              box-shadow: none;
              border: 0;
              color: $fff-brand-new-primary;
              width: 28px;
              height: 28px;
            }
          }
          ul {
            margin-top: 0.75rem;
            li {
              &:last-child {
                border-bottom: 0;
              }
              > div {
                display: flex;
                font-family: $fff-font-sans;
                color: $fff-brand-new-dark;
              }
              span {
                display: block;
                min-width: 130px;
                font-weight: 700;
              }
            }
          }
        }
      }
      .p-highlight:focus, .p-highlight:active {
        box-shadow: none;
      }
    }

    .p-datepicker td:focus {
      outline: none !important;
  }

    .p-datepicker {
      padding: 0;

      td > span {
        overflow: visible !important;

        &:focus, &:active {
          box-shadow: none !important;
        }
      }
    }

    .p-datepicker-title {
      font-family: $fff-font-sans;
      font-size: 18px !important;
      font-weight: bold !important;
      color: $fff-brand-new-accent !important;
    }

    .p-datepicker-prev {
      left: 0;

      &:focus, &:active {
        box-shadow: none;
      }
    }

    .p-datepicker-next {
      right: 0;

      &:focus, &:active {
        box-shadow: none;
      }
    }

    .p-datepicker-next-icon, 
    .p-datepicker-prev-icon {
      font-size: 20px;
      color: $fff-brand-new-dark;
    }

    .p-datepicker-calendar {
      thead tr th span {
        font-weight: 700;
        font-family: $fff-font-sans;
        color: $fff-brand-new-dark;
      }
      .p-highlight {
        background-color: rgba($fff-brand-new-fill, 1);

        &:focus, &:active {
          box-shadow: none !important;
        }

        .delivery-day {
          .delivery {
            border: 2px solid $fff-brand-new-fill;
          }

          &.delivery-paid-overlap, &.pending-delivery-paid-overlap {
            &::after { 
              border: 2px solid $fff-brand-new-fill;
            }
          }
        }
      }
    }

    .delivery-day {
      .delivery {
        border: 2px solid $fff-brand-new-light;

        &-paid {
          background-color: $fff-brand-new-primary;
          &.delivery-pending {
            background-image: repeating-linear-gradient(
              45deg,
              rgba($fff-brand-green, 0),
              rgba($fff-brand-green, 0) 5px,
              rgba($fff-brand-new-light, 0.4) 5px,
              rgba($fff-brand-new-light, 0.4) 10px
            );
          }
        }

        &-unpaid {
          &.delivery-pending {
            color: $fff-brand-new-light;
            background-image: repeating-linear-gradient(
              45deg,
              rgba($fff-brand-grey, 0),
              rgba($fff-brand-grey, 0) 5px,
              rgba($fff-brand-black-text, 0.8) 5px,
              rgba($fff-brand-black-text, 0.8) 10px
            );
          }
        }
      }

      &.pending-delivery-paid-overlap {
        &::after {
          color: $fff-brand-new-light;
          background-image: repeating-linear-gradient(
            45deg,
            rgba($fff-brand-green, 0),
            rgba($fff-brand-green, 0) 5px,
            rgba($fff-brand-new-light, 0.4) 5px,
            rgba($fff-brand-new-light, 0.4) 10px
          );
        }
      }

      &.delivery-paid-overlap {
        &::after { 
          background-color: $fff-brand-new-primary;
        }
      }

      .delivery-paid {
        background-color: $fff-brand-new-primary;
      }

      .delivery-unpaid {
        background-color: $fff-brand-new-secondary;
        color: $fff-brand-new-light;
        
        &.delivery-pending {
          background-color: $fff-brand-new-secondary;
        }
      }
    }

    .pending-delivery-paid-overlap{
      &::after {
        background-color: $fff-brand-new-primary;
      }
    }

    .delivery-legend {
      ul {
        padding: 0;
        justify-content: unset;
        li {
          font-weight: 600;
          font-family: $fff-font-sans;
          color: $fff-brand-new-dark;
          &:not(:last-child) {
            margin-right: 1rem;
          }
          span {
            &.paid {
              background-color: $fff-brand-new-primary;
            }
            &.unpaid {
              background-color: $fff-brand-new-secondary;
            }
            &.paused {
              background: rgba($fff-brand-new-secondary, 0.2) !important;
              border: none;
            }
          }
        }
      }
    }
  }
}

.user-profile-page-footer {
  .footer-bg-row {
    .logo-row {
    }
  }
  .footer-grey-row {
    display: none;
  }
}


// Modal Styles

%close {
  padding: 0;
  border: 1px solid $fff-brand-new-primary;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  position: relative;
  font-size: 26px;
  color: $fff-brand-new-primary;
}

.delivery-schedule-info-modal {
  background-color: $fff-brand-new-light;
  border-radius: 4px;

  .modal-content {
    padding: 1rem;
    background-color: $fff-brand-new-fill;

    .modal-header {
      box-shadow: none;
      border-bottom: 1px solid rgba($fff-brand-new-primary, .12);
      display: flex;
      align-items: center;
      padding: 0 0 1rem;
      margin: 0;

      .modal-title {
        text-align: left;
        color: $fff-brand-new-accent;
      }

      .close {
        @extend %close;
        border: none;
      }
    }

    .modal-body {
      padding: 1rem 0;
      margin-bottom: 0;

      .info-container {
        display: flex;
        flex-direction: row;
        margin-bottom: 1rem;
      }

      .info-row {
        display: flex;
        margin-bottom: 0;
        flex: 1 1 50%;

        p {
          margin-bottom: 0.25rem;
        }

        .lable {
          color: $fff-brand-new-dark;
          min-width: fit-content;
          font-weight: bold;
          margin-right: 2px;
        }
      }
    }
  }
}

.daily-menu-feedback-modal {
  .modal-content {
    .modal-body {
      padding-top: 1rem;
      margin-bottom: 0;

      .row {
        margin: 0;
      }

      .entry-row {
        justify-content: space-between;
        border-bottom: 1px solid rgba($fff-brand-new-dark, 0.12);
        margin-bottom: 1.5rem;
        align-items: center;
        padding-bottom: 1rem;

        .dv-star-rating {
          margin: 0;

          .dv-star-rating-star {
            width: 27px;
            height: 27px;
          }

          .dv-star-rating-full-star,
          .dv-star-rating-empty-star{
            &::before {
              width: 27px;
              height: 27px;
            }
          }
        }

        .close {
          @extend %close;
          border: 0;
          box-shadow: none;
        }
      }
      .content-row {
        margin-bottom: 1.5rem;

        textarea {
          width: 100%;
          min-height: 140px;
          outline: 0;
          border: 1px solid rgba($fff-brand-new-dark, 0.12);
          border-radius: 3px;
          padding: 1rem 0.75rem;
          box-shadow: inset 1px 1px 3px rgba($fff-brand-new-dark, 0.12);
        }
      }

      .btn-row {
        justify-content: center;

        .btn {
          width: 100%;
        }
      }
    }
  }
}

.no-data-found {
  font-weight: bold;
  color: $fff-brand-grey;
}

.delivery-actions-dropdown {
  margin-top: 4px;
  width: max-content;
  min-width: 120px;

  &.p-menu-overlay {
    box-shadow: 0 1px 6px rgba($fff-brand-grey, 0.12) !important;
    border-color: rgba($fff-brand-grey, 0.12) !important;
  }

  .p-menuitem {
    &:not(:last-child) {
      border-bottom: 1px solid rgba($fff-brand-grey, .12);
    }

    .p-menuitem-icon {
      height: 100%;
      font-size: 14px;
      position: absolute;
      right: 4px;
      top: 0;
      display: flex;
      align-items: center;
      color: $fff-brand-new-primary !important;
      display: none;
    }

    .p-menuitem-link {
      font-family: $fff-font-sans;

      &:hover {
        background-color: $fff-brand-new-fill !important;
      }

      &:focus {
        box-shadow: none;
      }
    }

    &.p-menuitem-active {
      .p-menuitem-link {
        background-color: $fff-brand-new-fill !important;
      }
    }
  }
}

.tab-menu-select {
  display: flex;
  border-bottom: 1px solid $fff-brand-foodbox-primary;
  margin-top: 3rem;

  button {
    max-width: 300px;
    outline: none;
    box-shadow: none !important;
    border: 0;
    flex: 1;
    border-radius: 0 25px 0 0;
    box-shadow: none;
    background-color: $fff-brand-new-fill !important;
    color: $fff-brand-foodbox-primary !important;
    font-size: 18px;
    font-weight: 700;
    position: relative;
    transition: 0.3s all;
    border-top: 2px solid $fff-brand-new-light;
    border-color: $fff-brand-new-light !important;
    border-right: 2px solid $fff-brand-new-light;
    min-height: 40px;
    height: auto;
    text-wrap: balance;
    &:not(:first-child) {
      &::before {
        content: '';
        width: 25px;
        height: 100%;
        position: absolute;
        z-index: -1;
        inset: 0;
        left: -24px;
        transition: 0.3s all;
        background-color: $fff-brand-new-fill;
      }
    }

    &:last-of-type {
      border-right: 0;
    }

    &.active {
      background-color: $fff-brand-foodbox-primary !important;
      color: $fff-brand-new-light !important;
      &::before {
        background-color: $fff-brand-foodbox-primary ;
      }
    }

    &:focus {
      border-color: $fff-brand-new-light;
    }

    @include breakpoint(mobile) {
      font-size: 13px;
      line-height: unset;
      padding-block: 5px;
      height: auto;
    }
  }
}

.info-widget-wrap{
  justify-content: space-between;

  @include breakpoint(mobile){
    flex-direction: column;
    gap: 8px;
  }

  .text-wrap{
    color: $fff-brand-black-text !important;
  }

  .checkout-button{
    background-color: $fff-brand-foodbox-primary;
    border-color: $fff-brand-foodbox-primary;

    &:hover{
      background-color: #72233f !important;
      border-color: #72233f !important;
    }

    &:active {
      background-color: #72233f !important;
      border-color: #72233f !important;
    }
  }
}