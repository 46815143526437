@import './responsive';

.f45-complete {
  color: rgba($fff-brand-grey, 0.8);
  font-family: $fff-font-sans;
  text-align: center;
  font-size: x-large;
  padding: 20px;

  .back-btn {
    text-align: center;
    font-family: $fff-font-sans;
  }
}

.header-meal-plan {
  background-color: $fff-brand-new-light;
  height: auto;
  padding: 30px 0px;
  border-bottom: 4px solid $fff-brand-dark-purple;

  .header-inside {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @include media-breakpoint-for(mobile) {
      display: block;
    }

    @include media-breakpoint-for(tab) {
      display: block;
    }

    @include media-breakpoint-for(tab-landscape) {
      display: block;
    }

    .header-logo {
      height: 105px;
      display: block;
      margin: 0 auto;
      width: 289px;
      @media screen and (min-width: 1024px) {
        display: inline-block;
        width: 289px;
        margin: 0px;       
      }

      img {
        height: 100%;
        background-repeat: no-repeat;
      }
    }

    .heading-timer {
      display: inline-block;
      text-align: center;
      margin: 0 auto;
      width: 100%;

      .timer-text-col {
        display: inline-block;
        text-align: left;
        width: 280px;

        @include media-breakpoint-for(mobile) {
          text-align: center;
          margin-top: 15px;
          width: 100%;
        }

        @include media-breakpoint-for(tab) {
          text-align: center;
          margin-top: 15px;
          width: 100%;
        }

        @include media-breakpoint-for(tab-landscape) {
          text-align: center;
          margin-top: 15px;
          width: 100%;
        }

        h1 {
          font-family: $fff-font-sans;
          float: left;
          color: $fff-brand-new-dark;
          font-size: 20px;
          padding-top: 20px;
          padding-right: 10px;
          font-weight: bold;
          margin: 0px;
          padding: 0px;
          display: block;
          width: 100%;

          @include media-breakpoint-for(mobile) {
            text-align: center;
          }

          @include media-breakpoint-for(tab) {
            text-align: center;
          }

          @include media-breakpoint-for(tab-landscape) {
            text-align: center;
          }
        }
      }

      .countdown-col {
        display: inline-block;
        text-align: left;
        color: red;

        @include media-breakpoint-for(mobile) {
          text-align: center;
          margin-top: 15px;
        }

        @include media-breakpoint-for(tab) {
          text-align: center;
          margin-top: 15px;
        }

        @include media-breakpoint-for(tab-landscape) {
          text-align: center;
          margin-top: 15px;
        }

        .countdown-box {
          width: 35%;
          display: table;
          margin: 0px;
          padding: 0px;
          width: 50%;
          margin: auto;

          .countdown-box-col {
            display: table-cell;
            padding: 0px;
            margin: 0px;
            border-radius: 8px;

            .gradient-box {
              background: rgb(90, 2, 2);
              background: linear-gradient(0deg, rgba(90, 2, 2, 1) 44%, rgba(227, 27, 35, 1) 100%);
              padding: 5px 0px;
              margin: 0 6px 0 5px;
              width: 56px;
              border-radius: 8px;

              p {
                text-align: center;
                color: #fff;
                font-weight: 300;
                line-height: normal;
                margin: 0px;
                padding: 0px;

                &.lg {
                  font-family: $fff-font-sans;
                  font-weight: 600;
                  font-size: 36px;
                }

                &.sm {
                  font-family: $fff-font-sans;
                  font-size: 10px;
                  text-transform: uppercase;
                }
              }
            }
          }
        }
      }
    }

    .header-right-col {
      text-align: right;

      .header-right-col-inner {
        max-width: 185px;
        margin: auto;
        margin-right: 0;

        @include media-breakpoint-for(mobile) {
          margin-right: auto;
        }
      }

      @include media-breakpoint-for(mobile) {
        text-align: center;
        margin-top: 15px;
        width: 100%;
      }

      @include media-breakpoint-for(tab) {
        text-align: center;
        margin-top: 15px;
        width: 100%;
      }

      @include media-breakpoint-for(tab-landscape) {
        text-align: center;
        margin-top: 15px;
        width: 100%;
      }

      p {
        font-family: $fff-font-sans;
        float: right;
        color: $fff-brand-new-dark;
        padding-top: 20px;
        padding-right: 10px;
        font-weight: 400;
        margin: 0px 0px 15px;
        padding: 0px;
        display: block;
        width: 100%;
        text-align: center;

        @include media-breakpoint-for(mobile) {
          margin: 0px 0px 5px;
        }

        @include media-breakpoint-for(tab) {
          margin: 0px 0px 5px;
        }

        @include media-breakpoint-for(tab-landscape) {
          margin: 0px 0px 5px;
        }
      }

      .supplier-logo {
        width: auto;
        height: auto;
        background-repeat: no-repeat;
        height: auto;
        margin-top: 0px;
        max-width: 185px;
        max-height: 84px;

        @include media-breakpoint-for(mobile) {
          max-width: unset;
          max-height: 50px;
          margin-top: 10px;
        }

        @include media-breakpoint-for(tab) {
          max-width: unset;
          max-height: 50px;
          margin-top: 10px;
        }

        @include media-breakpoint-for(tab-landscape) {
          max-width: unset;
          max-height: 50px;
          margin-top: 10px;
        }
      }
    }
  }
}

.meal-plan-banner-row {
  background-color: $fff-brand-new-light;
  margin: 0 0 5px 0px;
  border-bottom: 4px solid #e41b23;
  background-size: cover;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center right;
  padding: 99px 0;

  @include media-breakpoint-for(mobile) {
    background-position: center left;
    padding: 30px 0;
    background-image: none !important;
  }

  @include media-breakpoint-for(tab) {
    background-position: center left;
    padding: 30px 0;
    background-image: none !important;
  }

  @include media-breakpoint-for(tab-landscape) {
    background-position: center left;
    padding: 30px 0;
    background-image: none !important;
  }

  .banner-text {
    max-width: 310px;
    height: auto;
    background-repeat: no-repeat;
    margin-bottom: 20px;

    @include media-breakpoint-for(mobile) {
      max-width: 100%;
    }

    @include media-breakpoint-for(tab) {
      max-width: 402px;
    }

    @include media-breakpoint-for(tab-landscape) {
      max-width: 402px;
    }
  }

  .banner-heading-text {
    h1 {
      font-size: 48px;
      line-height: 1;
      margin-bottom: 2.5rem;
      font-family: $fff-font-sans;
      font-weight: 700;
    }
  }

  .banner-sub-text {
    &:after {
      content: "";
      width: 100px;
      border-bottom: 1px solid $fff-brand-dark-purple;
      position: absolute;
      left: 16px;
      bottom: -30px;
      height: 1px;
    }

    &.no-line {
      &::after {
        display: none;
      }
    }

    &.top-margin {
      margin-top: 60px;
    }

    p {
      color: $fff-brand-new-dark;
      font-family: $fff-font-sans;
      font-weight: 400;
      font-size: 19px;
      line-height: 1.8rem;
      margin: 0;

      &.color-red {
        font-family: $fff-font-sans;
        color: #e7272e;
        font-weight: 700;
        margin-bottom: 5px;
      }
    }
  }
}

.red-bar {
  padding: 30px 0px;
  background-color: #e41b23;

  h3 {
    font-family: $fff-font-sans;
    font-weight: 700;
    font-size: 28px;
    color: white;
    text-align: center;
  }
}

.meal-card-row {
  padding: 30px 0px;

  .meal-card-col {
    position: relative;

    h2 {
      font-size: 18px;
      color: #3d417d;
      font-weight: 500;
      padding: 0px 15px;
      font-family: $fff-font-sans;
    }

    .meal-card-tag {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background-color: #171a51;
      color: white;
      text-align: center;
      vertical-align: middle;
      position: absolute;
      right: 15px;
      top: 15px;
      padding: 3px;
      border: 1px solid white;

      .lg {
        font-size: 20px;
        font-weight: 600;
        display: block;
        line-height: normal;
        font-family: $fff-font-sans;
        padding-top: 4px;
        text-align: center;
      }

      .sm {
        font-size: 7px;
        text-transform: uppercase;
        display: block;
        line-height: normal;
        font-family: $fff-font-sans;
        text-align: center;
      }
    }

    .meal-card-img {
      margin-bottom: 0px;
      border-top-left-radius: 9px;
      border-top-right-radius: 9px;
      width: 100%;
      height: 1;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      position: relative;
      padding-top: 93%;
    }

    .meal-card-details {
      text-align: left;
      border-left: 1px solid #ccc;
      border-right: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      padding: 10px 15px 10px 15px;
      min-height: 68px;
      margin-bottom: 19px;
      display: block;

      h3 {
        font-family: $fff-font-sans;
        font-weight: 600;
        font-size: 12px;
        color: #2c7acd;
        margin-bottom: 0px;
      }

      p {
        font-family: $fff-font-sans;
        font-size: 13px;
        color: #2c7acd;
        margin-bottom: 0px;
        line-height: normal;

        &.black {
          color: black;
          font-weight: 700;
          text-align: right;
          font-size: 12px;
        }
      }
    }
  }
}

.meal-plan-form {
  margin-top: 45px;

  .genderButton {
    border-radius: 0;
    font-family: $fff-font-sans;
    color: #090148 !important;
    &.btn-primary {
      background-color: #090148 !important;
      color: white !important;
      border-color: #090148 !important;
    }
  }

  .card {
    border-radius: 0;
    font-family: $fff-font-sans;
    &.selected {
      border-color: #090148 !important;
    }
  }

  h1 {
    font-family: $fff-font-sans;
    font-size: 17.96px;
    color: #0f0f0f;
    font-weight: 600;

    &.tooltip {
      color: #090148 !important;
      .default-tooltip {
        color: #090148 !important;
      }
    }
  }

  .sub-heading {
    font-family: $fff-font-sans;
    font-size: 15px;
    color: #0f0f0f;
    font-weight: 600;
  }

  label {
    font-family: $fff-font-sans;
  }

  .lift {
    margin-top: -218px;

    @include media-breakpoint-for(mobile) {
      margin-top: 30px;

      &:last-of-type {
        margin-bottom: 30px;
      }
    }

    @include media-breakpoint-for(tab) {
      margin-top: 30px;

      &:last-of-type {
        margin-bottom: 30px;
      }
    }

    @include media-breakpoint-for(tab-landscape) {
      margin-top: 30px;

      &:last-of-type {
        margin-bottom: 30px;
      }
    }
  }

  .margin-bottom-md {
    margin-bottom: 30px;
  }

  .margin-bottom-lg {
    margin-bottom: 45px;
  }

  .form-group {
    margin-bottom: 8px;
  }

  .form-control {
    border: 2px solid #d7d7d7 !important;
    background: #fff !important;
    border-radius: 0px !important;

    &.disabled {
      background: #d7d7d7 !important;
      border: none !important;
      color: #8a8a8a;
      pointer-events: none;
    }

    &.margin-bottom {
      margin-bottom: 8px;
    }
  }

  .btn-red {
    height: 31px;
    border-radius: 0;
    padding: 0px 16px;
    font-size: 14.29px;
    color: #fff;
    font-family: $fff-font-sans;
    font-weight: 500;
    background: #f00 !important;
    border-color: transparent !important;
    box-shadow: none;
    color: #ffffff !important;
    text-transform: uppercase;
    line-height: unset;

    &:hover {
      box-shadow: none;
    }

    &:disabled {
      opacity: .45;

      &:hover {
        opacity: .45;
      }
    }
  }

  .selectBox {
    .fff_ {
      &_control {
        padding: 0px 0px 0px 12px;
        border: 2px solid #d7d7d7 !important;
        min-height: 40px;
        border-radius: 0px !important;

        &:hover {
          border: 2px solid #d7d7d7 !important;
        }

        &--is-focused {
          box-shadow: none;
          border: 2px solid #d7d7d7 !important;
        }
      }
    }
  }

  .grey-background {
    background-color: #f9f9f9;
    height: 100%;
  }

  .color-red {
    color: #f00 !important;
  }

  .checkbox-default {
    height: auto;
    padding-left: 25px;

    p {
      font-family: $fff-font-sans;
      font-weight: normal;
      vertical-align: middle;
      margin-top: 0px;
      padding-left: 0px;
      color: black;
    }

    input {
      position: absolute;
      opacity: 0;
      left: 0;
      top: 0;
      height: 20px;
      width: 20px;
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: #d7d7d7;
      border: 1px solid #8a8a8a;
      border-radius: 4px;
      margin-top: 2px;
      margin-right: 1px;
      margin-left: 0px;
    }

    &:hover input~.checkmark {
      background-color: #eee;
    }

    input:checked~.checkmark {
      background-color: #d7d7d7;
    }

    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }

    input:checked~.checkmark:after {
      display: block;
    }

    .checkmark:after {
      left: 6px;
      top: 2px;
      width: 6px;
      height: 12px;
      border: 1px solid #8a8a8a;
      border-width: 0px 2px 2px 0px;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  .summary-img {
    height: auto;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    background-position: center;
  }

  .summary-detail {
    padding: 25px 25px 60px;
    font-size: 14px;
    font-family: $fff-font-sans;
    color: #000000;
    font-weight: 600;

    .top-details {
      margin: 0px 0px 30px;

      >div {
        padding: 0px;
      }
    }

    .summary-list {
      margin-bottom: 30px;

      .list-row {
        margin: 5px 0px 0px;
        border-bottom: 1px solid #d8d7d7;
        padding: 5px 0px 5px;
        font-family: $fff-font-sans;
        font-weight: normal;

        >div {
          padding: 0px;
          font-family: $fff-font-sans;
          font-weight: normal;
          align-items: center;

          &.text-right {
            place-content: flex-end !important;
          }

          .bottom-note {
            margin-top: 4px;
            padding: 0;
          }

          p {
            margin: 0px;
          }

          .form-control {
            display: inline-block;
            width: 110px;
          }

          button {
            display: inline-block;
            margin-left: 4px;
          }
        }
      }

      .list-row-notify {
        text-align: center;
        margin: 5px;
        padding: 5px 0px 5px;
        font-family: $fff-font-sans;
        font-weight: normal;

        label {
          padding: 0px;
          font-family: $fff-font-sans;
          text-transform: unset;
          font-weight: normal;
          color: #000000;

          span {
            font-weight: 800;
          }
        }
      }
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  .StripeElement {
    border-color: #d7d7d7;
    border-radius: 0;
  }

  .genderButton {
    border-color: #d7d7d7;
    &:hover {
      border-color: #d7d7d7;
      background: none;
    }
  }

  .fff-phone-number-input {
    border-radius: 0;
    border-color: #d7d7d7;

    .form-control {
      border: 0 !important;
    }
  }

}

.faq {
  background-color: #dfdce3;
  padding: 30px 0px;

  .section-main {
    h1 {
      font-size: 42px;
      font-family: $fff-font-sans;
      font-weight: 700;
      text-align: center;

      @include media-breakpoint-for(mobile) {
        font-size: 28px;
      }

      @include media-breakpoint-for(tab) {
        font-size: 28px;
      }

      @include media-breakpoint-for(tab-landscape) {
        font-size: 28px;
      }
    }

    h3 {
      font-family: $fff-font-sans;
      color: black;
    }

    .accordion-main {
      .p-accordion-tab {

        .p-accordion-header {

          a {
            background-color: #f2f2f2 !important;

            &:hover {
              background-color: #090148 !important;

              h3 {
                color: white !important;
              }
            }

            .p-accordion-header-text {
              h3 {
                font-family: $fff-font-sans;
                font-weight: 600;
              }
            }

            .p-accordion-toggle-icon {}
          }
        }

        .p-accordion-content {
          background-color: #f2f2f2 !important;
          font-family: $fff-font-sans;

          p {
            font-family: $fff-font-sans;
          }
        }
      }
    }
  }
}

.diabled-overlay {
  opacity: .4;
  pointer-events: none;
}

.contact-btn-row {
  margin: 0px;
  text-align: center;
  padding: 30px 0px 60px;

  h1 {
    font-size: 28px;
    font-family: $fff-font-sans;
    font-weight: 700;
    text-align: center;
  }
}

.blue-btn {
  color: white;
  background-color: #090148;
  padding: 16px 41px 16px;
  height: 56px;
  font-size: 18px;
  line-height: 25px;
  border-radius: 0px;
  font-family: $fff-font-sans;
  text-align: center;
  margin: 0px 10px;
  display: inline-block;
  max-width: 160px;

  &.extend {
    max-width: 180px;
  }

  &:not(:disabled):not(.disabled):active,
  &:focus,
  &:hover {
    color: white;
  }

  @include media-breakpoint-for(mobile) {
    float: unset;
    vertical-align: middle;
    font-size: 14px;
    padding: 0px 26px;
    line-height: 17px;
    height: 40px;
    line-height: 40px;
    width: 100%;
    margin: 0px 2px;
  }

  @include media-breakpoint-for(tab) {
    float: unset;
    vertical-align: middle;
    font-size: 14px;
    padding: 0px 26px;
    line-height: 17px;
    height: 40px;
    line-height: 40px;
    width: 100%;
    margin: 0px 2px;
  }

  @include media-breakpoint-for(tab-landscape) {
    float: unset;
    vertical-align: middle;
    font-size: 14px;
    padding: 0px 26px;
    line-height: 17px;
    height: 40px;
    line-height: 40px;
    width: 100%;
    margin: 0px 2px;
  }
}

.f45-footer {
  .footer-row {
    .custom-container {
      display: none;
    }
  }
}

.btn-plus {
  width: 20px;
  height: 20px;
  margin: 0px 5px;
  padding: 0px;
  font-size: 20px;
  line-height: 19px;
  box-shadow: none;
  position: relative;
  display: inline-block;
  box-shadow: none !important;

  &::after {
    content: " ";
    position: absolute;
    background-image: url('../../assets/img/icons/fff-plus.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    top: 3px;
    bottom: 3px;
    left: 3px;
    right: 3px;
  }
}

.f45-order-confirm {
  padding: 80px 0px 30px;

  h1 {
    margin: 15px 0px 0px;
    text-align: center;
    width: 100%;
    font-size: 28px;
    font-family: $fff-font-sans;
    font-weight: 600;
  }

  p {
    margin: 15px 0px 0px;
    text-align: center;
    width: 100%;
    font-size: 14px;
    font-family: $fff-font-sans;
  }

  a {
    display: block;
    text-align: center;
    width: auto;
    margin: 0 auto;
  }

  button {
    margin: 0 auto;
  }

  img {
    width: 150px;
    height: auto;
    margin: 0px auto 50px;
  }

  .shadow-container {
    box-shadow: 0px 2px 8px rgba($fff-brand-grey, 0.1);
    margin: 60px auto;
    padding: 30px;
    background-color: white;
    max-width: 80%;

    img {
      width: 200px;
      height: auto;
      margin: 0px;
    }
  }
}

.f45-lone-label {
  font-weight: normal;
  vertical-align: middle;
  margin-top: 0px;
  padding-left: 0px;
  color: black;
  font-size: 14px;
  margin-bottom: 5px;
  text-transform: initial;
}

.btn-choice {
  border: 2px solid #d7d7d7 !important;
  color: #090148 !important;
  border-radius: 0;
  box-shadow: none !important;
  &-active {
    background-color: #090148 !important;
    color: white !important;
    border-color: #090148 !important;
  }
}

.meal-options-container {
  display: flex;
  justify-content: center;
  align-items: start;
  background-color: $fff_brand_yellow_green;

  @include media-breakpoint-for(mobile) {
    background-color: white;
    padding: 0px;
  }

  // For iPad Mini - portrait
  @media only screen 
    and (min-device-width: 768px) 
    and (max-device-width: 1024px)
    and (orientation: portrait)  {
      background-color: $fff_brand_yellow_green;
      height: 100vh;
      height: 100dvh;
  }

  // For iPad Air - portrait
  @media only screen 
    and (min-device-width: 820px) 
    and (max-device-width: 1180px)
    and (orientation: portrait) {
      background-color: $fff_brand_yellow_green;
      height: 100vh;
      height: 100dvh;
  }

  .meal-plan-image {
    position: absolute;

    @include media-breakpoint-for(mobile) {
      display: none;
    }

    // For iPad Pro - portrait
    @media only screen 
      and (min-device-width: 1024px) 
      and (max-device-width: 1336px)
      and (orientation: portrait) {
        display: none;
    }
    
    &.left {
      left: 35px;
      width: 345px;
      height: auto;
      rotate: -7.57deg;
      top: 60%;
      transform: translateY(-50%);
    }

    &.right {
      right: 0px;
      width: 330px;
      height: auto;
      rotate: -2.73deg;
      top: 55%;
      transform: translateY(-50%);
    }
  }

  .meal-choices-wrapper {
    margin: 30px;
    width: 859px;
    padding: 20px 35px;
    border-radius: 16px;
    background-color: white;
    z-index: 2;

    @include media-breakpoint-for(mobile) {
      padding: 0px 16px;
    }

    @media only screen 
      and (min-device-width: 820px) 
      and (max-device-width: 1180px)
      and (orientation: portrait) {
        padding: 20px 35px;
    }

    @media only screen 
      and (min-device-width: 768px) 
      and (max-device-width: 1024px)
      and (orientation: portrait) {
        padding: 20px 35px;
    }

    .header {
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @include media-breakpoint-for(mobile) {
        padding: 0 16px;
      }

      &__title-text {
        font-family: $fff-font-serif;
        font-weight: 400;
        font-size: 40px;
        line-height: 54.84px;
        color: $fff-brand-black-text;
        text-align: center;
        
        @include media-breakpoint-for(mobile) {
          margin-bottom: 0px;
        }
      }

      &__subtitle-text {
        font-family: $fff-font-sans;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        color: $fff-brand-grey;
        text-align: center;

        @media (max-width: 767px) {
          font-size: 14px;
          line-height: 18px;
          margin-bottom: 0px;

          br {
            display: none;
          }
        }
      }
    }

    .divider {
      position: relative;
      margin-left: auto;
      margin-right: auto;
      background-color: #DFDFDF;
      height: 3px;
      width: 627px;

      @media (max-width: 767px) {
        display: none;
      }
    }

    .meal-choice {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr 1fr;
      column-gap: 24px;
      padding: 26px 0;

      @include breakpoint(mobile-landscape) {
        grid-template-columns: 2fr 72px 72px 72px;
        column-gap: 8px !important;
      }

      @include media-breakpoint-for(mobile) {
        column-gap: 16px;
      }

      .grid-item {
        padding: 10px 0;
        display: flex;
        justify-content: center;
        align-items: center;

        @include media-breakpoint-for(mobile) {
          padding: 8px 0;
        }
      }

      &__title {
        font-size: 26px;
        font-weight: 700;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        text-align: center;
        padding: 20px 8px !important;

        @include breakpoint(mobile-landscape) {
          font-size: 14px !important;
          line-height: 14px !important;
        }

        @include media-breakpoint-for(mobile) {
          font-size: 18px;
          line-height: 24px;
        }
      }

      &__label {
        font-family: $fff-font-sans;
        font-weight: 400;
        font-size: 16px;
        color: $fff-brand-black-text;
        text-transform: unset;
        display: flex;
        justify-content: start !important;
        align-items: center;
      
        @include media-breakpoint-for(mobile) {
          font-size: 14px;
        }
      
        @media screen and (max-width: 414px) {
          font-size: 13px;
        }
      }

      .bespoke {
        color: $fff-brand-new-primary;
        background-color: #003A1F1A;

        &:last-child {
          border-bottom-left-radius: 12px;
          border-bottom-right-radius: 12px;
        }
      }

      .select {
        color: $fff-brand-foodbox-primary;
        background-color: #66102E1A;

        &:last-child {
          border-bottom-left-radius: 12px;
          border-bottom-right-radius: 12px;
        }
      }

      .custom-macros {
        color: $fff-brand-new-secondary;
        background-color: #f0f2e7;
        line-height: 22px;

        &:last-child {
          border-bottom-left-radius: 12px;
          border-bottom-right-radius: 12px;
        }

        .btn-macros {
          background-color: $fff-brand-new-secondary;
          border: none;
        }
      }

      .last {
        @include breakpoint(mobile-landscape) {
          border-bottom-left-radius: 12px;
          border-bottom-right-radius: 12px;
          padding-bottom: 16px !important;
        }
      }

      &__button-wrapper {
        text-align: center;
        padding-bottom: 16px !important;
        border-radius: 0 0 12px 12px;

        @media (max-width: 767px) {
          display: none !important;
        }

        @media only screen 
          and (min-device-width: 820px) 
          and (max-device-width: 1180px)
          and (orientation: portrait) {
            display: block;
        }

        @media only screen 
          and (min-device-width: 768px) 
          and (max-device-width: 1024px)
          and (orientation: portrait) {
            display: block;
        }

        .order-trial-button {
          white-space: nowrap;
        }

        .select {
          border-color: $fff-brand-foodbox-primary;
          background-color: $fff-brand-foodbox-primary;
          color: white;

          &:active,
          &:hover {
            background-color: #72233f !important;
            border-color: #72233f !important;
          }
        }

        .btn-macros {
          background-color: $fff-brand-new-secondary;
          border: none;

          &:active,
          &:hover {
            background-color: #6d8800 !important;
            border-color: #6d8800 !important;
          }
        }
      }

      &__mobile-buttons {
        display: none;

        @media (max-width: 767px) {
          display: grid;
          gap: 16px;
          justify-content: center;
          padding-bottom: 40px;
        }

        @include media-breakpoint-for(tablet) {
          display: none;
        }

        @media (min-width: 900px) {
          display: none;
        }

        @media only screen 
          and (min-device-width: 820px) 
          and (max-device-width: 1180px)
          and (orientation: portrait) {
            display: none;
        }

        @media only screen 
          and (min-device-width: 768px) 
          and (max-device-width: 1024px)
          and (orientation: portrait) {
            display: none;
        }

        .button-wrapper {
          height: 40px;
          margin-left: 24px;
          margin-right: 24px;
  
          .order-trial-button {
            size: 14px;
            line-height: 11px;
            width: 321px;

            @include media-breakpoint-for(mobile) {
              height: 42px;
            }
          }
  
          .select {
            border-color: $fff-brand-foodbox-primary;
            background-color: $fff-brand-foodbox-primary;
            color: white;
            size: 14px;
            width: 321px;

            &:active,
            &:hover {
              background-color: #72233f !important;
              border-color: #72233f !important;
            }
          }

          .btn-macros {
            background-color: #688300;
            border: none;

            &:hover {
              background-color: #6d8800 !important;
              border-color: #6d8800 !important;
            }
          }
        }
      }
    }
  }
}
