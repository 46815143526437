
.activity-col{
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
h2{  
  font-family: 'DINPro-Bold', sans-serif ;
  color:$fff-brand-grey;
  font-size: 16px;
  margin-bottom: 16px;
}
i{
  color: $fff-brand-green;
}
  .activity-row {
      border-left: 2px solid rgba($fff-brand-grey,0.12);
      padding: 0px 5px 16px 10px;
      margin: 0px 0px 5px 5px;
      height: 100%;
      position: relative;
      &:before{
        content: "";
        width: 8px;
        height: 8px;
        background: rgba($fff-brand-grey,0.12);
        border:2px solid rgba($fff-brand-green,0.18);
        border-radius: 50%;
        position: absolute;
        left: -5px;
        top:0px;
    }
    
    &.gift{
      .title{
        color: $fff-brand-green;   
        display: inline-flex;     
        span{
          margin-top: 1px;
        }
        i{
          margin-right: 5px;
          display: inline-block;
        }
      }
    }
  .title{
    font-size: 14px;
    color: $fff-brand-dark-grey;
    margin-top:-7px;
    font-family: 'SoleilLt', sans-serif;
    font-weight: 600;
    width: 100%;
    i{
     display: none;
    }
}
.description{
  font-family: 'SoleilLt', sans-serif;
    font-size: 13px;
    color: $fff-brand-grey;
    padding-bottom: 8px;
}
.time-stamp{
    font-size: 12px;
    font-family: 'Soleil-Bold', sans-serif;
    color: rgba($fff-brand-grey,0.36);
    padding-bottom: 0px
}
  }
}