@import "./responsive";

.btn {
  padding: 0px 15px;
  height: 40px;
  font-size: 14px;
  outline: none;
  border-radius: 20px;
  font-weight: 600;
  border-width: 2px;
  font-family: $fff-font-sans;
  touch-action: manipulation;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: unset;

  @include media-breakpoint-for(mobile) {
    line-height: 40px;
  }

  @include media-breakpoint-for(tab) {
    line-height: 40px;
  }

  @include media-breakpoint-for(tab-landscape) {
    line-height: 40px;
  }

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.36;
  }

  &.btn-wide {
    padding-left: 66.47px;
    padding-right: 66.47px;
    font-size: 14px;
    border-radius: 30px;

    @include media-breakpoint-for(mobile) {
      line-height: 40px;
    }

    @include media-breakpoint-for(tab) {
      line-height: 40px;
    }

    @include media-breakpoint-for(tab-landscape) {
      line-height: 40px;
    }
  }

  &.btn-lg {
    padding-left: 41px;
    padding-right: 41px;
    height: 56px;
    font-size: 18px;
    border-radius: 30px;
    min-width: 180px;

    @include media-breakpoint-for(mobile) {
      padding-left: 36px;
      padding-right: 36px;
      height: 40px;
      font-size: 14px;
      line-height: 40px;
    }

    @include media-breakpoint-for(tab) {
      padding-left: 36px;
      padding-right: 36px;
      height: 40px;
      font-size: 14px;
      line-height: 40px;
    }

    @include media-breakpoint-for(tab-landscape) {
      padding-left: 36px;
      padding-right: 36px;
      height: 40px;
      font-size: 14px;
      line-height: 40px;
    }
  }

  &.btn-md {
    padding-left: 14px;
    padding-right: 14px;
    height: 30px;
    font-size: 14px;

    @include media-breakpoint-for(mobile) {
      line-height: 30px;
    }

    @include media-breakpoint-for(tab) {
      line-height: 30px;
    }

    @include media-breakpoint-for(tab-landscape) {
      line-height: 30px;
    }
  }

  &.btn-sm {
    padding-left: 10px;
    padding-right: 10px;
    height: 26px;
    font-size: 12px;

    @include media-breakpoint-for(mobile) {
      line-height: 24px;
    }

    @include media-breakpoint-for(tab) {
      line-height: 24px;
    }

    @include media-breakpoint-for(tab-landscape) {
      line-height: 24px;
    }
  }

  &.btn-xsm {
    font-weight: 500;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
    height: 26px;

    @include media-breakpoint-for(mobile) {
      line-height: 24px;
    }

    @include media-breakpoint-for(tab) {
      line-height: 24px;
    }

    @include media-breakpoint-for(tab-landscape) {
      line-height: 24px;
    }
  }

  &.btn-primary {
    background-color: $fff-brand-new-primary;
    border-color: $fff-brand-new-primary;
    box-shadow: 0px 4px 8px rgba($fff-brand-new-primary, 0.18);

    &.add-new-color {
      background-color: $fff-brand-new-primary;
      border-color: $fff-brand-new-primary;
    }

    &:not(:disabled):not(.disabled):active,
    &:hover {
      background-color: $fff-brand-dark-green;
      border-color: $fff-brand-dark-green;
      box-shadow: 0px 4px 8px rgba($fff-brand-green, 0.18);
    }

    &:focus {
      background-color: $fff-brand-new-primary;
      border-color: $fff-brand-new-primary;
      box-shadow: 0px 4px 8px rgba($fff-brand-green, 0.18);
    }

    &.admin {
      background-color: $fff-brand-green;
      border-color: $fff-brand-green;
      padding: 0;
      line-height: 12px;

      &:hover {
        box-shadow: 0px 4px 8px rgba($fff-brand-green, 0.18) !important;
      }
    }

    &.btn-select {
      background-color: $fff-brand-foodbox-primary;
      border-color: $fff-brand-foodbox-primary;
      box-shadow: 0px 4px 8px rgba($fff-brand-foodbox-primary, 0.18);

      &:hover,
      &:active,
      &:focus {
        background-color: #72233f !important;
        border-color: #72233f !important;
      }
    }
  }

  &.btn-secondary {
    border-color: $fff-brand-new-secondary;
    color: $fff-brand-new-secondary;
    background-color: #ffffff;
    box-shadow: 0px 4px 8px rgba($fff-brand-green, 0.16);

    &:not(:disabled):not(.disabled):active,
    &:focus,
    &:hover {
      color: $fff-brand-new-secondary;
      border-color: $fff-brand-new-secondary;
      background-color: $fff-brand-new-fill;
      box-shadow: 0px 4px 8px rgba($fff-brand-green, 0.16);
    }

    &:focus {
      color: $fff-brand-new-secondary;
      border-color: $fff-brand-new-secondary;
      box-shadow: 0px 4px 8px rgba($fff-brand-green, 0.16);
    }

    .admin {
      border-color: rgba($fff-brand-green, 0.18);
    }
  }

  &.btn-transparentLink {
    color: $fff-brand-green;
    background-color: transparent;
    border: 0;
    box-shadow: none !important;
    height: 30px;
    padding-left: 0;
    padding-right: 0;
    font-weight: 400;
    border-radius: 0;

    &:not(:disabled):not(.disabled):active,
    &:hover,
    &:active,
    &:focus {
      box-shadow: none;
      border: 0;
      color: darken($fff-brand-green, 10);
    }
  }

  &.btn-transparent {
    color: $fff-brand-grey;
    background-color: transparent;
    border: 0;
    height: 42px;
    box-shadow: none !important;

    &:not(:disabled):not(.disabled):active,
    &:hover,
    &:active,
    &:focus {
      background: rgba($fff-brand-grey, 0.1);
      box-shadow: none;
      border: 0;
    }
  }

  .footer .footer-container & {
    min-width: 120px;
  }
}

button {

  &:focus,
  &:active {
    outline: none;
  }
}

.btn.disabled,
.btn:disabled {
  opacity: 0.36;
}

.btn-margin-top {
  margin-top: 10px;
}

.link-button {
  color: $fff-brand-new-secondary;
  font-family: $fff-font-body-bold !important;
  text-decoration: none;
  padding-right: 24px;
  background-image: url("../img/icons/arrow-right-olive-green.svg");
  background-position: 100% 4px;
  background-size: 28px;
  background-repeat: no-repeat;
  display: inline-flex !important;
  align-items: center;
  font-size: 16px;
  transition: background-position 0.36s ease;
  padding-left: 0;
  font-weight: 500;

  &:hover {
    color: $fff-brand-new-secondary;
    background-position: 103% 4px;
  }
}

.icon-button {
  padding: 0;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;

  &.danger {
    border-color: rgba($fff-brand-red, 0.16);

    &:hover {
      background-color: rgba($fff-brand-red, 0.16);
    }

    i {
      color: $fff-brand-red;
    }
  }
}

.btn-foodbox{
  background-color: $fff-brand-foodbox-primary;
}