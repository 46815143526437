.user-tabs {
  padding-top: 30px;
  margin-left: 0px;
  margin-right: 0px;
  width: 100%;

  .banner-text {
    position: relative;
    width: 100%;
    margin: 0px 0px 15px;
    padding: 0px 15px;

    .text-col {
      margin: 0px 0px 20px;
      padding: 15px;
      background-color: #F2fff9;
    }

    .title {
      text-align: center;
      margin: 0px 0px 0px;
      display: block;
    }

    .message {
      text-align: justify;
      margin: 0px 10px 0px;
    }
  }

  .top-main-row {
    position: relative;
    width: 100%;
    margin: 0px 0px 30px;
    padding: 0px 15px;

    h1 {
      font-size: 20px;
      font-weight: 600;
      color: black;
      margin-bottom: 0rem;
      margin-top: 0rem;
      font-family: 'DINPro-Bold', sans-serif;
      display: block;
      float: left;

      @include media-breakpoint-for(mobile) {
        text-align: center;
        float: none;
      }

      @include media-breakpoint-for(tab) {
        text-align: center;
        float: none;
      }

      @include media-breakpoint-for(tab-landscape) {
        text-align: center;
        float: none;
      }
    }

    button {
      float: right;
      box-shadow: none;

      @include media-breakpoint-for(mobile) {
        clear: both;
        margin-right: 0px;
        margin: 5px auto;
        width: 100%;
      }

      @include media-breakpoint-for(tab) {
        clear: unset;
        margin-right: 0px;
        margin: 5px;
        width: auto;
      }

      @include media-breakpoint-for(tab-landscape) {
        clear: unset;
        margin-right: 0px;
        margin: 5px;
        width: auto;
      }
    }
  }

  .detail-row {
    margin: 0px 0px 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(66, 69, 70, 0.08);

    &:last-of-type {
      border-bottom: 0px;
    }

    .col-md-6 {
      @include media-breakpoint-for(tab) {
        max-width: 100%;
        flex: 0 0 100%;
      }

      @include media-breakpoint-for(tab-landscape) {
        max-width: 50%;
        flex: 0 0 50%;
      }
    }

    .white-box {
      box-shadow: 0px 1px 4px rgba($fff-brand-grey, 0.08);
      padding: 12px 16px;
      margin-bottom: 20px;

      .top-row {
        position: relative;
        width: 100%;
        margin: 0px 0px 20px;
        padding: 0;

        h2 {
          font-size: 16px;
          font-weight: 600;
          color: rgba($fff-brand-grey, 0.72);
          margin-bottom: 0rem;
          margin-top: 0rem;
          font-family: 'DINPro-Bold', sans-serif;
          display: inline-block;

          &::after {
            content: "";
            width: 43px;
            border-bottom: 2px solid $fff-brand-green;
            position: absolute;
            left: 0px;
            top: 30px;
            height: 1px;
          }
        }

        a {
          font-size: 14px;
          font-weight: 300;
          color: $fff-brand-green;
          margin-bottom: 0rem;
          margin-top: 0rem;
          font-family: 'SoleilLt', sans-serif;
          float: right;
        }

        a:not([href]):not([tabindex]) {
          color: $fff-brand-green;
        }

        button {
          position: relative;
          float: right;
          margin-left: 2px;
          top: 0px;
          box-shadow: none;
        }
      }

      .affiliatedBy-Row {
        position: relative;
        width: 100%;
        margin: 0;
        padding: 15px 0px 15px;
        background-color: #F2fff9;
        text-align: center;

        p {
          font-weight: bold;
          margin-bottom: 0px;
        }
      }

      a {
        color: $fff-brand-green;
        font-weight: 600;
      }

      p {
        word-break: break-word;
      }

      .section-row {
        position: relative;
        padding-bottom: 10px;
        margin-bottom: 15px;
        border-bottom: 1px solid rgba($fff-brand-grey, 0.16);

        .close {
          position: absolute;
          right: 0px;
          top: -4px;
          font-size: 12px;
          padding: 0px;
          height: 25px;
          width: 25px;
          border-radius: 50%;

          &.reduce-weight {
            z-index: 3;
            i {
              font-weight: normal;
              font-size: 17px;
            }
          }

          i {
            font-weight: 600;
          }
        }
      }

      .fff-table {
        .p-datatable-wrapper {

          table {
            thead {
              &.p-datatable-thead {
                tr {
                  th {
                    &:first-of-type {
                      padding-left: 0px;
                    }

                    &:last-of-type {
                      padding-right: 0px;
                    }
                  }
                }
              }
            }

            tbody {
              &.p-datatable-tbody {
                tr {
                  td {
                    &:first-of-type {
                      padding-left: 0px;
                    }

                    &:last-of-type {
                      padding-right: 0px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .white-box-no-padding {
      box-shadow: 0px 1px 4px rgba($fff-brand-grey, 0.08);
      padding: 0px;
      margin-bottom: 20px;

      .top-row-padding {
        position: relative;
        width: 100%;
        margin: 0px 0px 20px;
        padding: 12px 16px 0px;

        h2 {
          font-size: 16px;
          font-weight: 600;
          color: rgba($fff-brand-grey, 0.72);
          margin-bottom: 0rem;
          margin-top: 0rem;
          font-family: 'DINPro-Bold', sans-serif;
          display: inline-block;

          &::after {
            content: "";
            width: 43px;
            border-bottom: 2px solid $fff-brand-green;
            position: absolute;
            left: 16px;
            top: 41px;
            height: 1px;
          }
        }

        a {
          font-size: 16px;
          font-weight: 300;
          color: $fff-brand-green;
          margin-bottom: 0rem;
          margin-top: 0rem;
          font-family: 'SoleilLt', sans-serif;
          float: right;
        }

        a:not([href]):not([tabindex]) {
          color: $fff-brand-green;
        }

        button {
          position: relative;
          float: right;
          margin-left: 2px;
          top: 0px;
          box-shadow: none;
        }

      }

    }
  }
}

.three-col-row {
  padding: 30px 30px 10px 30px;
  margin: 0px;
  width: 100%;

  .text-col {
    text-align: left;
  }

  .icon-col {
    text-align: center;

    @include media-breakpoint-for(mobile) {
      text-align: right;
    }

    @include media-breakpoint-for(tab) {
      text-align: right;
    }

    @include media-breakpoint-for(tab-landscape) {
      text-align: right;
    }

    .btn-circle {
      width: 40px;
      height: 40px;
      font-size: 12px;
      border-radius: 50%;
      padding: 0px;
      line-height: 38px;
    }
  }

  .button-col {
    text-align: right;
  }
}

.day-of-the-week {
  display: inline-block;
  position: relative;
  border-radius: 0px;
  text-align: center;
  width: 25px;
  height: 25px;
}

p {
  font-size: 14px;
  font-weight: 300;
  color: $fff-brand-grey;
  margin-bottom: 20px;
  margin-top: 0rem;
  font-family: $fff-font-sans;

  strong {
    font-family: $fff-font-sans;
    font-weight: 700;
  }

  &.lg {
    font-size: 20px;
    font-family: 'Soleil-Bold', sans-serif;
    margin-bottom: 0px;
    line-height: 20px;
  }

  &.sm {
    font-size: 12px;
  }

  &.noMargin {
    margin-bottom: 0px;
  }
}

ul {
  margin: 0px;
  padding: 0px 0px 0px 20px;

  li {
    font-size: 14px;
    font-weight: 300;
    color: $fff-brand-grey;
    margin-top: 0rem;
    font-family: 'SoleilLt', sans-serif;
  }
}

.button-save {
  margin: 0 auto 40px auto;
}

.chart-button {
  border: 0px;
  padding: 0px 2px;
  color: $fff-brand-grey;
  font-family: 'Soleil-Bold', sans-serif;
  font-size: 14px;
  box-shadow: none !important;

  &:not(:disabled):not(.disabled):active,
  &:hover,
  &:focus {
    background: transparent !important;
  }

  i {
    font-size: 23px !important;
    padding: 0px 5px;
    color: rgba($fff-brand-grey, 0.72);
  }

  .year {
    color: $fff-brand-green;
    position: relative;

    &::after {
      content: "";
      width: 100%;
      border-bottom: 1px solid $fff-brand-green;
      position: absolute;
      left: 0;
      top: 22px;
      height: 1px;
    }
  }
}

.chart-weight {
  height: 38px;
  color: $fff-brand-grey;
  font-weight: 500;
  font-family: 'Soleil-Bold', sans-serif;
  display: block;
  line-height: 38px;
  font-size: 14px;
}

.shadow-row {
  .calendar-row {
    box-shadow: 0px 1px 4px rgba($fff-brand-grey, 0.08);
    padding: 16px;
    display: table;
    vertical-align: middle;
    border-radius: 4px;
    width: auto;
    margin: 0 auto;

    p {
      margin-bottom: 0rem;
      color: $fff-brand-grey;
      font-size: 14px;
      font-family: 'SoleilLt', sans-serif;
      position: relative;
      display: inline-block;
      -webkit-column-count: auto;
      -moz-column-count: auto;
      width: 30px;
      margin-bottom: 0px;
      vertical-align: middle;

      &.auto {
        width: auto;
        padding-right: 10px;
      }
    }

    label {
      display: inline-flex;
      width: 164px;
      justify-content: space-evenly;
      padding: 0px 10px;
      margin-bottom: 0px;
      vertical-align: middle;
      font-size: 14px;
    }

    .input-row {
      display: inline-flex;
      width: 365px;
      justify-content: space-evenly;
      padding: 0px;
      margin-bottom: 0px;
      vertical-align: middle;
      position: relative;

      @include media-breakpoint-for(mobile) {
        width: 226px;
      }

      @include media-breakpoint-for(tab) {
        width: 226px;
      }

      @include media-breakpoint-for(tab-landscape) {
        width: 226px;
      }

      .p-calendar {
        width: 100%;
      }

      input {
        font-weight: 600;
      }

      i {
        color: rgba($fff-brand-green, 0.72);
        position: absolute;
        right: 5px;
        top: 5px;
        pointer-events: none;
        cursor: pointer;
      }
    }

    .date-col {
      position: relative;
      display: inline-block;
      -webkit-column-count: auto;
      -moz-column-count: auto;
      width: 40px;
      margin-bottom: 0px !important;
      vertical-align: middle;

      >div {
        float: right;
      }
    }
  }
}

.shadow-none-row {
  p {
    margin-bottom: 0rem;
    color: $fff-brand-grey;
    font-size: 14px;
    font-family: 'SoleilLt', sans-serif;
  }

  .delivery-row {
    background-color: transparent;
    margin: 0.1rem 0rem;
    padding: 16px;
    display: table;
    vertical-align: middle;
    border-radius: 4px;
    width: auto;
    margin: 0 auto;

    p {
      display: inline-block;
      -webkit-column-count: auto;
      -moz-column-count: auto;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: auto;
      margin-bottom: 0px !important;
      vertical-align: middle;
      line-height: 40px;
    }

    .btn-group {
      display: inline-flex;
      width: auto;
      justify-content: space-evenly;
      line-height: 40px;
      padding: 0px 10px;

      .total {
        margin: 0px 5px;
        width: 6px;
        text-align: center;
      }
    }
  }
}

.detail-wrap {
  display: -webkit-inline-flex;
  display: inline-flex;
  margin-top: 15px;
  width: 100%;

  .img-wrap {
    background-repeat: no-repeat;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: rgba($fff-brand-grey, 0.1);
  }

  .text-wrap {
    display: block;
    padding-left: 12px;
    z-index: 1;

    p {
      font-size: 14px;
      font-weight: 400;
      color: rgba($fff-brand-grey, 0.72);
      margin-bottom: 0rem;
      margin-top: 0rem;
      font-family: 'DINPro-Bold', sans-serif;
      display: inline-block;

      .lg {
        font-size: 18px;

        @include media-breakpoint-for(mobile) {
          font-size: 16px;
        }

        @include media-breakpoint-for(tab) {
          font-size: 16px;
        }

        @include media-breakpoint-for(tab-landscape) {
          font-size: 16px;
        }
      }

      strong {
        color: $fff-brand-grey;
        font-weight: 500;
      }
    }

    a {
      color: $fff-brand-green;
      display: inline-block;
      font-weight: 600;
    }

    .disclaimer-label {
      font-size: 18px;
      font-weight: 900;
      color: black;
      margin-bottom: 0rem;
      margin-top: 0rem;
      font-family: 'DINPro-Bold', sans-serif;
      display: block;
      width: 100%;

      @include media-breakpoint-for(mobile) {
        font-size: 16px;
      }

      @include media-breakpoint-for(tab) {
        font-size: 16px;
      }

      @include media-breakpoint-for(tab-landscape) {
        font-size: 16px;
      }
    }

    h1 {
      font-size: 28px;
      font-weight: 600;
      color: black;
      margin-bottom: 0rem;
      margin-top: 0rem;
      font-family: 'DINPro-Bold', sans-serif;
      display: block;
      width: 100%;

      @include media-breakpoint-for(mobile) {
        font-size: 22px;
      }

      @include media-breakpoint-for(tab) {
        font-size: 22px;
      }

      @include media-breakpoint-for(tab-landscape) {
        font-size: 22px;
      }

      .sm {
        font-size: 18px;

        @include media-breakpoint-for(mobile) {
          font-size: 16px;
        }

        @include media-breakpoint-for(tab) {
          font-size: 16px;
        }

        @include media-breakpoint-for(tab-landscape) {
          font-size: 16px;
        }
      }
    }
  }

}

.bag-collection-log-row {
  border-left: 2px solid rgba($fff-brand-grey, 0.12);
  padding: 0px 5px 16px 10px;
  margin: 0px 0px 5px 5px;
  height: 100%;
  position: relative;

  &:before {
    content: "";
    width: 8px;
    height: 8px;
    background: rgba($fff-brand-grey, 0.12);
    border: 2px solid rgba($fff-brand-green, 0.18);
    border-radius: 50%;
    position: absolute;
    left: -5px;
    top: 0px;
  }

  &.gift {
    .title {
      color: $fff-brand-green;
      display: inline-flex;

      span {
        margin-top: 1px;
      }

      i {
        margin-right: 5px;
        display: inline-block;
      }
    }
  }

  .title {
    font-size: 14px;
    color: $fff-brand-dark-grey;
    margin-top: -7px;
    font-family: 'SoleilLt', sans-serif;
    font-weight: 600;
    width: 100%;
  }

  .description {
    font-family: 'SoleilLt', sans-serif;
    font-size: 13px;
    color: $fff-brand-grey;
    padding-bottom: 8px;
  }

  .time-stamp {
    font-size: 12px;
    font-family: 'Soleil-Bold', sans-serif;
    color: rgba($fff-brand-grey, 0.36);
    padding-bottom: 0px
  }
}

.daily-menu-card {
  background-color: rgba($fff-brand-grey, 0.08);
  box-shadow: 0px 1px 4px rgba($fff-brand-grey, 0.08);
  padding: 15px;
  height: 100%;
}

.offers-section-header {
  margin: 0 0 2rem;
  h1 {    
    font-family: $fff-font-serif;
    font-size: 36px;
    line-height: 1;
    font-weight: 400;
    margin-bottom: 1rem;
  }

  p {
    font-size: 16px;
    font-weight: bolder;
    color: $fff-brand-black-text;
    margin-bottom: 0;
  }
}

.offer-section-no-items {
  text-align: center;
  margin: 20px 0 35px 0;
  font-size: 14px;
  color: grey;
}

.partner-offers {
  .inventory-card-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    margin-bottom: 1rem;

    @media (max-width: 991px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 767px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 480px) {
      grid-template-columns: repeat(1, 1fr);
    }

    > div {
      &::before {
        width: 100%;
        background-color: $fff-brand-grey-light;
        left: 0;
        right: 0;
        border-radius: 6px;
      }
    }

    .frozen-card-container {
      padding: 0;
      margin: 0;
    }

    .frozen-card {
      padding-bottom: 0;
      border-radius: 6px;
      cursor: pointer;
      &::after {
        content: '';
        width: 100%;
        height: 2px;
        display: block;
        position: absolute;
        bottom: 0;
        transition: 0.2s all;
        background-color: transparent;
      }
      &:hover, &:active, &:focus, &:visited {
        box-shadow: 0 3px 16px #dddddd;
        &::after {
          background-color: $fff-brand-new-accent;
        }
      }
      &.visited {
        box-shadow: 0 3px 16px #dddddd;
        &::after {
          background-color: $fff-brand-new-accent;
        }
      }
      .frozen-img {
        height: 200px;
        margin-bottom: 0;
      }

      .banner-text {
        background-color: $fff-brand-new-primary;
        padding: 0.3rem 0.625rem;
        margin-bottom: 0;
        h4 {
          position: relative;
          inset: 0;
          color: $fff-brand-new-light;
        }
      }

      .item-logo-wrapper {
        height: auto;
        width: 100%;
        padding: 0.5rem;
        font-family: $fff-font-title-bold;
        text-align: center;
        font-size: 32px;
        word-wrap: break-word;
        line-height: 1.2;
        margin: auto;

        img {
          width: 100%;
          height: 50px;
          object-fit: contain;
        }

        @media (max-width: 767px) {
          padding: 1rem 0.5rem;
          font-size: 26px;
        }
      }

    }
  }
}

.client-offer-catalogue-item-view {
  .btn-close {
    border-radius: 50%;
    width: 28px;
    height: 28px;
    position: fixed;
    right: 1rem;
    top: 1rem;
    box-shadow: none;
    background: transparent;
    border: 1px solid $fff-brand-new-primary;
    cursor: pointer;
    z-index: 1;
    font-size: 20px;
    padding: 0;
    border: 0 !important;
    &:hover, &:active, &:focus {
      border: 1px solid $fff-brand-new-primary;
      box-shadow: none;
    }

    i {
      &::before {
        color: $fff-brand-new-primary;
      }
    }
  }

  .modal-content {
    background-color: $fff-brand-new-fill;
    padding: 2.5rem 0rem 0rem;

    .modal-body {
      margin-bottom: 0;
      padding: 0 1.5rem 2rem;
    }

    .item-logo-wrapper {
      width: auto;
      height: auto;
      font-family: $fff-font-title-bold;
      font-size: 26px;
      line-height: 1;
      margin-bottom: 1.5rem;
      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 70px;
      }
    }

    .item-description {
      width: auto;
      margin: 0 -15px 1.5rem -15px;

      p {
        margin-bottom: 0;
        text-align: justify;
      }
    }

    .btn-wrapper {
      justify-content: center;
    }

    .offer-code-wrapper {
      position: relative;
      margin-bottom: 2rem;
      height: 40px;
      width: fit-content;
      min-width: 300px;
      margin: auto auto 2rem;

      .offer-code {
        width: 100%;
        background-color: $fff-brand-new-light;
        border: 1px solid $fff-brand-new-secondary;
        text-align: center;
        font-weight: 700;
        text-transform: uppercase;
        color: $fff-brand-new-secondary;
        padding: 0 3.5rem 0 1rem;
      }

      input:disabled {
        -webkit-appearance: none;
        opacity:1;
        -webkit-text-fill-color: $fff-brand-new-secondary;
      }
  
      .copy-to-clipboard {
        position: absolute;
        background: none;
        right: 0;
        height: 100%;
        border: none;
        box-shadow: none;
        min-width: 40px;
      }
    }

  }
}