@import './responsive';

.home-wrap {

  // new set of changes to home page 22-12-2020
  &.second-version {
    .slide-row {
      margin-bottom: 0 !important;
    }

    .right-btn-row {
      height: 0 !important;
      opacity: 0 !important;
    }

    .footer-row {
      padding-top: 20px;
    }

    .green-bg-without-big-padding {
      background-color: $fff-brand-light-green;
      padding-top: 60px;
      padding-bottom: 40px;
    }

    // .cta-box {
    //   margin-bottom: 0 !important;

    //   &::before {
    //     background-image: url('../img/new-static-changes/cta-left-banner.png') !important;
    //     // @include media-breakpoint-for(mobile) {
    //     //   background-image: url('../../assets/img/CTA-mobile.jpg') !important;
    //     // }

    //     // @include media-breakpoint-for(tab) {
    //     //   background-image: url('../../assets/img/CTA-mobile.jpg') !important;
    //     // }

    //     // @include media-breakpoint-for(tab-landscape) {
    //     //   background-image: url('../../assets/img/CTA-mobile.jpg') !important;
    //     // }
    //   }
    //   &::after {
    //     background-image: url('../img/new-static-changes/cta-right-banner.png') !important;
    //   }
    //   .cta-box-inside {
    //     h3 {
    //       font-size: 18px !important;
    //       width: 50%;
    //       margin: 0 auto 2rem;
    //       @include media-breakpoint-for(mobile) {
    //         width: 100%;
    //       }
    //     }
    //   }
    // }

    .cta-box {
      @include media-breakpoint-for(mobile) {
        margin-top: 0px;
      }

      @include media-breakpoint-for(tab) {
        margin-top: 0px;
      }

      @include media-breakpoint-for(tab-landscape) {
        margin-top: 0px;
      }
    }

    .book-a-call-wrapper {
      .link-button {
        margin-bottom: 8px;
        margin-left: 15px;

        @include media-breakpoint-for(mobile) {
          margin-top: 8px;
        }
      }
    }

    .right-btn-row {
      position: relative !important;
      right: 0px;
      bottom: 0 !important;
      padding: 0px;
      z-index: 1;
      padding-left: 15px;
      text-align: left;
    }

    .adjust-slider-background {
      background-color: #ffffff !important;
      background: #ffffff !important;
    }

    .carousel {
      &::after {
        display: none;
      }

      &-item {
        display: flex !important;
        align-items: center;
        justify-content: flex-end;

        @include media-breakpoint-for(mobile) {
          flex-direction: column;
        }
      }

      &-image {
        float: none;
        padding: 0;
        vertical-align: middle;
        height: 550px !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;

        &::after {
          display: none;
        }
      }

      &-caption {
        float: none !important;
        vertical-align: middle;
        display: flex !important;
        flex-direction: column;
        justify-content: center;
        padding: 0 40px 0 60px;

        @include media-breakpoint-for(mobile) {
          order: 1;
        }

        .custom-height-middle {
          display: flex;
          align-items: center;
        }

        .link-button {
          max-width: fit-content;

          @include breakpoint(mobile) {
            display: flex !important;
            margin: 0 auto;
          }
        }

        .text-left {
          @include breakpoint(mobile) {
            background-color: #ffffff !important;
            background: #ffffff !important;
          }

          h1 {
            text-align: left !important;
            margin-top: 0 !important;
            padding-top: 0 !important;
            font-size: 34px !important;
            line-height: 40px !important;

            @include breakpoint(tab-up) {
              font-size: 40px !important;
              line-height: 47px !important;
            }
          }

          p {
            left: 0 !important;
            position: relative !important;
            transform: none !important;
            top: 0 !important;
            text-align: left !important;
            font-family: $fff-font-sans;
          }
        }
      }

      .carousel-indicators {
        right: 0 !important;
        flex: 0 0 50% !important;
        max-width: 50% !important;
        width: 50% !important;
        transform: translateY(-60px);
        z-index: 3;

        li {
          background-color: rgba($color: #ffffff, $alpha: 0.5) !important;

          &.active {
            background-color: #ffffff !important;
          }
        }

        @include media-breakpoint-for(mobile) {
          max-width: 100% !important;
          width: 100% !important;
          top: 530px !important;
        }
      }

      .main-slider-carousel-controller {
        display: none;
      }
    }

    .home-page-top {
      padding-bottom: 40px;

      .top-image-frame {
        max-height: 600px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 0;
        padding-right: 0;
        max-height: 400px;

        @include breakpoint(tab-up) {
          max-height: 600px;
        }

        .video {
          width: 100%;
          height: 600px;
          position: relative;

          #video_container {
            &>div {
              position: absolute;
              width: 100% !important;
              height: 100% !important;
              top: 0;
              left: 0;

              video {
                object-fit: cover;
                object-position: center;
                width: 100%;
                height: 100%;
                max-height: 600px;
              }
            }
          }
        }
      }

      .text-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 55px;
        background-color: $fff-brand-new-fill;

        @include breakpoint(tablet) {
          padding-left: 15px;
          align-items: center;
          text-align: center;
          padding-top: 40px;

          h1 {
            font-size: 34px !important;
            line-height: 42px !important;
          }
        }

        h1 {
          font-family: $fff-font-serif;
          font-size: 48px;
          font-weight: 400;
          width: 100%;
          margin-top: 0px;
          margin-bottom: 20px;
          line-height: 55px;
        }

        p {
          font-family: $fff-font-sans;
          font-weight: 300;
          color: $fff-brand-grey;
          margin-bottom: 37px;
          margin-top: 0rem;
          font-size: 18px;
        }

        &>div {
          &>button {
            &:first-child {
              margin-bottom: 1rem;

              @include breakpoint(tab-up) {
                margin-bottom: 0;
              }
            }

            @include breakpoint(tab-up) {
              margin-right: 2rem;
            }
          }
        }

      }
    }

    .mobile-app-section-row {
      background-color: $fff-brand-grey-light;
      padding: 60px 0px;

      .banner-wrapper {
        position: relative;
        min-height: 449px;

        @include media-breakpoint-for(mobile) {
          min-height: 300px;
        }

        .app-banner {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-size: contain;
          background-position: right center;
          background-repeat: no-repeat;

          @include media-breakpoint-for(mobile) {
            position: relative;
            transform: none;
          }
        }
      }

      .mobile-app-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;

        @include media-breakpoint-for(mobile) {
          text-align: center;
        }

        h2 {
          font-size: 42px;
          line-height: 50px;
          margin-top: 0;
          margin-bottom: 20px;
          font-family: $fff-font-serif;

          @include media-breakpoint-for(mobile) {
            font-size: 34px;
            line-height: 42px;
          }
        }

        p {
          margin-bottom: 37px;
          width: 80%;
          font-size: 16px;
          font-family: $fff-font-sans;

          @include media-breakpoint-for(mobile) {
            width: 100%;
          }
        }

        img.fitness-banner {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 90%;
          height: 50%;
          transform: translate(75%, 5%);

          @include media-breakpoint-for(mobile) {
            position: relative;
            transform: none;
            margin: 50px auto;
          }

          @media screen and (min-width: 1900px) {
            transform: translate(90%, 5%);
          }
        }
      }
    }

    .slider-wrapper {
      @include media-breakpoint-for(mobile) {
        margin: 0 -15px;
      }
    }

    .products-section {
      .page-section-title {
        margin-bottom: 20px;
      }

      .meal-text-row {
        color: $fff-brand-black-text;
        font-size: 16px;
      }

      .products-section-row {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 24px;
        padding-top: 20px;
        justify-content: center;

        @include media-breakpoint-for(mobile) {
          display: flex;
          overflow-x: auto;
          scroll-snap-type: x mandatory;
          gap: 16px;
          padding: 20px 16px 16px;
          scroll-behavior: smooth;
          justify-content: start;
          scrollbar-width: none;
        }

        @include breakpoint(tablet) {
          display: flex;
          overflow-x: auto;
          gap: 16px;
          justify-content: start;
          scrollbar-width: none;
      
          .products-card {
            flex: 0 0 300px;
            scroll-snap-align: center;
          }
        }

        @include media-breakpoint-for(ipad-pro-portrait) {
          grid-template-columns: repeat(3, 1fr);
        }
  
        .products-card {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          background-color: $fff-brand-new-light;
          padding: 20px;
          border-radius: 8px;
          box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;

          @include breakpoint(mobile-landscape) {
            flex: 0 0 300px;
            scroll-snap-align: center;
          }

          @include breakpoint(tablet) {
            flex: 0 0 300px;
            scroll-snap-align: center;
          }
  
          h3 {
            font-size: 24px;
            margin-bottom: 0;
            white-space: nowrap;
          }
  
          p {
            text-align: start;
          }

          img {
            width: 50px;
            height: 50px;
          }
  
          @include breakpoint(mobile-landscape) {
            width: auto;
          }
  
          button {
            color: $fff-brand-new-light;
            width: 100%;
            height: 40px;
            font-size: 14px;
          }
  
          .btn-styles-0 {
            background-color: $fff-brand-foodbox-primary;
            &:active,
            &:hover {
              background-color: #72233f !important;
            }
          }
  
          .btn-styles-1 {
            background-color: $fff-brand-new-primary;
            &:active,
            &:hover {
              background-color: #014925;
            }
          }
  
          .btn-styles-2 {
            background-color: $fff-brand-new-secondary;
            &:active,
            &:hover {
              background-color: #6d8800 !important;
            }
          }
  
          .products-content-wrapper {
            display: flex;
            flex-direction: column;
            align-items: start;
            padding-bottom: 10px;
            gap: 16px;

            .products-title-wrapper {
              display: flex;
              gap: 10px;
              align-items: center;
            }
    
            .title-styles-0 {
              color: $fff-brand-foodbox-primary;
            }
    
            .title-styles-1 {
              color: $fff-brand-new-primary;
            }
    
            .title-styles-2 {
              color: $fff-brand-new-secondary;
            }
          }
        }
      }
    }

    .image-wrap {
      display: flex;
      overflow: hidden;
      justify-content: center;
      align-items: flex-end;

      .tag {
        left: 50% !important;
        transform: translateX(-50%);
        white-space: nowrap;
        z-index: 2;
        box-shadow: 0px 6px 6px rgba($color: #000000, $alpha: 0.16);
        min-width: 162px;

        &.lean-gain {
          background-color: #f1d7bf !important;
        }

        &.fat-loss {
          background-color: #bad7be !important;
        }

        &.general-health {
          background-color: #f8f0a2 !important;
        }

        &.build-muscle {
          background-color: #b9e2da !important;
        }
      }

      img {
        @include media-breakpoint-for(mobile) {
          width: 331px !important;
          transform: scale(1.2);
        }
      }
    }

    .how-it-works {
      padding: 30px 0px;

      @include breakpoint(tab-up) {
        margin-top: 30px;
      }

      @include breakpoint(desktop-up) {
        margin-top: 0px;
        padding: 20px 0px 60px;
      }

      .how-it-container {
        display: grid;
        grid: auto / auto;

        @include breakpoint(tab-up) {
          grid: auto / repeat(2, 1fr);
        }

        @include breakpoint(tab-landscape-up) {
          grid: auto / repeat(4, 1fr);
        }

        gap: 15px;
      }

      .how-it-box {
        .how-it-title {
          padding: 5px 15px;
          margin-bottom: 1.5px;

          h2 {
            font-size: 18px;
            font-family: $fff-font-sans;
            font-weight: bold;
            margin-bottom: 0px;
            color: $fff-brand-black-text;

            @include breakpoint(desktop-up) {
              font-size: 20px;
            }

            i {
              font-family: $fff-font-title-light;
            }
          }
        }

        img {
          width: 100%;
          max-height: 266.5px;
          object-fit: cover;
          margin-bottom: 15px;
        }
      }
    }
  }

  .gradient-bg-row {
    background-color: $fff-brand-light-green;
    /* For browsers that do not support gradients */
    background-image: linear-gradient($fff-brand-light-green, white);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$fff-brand-light-green', endColorstr='white', GradientType=0);
    /* IE6-9 */

    @include media-breakpoint-for(mobile) {
      background-image: none;
      background-color: white;
    }

    @include media-breakpoint-for(tab) {
      background-image: none;
      background-color: white;
    }

    @include media-breakpoint-for(tab-landscape) {
      background-image: none;
      background-color: white;
    }

    .custom-container {
      padding: 0;

      @include media-breakpoint-for(mobile) {
        padding: 0;
      }

      @include media-breakpoint-for(tab) {
        padding: 0;
      }

      @include media-breakpoint-for(tab-landscape) {
        padding: 0;
      }

      .slide-row {
        margin: 0px;
        height: auto;
        position: relative;
        margin-bottom: 90px;

        @include media-breakpoint-for(mobile) {
          height: auto;
          margin-bottom: 60px;
        }

        @include media-breakpoint-for(tab) {
          height: auto;
          margin-bottom: 60px;
        }

        @include media-breakpoint-for(tab-landscape) {
          height: auto;
          margin-bottom: 60px;
        }

        .carousel {
          &::after {
            content: '';
            background-image: url('../../assets/img/fff-leaf-s.svg');
            background-repeat: no-repeat;
            position: absolute;
            -ms-transform: rotate(64deg);
            -webkit-transform: rotate(64deg);
            transform: rotate(64deg);
            width: 195px;
            height: 90px;
            z-index: 1;
            bottom: 30px;
            left: -70px;
            background-size: contain;
            opacity: 0.8;

            @include media-breakpoint-for(mobile) {
              top: 238px;
              left: 5px;
              -ms-transform: rotate(15deg);
              -webkit-transform: rotate(15deg);
              transform: rotate(15deg);
              width: 150px;
              height: 70px;
            }

            @include media-breakpoint-for(tab) {
              top: 409px;
              left: 5px;
              -ms-transform: rotate(15deg);
              -webkit-transform: rotate(15deg);
              transform: rotate(15deg);
              width: 150px;
              height: 70px;
            }

            @include media-breakpoint-for(tab-landscape) {
              top: 409px;
              left: 5px;
              -ms-transform: rotate(15deg);
              -webkit-transform: rotate(15deg);
              transform: rotate(15deg);
              width: 150px;
              height: 70px;
            }
          }

          &.slide {
            .carousel-indicators {
              left: unset;
              right: unset;
              bottom: 0px;
              margin: 0;
              -webkit-flex: 0 0 58.33333%;
              flex: 0 0 58.33333%;
              max-width: 58.33333%;
              width: 58.33333%;

              @include media-breakpoint-for(mobile) {
                -webkit-flex: 0 0 100%;
                flex: 0 0 100%;
                max-width: 100%;
                width: 100%;
                bottom: unset;
                top: 310px;
              }

              @include media-breakpoint-for(tab) {
                -webkit-flex: 0 0 100%;
                flex: 0 0 100%;
                max-width: 100%;
                width: 100%;
                bottom: unset;
                top: 485px;
              }

              @include media-breakpoint-for(tab-landscape) {
                -webkit-flex: 0 0 100%;
                flex: 0 0 100%;
                max-width: 100%;
                width: 100%;
                bottom: unset;
                top: 485px;
              }

              >li {
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background-color: rgba($fff-brand-green, 0.16);
                border-top: 0px;
                border-bottom: 0px;

                @include media-breakpoint-for(mobile) {
                  width: 8px;
                  height: 8px;
                }

                @include media-breakpoint-for(tab) {
                  width: 8px;
                  height: 8px;
                }

                @include media-breakpoint-for(tab-landscape) {
                  width: 8px;
                  height: 8px;
                }

                &.active {
                  background-color: $fff-brand-green;
                }
              }
            }

            .carousel-control {
              margin: 0;
              position: absolute;
              top: 47%;

              @include media-breakpoint-for(mobile) {
                top: 125px;
              }

              @include media-breakpoint-for(tab) {
                top: 200px;
                flex: 0 0 100%;
                max-width: 100%;
              }

              @include media-breakpoint-for(tab-landscape) {
                top: 200px;
              }

              .carousel-control-prev {
                top: 5px;
                background-image: url(../../assets/img/icons/fff-chevron-white.svg);
                -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
                background-repeat: no-repeat;
                background-position: center;
                display: block;
                width: 35px;
                height: 35px;
                border-radius: 50%;
                background-color: #000;
                margin-left: 25px;
                opacity: 0.6;
                background-size: 30px;

                @include media-breakpoint-for(mobile) {
                  margin-left: 5px;
                  top: 0px;
                }

                @include media-breakpoint-for(tab) {
                  margin-left: 5px;
                  top: 0px;
                }

                @include media-breakpoint-for(tab-landscape) {
                  margin-left: 5px;
                  top: 0px;
                }

                &:hover {
                  opacity: 1;
                }

                span {
                  display: none;
                }
              }

              .carousel-control-next {
                top: 5px;
                background-image: url(../../assets/img/icons/fff-chevron-white.svg);
                -webkit-transform: rotate(-90deg);
                transform: rotate(-90deg);
                background-repeat: no-repeat;
                background-position: center;
                display: block;
                width: 35px;
                height: 35px;
                border-radius: 50%;
                background-color: #000;
                margin-right: 25px;
                opacity: 0.6;
                background-size: 30px;

                @include media-breakpoint-for(mobile) {
                  margin-right: 5px;
                  top: 0px;
                }

                @include media-breakpoint-for(tab) {
                  margin-right: 5px;
                  top: 0px;
                }

                @include media-breakpoint-for(tab-landscape) {
                  margin-right: 5px;
                  top: 0px;
                }

                &:hover {
                  opacity: 1;
                }

                span {
                  display: none;
                }
              }
            }

            .carousel-inner {
              @include media-breakpoint-for(mobile) {
                height: auto;
              }

              @include media-breakpoint-for(tab) {
                height: auto;
              }

              @include media-breakpoint-for(tab-landscape) {
                height: auto;
              }

              .carousel-item {
                width: 100%;
                height: auto;
                display: inline-block;

                .carousel-image {
                  position: relative;
                  display: inline-block;
                  height: 459px;
                  margin-top: 47px;
                  margin-bottom: 47px;

                  &:hover {
                    .video-control.pause {
                      opacity: 1;
                    }
                  }

                  @include media-breakpoint-for(mobile) {
                    margin-top: 0px;
                    margin-bottom: 0px;
                    padding: 0px;
                    height: 287px;
                  }

                  @include media-breakpoint-for(tab) {
                    margin-top: 0px;
                    margin-bottom: 0px;
                    padding: 0px;
                    height: 459px;
                    width: 100%;
                    max-width: 100%;
                  }

                  @include media-breakpoint-for(tab-landscape) {
                    margin-top: 0px;
                    margin-bottom: 0px;
                    padding: 0px;
                    height: 459px;
                    width: 100%;
                    max-width: 100%;
                  }

                  &::after {
                    content: '';
                    background-image: url('../../assets/img/fff-leaf-s.svg');
                    background-repeat: no-repeat;
                    position: absolute;
                    -ms-transform: rotate(114deg);
                    -webkit-transform: rotate(114deg);
                    transform: rotate(114deg);
                    width: 140px;
                    height: 66px;
                    z-index: 1;
                    top: 3px;
                    right: -53px;
                    background-size: contain;
                    opacity: 0.8;

                    @include media-breakpoint-for(mobile) {
                      display: none;
                    }

                    @include media-breakpoint-for(tab) {
                      display: none;
                    }

                    @include media-breakpoint-for(tab-landscape) {
                      display: none;
                    }
                  }

                  .video {
                    width: 100%;
                    height: 550px;
                    position: relative;

                    #video_container {
                      &>div {
                        position: absolute;
                        width: 100% !important;
                        height: 100% !important;
                        top: 0;
                        left: 0;

                        video {
                          object-fit: cover;
                          object-position: center;
                        }
                      }
                    }
                  }

                  .video-control {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    border: 1px solid white;
                    border-radius: 50%;
                    width: 65px;
                    height: 65px;
                    padding: 0;
                    background-color: rgba($color: #000000, $alpha: .16);
                    display: grid;
                    place-content: center;
                    backdrop-filter: blur(5px);
                    z-index: 2;
                    grid-auto-flow: column;
                    gap: 8px;
                    transition: opacity 0.24s ease-in-out;

                    &.play {
                      &::before {
                        content: '';
                        display: block;
                        width: 32px;
                        height: 32px;
                        border: 16px solid transparent;
                        border-left-color: white;
                        border-left-width: 24px;
                        margin-right: -20px;
                      }
                    }

                    &.pause {
                      @media screen and (min-width: 1200px) {
                        opacity: 0;
                      }

                      &::before,
                      &::after {
                        content: '';
                        display: block;
                        width: 5px;
                        height: 24px;
                        background-color: white;
                      }
                    }
                  }

                  .slider-image {
                    width: 100%;
                    height: 100%;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: cover;

                    &.custom-img {
                      background-size: contain;
                      background-color: white;
                    }

                    @include media-breakpoint-for(mobile) {
                      width: 100%;
                      height: 100%;
                    }

                    @include media-breakpoint-for(tab) {
                      width: 100%;
                      height: 100%;
                    }

                    @include media-breakpoint-for(tab-landscape) {
                      width: 100%;
                      height: 100%;
                    }
                  }
                }

                .carousel-caption {
                  position: relative;
                  display: inline-block;
                  float: right;
                  right: unset;
                  top: unset;
                  bottom: unset;
                  left: unset;
                  padding-top: 0;
                  padding-bottom: 0;

                  @include media-breakpoint-for(mobile) {
                    padding: 0px;
                    margin-top: -6px;
                  }

                  @include media-breakpoint-for(tab) {
                    padding: 0px;
                    margin-top: -6px;
                    width: 100%;
                    max-width: 100%;
                  }

                  @include media-breakpoint-for(tab-landscape) {
                    padding: 0px;
                    margin-top: -6px;
                    width: 100%;
                    max-width: 100%;
                  }

                  .text-left {
                    text-align: center;

                    @include media-breakpoint-for(mobile) {
                      padding: 60px 30px 10px;
                      background-color: $fff-brand-new-fill;
                      /* For browsers that do not support gradients */
                      background-image: linear-gradient($fff-brand-new-fill, white);
                      background-image: -moz-linear-gradient($fff-brand-new-fill, white);
                      /* FF3.6-15 */
                      background-image: -webkit-linear-gradient($fff-brand-new-fill, white);
                      /* Chrome10-25,Safari5.1-6 */
                      background-image: linear-gradient($fff-brand-new-fill, white);
                      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$fff-brand-new-fill', endColorstr='white', GradientType=0);
                      /* IE6-9 */
                    }

                    @include media-breakpoint-for(tab) {
                      padding: 60px 30px 10px;
                      background-color: $fff-brand-new-fill;
                      /* For browsers that do not support gradients */
                      background-image: linear-gradient($fff-brand-new-fill, white);
                      background-image: -moz-linear-gradient($fff-brand-new-fill, white);
                      /* FF3.6-15 */
                      background-image: -webkit-linear-gradient($fff-brand-new-fill, white);
                      /* Chrome10-25,Safari5.1-6 */
                      background-image: linear-gradient($fff-brand-new-fill, white);
                      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$fff-brand-new-fill', endColorstr='white', GradientType=0);
                      /* IE6-9 */
                    }

                    @include media-breakpoint-for(tab-landscape) {
                      padding: 60px 30px 10px;
                      background-color: $fff-brand-new-fill;
                      /* For browsers that do not support gradients */
                      background-image: linear-gradient($fff-brand-new-fill, white);
                      background-image: -moz-linear-gradient($fff-brand-new-fill, white);
                      /* FF3.6-15 */
                      background-image: -webkit-linear-gradient($fff-brand-new-fill, white);
                      /* Chrome10-25,Safari5.1-6 */
                      background-image: linear-gradient($fff-brand-new-fill, white);
                      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$fff-brand-new-fill', endColorstr='white', GradientType=0);
                      /* IE6-9 */
                    }

                    ul {
                      padding-left: 0px;
                      margin: 0 auto;
                      width: 270px;

                      @include media-breakpoint-for(mobile) {
                        width: 205px;
                      }

                      @include media-breakpoint-for(tab) {
                        width: 205px;
                      }

                      @include media-breakpoint-for(tab-landscape) {
                        width: 205px;
                      }

                      li {
                        list-style: none;
                        font-family: 'SoleilLt', sans-serif;
                        background-image: url('../../assets/img/fff-bullet-r-green.svg');
                        background-repeat: no-repeat;
                        background-position: left;
                        background-size: 37px;
                        padding-left: 45px;
                        font-size: 23px;
                        font-weight: 400;
                        color: $fff-brand-grey;
                        margin-bottom: 25px;
                        text-align: left;

                        @include media-breakpoint-for(mobile) {
                          background-size: 24px;
                          padding-left: 30px;
                          font-size: 18px;
                          margin-bottom: 8px;
                          background-position: left 0px top 5px;
                        }

                        @include media-breakpoint-for(tab) {
                          background-size: 24px;
                          padding-left: 30px;
                          font-size: 18px;
                          margin-bottom: 8px;
                          background-position: left 0px top 5px;
                        }

                        @include media-breakpoint-for(tab-landscape) {
                          background-size: 24px;
                          padding-left: 30px;
                          font-size: 18px;
                          margin-bottom: 8px;
                          background-position: left 0px top 5px;
                        }
                      }
                    }

                    h1 {
                      font-family: $fff-font-serif;
                      font-weight: 400;
                      font-size: 48px;
                      padding-top: 30px;
                      width: 100%;
                      margin-top: 47px;
                      margin-bottom: 36px;
                      line-height: 55px;
                      text-align: center;

                      @include media-breakpoint-for(mobile) {
                        font-size: 34px;
                        margin-right: 0px;
                        padding-top: 0px;
                        margin-top: 0px;
                        margin-bottom: 20px;
                        background-size: 142px;
                        position: relative;
                        width: 100%;
                        background-position: right 33px top 0px;
                        line-height: 40px;
                      }

                      @include media-breakpoint-for(tab) {
                        font-size: 34px;
                        margin-right: 0px;
                        padding-top: 0px;
                        margin-top: 0px;
                        margin-bottom: 20px;
                        background-size: 142px;
                        position: relative;
                        width: 100%;
                        background-position: right 33px top 0px;
                        line-height: 40px;
                      }

                      @include media-breakpoint-for(tab-landscape) {
                        font-size: 34px;
                        margin-right: 0px;
                        padding-top: 0px;
                        margin-top: 0px;
                        margin-bottom: 20px;
                        background-size: 142px;
                        position: relative;
                        width: 100%;
                        background-position: right 33px top 0px;
                        line-height: 40px;
                      }
                    }

                    h2 {
                      font-family: 'DINPro-Bold', sans-serif;
                      font-size: 38px;
                      padding-top: 30px;
                      width: 100%;
                      margin-top: 47px;
                      margin-bottom: 36px;
                      line-height: 45px;
                      text-align: center;
                      color: black;

                      @include media-breakpoint-for(mobile) {
                        font-size: 24px;
                        margin-right: 0px;
                        padding-top: 0px;
                        margin-top: 0px;
                        margin-bottom: 20px;
                        background-size: 142px;
                        position: relative;
                        width: 100%;
                        background-position: right 33px top 0px;
                        line-height: 30px;
                      }

                      @include media-breakpoint-for(tab) {
                        font-size: 24px;
                        margin-right: 0px;
                        padding-top: 0px;
                        margin-top: 0px;
                        margin-bottom: 20px;
                        background-size: 142px;
                        position: relative;
                        width: 100%;
                        background-position: right 33px top 0px;
                        line-height: 30px;
                      }

                      @include media-breakpoint-for(tab-landscape) {
                        font-size: 24px;
                        margin-right: 0px;
                        padding-top: 0px;
                        margin-top: 0px;
                        margin-bottom: 20px;
                        background-size: 142px;
                        position: relative;
                        width: 100%;
                        background-position: right 33px top 0px;
                        line-height: 30px;
                      }
                    }

                    .custom-height-middle {
                      height: 380px;
                      margin-top: 45px;
                      position: relative;

                      @include media-breakpoint-for(mobile) {
                        height: 255px;
                        margin-top: 0px;
                      }

                      @include media-breakpoint-for(tab) {
                        height: 110px;
                        margin-top: 0px;
                      }

                      @include media-breakpoint-for(tab-landscape) {
                        height: 110px;
                        margin-top: 0px;
                      }

                      p {
                        margin: 0;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 100%;
                      }
                    }

                    p {
                      font-size: 18px;
                      text-align: center;
                    }

                    .header-slider-preferences {
                      &:first-of-type {
                        margin-bottom: 10px;
                      }

                      .slider-preferences-col {
                        text-align: center;

                        @include media-breakpoint-for(mobile) {
                          flex: 0 0 33.33333%;
                          max-width: 33.33333%;
                        }

                        @include media-breakpoint-for(tab) {
                          flex: 0 0 33.33333%;
                          max-width: 33.33333%;
                        }

                        @include media-breakpoint-for(tab-landscape) {
                          flex: 0 0 33.33333%;
                          max-width: 33.33333%;
                        }

                        img {
                          height: 40px;
                          display: block;
                          margin: 0 auto 5px;
                        }

                        span {
                          width: 100%;
                          display: block;
                          font-size: 18px;
                          color: black;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .right-btn-row {
          position: absolute;
          right: 0px;
          bottom: 77px;
          padding: 0px;
          z-index: 1;
          padding-left: 15px;
          text-align: center;

          @include media-breakpoint-for(mobile) {
            position: relative;
            bottom: unset;
            padding: 15px 30px 0;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          @include media-breakpoint-for(tab) {
            position: relative;
            bottom: unset;
            padding: 15px 30px 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            max-width: 100%;
            margin: 0 auto;
          }

          @include media-breakpoint-for(tab-landscape) {
            position: relative;
            bottom: unset;
            padding: 15px 30px 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            max-width: 100%;
            margin: 0 auto;
          }

          button {
            margin-right: 15px;
            box-shadow: none;
            vertical-align: top;

            @include media-breakpoint-for(mobile) {
              padding: 0px 20px;
              width: 100%;
              margin: 0px 2px;
              min-width: unset;
            }

            @include media-breakpoint-for(tab) {
              padding: 0px 20px;
              width: 100%;
              margin: 0px 10px;
              min-width: 200px;
            }

            @include media-breakpoint-for(tab-landscape) {
              padding: 0px 20px;
              width: 100%;
              margin: 0px 10px;
              min-width: 200px;
            }

            &.btn-center {
              margin: 0 auto;
              float: none;
            }
          }

          a {
            color: #066d38 !important;
            border: 2px solid rgba($fff-brand-green, 0.18);
            background-color: #ffffff;
            padding: 16px 41px 16px;
            height: 56px;
            font-size: 18px;
            line-height: 18px;
            border-radius: 30px;
            font-family: 'Soleil-Bold', sans-serif;
            text-align: center;
            margin: 0 auto;

            &:not(:disabled):not(.disabled):active,
            &:focus,
            &:hover {
              color: $fff-brand-green;
              border-color: #d1e5da;
              background: #d1e5da;
            }

            @include media-breakpoint-for(mobile) {
              float: unset;
              vertical-align: middle;
              font-size: 14px;
              padding: 0px 26px;
              line-height: 17px;
              height: 40px;
              line-height: 40px;
              width: 100%;
              margin: 0px 2px;
            }

            @include media-breakpoint-for(tab) {
              float: unset;
              vertical-align: middle;
              font-size: 14px;
              padding: 0px 26px;
              line-height: 17px;
              height: 40px;
              line-height: 40px;
              width: 100%;
              margin: 0px 10px;
              min-width: 200px;
            }

            @include media-breakpoint-for(tab-landscape) {
              float: unset;
              vertical-align: middle;
              font-size: 14px;
              padding: 0px 26px;
              line-height: 17px;
              height: 40px;
              line-height: 40px;
              width: 100%;
              margin: 0px 10px;
              min-width: 200px;
            }
          }
        }
      }

      .scroll-down-row {
        margin: 0px;

        .scroll-down-icon {
          margin: 0 auto;
          position: relative;
          display: block;
          width: auto;
          height: 40px;
          color: rgba($fff-brand-grey, 0.36);
          padding-top: 20px;
          text-align: center;
          cursor: pointer;

          i {
            font-size: 35px;
          }
        }
      }

      .review-row {
        margin: 0px;

        .review-content-row {
          padding: 0px 15px;
          text-align: center;

          p {
            font-size: 18px;
            margin: 0px;
            display: inline-block;
          }

          .star-row {
            display: inline-block;
            margin: 0px 10px;

            .star-rating-medium {
              width: 108px;
              display: flex;

              .star-item {
                background-color: #00b67a;
                width: 20px;
                height: 20px;

                &:not(:last-of-type) {
                  margin-right: 2px;
                }

                img {
                  width: 100%;
                  height: 100%;
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }

  .grey-backgrund-row {
    .custom-container {
      .review-row {
        background-color: $fff-brand-new-light;
        padding: 30px 0 60px 0;

        @include media-breakpoint-for(mobile) {
          padding: 30px 0 30px 0;
        }
      }
    }
  }

  .logo-carousel-container {
    background-color: $fff-brand-new-light;
  }

  .white-backgrund-row {
    .custom-container {
      padding: 0;

      @include media-breakpoint-for(mobile) {
        padding: 0;
      }

      @include media-breakpoint-for(tab) {
        padding: 0;
      }

      @include media-breakpoint-for(tab-landscape) {
        padding: 0;
      }

      // Home Testimonials section
      .slide-row {
        position: relative;
        margin: 0px 15px;
        margin-top: 90px;

        @include media-breakpoint-for(mobile) {
          margin-top: 60px;
          margin-left: 0px;
          margin-right: 0px;
          margin-bottom: 0px;
        }

        @include media-breakpoint-for(tab) {
          margin-top: 60px;
          margin-left: 0px;
          margin-right: 0px;
          margin-bottom: 0px;
        }

        @include media-breakpoint-for(tab-landscape) {
          margin-top: 60px;
          margin-left: 0px;
          margin-right: 0px;
          margin-bottom: 0px;
        }

        .slider {
          height: 100%;
          margin: 0 auto;
          padding: 0px;

          @include media-breakpoint-for(mobile) {
            background-color: rgba($fff-brand-grey, 0.16);
          }

          @include media-breakpoint-for(tab) {
            background-color: rgba($fff-brand-grey, 0.16);
            max-width: 100%;
            flex: 0 0 100%;
          }

          @include media-breakpoint-for(tab-landscape) {
            background-color: rgba($fff-brand-grey, 0.16);
            max-width: 100%;
            flex: 0 0 100%;
          }

          .carousel {
            height: 100%;

            &.slide {
              height: 100%;

              .carousel-indicators {
                left: unset;
                right: unset;
                bottom: 0px;
                margin: 0;
                -webkit-flex: 0 0 58.33333%;
                flex: 0 0 58.33333%;
                max-width: 58.33333%;
                width: 58.33333%;

                @include media-breakpoint-for(mobile) {
                  -webkit-flex: 0 0 100%;
                  flex: 0 0 100%;
                  max-width: 100%;
                  width: 100%;
                  bottom: 10px;
                }

                @include media-breakpoint-for(tab) {
                  -webkit-flex: 0 0 100%;
                  flex: 0 0 100%;
                  max-width: 100%;
                  width: 100%;
                  bottom: 10px;
                }

                @include media-breakpoint-for(tab-landscape) {
                  -webkit-flex: 0 0 100%;
                  flex: 0 0 100%;
                  max-width: 100%;
                  width: 100%;
                  bottom: 10px;
                }

                >li {
                  width: 12px;
                  height: 12px;
                  border-radius: 50%;
                  border: 2px solid #fff;
                  background-color: transparent;

                  @include media-breakpoint-for(mobile) {
                    width: 8px;
                    height: 8px;
                  }

                  @include media-breakpoint-for(tab) {
                    width: 8px;
                    height: 8px;
                  }

                  @include media-breakpoint-for(tab-landscape) {
                    width: 8px;
                    height: 8px;
                  }

                  &.active {
                    background-color: rgba($fff-brand-grey, 0.36);
                  }
                }

                &.customIndicators {
                  left: unset;
                  right: unset;
                  bottom: 94px;
                  margin: 0;
                  -webkit-flex: 0 0 10%;
                  flex: 0 0 10%;
                  max-width: 10%;
                  width: 10%;
                  position: absolute;
                  left: 26%;
                  transform: translate(-26%, 0%);
                  z-index: 15;
                  display: -webkit-flex;
                  display: flex;
                  -webkit-justify-content: center;
                  justify-content: center;
                  list-style: none;

                  li {
                    width: 8px;
                    height: 8px;
                  }

                  @include media-breakpoint-for(mobile) {
                    bottom: 24px;
                    -webkit-flex: 0 0 100%;
                    -webkit-flex: 0 0 100%;
                    flex: 0 0 100%;
                    max-width: 100%;
                    width: 100%;
                  }

                  @include media-breakpoint-for(tab) {
                    bottom: 24px;
                    -webkit-flex: 0 0 100%;
                    -webkit-flex: 0 0 100%;
                    flex: 0 0 100%;
                    max-width: 100%;
                    width: 100%;
                  }

                  @include media-breakpoint-for(tab-landscape) {
                    bottom: 24px;
                    -webkit-flex: 0 0 100%;
                    -webkit-flex: 0 0 100%;
                    flex: 0 0 100%;
                    max-width: 100%;
                    width: 100%;
                  }
                }
              }

              .carousel-control {
                margin: 0;
                -webkit-flex: 0 0 13%;
                flex: 0 0 13%;
                max-width: 13%;
                width: 13%;
                position: absolute;
                bottom: 106px;
                left: 25%;
                transform: translate(-25%, 0%);
                z-index: 1;

                @include media-breakpoint-for(mobile) {
                  display: none;
                }

                @include media-breakpoint-for(tab) {
                  display: none;
                }

                @include media-breakpoint-for(tab-landscape) {
                  display: none;
                }

                .carousel-control-prev {
                  color: $fff-brand-grey;
                  opacity: 1;
                  top: -9px;
                  background-image: url(../../assets/img/icons/arrow_left-grey.svg);
                  background-repeat: no-repeat;
                  background-position: center;
                  display: block;
                  background-size: 15px;
                  width: 20px;
                  height: 30px;
                  margin-right: 5px;
                  opacity: 0.3;
                  z-index: 10000;

                  span {
                    display: none;
                  }
                }

                .carousel-control-next {
                  color: $fff-brand-grey;
                  opacity: 1;
                  top: -9px;
                  background-image: url(../../assets/img/icons/arrow_right-grey.svg);
                  background-repeat: no-repeat;
                  background-position: center;
                  display: block;
                  background-size: 15px;
                  width: 20px;
                  height: 30px;
                  margin-left: 5px;
                  opacity: 0.3;
                  z-index: 10000;

                  span {
                    display: none;
                  }
                }
              }

              .carousel-inner {
                overflow: visible;

                .carousel-item {
                  padding: 60px 0px;

                  @include media-breakpoint-for(mobile) {
                    padding: 0px;
                  }

                  @include media-breakpoint-for(tab) {
                    padding: 0px;
                  }

                  @include media-breakpoint-for(tab-landscape) {
                    padding: 0px;
                  }

                  .carousel-grey-row {
                    background-color: rgba($fff-brand-grey, 0.16);
                    padding: 40px 0px;
                    margin: 0px;
                    height: 255px;

                    @include media-breakpoint-for(mobile) {
                      background-color: transparent;
                      padding: 16px 16px 24px;
                      height: 260px;

                      .col-md-1 {
                        display: none;
                      }
                    }

                    @include media-breakpoint-for(tab) {
                      background-color: transparent;
                      padding: 16px 16px 24px;
                      height: 195px;

                      .col-md-1 {
                        display: none;
                      }
                    }

                    @include media-breakpoint-for(tab-landscape) {
                      background-color: transparent;
                      padding: 16px 16px 24px;
                      height: 195px;

                      .col-md-1 {
                        display: none;
                      }
                    }

                    &::before {
                      content: '';
                      background-image: url('../../assets/img/fff-leaf-ml.svg');
                      background-repeat: no-repeat;
                      background-position: top left;
                      -webkit-transform: rotate(90deg);
                      transform: rotate(90deg);
                      position: absolute;
                      z-index: 1;
                      background-size: contain;
                      width: 35px;
                      height: 18px;
                      top: -5px;
                      left: 5px;

                      @media screen and (min-width: 1024px) {
                        width: 50px;
                        height: 27px;
                        top: 50px;
                        left: 25px;
                      }
                    }

                    .carousel-captions {
                      text-align: center;
                      float: left;
                      position: relative;
                      margin-bottom: 10px;
                      padding: 0px 32px;

                      @media screen and (min-width: 990px) {
                        padding: 0px 32px;
                        max-width: 100%;
                        flex: 0 0 100%;
                      }

                      .text-center {
                        margin-bottom: 10px;
                        height: auto;
                      }

                      p {
                        font-family: 'SoleilLt', sans-serif;
                        color: #000;
                        margin-bottom: 5px;
                      }

                      h5 {
                        font-family: 'SoleilLt', sans-serif;
                        color: $fff-brand-green;
                        margin-bottom: 11px;

                        @include media-breakpoint-for(mobile) {
                          margin-bottom: 24px;
                        }

                        @include media-breakpoint-for(tab) {
                          margin-bottom: 24px;
                        }

                        @include media-breakpoint-for(tab-landscape) {
                          margin-bottom: 24px;
                        }
                      }
                    }
                  }

                  .carousel-image {
                    text-align: left;
                    display: inline-block;
                    position: absolute;
                    top: 0px;
                    height: 100%;
                    right: 0px;

                    @include media-breakpoint-for(mobile) {
                      text-align: center;
                      position: relative;
                    }

                    @include media-breakpoint-for(tab) {
                      text-align: center;
                      position: relative;
                      max-width: 100%;
                      flex: 0 0 100%;
                    }

                    @include media-breakpoint-for(tab-landscape) {
                      text-align: center;
                      position: relative;
                      max-width: 100%;
                      flex: 0 0 100%;
                    }

                    .slider-image {
                      background-size: contain;
                      background-repeat: no-repeat;
                      width: 100%;
                      height: 100%;
                      position: relative;

                      @include media-breakpoint-for(mobile) {
                        position: relative;
                        top: 0;
                        -webkit-transform: none;
                        -ms-transform: none;
                        transform: none;
                        background-size: contain;
                        background-repeat: no-repeat;
                        width: 100%;
                        height: 200px;
                        display: block;
                        max-height: unset;
                        margin: -20px auto 0px;
                        background-position: center;
                      }

                      @include media-breakpoint-for(tab) {
                        position: relative;
                        top: 0;
                        -webkit-transform: none;
                        -ms-transform: none;
                        transform: none;
                        background-size: contain;
                        background-repeat: no-repeat;
                        width: 100%;
                        height: 320px;
                        display: block;
                        max-height: unset;
                        margin: -20px auto 0px;
                        background-position: center;
                      }

                      @include media-breakpoint-for(tab-landscape) {
                        position: relative;
                        top: 0;
                        -webkit-transform: none;
                        -ms-transform: none;
                        transform: none;
                        background-size: contain;
                        background-repeat: no-repeat;
                        width: 100%;
                        height: 320px;
                        display: block;
                        max-height: unset;
                        margin: -20px auto 0px;
                        background-position: center;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .video-row {
        padding-top: 60px;
        margin: 0px 15px;

        @include media-breakpoint-for(mobile) {
          margin: 0px;
        }

        @include media-breakpoint-for(tab) {
          margin: 0px;
        }

        @include media-breakpoint-for(tab-landscape) {
          margin: 0px;
        }

        h1 {
          font-size: 42px;
          text-align: center;
          font-family: 'DINPro-Bold', sans-serif;

          @include media-breakpoint-for(mobile) {
            font-size: 28px;
            line-height: 30px;
          }

          @include media-breakpoint-for(tab) {
            font-size: 28px;
            line-height: 30px;
          }

          @include media-breakpoint-for(tab-landscape) {
            font-size: 28px;
            line-height: 30px;
          }
        }

        a {
          color: $fff-brand-green;
          font-size: 16px;
          font-family: 'SoleilLt', sans-serif;
          display: block;
          text-align: center;
          margin: 40px 0px 40px;

          @include media-breakpoint-for(mobile) {
            margin: 30px 0px 30px;
          }

          @include media-breakpoint-for(tab) {
            margin: 30px 0px 30px;
          }

          @include media-breakpoint-for(tab-landscape) {
            margin: 30px 0px 30px;
          }
        }

        .video-container {
          position: relative;
          width: 100%;

          @include media-breakpoint-for(mobile) {
            height: auto;
            padding: 0px;
          }

          @include media-breakpoint-for(tab) {
            height: auto;
            padding: 0px;
          }

          @include media-breakpoint-for(tab-landscape) {
            height: auto;
            padding: 0px;
          }

          .video {
            position: relative;
            z-index: 2;

            .rh5v-Overlay_inner,
            .rh5v-Overlay_component {
              display: none;
            }

            #video_container {
              >div {
                height: 100% !important;
                width: 100% !important;

                .embed-responsive video {
                  @include media-breakpoint-for(mobile) {
                    height: 100% !important;
                    width: 100% !important;
                  }

                  @include media-breakpoint-for(tab) {
                    height: 420px !important;
                  }

                  @include media-breakpoint-for(tab-landscape) {
                    height: 420px !important;
                  }
                }
              }
            }
          }

          .text-main {
            position: absolute;
            z-index: 3;
            margin: 0 auto;
            padding: 0px;
            left: 0px;
            right: 0px;
            top: 60px;
            bottom: 60px;

            @include media-breakpoint-for(mobile) {
              top: 0px;
              position: relative;
              padding: 0px 15px 0px;
            }

            @include media-breakpoint-for(tab) {
              top: 0px;
              position: relative;
              padding: 0px 15px 0px;
              width: 100%;
              max-width: 100%;
            }

            @include media-breakpoint-for(tab-landscape) {
              top: 0px;
              position: relative;
              padding: 0px 15px 0px;
              width: 100%;
              max-width: 100%;
            }

            .inside-text-wrap {
              position: relative;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              margin: 0px;
              padding: 0px;

              @media screen and (max-width: 1200px) {
                background: none;
                box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
                padding: 0px 1px;
              }

              .left-col {
                position: relative;
                padding: 40px;
                background: rgba(255, 255, 255, 0.89);
                background: -moz-linear-gradient(left,
                    rgba(255, 255, 255, 0.89) 0%,
                    rgba(255, 255, 255, 0.89) 76%,
                    rgba(255, 255, 255, 0) 100%);
                background: -webkit-gradient(left top,
                    right top,
                    color-stop(0%, rgba(255, 255, 255, 0.89)),
                    color-stop(76%, rgba(255, 255, 255, 0.89)),
                    color-stop(100%, rgba(255, 255, 255, 0)));
                background: -webkit-linear-gradient(left,
                    rgba(255, 255, 255, 0.89) 0%,
                    rgba(255, 255, 255, 0.89) 76%,
                    rgba(255, 255, 255, 0) 100%);
                background: -o-linear-gradient(left,
                    rgba(255, 255, 255, 0.89) 0%,
                    rgba(255, 255, 255, 0.89) 76%,
                    rgba(255, 255, 255, 0) 100%);
                background: -ms-linear-gradient(left,
                    rgba(255, 255, 255, 0.89) 0%,
                    rgba(255, 255, 255, 0.89) 76%,
                    rgba(255, 255, 255, 0) 100%);
                background: linear-gradient(to right,
                    rgba(255, 255, 255, 0.89) 0%,
                    rgba(255, 255, 255, 0.89) 76%,
                    rgba(255, 255, 255, 0) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1);

                @include media-breakpoint-for(mobile) {
                  background: white;
                  float: left;
                  padding: 16px;
                }

                @include media-breakpoint-for(tab) {
                  background: white;
                  float: left;
                  padding: 16px;
                  width: 100%;
                  max-width: 100%;
                  flex: 0 0 100%;
                }

                @include media-breakpoint-for(tab-landscape) {
                  background: white;
                  float: left;
                  padding: 16px;
                  width: 100%;
                  max-width: 100%;
                  flex: 0 0 100%;
                }

                .work-list-wrap {
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  -ms-transform: translateY(-50%);
                  -webkit-transform: translateY(-50%);

                  @include media-breakpoint-for(mobile) {
                    position: relative;
                    top: unset;
                    transform: unset;
                    -ms-transform: unset;
                    -webkit-transform: unset;
                  }

                  @include media-breakpoint-for(tab) {
                    position: relative;
                    top: unset;
                    transform: unset;
                    -ms-transform: unset;
                    -webkit-transform: unset;
                  }

                  @include media-breakpoint-for(tab-landscape) {
                    position: relative;
                    top: unset;
                    transform: unset;
                    -ms-transform: unset;
                    -webkit-transform: unset;
                  }

                  .work-list {
                    display: inline-block;
                    position: relative;
                    float: left;
                    margin-bottom: 20px;
                    width: 100%;
                    text-transform: none;

                    &:last-of-type {
                      margin-bottom: 0px;
                    }

                    .img-container {
                      display: inline-block;
                      margin-bottom: 0px;
                      margin-right: 24px;
                      width: 90px;
                      height: 90px;
                      border-radius: 50%;
                      background-color: rgba($fff-brand-grey, 0.08);
                      position: relative;

                      .number-tag {
                        background-color: #f5ec3f;
                        color: $fff-brand-green;
                        width: 36px;
                        height: 36px;
                        line-height: 36px;
                        font-size: 20px;
                        font-family: 'DINPro-Bold', sans-serif;
                        text-align: center;
                        position: absolute;
                        display: block;
                        border-radius: 50%;
                        right: -8px;
                        z-index: 1;
                      }

                      img {
                        display: block;
                        background-size: cover;
                        top: 50%;
                        left: 50%;
                        -webkit-transform: translate(-50%, -50%);
                        transform: translate(-50%, -50%);
                        position: absolute;
                        width: 50px;
                        height: 50px;
                      }
                    }

                    span {
                      display: inline-block;
                      vertical-align: middle;
                      font-size: 20px;
                      font-family: 'DINPro-Bold', sans-serif;
                      color: $fff-brand-grey;
                      width: 60%;

                      @media screen and (max-width: 1200px) {
                        font-size: 18px;
                        width: 62%;
                      }
                    }
                  }
                }
              }

              .right-col-mobile {
                position: absolute;
                max-width: 100%;
                z-index: 10000;
                bottom: -100px;
                height: 100px;
                display: none;

                @include media-breakpoint-for(mobile) {
                  display: block;
                  background: rgba(255, 255, 255, 0.89);
                  background: -moz-linear-gradient(top,
                      rgba(255, 255, 255, 0.89) 0%,
                      rgba(255, 255, 255, 0.89) 2%,
                      rgba(255, 255, 255, 0) 50%);
                  background: -webkit-gradient(left top,
                      left bottom,
                      color-stop(0%, rgba(255, 255, 255, 0.89)),
                      color-stop(2%, rgba(255, 255, 255, 0.89)),
                      color-stop(50%, rgba(255, 255, 255, 0)));
                  background: -webkit-linear-gradient(top,
                      rgba(255, 255, 255, 0.89) 0%,
                      rgba(255, 255, 255, 0.89) 2%,
                      rgba(255, 255, 255, 0) 50%);
                  background: -o-linear-gradient(top,
                      rgba(255, 255, 255, 0.89) 0%,
                      rgba(255, 255, 255, 0.89) 2%,
                      rgba(255, 255, 255, 0) 50%);
                  background: -ms-linear-gradient(top,
                      rgba(255, 255, 255, 0.89) 0%,
                      rgba(255, 255, 255, 0.89) 2%,
                      rgba(255, 255, 255, 0) 50%);
                  background: linear-gradient(to bottom,
                      rgba(255, 255, 255, 0.89) 0%,
                      rgba(255, 255, 255, 0.89) 2%,
                      rgba(255, 255, 255, 0) 50%);
                  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0);
                }
              }

              .right-col {
                position: relative;

                @include media-breakpoint-for(mobile) {
                  width: 100%;
                  max-width: 100%;
                  flex: 0 0 100%;
                }

                @include media-breakpoint-for(tab) {
                  width: 100%;
                  max-width: 100%;
                  flex: 0 0 100%;
                }

                @include media-breakpoint-for(tab-landscape) {
                  width: 100%;
                  max-width: 100%;
                  flex: 0 0 100%;
                }

                &::before {
                  content: '';
                  width: 33px;
                  height: 33px;
                  display: block;
                  position: absolute;
                  background-image: url('../../assets/img/box-corner.png');
                  background-repeat: no-repeat;
                  background-position: top right;
                  top: 0;
                  right: 0;
                  background-size: contain;
                  z-index: 1;

                  @media screen and (max-width: 1200px) {
                    -webkit-transform: rotate(180deg);
                    transform: rotate(180deg);
                    background-position: bottom left;
                    top: unset;
                    right: unset;
                    bottom: 0;
                    left: 0;
                  }
                }

                &::after {
                  content: '';
                  width: 33px;
                  height: 33px;
                  display: block;
                  position: absolute;
                  background-image: url('../../assets/img/box-corner.png');
                  background-repeat: no-repeat;
                  background-position: top right;
                  -webkit-transform: rotate(90deg);
                  transform: rotate(90deg);
                  bottom: 0;
                  right: 0;
                  background-size: contain;
                  z-index: 1;
                }

                @include media-breakpoint-for(mobile) {
                  position: absolute;
                  z-index: 10000;
                  bottom: -200px;
                  height: 35px;
                  background: transparent;
                  // background: rgba(255,255,255,0.89);
                  // background: -moz-linear-gradient(top, rgba(255,255,255,0.89) 0%, rgba(255,255,255,0.89) 2%, rgba(255,255,255,0) 50%);
                  // background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,0.89)), color-stop(2%, rgba(255,255,255,0.89)), color-stop(50%, rgba(255,255,255,0)));
                  // background: -webkit-linear-gradient(top, rgba(255,255,255,0.89) 0%, rgba(255,255,255,0.89) 2%, rgba(255,255,255,0) 50%);
                  // background: -o-linear-gradient(top, rgba(255,255,255,0.89) 0%, rgba(255,255,255,0.89) 2%, rgba(255,255,255,0) 50%);
                  // background: -ms-linear-gradient(top, rgba(255,255,255,0.89) 0%, rgba(255,255,255,0.89) 2%, rgba(255,255,255,0) 50%);
                  // background: linear-gradient(to bottom, rgba(255,255,255,0.89) 0%, rgba(255,255,255,0.89) 2%, rgba(255,255,255,0) 50%);
                  // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0 );
                }

                @include media-breakpoint-for(tab) {
                  position: absolute;
                  z-index: 10000;
                  bottom: -416px;
                  height: 416px;
                  background: rgba(255, 255, 255, 0.89);
                  background: -moz-linear-gradient(top,
                      rgba(255, 255, 255, 0.89) 0%,
                      rgba(255, 255, 255, 0.89) 2%,
                      rgba(255, 255, 255, 0) 50%);
                  background: -webkit-gradient(left top,
                      left bottom,
                      color-stop(0%, rgba(255, 255, 255, 0.89)),
                      color-stop(2%, rgba(255, 255, 255, 0.89)),
                      color-stop(50%, rgba(255, 255, 255, 0)));
                  background: -webkit-linear-gradient(top,
                      rgba(255, 255, 255, 0.89) 0%,
                      rgba(255, 255, 255, 0.89) 2%,
                      rgba(255, 255, 255, 0) 50%);
                  background: -o-linear-gradient(top,
                      rgba(255, 255, 255, 0.89) 0%,
                      rgba(255, 255, 255, 0.89) 2%,
                      rgba(255, 255, 255, 0) 50%);
                  background: -ms-linear-gradient(top,
                      rgba(255, 255, 255, 0.89) 0%,
                      rgba(255, 255, 255, 0.89) 2%,
                      rgba(255, 255, 255, 0) 50%);
                  background: linear-gradient(to bottom,
                      rgba(255, 255, 255, 0.89) 0%,
                      rgba(255, 255, 255, 0.89) 2%,
                      rgba(255, 255, 255, 0) 50%);
                  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0);
                }

                @include media-breakpoint-for(tab-landscape) {
                  position: absolute;
                  z-index: 10000;
                  bottom: -560px;
                  height: 560px;
                  background: rgba(255, 255, 255, 0.89);
                  background: -moz-linear-gradient(top,
                      rgba(255, 255, 255, 0.89) 0%,
                      rgba(255, 255, 255, 0.89) 2%,
                      rgba(255, 255, 255, 0) 50%);
                  background: -webkit-gradient(left top,
                      left bottom,
                      color-stop(0%, rgba(255, 255, 255, 0.89)),
                      color-stop(2%, rgba(255, 255, 255, 0.89)),
                      color-stop(50%, rgba(255, 255, 255, 0)));
                  background: -webkit-linear-gradient(top,
                      rgba(255, 255, 255, 0.89) 0%,
                      rgba(255, 255, 255, 0.89) 2%,
                      rgba(255, 255, 255, 0) 50%);
                  background: -o-linear-gradient(top,
                      rgba(255, 255, 255, 0.89) 0%,
                      rgba(255, 255, 255, 0.89) 2%,
                      rgba(255, 255, 255, 0) 50%);
                  background: -ms-linear-gradient(top,
                      rgba(255, 255, 255, 0.89) 0%,
                      rgba(255, 255, 255, 0.89) 2%,
                      rgba(255, 255, 255, 0) 50%);
                  background: linear-gradient(to bottom,
                      rgba(255, 255, 255, 0.89) 0%,
                      rgba(255, 255, 255, 0.89) 2%,
                      rgba(255, 255, 255, 0) 50%);
                  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0);
                }

                @include media-breakpoint-for(ipad-pro-portrait) {
                  position: absolute;
                  z-index: 10000;
                  bottom: -555px;
                  height: 555px;
                  background: rgba(255, 255, 255, 0.89);
                  background: -moz-linear-gradient(top,
                      rgba(255, 255, 255, 0.89) 0%,
                      rgba(255, 255, 255, 0.89) 2%,
                      rgba(255, 255, 255, 0) 50%);
                  background: -webkit-gradient(left top,
                      left bottom,
                      color-stop(0%, rgba(255, 255, 255, 0.89)),
                      color-stop(2%, rgba(255, 255, 255, 0.89)),
                      color-stop(50%, rgba(255, 255, 255, 0)));
                  background: -webkit-linear-gradient(top,
                      rgba(255, 255, 255, 0.89) 0%,
                      rgba(255, 255, 255, 0.89) 2%,
                      rgba(255, 255, 255, 0) 50%);
                  background: -o-linear-gradient(top,
                      rgba(255, 255, 255, 0.89) 0%,
                      rgba(255, 255, 255, 0.89) 2%,
                      rgba(255, 255, 255, 0) 50%);
                  background: -ms-linear-gradient(top,
                      rgba(255, 255, 255, 0.89) 0%,
                      rgba(255, 255, 255, 0.89) 2%,
                      rgba(255, 255, 255, 0) 50%);
                  background: linear-gradient(to bottom,
                      rgba(255, 255, 255, 0.89) 0%,
                      rgba(255, 255, 255, 0.89) 2%,
                      rgba(255, 255, 255, 0) 50%);
                  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0);
                }

                .play {
                  margin: 0;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  padding-left: 30px;
                  padding-right: 30px;
                  min-width: unset;
                  transform: translate(-50%, -50%);
                  outline: none;

                  @include media-breakpoint-for(mobile) {
                    top: 60%;
                    transform: translate(-50%, -60%);
                  }

                  @include media-breakpoint-for(tab) {
                    top: 60%;
                    transform: translate(-50%, -60%);
                  }

                  @include media-breakpoint-for(tab-landscape) {
                    top: 60%;
                    transform: translate(-50%, -60%);
                  }

                  span {
                    vertical-align: text-bottom;
                    display: inline-block;
                    padding-right: 2px;

                    @include media-breakpoint-for(mobile) {
                      display: none;
                    }

                    @include media-breakpoint-for(tab) {
                      display: none;
                    }

                    @include media-breakpoint-for(tab-landscape) {
                      display: none;
                    }
                  }

                  i {
                    vertical-align: middle;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .green-bg-row {
    background: $fff-brand-new-fill;
    padding: 60px 0px;

    @include media-breakpoint-for(mobile) {
      padding: 30px 0px;
    }

    @include media-breakpoint-for(tab) {
      padding: 30px 0px;
    }

    @include media-breakpoint-for(tab-landscape) {
      padding: 30px 0px;
    }

    .custom-container {
      padding: 0;

      @include media-breakpoint-for(mobile) {
        padding: 0px;
      }

      @include media-breakpoint-for(tab) {
        padding: 0px;
      }

      @include media-breakpoint-for(tab-landscape) {
        padding: 0px;
      }

      h1 {
        font-size: 42px;
        text-align: center;
        font-family: 'DINPro-Bold', sans-serif;

        @include media-breakpoint-for(mobile) {
          font-size: 28px;
        }

        @include media-breakpoint-for(tab) {
          font-size: 28px;
        }

        @include media-breakpoint-for(tab-landscape) {
          font-size: 28px;
        }
      }

      h3 {
        text-align: center;
        font-size: 28px;
        font-family: 'DINPro-Bold', sans-serif;
        display: block;
        margin-bottom: 30px;

        @include media-breakpoint-for(mobile) {
          font-size: 22px;
          margin-top: 15px;
          margin-bottom: 20px;
        }

        @include media-breakpoint-for(tab) {
          font-size: 22px;
          margin-top: 15px;
          margin-bottom: 20px;
        }

        @include media-breakpoint-for(tab-landscape) {
          font-size: 22px;
          margin-top: 15px;
          margin-bottom: 20px;
        }

        .leaf-img {
          background-image: url('../../assets/img/fff-leaf-l.svg');
          background-repeat: no-repeat;
          background-size: 90px;
          background-position: bottom 0px right 20px;

          @include media-breakpoint-for(mobile) {
            background-size: 70px;
          }

          @include media-breakpoint-for(tab) {
            background-size: 70px;
          }

          @include media-breakpoint-for(tab-landscape) {
            background-size: 70px;
          }
        }
      }

      a {
        color: $fff-brand-new-secondary;
        font-size: 16px;
        font-family: 'SoleilLt', sans-serif;
        display: block;
        text-align: center;
        margin-bottom: 30px;

        @include media-breakpoint-for(mobile) {
          margin-bottom: 20px;
        }

        @include media-breakpoint-for(tab) {
          margin-bottom: 20px;
        }

        @include media-breakpoint-for(tab-landscape) {
          margin-bottom: 20px;
        }
      }

      .book-a-call {
        box-shadow: 0px 4px 8px rgba($fff-brand-new-primary, 0.16);
        color: #fff !important;
        border: 2px solid $fff-brand-new-primary;
        background-color: $fff-brand-new-primary;
        padding: 0px 40px;
        height: 56px;
        line-height: 51px;
        font-size: 18px;
        border-radius: 30px;
        font-family: 'Soleil-Bold', sans-serif;
        text-align: center;
        margin: 0 auto;

        &:not(:disabled):not(.disabled):active,
        &:focus,
        &:hover {
          border-color: $fff-brand-dark-green;
          background: $fff-brand-dark-green;
        }

        @include media-breakpoint-for(mobile) {
          font-size: 14px;
          height: 40px;
          display: block;
          vertical-align: middle;
          padding: 0px 30px;
          line-height: 37px;
        }

        @include media-breakpoint-for(tab) {
          font-size: 14px;
          height: 40px;
          display: block;
          vertical-align: middle;
          padding: 0px 30px;
          line-height: 37px;
          width: 250px;
        }

        @include media-breakpoint-for(tab-landscape) {
          font-size: 14px;
          height: 40px;
          display: block;
          vertical-align: middle;
          padding: 0px 30px;
          line-height: 37px;
          width: 250px;
        }
      }

      .meal-text-row {
        margin: 0px auto;
        text-align: center;
      }

      .food-img-row {
        margin: 52px 0px 30px;

        @include media-breakpoint-for(mobile) {
          margin: 24px 0px 0px;
        }

        @include media-breakpoint-for(tab) {
          margin: 24px 0px 0px;
        }

        @include media-breakpoint-for(tab-landscape) {
          margin: 24px 0px 0px;
        }

        a {
          margin: 0px;
          position: relative;

          &::after {
            content: '';
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 0;
            display: block;
            background-color: $fff-brand-new-accent;
          }

          &:hover {
            &::after {
              height: 2px;
            }
          }
        }

        p {
          margin-top: 10px;
          text-align: center;
        }

        .custom-padding-right {

          @include media-breakpoint-for(tab) {
            flex: 0 0 50%;
            max-width: 50%;
          }

          @include media-breakpoint-for(tab-landscape) {
            flex: 0 0 50%;
            max-width: 50%;
          }
        }

        .custom-padding-left {
          @include media-breakpoint-for(mobile) {
            padding-left: 7.5px;

            .image-wrap {
              .tag {
                left: 2px;
              }
            }
          }

          @include media-breakpoint-for(tab) {
            padding-left: 7.5px;
            flex: 0 0 50%;
            max-width: 50%;

            .image-wrap {
              .tag {
                left: 2px;
              }
            }
          }

          @include media-breakpoint-for(tab-landscape) {
            padding-left: 7.5px;
            flex: 0 0 50%;
            max-width: 50%;

            .image-wrap {
              .tag {
                left: 2px;
              }
            }
          }
        }

        .tag {
          font-family: $fff-font-sans;
          font-weight: 700;
          font-size: 18px;
          color: #000;
        }

        .image-wrap {
          width: 100%;
          margin: 8px 0px 0px;
          display: flex;
          overflow: hidden;
          justify-content: center;
          align-items: flex-end;
          box-shadow: 5px 5px 16px rgba($fff-brand-green, 0.16);


          @include media-breakpoint-for(mobile) {
            margin: 4px 0px 15px;
          }

          @include media-breakpoint-for(tab) {
            margin: 4px 0px 15px;
          }

          @include media-breakpoint-for(tab-landscape) {
            margin: 4px 0px 15px;
          }

          .tag {
            font-family: $fff-font-sans;
            background-color: $fff-brand-yellow;
            color: $fff-brand-grey;
            font-size: 18px;
            padding: 5px 16px;
            display: block;
            position: absolute;
            top: 0px;
            left: 7px;
            margin-bottom: 0px;
            font-weight: bold;

            @include media-breakpoint-for(mobile) {
              top: 0px;
              left: 10px;
              font-size: 16px;
              margin-top: 0px;
            }

            @include media-breakpoint-for(tab) {
              top: 0px;
              left: 10px;
              font-size: 16px;
              margin-top: 0px;
            }

            @include media-breakpoint-for(tab-landscape) {
              top: 0px;
              left: 10px;
              font-size: 16px;
              margin-top: 0px;
            }
          }

          img {
            width: 100%;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }

  .faq-row {
    width: 100%;
    background: $fff-brand-light-green;
    padding: 60px 0px;
    margin: 60px 0px 0px;

    @include media-breakpoint-for(mobile) {
      margin-top: 0px;
      padding: 30px 15px;
    }

    @include media-breakpoint-for(tab) {
      margin-top: 0px;
      padding: 30px 15px;
    }

    @include media-breakpoint-for(tab-landscape) {
      margin-top: 0px;
      padding: 30px 15px;
    }

    .custom-container {
      padding: 0px 15px;

      @include media-breakpoint-for(mobile) {
        padding: 0px;
      }

      @include media-breakpoint-for(tab) {
        padding: 0px;
      }

      @include media-breakpoint-for(tab-landscape) {
        padding: 0px;
        max-width: 100%;
      }

      h1 {
        font-size: 42px;
        text-align: center;
        font-family: 'DINPro-Bold', sans-serif;

        @include media-breakpoint-for(mobile) {
          font-size: 28px;
        }

        @include media-breakpoint-for(tab) {
          font-size: 28px;
        }

        @include media-breakpoint-for(tab-landscape) {
          font-size: 28px;
        }
      }

      a {
        color: $fff-brand-green;
        font-size: 16px;
        font-family: 'SoleilLt', sans-serif;
        display: block;
        text-align: center;
      }

      .margin-top-lg {
        margin-top: 40px;

        @include media-breakpoint-for(mobile) {
          margin-top: 20px;
        }

        @include media-breakpoint-for(tab) {
          margin-top: 20px;
        }

        @include media-breakpoint-for(tab-landscape) {
          margin-top: 20px;
        }
      }
    }
  }

  .suppliers-row {
    padding-top: 60px;

    .custom-container {
      @include media-breakpoint-for(mobile) {
        padding: 0;
      }

      @include media-breakpoint-for(tab) {
        padding: 0;
      }

      @include media-breakpoint-for(tab-landscape) {
        padding: 0;
      }

      h1 {
        font-size: 42px;
        text-align: center;
        font-family: 'DINPro-Bold', sans-serif;

        @include media-breakpoint-for(mobile) {
          font-size: 28px;
        }

        @include media-breakpoint-for(tab) {
          font-size: 28px;
        }

        @include media-breakpoint-for(tab-landscape) {
          font-size: 28px;
        }
      }

      h2 {
        @include media-breakpoint-for(mobile) {
          margin-bottom: 20px;
        }

        @include media-breakpoint-for(tab) {
          margin-bottom: 20px;
        }

        @include media-breakpoint-for(tab-landscape) {
          margin-bottom: 20px;
        }
      }

      p {
        text-align: left;
      }

      .textRow {
        margin: 0px auto;
        text-align: center;

        @include media-breakpoint-for(mobile) {
          margin: 0;
        }

        @include media-breakpoint-for(tab) {
          margin: 0;
          max-width: 100%;
          flex: 0 0 100%;
        }

        @include media-breakpoint-for(tab-landscape) {
          margin: 0;
          max-width: 100%;
          flex: 0 0 100%;
        }
      }

      .supplier-img-gallery {
        display: none;

        @include media-breakpoint-for(mobile) {
          margin: 30px 0px 45px;
          display: block;
        }

        @include media-breakpoint-for(tab) {
          margin: 30px 0px 45px;
          display: block;
        }

        @include media-breakpoint-for(tab-landscape) {
          margin: 30px 0px 45px;
          display: block;
        }

        .bottom-description {
          display: none;
        }
      }

      .supplier-img-row {
        padding-bottom: 33%;
        justify-content: space-between;
        flex-wrap: wrap;
        display: flex;
        margin-bottom: 90px;
        margin-top: 60px;

        @include media-breakpoint-for(mobile) {
          padding: 0px 15px;
          margin: 26px 0px 0px;
          display: none;
        }

        @include media-breakpoint-for(tab) {
          padding: 0px 15px;
          margin: 26px 0px 0px;
          display: none;
        }

        @include media-breakpoint-for(tab-landscape) {
          padding: 0px 15px;
          margin: 26px 0px 0px;
          display: none;
        }

        .suppleir-img-col {
          width: 33%;
          height: 0;
          padding-bottom: 33%;
          padding: 0;
          position: relative;

          @include media-breakpoint-for(mobile) {
            width: 100%;
            height: auto;
            padding-bottom: 0px;
            margin-bottom: 8px;
          }

          @include media-breakpoint-for(tab) {
            width: 100%;
            height: auto;
            padding-bottom: 0px;
            margin-bottom: 8px;
          }

          @include media-breakpoint-for(tab-landscape) {
            width: 100%;
            height: auto;
            padding-bottom: 0px;
            margin-bottom: 8px;
          }

          .img-wrap {
            width: 100%;
            height: 0;
            margin: 0px;
            padding-bottom: 100%;
            position: relative;
            overflow: hidden;

            @include media-breakpoint-for(mobile) {
              height: 343px;
              padding-bottom: 0px;
            }

            @include media-breakpoint-for(tab) {
              height: 343px;
              padding-bottom: 0px;
            }

            @include media-breakpoint-for(tab-landscape) {
              height: 343px;
              padding-bottom: 0px;
            }

            &:hover {
              .img-overlay {
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                height: 100%;
                width: 100%;
                opacity: 1;
                transition: 0.5s ease;
                background-color: rgba(0, 0, 0, 0.69);
                z-index: 1;

                h3 {
                  color: white;
                  font-size: 28px;
                  position: absolute;
                  text-align: center;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  font-family: 'DINPro-Bold', sans-serif;
                }
              }
            }

            .img-overlay {
              opacity: 0;
              display: none;
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              height: 100%;
              width: 100%;
            }

            .inside-img {
              position: absolute;
              top: 0px;
              left: 0px;
              right: 0px;
              bottom: 0px;
              background-size: cover;
              background-position: center;
            }
          }
        }
      }
    }
  }

  .cta-container {
    margin-top: 5.625rem;

    @include media-breakpoint-for(mobile) {
      padding: 0;
      margin-top: 0rem;
    }

    @include media-breakpoint-for(tab) {
      padding: 0;
      margin-top: 0rem;
    }

    @include media-breakpoint-for(tab-landscape) {
      padding: 0;
      margin-top: 0rem;
    }
  }

  .content-block-with-bg-image {
    padding: 60px 0;
    margin: 60px 0;
    position: relative;
    overflow: hidden;

    img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      background-repeat: no-repeat;
    }

    .inside-box {
      background-color: $fff-brand-new-fill;
      padding: 30px 50px;
      width: fit-content;
      margin: 0 auto;
      position: relative;
      z-index: 1;

      h3 {
        font-size: 20px;
      }

      p {
        font-size: 20px;
        color: #000;
      }

      strong {
        color: #000;
      }

      ul {
        margin-bottom: 0.5rem;
        padding: 0;

        li {
          list-style-type: none;
          color: #000;
          padding-left: 30px;
          text-align: left;
          margin-bottom: 4px;
          display: flex;
          column-gap: 5px;
          flex-direction: row;
          align-items: center;

          i {
            font-size: 22px;
          }
        }


      }
    }

    @include media-breakpoint-for(mobile) {
      padding: 30px 0px;

      .inside-box {
        padding: 30px;
      }
    }
  }
}

.fixed-button-mobile {
  display: flex;
  position: fixed !important;
  padding: 20px 15px;
  position: relative;
  background: white;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 99;

  button {
    float: left;
    min-width: unset !important;
  }

  a {
    font-family: 'Soleil-Bold', sans-serif;
    text-align: center;
    box-shadow: 0px 4px 8px rgba($fff-brand-green, 0.16);
    color: #066d38;
    border: 2px solid rgba($fff-brand-green, 0.18);
    background-color: #ffffff;
    display: block;
    float: right;
    vertical-align: middle;
    border-radius: 30px;
    padding: 0px 36px;
    line-height: 17px;
    height: 40px;
    width: 100%;
    line-height: 40px;
    font-size: 14px;

    &:not(:disabled):not(.disabled):active,
    &:focus,
    &:hover {
      color: $fff-brand-green;
      border-color: #d1e5da;
      background: #d1e5da;
    }
  }

  .btn-get-started {
    width: 100%;
  }

  .view-account {
    float: left;
    background-color: #036e38;
    border-color: #036e38;
    color: white;
  }

  .book-a-call {
    color: #066d38 !important;
  }

  @include breakpoint(desktop-up) {
    display: none;
  }
}

.newsletter-row {
  background-color: white;
  position: relative;
  display: flex;
  border: 0px;
  padding: 60px 10px;
  font-size: 16px;
  vertical-align: middle;

  @include media-breakpoint-for(mobile) {
    padding: 30px 0px;
  }
}

// 2019.05.13 ---------
.main-header {
  .dropdown {
    .dropdown-menu {
      display: none;
      border: 1px solid #f2f2f2;

      a {
        width: 100%;
        background-color: white;
        border: none;
        border-bottom: 1px solid #f2f2f2;
        padding: 10px;
        display: inline-block;

        &:hover {
          background-color: #f2f2f2;
        }
      }
    }

    &:hover {
      .dropdown-menu {
        display: inline-block;
      }
    }

    .on-the-menu {
      max-width: 100%;

      ul {
        width: 100%;
        display: inline-block;

        li {
          max-width: 100px;
          display: inline-block;

          img {
            width: 100%;
          }
        }
      }
    }
  }
}