@import './responsive';

.card {
  margin-bottom: 16px;
  padding: 8px 12px;
  border-width: 2px;

  &.selected {
    border: 2px solid $fff-brand-green;
    color: black;
  }

  .card-body {
    padding: 0rem;

    .card-title {
      .card-container {
        display: inline-block;
        position: relative;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        text-align: left;
        padding-top: 1px !important;
        float: left;
        padding-left: 0px;
        margin-bottom: 0rem;
        width: 100%;
        text-transform: none;

        .img-container {
          display: inline-block;
          margin-bottom: 0rem;
          width: 60px;
          height: 60px;
          margin: 0rem 0.5rem 0rem 0rem;
          padding: 10px;
          border-radius: 50%;
          background-color: rgba($fff-brand-grey, 0.1);
          overflow: hidden;
          position: relative;

          .img {
            width: 100%;
            height: 100%;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            margin: 0px;
          }
        }

        span {
          display: inline-block;
          vertical-align: middle;

          //width: 84%;
          @include media-breakpoint-for(mobile) {
            width: 65%;
          }

          @include media-breakpoint-for(tab) {
            width: 65%;
          }

          @include media-breakpoint-for(tab-landscape) {
            width: 65%;
          }

          &.text {
            width: 83%;
            margin: 0;
            display: inline-block;

            @include media-breakpoint-for(mobile) {
              width: 70%;
            }

            @include media-breakpoint-for(tab) {
              width: 70%;
            }

            @include media-breakpoint-for(tab-landscape) {
              width: 70%;
            }
          }

          &.value {
            width: 17%;
            margin: 0;
            text-align: right;
            display: inline-block;

            @include media-breakpoint-for(mobile) {
              width: 30%;
            }

            @include media-breakpoint-for(tab) {
              width: 30%;
            }

            @include media-breakpoint-for(tab-landscape) {
              width: 30%;
            }

            span {
              width: 100%;
              display: block;
              margin: 0px;

              @include media-breakpoint-for(mobile) {
                width: 100%;
              }

              @include media-breakpoint-for(tab) {
                width: 100%;
              }

              @include media-breakpoint-for(tab-landscape) {
                width: 100%;
              }
            }
          }

          h1 {
            font-weight: 400;
            font-size: 16px;
            display: block;
            margin-bottom: 0rem;
            margin-top: 0rem;
            text-align: left;
            color: $fff-brand-grey;
            font-family: 'Soleil-Bold', sans-serif;
            opacity: 1;

            &.item-title {
              display: inline;
            }

            &.tooltip {
              display: inline;
              color: $fff-brand-green;
              font-size: 12px;
              line-height: 1.8rem;
              margin-left: 0.5rem;
              z-index: auto;
            }
          }

          h2 {
            font-weight: 500;
            font-size: 14px;
            display: block;
            margin-bottom: 0rem;
            color: $fff-brand-grey;
            font-family: 'SoleilLt', sans-serif;
          }
        }

        input {
          position: absolute;
          opacity: 0;
        }

        .checkmark {
          display: none;
        }
      }
    }
  }
}

// Contact details card styles. TODO: find a better place when doing order form revamp

.contact-details-card {
  background-color: rgba($color: $fff-brand-green, $alpha: .08);
  border-radius: 6px;
}