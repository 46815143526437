// over lay, when screen is in landscape orientation in mobile
#app-overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(79, 79, 79, 0.75);
  z-index: 99999;
  // cursor: pointer;

  i{
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 100px;
    text-align: center;
    z-index: 99997;
    color: rgba(189, 189, 189, 0.3);
    transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
  }

  div{
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 18px;
    text-align: center;
    font-weight: bold;
    z-index: 99998;
    color: white;
    transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
  }
}

html {
  overflow-y: auto;
}


