@import "./responsive";

.alert {
  padding: 13px 15px 12px;
  border-radius: 4px;
  width: 100%;
  font-size: 14px;
  font-family: "SoleilLt", sans-serif;
  border: 0px;

  @include media-breakpoint-for(mobile) {
    border-radius: 0px;
    width: 100%;
  }

  @include media-breakpoint-for(tab) {
    border-radius: 0px;
    width: 100%;
  }

  @include media-breakpoint-for(tab-landscape) {
    border-radius: 0px;
    width: 100%;
  }

  &.alert-success {
    color: $fff-brand-green;
    background-color: $fff-brand-shaded-green;
  }

  &.alert-primary {
    color: $fff-brand-dark-blue;
    background-color: $fff-brand-shaded-blue;
  }

  &.alert-warning {
    color: $fff-brand-brown;
    background-color: $fff-brand-shadded-brown;
  }

  &.alert-danger {
    color: $fff-brand-dark-red;
    background-color: $fff-brand-shadded-red;
  }
}

.p-growl {
  width: 100%;

  &.p-growl-topright {
    top: 70px !important;
    margin: 0 0 0.5em 0 !important;
    min-width: 300px;
    width: fit-content;

    @include media-breakpoint-for(mobile) {
      margin: 0 !important;
      width: 100% !important;
    }

    @include media-breakpoint-for(tab) {
      margin: 0 !important;
      width: 100% !important;
    }

    @include media-breakpoint-for(tab-landscape) {
      margin: 0 !important;
      width: 100% !important;
    }
  }

  .p-growl-item-container {
    padding: 13px 15px 12px;
    border-radius: 4px !important;
    font-size: 14px;
    border: 0px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1) !important;

    @include media-breakpoint-for(mobile) {
      border-radius: 0px !important;
    }

    @include media-breakpoint-for(tab) {
      border-radius: 0px !important;
    }

    @include media-breakpoint-for(tab-landscape) {
      border-radius: 0px !important;
    }

    &.p-growl-message-success {
      color: $fff-brand-new-light !important;
      background-color: $fff-brand-new-secondary !important;

      .p-growl-icon-close {
        color: $fff-brand-new-light !important;
      }
    }

    &.p-growl-message-info {
      color: $fff-brand-new-light !important;
      background-color: $fff-brand-dark-blue !important;

      .p-growl-icon-close {
        color: $fff-brand-new-light !important;
      }
    }

    &.p-growl-message-error {
      color: $fff-brand-new-light !important;
      background-color: $fff-brand-red !important;

      .p-growl-icon-close {
        color: $fff-brand-new-light !important;

        .pi-times:before {}
      }
    }

    &.p-growl-message-warn {
      color: $fff-brand-new-light !important;
      background-color: $fff-brand-brown !important;

      .p-growl-icon-close {
        color: $fff-brand-new-light !important;
      }
    }

    .p-growl-item {
      padding: 0px 25px 0px 0px !important;

      @include media-breakpoint-for(mobile) {
        height: unset !important;
      }

      @include media-breakpoint-for(tab) {
        height: unset !important;
      }

      @include media-breakpoint-for(tab-landscape) {
        height: unset !important;
      }

      .p-growl-icon-close {
        opacity: 0.5;
        right: 0 !important;

        &:hover {
          opacity: 1;
        }
      }

      .p-growl-image {
        display: none;
      }

      .p-growl-message {
        margin: 0em !important;

        .p-growl-title {
          display: none;
        }

        p {
          color: inherit;
        }
      }
    }
  }
}

.main-site-notification {
  z-index: 20;
  width: 100%;
  border-radius: 0px;
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
  font-family: "SoleilLt", sans-serif;
  font-size: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  margin-bottom: 0px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  position: sticky;
  top: 65px;

  @media screen and (min-width: 1024px) {
    left: 0px;
    position: fixed;
    top: 64px;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .text {
    text-align: center;
  }

  .close {
    position: absolute;
    top: 0px;
    right: 5px;
    padding: 8px;
    color: inherit;
    font-size: 28px;
    font-weight: 700;
    line-height: 1;
    text-shadow: 0 1px 0 #fff;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }
}

.main-site-notification.announcement-banner {
  color: #000000;
  font-family: "Soleil-Bold", sans-serif;
  text-align: center;
  background-color: #92A14B;
  border-color: $fff-brand-grey;

  a {
    color: #036E38;
  }
}

.has-main-site-notification {
  padding-top: 110px;

  .user-profile-container .right-container {
    top: 110px;
  }
}

.p-toast-top-right {
  top: 70px;
}

.p-toast {
  opacity: 0.9;

  .p-toast-message {
    .p-toast-message-content {
      padding: 0;
      align-items: start;

      .p-toast-message-icon {
        display: none;
      }

      .p-toast-message-text {
        margin: 0;

        .p-toast-summary {
          display: none;
        }
      }

      .p-toast-detail {
        color: #fff;
        font-family: "SoleilLt", sans-serif;
        font-size: 14.5px;
        margin: 0;
      }

      // .p-toast-icon-close {
      //   padding-top: 2px;
      // }
    }
  }

  .p-toast-message-success {
    color: $fff-brand-new-light !important;
    background-color: $fff-brand-new-secondary !important;
    border: 0 none;
    border-width: 0;

    padding: 13px 15px 12px;
    border-radius: 4px !important;
    font-size: 14px;
    border: 0;
    box-shadow: 0 4px 16px rgba(0, 0, 0, .1) !important;

    .p-toast-message-icon {
      display: none;
    }
  }

  .p-toast-message-error {
    color: $fff-brand-new-light !important;
    background-color: $fff-brand-red !important;
    border: 0 none;
    border-width: 0;

    padding: 13px 15px 12px;
    border-radius: 4px !important;
    font-size: 14px;
    border: 0;
    box-shadow: 0 4px 16px rgba(0, 0, 0, .1) !important;

    .p-toast-message-icon {
      display: none;
    }
  }

  .p-toast-message-info {
    color: $fff-brand-new-light !important;
    background-color: $fff-brand-dark-blue !important;
    border: 0 none;
    border-width: 0;

    padding: 13px 15px 12px;
    border-radius: 4px !important;
    font-size: 14px;
    border: 0;
    box-shadow: 0 4px 16px rgba(0, 0, 0, .1) !important;

    .p-toast-message-icon {
      display: none;
    }
  }

  .p-toast-message-warn {
    color: $fff-brand-new-light !important;
    background-color: $fff-brand-brown !important;
    border: 0 none;
    border-width: 0;

    padding: 13px 15px 12px;
    border-radius: 4px !important;
    font-size: 14px;
    border: 0;
    box-shadow: 0 4px 16px rgba(0, 0, 0, .1) !important;

    .p-toast-message-icon {
      display: none;
    }
  }
}

.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close .pi,
.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close .pi,
.p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close .pi,
.p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close .pi {
  color: #fff;
  opacity: 0.7;
  font-weight: bolder;
}