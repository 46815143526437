@font-face {
  font-family: "DINPro-Bold";
  src: url('../fonts/dinpro/DINPro-Bold.eot');
  src: url('../fonts/dinpro/DINPro-Bold.eot?#iefix') format('embedded-opentype'),
       url('../fonts/dinpro/DINPro-Bold.woff2') format('woff2'),
       url('../fonts/dinpro/DINPro-Bold.woff') format('woff'),
       url('../fonts/dinpro/DINPro-Bold.svg#DINPro-Bold') format('svg');
}


@font-face {
  font-family: "DINPro-Light";
  src: url('../fonts/dinpro/DINPro-Light.eot');
  src: url('../fonts/dinpro/DINPro-Light.eot?#iefix') format('embedded-opentype'),
       url('../fonts/dinpro/DINPro-Light.woff2') format('woff2'),
       url('../fonts/dinpro/DINPro-Light.woff') format('woff'),
       url('../fonts/dinpro/DINPro-Light.svg#DINPro-Light') format('svg');
}
