@import './responsive';

.whiteTabs{
    box-shadow: 0px 1px 4px rgba($fff-brand-grey, 0.08);
    padding: 5px 15px 0px;
    margin-left: 15px;
    margin-right: 15px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    background-color: white;  

     @include media-breakpoint-for(mobile) {
        padding: 0px;
      }
      @include media-breakpoint-for(tab) {
        padding: 0px;
      }
      @include media-breakpoint-for(tab-landscape) {
        padding: 0px;
      }
    .active{
        width: 49%; 
        border-bottom-color: $fff-brand-green !important;
        border-top-color: white !important;
        border-left-color: white !important;
        border-right-color: white !important;
        color: $fff-brand-green;
        background-color: white;
        border-radius: 0px;

        @include media-breakpoint-for(mobile) {
            width: 50%;
          }
          @include media-breakpoint-for(tab) {
            width: 50%;
          }
          @include media-breakpoint-for(tab-landscape) {
            width: 50%;
          }
    }
    .normal{
        width: 49%;        
        border-radius: 0px;
        color: rgba(66, 69, 70, 0.72) !important;
        border-color: white !important;
   
        @include media-breakpoint-for(mobile) {
            width: 50%;
          }
          @include media-breakpoint-for(tab) {
            width: 50%;
          }
          @include media-breakpoint-for(tab-landscape) {
            width: 50%;
          }
    }

   .btn-secondary:not(:disabled):not(.disabled).active,{       
    background-color: white;
    color: $fff-brand-green;
}
    .separator{
        &:first-of-type{
            width: 1px;
            height: 24px;
            display: -webkit-inline-flex;
            display: inline-flex;
            border-right:1px solid rgba(66, 69, 70, 0.18) !important;
            margin: 0 0.9%;
   
            @include media-breakpoint-for(mobile) {
                display: none;
              }
              @include media-breakpoint-for(tab) {
                display: none;
              }
              @include media-breakpoint-for(tab-landscape) {
                display: none;
              }
        }
      
    }
}


.tabContent{
    padding: 15px;
  }

.mainTabs{
    padding: 5px 15px 0px;
    margin-left: 15px;
    margin-right: 15px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
  
     @include media-breakpoint-for(mobile) {
        padding: 0px 4px;
       }
       @include media-breakpoint-for(tab) {
        padding: 0px 4px;
       }
       @include media-breakpoint-for(tab-landscape) {
        padding: 0px 4px;
       }
     &.grey{
        background-color: rgba(66, 69, 70, 0.08);
     box-shadow: 0px 1px 4px rgba($fff-brand-grey, 0.08);}
     &.green{
       background-color: $fff-brand-green;
        // background-color: #d6ede2;
        box-shadow: 0px 1px 4px rgba($fff-brand-green, 0.12);}
    .btn{ 
        padding: 0px 2px;
        box-shadow: none !important;
    &.active{
        // width:22%;  
        flex:1 1;  
        border-bottom-color: $fff-brand-yellow !important;
        border-top-color: transparent !important;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        color: white !important;
        border-radius: 0px;
        background-color: $fff-brand-green !important;
        box-shadow: none !important;

           @include media-breakpoint-for(mobile) {
            text-align: center;
            padding: 0px;
           }
           @include media-breakpoint-for(tab) {
            text-align: center;
            padding: 0px;
           }
           @include media-breakpoint-for(tab-landscape) {
            text-align: center;
            padding: 0px;
           }
    }
    &.normal{ 
        flex:1 1;     
        border-radius: 0px;
        color: white !important;
        background-color: $fff-brand-green !important;
        border-top-color: transparent !important;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
          @include media-breakpoint-for(mobile) {
            text-align: center;
            padding: 0px;
           }
           @include media-breakpoint-for(tab) {
            text-align: center;
            padding: 0px;
           }
           @include media-breakpoint-for(tab-landscape) {
            text-align: center;
            padding: 0px;
           }
    }
     .btn-secondary:not(:disabled):not(.disabled).active,{    
    background-color: transparent !important;
    color: $fff-brand-green;
}
    

@include media-breakpoint-for(mobile) {
    >span{
        display: none;
    }
    
    .m-display{
    display: inline-block !important;  
    }
   }
   @include media-breakpoint-for(tab) {
    >span{
        display: none;
    }
    
    .m-display{
    display: inline-block !important;  
    }
   }
   @include media-breakpoint-for(tab-landscape) {
    >span{
        display: none;
    }
    
    .m-display{
    display: inline-block !important;  
    }
   }    
i{
    font-size: 20px;
    display: inline-block;
    margin-right: 5px;
    vertical-align: middle;

      @include media-breakpoint-for(mobile) {
        margin-right: 0px;
        font-size: 25px;
      }
      @include media-breakpoint-for(tab) {
        margin-right: 0px;
        font-size: 25px;
      }
      @include media-breakpoint-for(tab-landscape) {
        margin-right: 0px;
        font-size: 25px;
      }
}
    }
    .separator{
            width: 1px;
            height: 24px;
            display: -webkit-inline-flex;
            display: inline-flex;
            border-right:1px solid rgba(66, 69, 70, 0.18) !important;
            margin: 0 0.9%;
            &:last-of-type{
                display: none;
            }
        }
}
.secondaryTabs{
    padding: 5px 15px 0px;
    margin-left: 0px;
    margin-right: 0px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    background-color: #f0f0f0;
    box-shadow: 0px 1px 4px rgba($fff-brand-grey, 0.08);

     @include media-breakpoint-for(mobile) {
        padding: 0px 4px;
        margin-left: 15px;
        margin-right: 15px;
      }
      @include media-breakpoint-for(tab) {
        padding: 0px 4px;
        margin-left: 15px;
        margin-right: 15px;
      }
      @include media-breakpoint-for(tab-landscape) {
        padding: 0px 4px;
        margin-left: 15px;
        margin-right: 15px;
      }
    .btn{ 
        padding: 0px 2px;
        border-width: 1px;
        box-shadow: none;
    &.active{
        // width:22%;  
        flex:1 1;  
        border-bottom-color: $fff-brand-green !important;
        border-top-color: transparent !important;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        color: $fff-brand-green !important;
        border-radius: 0px;
        background-color: #f0f0f0 !important;
        box-shadow: none !important;
  
         @include media-breakpoint-for(mobile) {
            text-align: center;
            padding: 0px;
          }
          @include media-breakpoint-for(tab) {
            text-align: center;
            padding: 0px;
          }
          @include media-breakpoint-for(tab-landscape) {
            text-align: center;
            padding: 0px;
          }
    }
    &:hover{
        
        box-shadow: none !important;
    }
    &.normal{
        // width: 22%;   
        flex:1 1;     
        border-radius: 0px;
        color: rgba(66, 69, 70, 0.72) !important;
        background-color: #f0f0f0 !important;
        border-top-color: transparent !important;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
    
        @include media-breakpoint-for(mobile) {
            text-align: center;
            padding: 0px;
          }
          @include media-breakpoint-for(tab) {
            text-align: center;
            padding: 0px;
          }
          @include media-breakpoint-for(tab-landscape) {
            text-align: center;
            padding: 0px;
          }
    }
     .btn-secondary:not(:disabled):not(.disabled).active,{    
    background-color: transparent !important;
    color: $fff-brand-green;
}
     
i{
    font-size: 20px;
    display: inline-block;
    margin-right: 5px;
    vertical-align: middle;
  
    @include media-breakpoint-for(mobile) {
        margin-right: 0px;
        font-size: 25px;
      }
      @include media-breakpoint-for(tab) {
        margin-right: 0px;
        font-size: 25px;
      }
      @include media-breakpoint-for(tab-landscape) {
        margin-right: 0px;
        font-size: 25px;
      }
}
    }
    .separator{
            width: 1px;
            height: 24px;
            display: -webkit-inline-flex;
            display: inline-flex;
            border-right:1px solid rgba(66, 69, 70, 0.18) !important;
            margin: 0 0.9%;
            &:last-of-type{
                display: none;
            }
        }
}