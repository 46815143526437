@import './responsive';

.custom-paginate-styles{
  content: '' !important;
  font-family: 'Material Icons';
  font-size: 18px;
  display: block;
  background-size: 20px;
  width: 12px;
  height: 18px;
  background-position: center;
}
.p-datatable {
  .p-paginator {
    background-color: #ffffff;
    border: 0;
    .p-paginator-pages {
      .p-paginator-page {
        font-weight: 600;
        &.p-highlight {
          background-color: #ffffff !important;
          color: $fff-brand-new-dark;
          font-weight: 600;
          font-size: 14px;
        }
        &.p-link:focus{
          box-shadow: none;
        }
      }
    }
    &.p-component {
      padding-left: 3px;
      &.p-paginator-bottom {
        padding-top: 1.5rem;
        text-align: right;

        .p-paginator-first {
          margin-left: auto;
        }
        &.p-paginator .p-paginator-left-content{
          padding: 5px 2px;
          color: $fff-brand-grey;
          margin-right: 0px;

          @include media-breakpoint-for(mobile) {
            width: 100%;
            margin-right: 0px;
          }
          @include media-breakpoint-for(tab) {
            width: 100%;
            margin-right: 0px;
          }
          @include media-breakpoint-for(tab-landscape) {
            width: 100%;
            margin-right: 0px;
          }
          &>div{
            &>span{
              margin-left: 20px !important;
            }
          }
          .paginator-row-count {
            display: flex;
          }
        }
        > .p-dropdown {
          width: 60px !important;
          float: left;
          background: transparent;
          border:0px;
          margin-left: 0px;
          margin-right: auto;
          &.p-focus, &:hover, &:focus {
            outline: 0px;
            box-shadow: none;
            border:0px;
          }
          .p-dropdown-label {
            &.p-inputtext {
              background: transparent;
              text-align: center;
              color:$fff-brand-green;
              min-height: unset;
              display: flex;
              align-items: center;
            }
          }
          .p-dropdown-trigger {
            background: transparent;
            color: rgba($fff-brand-grey, 0.8);
            line-height: unset;
            height: auto;
            padding-top: 3px;
            .p-dropdown-trigger-icon {
              top: 0;
              left: 0;
              margin-top:0px;
              margin-left: 0px;
              position: relative;
              &.pi {
                font-size: 0.8rem;
                color:$fff-brand-green;
              }
            }
          }
          .p-dropdown-panel {
            min-width: 100px;
            .p-dropdown-items-wrapper {
              .p-dropdown-items {
                .p-dropdown-item {
                  &.p-highlight {
                    background-color: $fff-brand-green;
                  }
                }
              }
            }
          }
        }
        .p-paginator-icon{
          &.pi-caret-right{
            &::before{
              // background-image: url(../img/icons/paginate-next.svg);
              content: '\e409' !important;
              @extend .custom-paginate-styles;
            }
          }
          &.pi-caret-left{
            &::before{  
              content: '\e408' !important;
              @extend .custom-paginate-styles;
            }
          }
          &.pi-step-forward{
            &::before{
              content: '\e5dd' !important;
              @extend .custom-paginate-styles;
            }
          }
          &.pi-step-backward{
            &::before{
              content: '\e5dc' !important;
              @extend .custom-paginate-styles;
            }
          }
        }  
      }
    }
  }
}
.p-component{
  overflow: visible !important;
}