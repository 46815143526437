@import './responsive';

.accordion-main{
.p-accordion-tab{
  margin: 0 auto;
    padding: 0px;
    margin-top: 0px !important;
    margin-bottom: 6px !important;
        @include media-breakpoint-for(mobile) {
          width: 100%;
          padding: 0px;
          margin: 0px;
         }
         @include media-breakpoint-for(tab) {
          width: 100%;
          padding: 0px;
          margin: 0px;
         }
         @include media-breakpoint-for(tab-landscape) {
          width: 100%;
          padding: 0px;
          margin: 0px;
         }
    .p-accordion-header{
          padding: 0px;
          margin-top: 0px !important;
          margin-bottom: -3px !important;
          cursor: pointer;
          position: relative;
          outline: none !important;
      
            a{ width: 100%;
              padding: 20px 60px 20px 30px;
              text-transform: unset;
              background-color: rgba($fff-brand-grey, 0.08)  !important;
              border:0px !important;
              outline: none !important;    
              display: inline-block !important; 
              border-radius: 0px !important; 
              margin-top: 0px !important;
              margin-bottom: 0px !important;
              @include media-breakpoint-for(mobile) {
                padding:12px 30px 12px 16px
               }
               @include media-breakpoint-for(tab) {
                padding:12px 30px 12px 16px
               }
               @include media-breakpoint-for(tab-landscape) {
                padding:12px 30px 12px 16px
               }       
              &:hover{
                background-color: $fff-brand-yellow !important;
              } 
              &:focus{
                box-shadow: none !important;
              }
              .p-accordion-header-text{
                float: left;
                h3{
                  font-size: 20px;
                  font-family: 'DINPro-Bold', sans-serif ;
                  color: black !important;  
                  margin-bottom: 0px;   
                  text-align: left;
                  @include media-breakpoint-for(mobile) {
                    font-size: 18px;
                   }
                   @include media-breakpoint-for(tab) {
                    font-size: 18px;
                   }
                   @include media-breakpoint-for(tab-landscape) {
                    font-size: 18px;
                   } 
                }
              }
              .p-accordion-toggle-icon{
                right: 30px !important;
                position: absolute;
                font-size: 16px;
                right: 20px;  
                align-items: center;
                vertical-align: middle;
                display: flex;
                color: rgba($fff-brand-grey, 0.36) !important;
                @include media-breakpoint-for(mobile) {
                  right: 15px !important;
                 }
                 @include media-breakpoint-for(tab) {
                  right: 15px !important;
                 }
                 @include media-breakpoint-for(tab-landscape) {
                  right: 15px !important;
                 } 
                &.pi-caret-right{                  
                -ms-transform: rotate(90deg);
                -webkit-transform:  rotate(90deg);
                transform:  rotate(90deg); 
                height: 35px;
                }
                &.pi-caret-down{         
                  -ms-transform: rotate(180deg);
                  -webkit-transform:  rotate(180deg);
                  transform:  rotate(180deg); 
                  height: 25px;
                }
              }
            }
    }
    .p-accordion-content{
      padding: 20px 30px;
      text-transform: unset;
      background-color: rgba($fff-brand-grey, 0.08);
      color: $fff-brand-grey;
      font-size: 16px;
      font-family: 'SoleilLt', sans-serif;
      border:0px;
      border-radius: 0px;
      margin-top: 0px !important;
      margin-bottom: 0px !important;
      p{
        color: $fff-brand-grey;
        font-size: 16px;
        font-family: 'SoleilLt', sans-serif;
      }
    }
}
}

.delivery-schedule-info-box {
  .delveiry-instruction {
    color: $fff-brand-new-dark;
    span {
      font-weight: bold;
    }
  }
  
  .accordion {
    &__item {
      background-color: $fff-brand-new-light;
      border: 1px solid rgba($fff-brand-new-dark, .12);
      border-radius: 2px;
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
    &__button {
      background-color: $fff-brand-new-light;
      padding: 0.5rem;
      display: flex;
      align-items: center;
      position: relative;
      color: $fff-brand-new-dark;

      &[aria-expanded="true"] {
        &::after {
          transform: rotate(-180deg);
        }
      }

      &::after {
        content:"\E902";
        width: 24px;
        height: auto;
        margin-right: .5rem;
        text-align: center;
        color: black;
        font-family: 'primeicons';
        position: absolute;
        right: 0;
      }

      .fff-img {
        width: 40px;
        height: 40px;
        margin-right: .5rem;
        background-size: contain;
        background-repeat: no-repeat;
        &.change-location {
          background-image: url('../img/fff-icon-change-location.svg');
        }
        &.skip-delivery {
          background-image: url('../img/fff-icon-skip-delivery.svg');
        }
        &.add-delivery {
          background-image: url('../img/fff-icon-add-delivery.svg');
        }
        &.cancel-bags {
          background-image: url('../img/fff-icon-add-delivery.svg');
        }
      }
    }

    %number-circle {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      font-size: 16px;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $fff-brand-new-primary;
      color: $fff-brand-new-light;
    }

    &__panel {
      margin: 0 1rem 1rem;
      padding-top: 1rem;
      border-top: 1px solid rgba($fff-brand-new-dark, .125);

      > .row {
        margin: 0;
      }

      .item-content {
        padding: 0 0 0.25rem;
        &.text-center {
          text-align: center;
        }

        .col {
          padding: 0;
          width: 100%;
          display: inline-block;
        }

        p {
          font-family: $fff-font-sans;
        }
      }

      .radio-btn-default {
        padding-left: 1.75rem;
        margin-bottom: .25rem;
      }

      .checkmark {
        width: 18px;
        height: 18px;
        border: 1px solid $fff-brand-new-primary;

        &::after {
          width: 12px;
          height: 12px;
          position: absolute;
          inset: 2px 0;
          margin: 0 auto;
          background-color: $fff-brand-new-primary;
        }
      }

      p {
        margin-bottom: 0.5rem;
        font-weight: 400;
        &:last-child {
          margin-bottom: 1rem;
        }
      }

      button {
        width: 100%;
      }

      .btn-primary, .btn-secondary {
        font-weight: 700;
      }

      .shadow-none-row {
        .delivery-row {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;
          margin-bottom: 1rem;
          .btn-group {
            padding: 0;

            .btn {
              width: 25px;
              height: 25px;
            }
          }

          .btn-primary {
            box-shadow: none
          }

          .total {
            @extend %number-circle;
            margin: 0 0.75rem;
          }
        }
      }

      .bags-count {
        @extend %number-circle;
        margin: 0 auto;
      }

      .input-bags-count {
        min-width: 80px;
        max-width: fit-content;
        margin: 0 auto;
        text-align: center;
      }

      .custom-p-dropdown {
        border: 1px solid rgba($fff-brand-new-dark, .12) !important;
        line-height: 38px;
        margin-bottom: 1rem;

        &.p-dropdown {
          .p-dropdown-panel {
            .p-dropdown-items-wrapper {
              .p-dropdown-items {
                .p-dropdown-item {
                  &.p-highlight {
                    background-color: $fff-brand-new-primary;
                  }
                }
              }
            }
          }
        }

        .p-inputtext {
          color: $fff-brand-new-dark;
          font-family: $fff-font-sans;
        }

        .p-dropdown-trigger {
          span {
            color: $fff-brand-new-dark;
            font-size: 14px;
          }
        }
      }
    }
  }
}

.accordion-account-logs {
  .accordion {
    width: 100%;

    &__item {
      margin-bottom: 1rem;
    }

    &__button {
      height: 48px;
      line-height: 32px;
      padding: 0.625rem 1rem;
      background-color: $fff-brand-new-primary;
      color: $fff-brand-new-fill;
      font-weight: 700;
      border-radius: 3px;
      width: 100%;
      position: relative;

      &[aria-expanded="true"] {
        &::after {
          transform: rotate(-180deg);
        }
      }

      &::after {
        content:"\E902";
        width: 24px;
        height: auto;
        margin-right: .5rem;
        font-weight: 100;
        text-align: center;
        color: $fff-brand-new-fill;
        font-family: 'primeicons';
        position: absolute;
        right: 0;
      }
    }

    &__panel {
      padding: 1rem 1rem 0.5rem;
      margin: 1rem 0.5rem 2.5rem;
      box-shadow: 0px 1px 4px rgba($fff-brand-grey, 0.08);
      border-radius: 3px;

      .margin-bottom-md {
        margin-bottom: 0;
      }

      .row {
        .col {
          min-height: unset !important;
        }
      }

      .entry-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;

        @include breakpoint(mobile) {
          flex-direction: column;
          gap: 1rem;
          align-items: flex-start;
        }

        &.content-right {
          justify-content: end;
        }

        .p-inputtext {
          min-width: 240px;
          color: $fff-brand-new-accent;
          font-weight: 700;
          &:hover , &:focus {
            border-right: 2px solid $fff-brand-new-secondary !important;
          }
        }
      }

      .custom-calendar-dropdown { 
        max-width: 50%;
        @include breakpoint(mobile) {
          width: 100%;
          max-width: 100%;
        }
      }

      .fff-table {
        padding: 0;
        box-shadow: none;
        .p-datatable-wrapper {
          table {

            th, td {
              font-family: $fff-font-sans;
            }
            
            th {
              font-weight: 600;
              text-transform: unset;
              color: $fff-brand-new-dark;
            }

            td {
              font-size: 12px;
            }
          }
        }
        .p-paginator {

          button {
            span {
            color: $fff-brand-new-dark;
            }
          }

          .p-paginator-left-content {
            color: $fff-brand-new-dark;
          }
          
          .p-dropdown {
            .p-dropdown-lable {
              .p-inputtext {
                color: $fff-brand-new-dark;
              }
            }
          }
        }
      }
    }
  }
}

.subscription-modal {
  &.modal-dialog {
    .modal-content {
      .modal-header {
        padding: 0.75rem 0;
      }
  
      .modal-body {
        padding: 1rem !important;
        margin-bottom: 0 !important;

        p {
          color: $fff-brand-new-dark;
          margin-bottom: 1rem;
        }
      }
    }
  }

  .accordion {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    &__item {
      border: 1px solid rgba($fff-brand-grey, 0.12);
    }

    &__heading {

    }

    &__button {
      display: flex;
      align-items: center;
      font-weight: 700;
      padding: 1rem;

      .form-check-input {
        opacity: 0;
      }

      .checkmark {
        width: 18px;
        height: 18px;
        border-radius: 100%;
        border: 1px solid $fff-brand-new-primary;
        margin-right: 0.5rem;
        position: relative;
    
        &::after {
          content: '';
          width: 12px;
          height: 12px;
          border-radius: 100%;
          position: absolute;
          inset: 2px 0;
          margin: 0 auto;
        }
      }
      
      input:checked ~ .checkmark {
        &::after {
          background-color: $fff-brand-new-primary;
        }
      }
    }

    &__panel {
      margin: 0 1rem 1rem;
      border-top: 1px solid rgba($fff-brand-grey, .12);

      .inner-content {
        padding: 1rem 0 0;

        p {
          margin-bottom: 1rem;
        }

        .form-control {
          border: 1px solid rgba($fff-brand-new-dark, .125);
          &:hover, &:focus {
            border: 1px solid $fff-brand-new-secondary;
          }
        }

        .template-copy-status {
          background-color: $fff-brand-new-fill;
          border-color: rgba($fff-brand-new-primary, 0.12);
          padding: 0.5rem 1rem;
          text-align: left;
          
          p {
            padding: 0;
            margin: 0;
          }
        }
      }

      p {
        color: $fff-brand-new-dark;
      }

      button {
        width: 100%;
      }

      .p-link {
        width: inherit;
      }
      
      .pause-option-wrapper {
        margin: 1rem 0;
        gap: 1rem;
        justify-content: center;

        .pause-option {
          max-width: fit-content;
          padding: 0;

          .radio-btn-default {
            padding-left: 1.75rem;
            margin: 0;

            p {
              font-family: $fff-font-sans;
              font-weight: 700;

              @include media-breakpoint-for(mobile) {
                font-size: 12px;
               }
            }
            
            .checkmark {
              width: 18px;
              height: 18px;
              border: 1px solid $fff-brand-new-primary;
      
              &::after {
                width: 12px;
                height: 12px;
                position: absolute;
                inset: 2px 0;
                margin: 0 auto;
                background-color: $fff-brand-new-primary;
              }
            }
          }
        }
      }

      .p-calendar {
        &.p-inputwraper-focus {
          border-right: 1px solid $fff-brand-new-secondary;

        }
        .p-inputtext {
          color: $fff-brand-new-accent;
          font-weight: 700;
          border: 1px solid rgba($fff-brand-new-dark, .125);
          &:hover, &:focus {
            border-right: 1px solid $fff-brand-new-secondary !important;
          }
        }
      }
    }
  }
}

.pause-order-form-datepicker {
  .p-button {
    width: unset !important;
    padding-right: 16px;
    .pi-calendar {
      font-size: 22px;
    }
  }
  .p-datepicker{
    top: 40px !important;
  }
}