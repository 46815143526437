@import "./responsive";

table {
  &.calendar-table {
    thead {
      tr {
        th {
          border-top: 0px !important;
          padding: 0px;
          font-size: 14px;
          position: relative;
          text-align: center;
          color: $fff-brand-grey;
          font-family: "SoleilLt", sans-serif;
          font-weight: 600;

          &.border-less {
            border: 0;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          border: 1px solid rgba($fff-brand-grey, 0.16);
          text-align: center;
          padding: 5px 0px;
          color: $fff-brand-grey;
          width: 14.2%;

          &.green-bg {
            background-color: $fff-brand-light-green;
            cursor: default;
            position: relative;
          }

          &.pending-class {
            position: relative;

            .pending-class-inside {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              background: repeating-linear-gradient(45deg,
                  white,
                  white 5px,
                  rgba($fff-brand-grey, 0.08) 5px,
                  rgba($fff-brand-grey, 0.08) 10px);
              cursor: default;

              &.disabled {
                opacity: 0.5;
              }
            }

            .deliveryDate {
              z-index: 1;
            }

            .deliveryDateMultiple {
              z-index: 1;
            }
          }

          &.border-less {
            border: 0;
          }

          &:last-of-type {
            text-align: right;
          }

          button {
            margin-left: 5px;
            box-shadow: none !important;
          }
        }
      }
    }
  }

  %circle {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 22px;
    height: 22px;

    @media screen and (min-width: 600px) {
      width: 27px;
      height: 27px;
    }
  }

  .popover {
    background-color: $fff-brand-light-green;
    display: block;
    position: absolute;
    border: 1px solid rgba($fff-brand-green, 0.5);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    width: 155px;
    text-align: left;
    top: 36px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 999;

    h1 {
      color: $fff-brand-grey;
      font-size: 16px;
      font-family: "DINPro-Bold", sans-serif;
      padding: 8px 8px;
      border-bottom: 1px solid rgba($fff-brand-grey, 0.16);
      margin-bottom: 0px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .date {
        @extend %circle;
        grid-area: delivery;
        margin: 0.5rem 0.5rem 0.5rem 0;
        font-size: 14px;

        &-unpaid {
          color: $fff-brand-black-text;
          background-color: lighten($color: $fff-brand-yellow, $amount: 15);

          &:hover {
            background-color: lighten($color: $fff-brand-yellow, $amount: 15) !important;
          }

          &.date-pending {
            background-image: repeating-linear-gradient(45deg,
                rgba($fff-brand-grey, 0),
                rgba($fff-brand-grey, 0) 5px,
                rgba($fff-brand-black-text, 0.08) 5px,
                rgba($fff-brand-black-text, 0.08) 10px);
          }
        }

        &-paid {
          color: white;
          background-color: $fff-brand-green;

          &:hover {
            background-color: $fff-brand-green !important;
          }

          &.date-pending {
            background-image: repeating-linear-gradient(45deg,
                rgba($fff-brand-green, 0),
                rgba($fff-brand-green, 0) 5px,
                rgba($fff-brand-black-text, 0.5) 5px,
                rgba($fff-brand-black-text, 0.5) 10px);
            text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
          }
        }
      }
    }

    p {
      color: $fff-brand-grey;
      font-size: 14px;
      font-family: "SoleilLt", sans-serif;
      padding: 9px 12px;
      border-bottom: 1px solid rgba($fff-brand-grey, 0.16);
      font-weight: 400;
      margin-bottom: 0px;

      strong {
        font-family: "DINPro-Bold", sans-serif;
      }
    }

    ul {
      padding: 0px;
      margin: 0px;

      li {
        padding: 0px;
        margin: 0px;
        list-style: none;

        p {
          border-bottom: 0;
          padding: 7px 12px 6px;

          strong {
            font-family: "DINPro-Bold", sans-serif;
          }
        }

        a {
          color: $fff-brand-green !important;
          font-size: 14px;
          font-family: "SoleilLt", sans-serif;
          padding: 7px 12px 6px;
          margin-bottom: 0px;
          width: 100%;
          display: block;
          font-weight: 300 !important;

          i {
            float: right;
            color: $fff-brand-green;
            font-weight: 300 !important;
          }
        }

        &:last-of-type {
          border-bottom: 1px solid rgba($fff-brand-grey, 0.16);
        }
      }
    }
  }

  &.fff-table-default {
    width: 100%;
    border-collapse: collapse;
    position: relative;
    table-layout: inherit;

    @include media-breakpoint-for(mobile) {
      -webkit-overflow-scrolling: touch;
      white-space: nowrap;
    }

    @include media-breakpoint-for(tab) {
      -webkit-overflow-scrolling: touch;
      white-space: nowrap;
    }

    @include media-breakpoint-for(tab-landscape) {
      -webkit-overflow-scrolling: touch;
      white-space: nowrap;
    }

    thead {
      display: table-header-group;
      vertical-align: middle;
      border-color: inherit;

      tr {
        th {
          display: table-cell;
          padding: 12px 15px;
          color: $fff-brand-grey;
          font-family: "Soleil-Bold", sans-serif;
          font-weight: normal;
          font-size: 12px;
          background-color: white;
          border-top: 0px;
          border-left: 0px;
          border-right: 0px;
          text-transform: uppercase;
          text-align: left;
          white-space: nowrap;
          border-color: $fff-brand-grey;
          border-bottom: 1px solid;
          outline: 0;

          &:first-of-type {
            text-align: left !important;
            padding-left: 0px;
          }

          &:last-of-type {
            text-align: right !important;
            padding-right: 0px;
          }
        }
      }
    }

    tbody {
      display: table-row-group;
      vertical-align: middle;
      border-color: inherit;

      tr {
        height: 50px !important;
        border-top: 1px solid #c8c8c8;
        border-left: 0px;
        border-right: 0px;
        border-bottom: 1px solid #c8c8c8;

        td {
          display: table-cell;
          padding: 8px 15px;
          color: $fff-brand-grey;
          font-family: "SoleilLt", sans-serif;
          font-size: 14px;

          &:first-of-type {
            padding-left: 0px;
          }

          &:last-of-type {
            text-align: right;
            white-space: nowrap;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 600;
          }

          .btn {
            width: auto;
            height: auto;
            border: 2px solid transparent !important;
            box-shadow: none;
            font-family: "SoleilLt", sans-serif;
            font-weight: 600;
            text-transform: uppercase;
            margin-left: 10px;
            background-color: white;
            padding: 5px 15px 5px;
            line-height: 17px;
            font-size: 12px;

            &.btn-secondary {
              color: $fff-brand-green;

              &:not(:disabled):not(.disabled):active,
              &:hover,
              &:focus {
                background: transparent;
              }
            }

            &.btn-danger {
              color: $fff-brand-red;

              &:not(:disabled):not(.disabled):active,
              &:hover,
              &:focus {
                background: transparent;
                border: 2px solid $fff-brand-red !important;
              }
            }

            &.btn-warning {
              color: $fff-brand-brown;

              &:not(:disabled):not(.disabled):active,
              &:hover,
              &:focus {
                background: transparent;
                border: 2px solid $fff-brand-brown !important;
              }
            }

            &.circle {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              font-size: 20px;
            }

            i {
              vertical-align: text-top;
              font-size: 18px;
              margin-top: 0px;
              font-weight: 600;
            }
          }

          a {
            color: $fff-brand-green;
          }

          &:first-of-type {
            text-align: left !important;
          }
        }

        &.no-border {
          border: none;
        }

        &.double-underline {
          border-bottom: 4px double #c8c8c8;
        }

        &:nth-child(even) {
          background-color: white;
        }

        &:hover {
          td {
            background-color: $fff-brand-light-green;

            .btn {
              border: 2px solid rgba($fff-brand-green, 0.18) !important;
              box-shadow: 0px 4px 8px rgba($fff-brand-green, 0.16) !important;
            }
          }
        }
      }
    }
  }
}

.fff-table {
  overflow-x: hidden;
  width: 100%;
  box-shadow: 0px 1px 4px rgba($fff-brand-grey, 0.08);
  padding: 15px;
  background: white;
  border-radius: 5px;

  .p-datatable-wrapper {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    border-radius: 6px;

    @include media-breakpoint-for(mobile) {
      -webkit-overflow-scrolling: touch;
      white-space: nowrap;
    }

    @include media-breakpoint-for(tab) {
      -webkit-overflow-scrolling: touch;
      white-space: nowrap;
    }

    @include media-breakpoint-for(tab-landscape) {
      -webkit-overflow-scrolling: touch;
      white-space: nowrap;
    }

    table {
      width: 100%;
      border-collapse: collapse;
      position: relative;
      table-layout: inherit;

      @include media-breakpoint-for(mobile) {
        -webkit-overflow-scrolling: touch;
        white-space: nowrap;
      }

      @include media-breakpoint-for(tab) {
        -webkit-overflow-scrolling: touch;
        white-space: nowrap;
      }

      @include media-breakpoint-for(tab-landscape) {
        -webkit-overflow-scrolling: touch;
        white-space: nowrap;
      }

      thead {
        display: table-header-group;
        vertical-align: middle;
        border-color: inherit;

        &.p-datatable-thead {
          tr {
            th {
              display: table-cell;
              padding: 12px 15px;
              color: $fff-brand-grey;
              font-family: "Soleil-Bold", sans-serif;
              font-weight: normal;
              font-size: 12px;
              background-color: white;
              border-top: 0px;
              border-left: 0px;
              border-right: 0px;
              text-transform: uppercase;
              text-align: left;
              white-space: nowrap;
              border-color: $fff-brand-grey;
              outline: 0;

              &:first-of-type {
                text-align: left !important;
              }

              &.p-highlight {
                background-color: rgba($fff-brand-dark-green, 0.12) !important;
                outline: 0;
              }

              &.p-sortable-column {
                background-color: white;

                &:focus {
                  box-shadow: none;
                }

                .p-sortable-column-icon {
                  font-size: 12px;
                  color: $fff-brand-green;
                  margin-top: -3px;
                }
              }
            }
          }
        }
      }

      tbody {
        display: table-row-group;
        vertical-align: middle;
        border-color: inherit;

        &.p-datatable-tbody {
          tr {
            height: 50px !important;

            .td-post-code-cell {
              white-space: normal;
              max-width: 230px;
            }

            .td-cell-percentage-width {
              white-space: normal;
              width: 10%;
            }

            td {
              display: table-cell;
              padding: 8px 15px;
              color: $fff-brand-grey;
              font-family: "SoleilLt", sans-serif;
              font-size: 14px;
              border-top: 0px;
              border-left: 0px;
              border-right: 0px;

              &:last-of-type {
                text-align: right;
                white-space: nowrap;
              }

              .btn {
                background-color: white;
                padding: 5px 15px 5px;
                line-height: 17px;
                font-size: 12px;
                width: auto;
                height: auto;
                border: 2px solid transparent !important;
                box-shadow: none;
                font-family: "SoleilLt", sans-serif;
                font-weight: 600;
                text-transform: uppercase;
                margin-left: 10px;

                &.btn-secondary,
                &.text-secondary {
                  color: $fff-brand-green;

                  &:not(:disabled):not(.disabled):active,
                  &:hover {
                    border: 2px solid $fff-brand-green !important;
                    background-color: white !important;
                  }
                }

                &.btn-danger,
                &.text-danger {
                  color: $fff-brand-red !important;

                  &:not(:disabled):not(.disabled):active,
                  &:hover {
                    border: 2px solid $fff-brand-red !important;
                    background-color: white !important;
                  }

                  &:focus {
                    border-color: transparent !important;
                  }
                }

                &.btn-success,
                &.text-success {
                  color: $fff-brand-green !important;

                  &:not(:disabled):not(.disabled):active,
                  &:hover {
                    border: 2px solid $fff-brand-green !important;
                    background-color: white !important;
                  }

                  &:focus {
                    border-color: transparent !important;
                  }
                }

                &.btn-warning,
                &.text-warning {
                  color: $fff-brand-brown !important;

                  &:not(:disabled):not(.disabled):active,
                  &:hover {
                    border: 2px solid $fff-brand-brown !important;
                    background-color: white !important;
                  }

                  &:focus {
                    border-color: transparent !important;
                  }
                }

                &.circle {
                  border-radius: 50%;
                  min-width: 30px;
                  max-width: 30px;
                  width: 30px;
                  height: 30px;
                }

                i {
                  vertical-align: text-top;
                  font-size: 18px;
                  margin-top: 0px;
                  font-weight: 600;
                }
              }

              a {
                color: $fff-brand-green;
              }

              .table-button-container {
                display: flex;
                justify-content: flex-end;

                button {
                  max-height: 30px;
                }
              }

              &:first-of-type {
                text-align: left !important;
              }

              .text-green {
                color: $fff-brand-medium-green;
              }

              .text-blue {
                color: $fff-brand-blue;
              }

              .dv-star-rating {
                width: 95px;
              }

              .message {
                word-break: break-all;
                text-transform: none;
                white-space: normal;
                text-align: left;
                font-weight: normal;
                font-size: 14px;
              }
            }

            &:nth-child(even) {
              background-color: white;
            }

            &:hover {
              td {
                background-color: $fff-brand-light-green;

                .btn {
                  border: 2px solid rgba($fff-brand-green, 0.18) !important;
                  box-shadow: 0px 4px 8px rgba($fff-brand-green, 0.16) !important;
                }
              }
            }
          }
        }
      }
    }
  }
}