.frame-container {
  height: 40px;
  margin-bottom: 1rem;
}

.StripeElement {
  background-color: $fff-brand-new-light;
  padding: 10px 12px;
  border-radius: 5px;
  border: 2px solid #e0dddd;
}

.StripeElement--empty {
  border: 2px solid #d2e5db;
}

.StripeElement--focus {
    border: 2px solid $fff-brand-new-secondary;
}

.StripeElement--invalid {
    border: 2px solid $fff-brand-red !important;
}

.StripeElement--complete {
    border: 2px solid #d2e5db;
}

.stripe-error {
  color: #b22222;
  margin: -0.5rem 0 1rem;
}