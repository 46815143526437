@import './responsive';

.modal-open {
  padding: 0 !important;
  overflow: unset;
}

.modal-dialog {
  position: relative;
  top: 50%;
  transform: translateY(-50%) !important;

  &.supplement-wizard--modal {
    .modal-body {
      margin-bottom: 24px !important;
    }
  }

  &.meal-plan-confirmation {
    max-width: 600px;
  }

  .modal-content {
    .modal-header {
      justify-content: center;
      border-bottom: none;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
      padding: 12px;
      &.long-titles{
        .modal-title{
          max-width: 90%;
        }
      }

      .modal-title {
        font-size: 16px;
        font-weight: 400;
        text-align: left;
        color:$fff-brand-new-dark;
        font-family: $fff-font-sans;
        font-weight: 700;
        width: 100%;

        @include media-breakpoint-for(mobile) {
          width: 88%;
        }

        @include media-breakpoint-for(tab) {
          width: 88%;
        }

        @include media-breakpoint-for(tab-landscape) {
          width: 88%;
        }
      }

      .close {
        color: $fff-brand-new-dark;
        background: transparent;
        border: 0px;
        outline: 0px;
        font-size: 29px;
        padding: 8px 15px 8px;
        font-weight: 400;
        position: absolute;
        right: 0px;
        top: 0;
        height: auto;
        line-height: 29px;
        margin: 0;
      }
    }

    .modal-body {
      &.remove-bottom-margin {
        margin-bottom: 0;

        @include media-breakpoint-for(mobile) {
          min-height: 140px;
          max-height: calc(100vh - 170px);
        }
      }

      padding: 1.5rem 1rem;
      max-height: calc(100vh - 200px);
      overflow-y: auto;
      overflow-x: hidden;
      margin-bottom: 55px;

      &.noScroll {
        max-height: calc(100vh - 200px);
        overflow: visible;
        border: 1px solid #fff;
      }

      &.scroll-x {
        overflow-x: auto;
        margin-right: 5px;
      }

      .order-form-top-section {
        margin-top: 0px;

        p {
          margin-top: 0px;
        }
      }
    }

    .modal-footer {
      justify-content: center;
      padding: 8px 16px;
      display: block;
      text-align: center;

      > :first-of-type {
        float: left;
      }

      > :last-of-type {
        float: right;
      }

      .center {
        margin: 0 auto;
        float: none;
      }

      button {
        min-width: 120px;
      }

      &.btn-center-m {
        @include media-breakpoint-for(mobile) {
          text-align: center;
          // height: 120px; //TODO: verify
        }

        button {
          @include media-breakpoint-for(mobile) {
            float: unset !important;
            margin: .25rem; //TODO: verify
            // margin: 0px 5px 20px;
          }
        }
      }
    }
  }
}

.modal-backdrop.show {
  opacity: 0.64;
}

.custom-modal-body {
  margin-bottom: 0 !important;
}

.custom-modal-footer {
  position: fixed;
  bottom: 0;
  margin: 0 auto;
  right: 0;
  left: 0;
  width: calc(100% - 2px);
  border-radius: 0 0 0.3rem 0.3rem;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-end;
  justify-content: center;
  border-top: 1px solid #dee2e6;
  height: 57px;
  background-color: white;
  display: block;
  padding: 8px 16px;
  text-align: center;

  > :first-of-type {
    float: left;
  }

  > :last-of-type {
    float: right;
  }

  &.make-it-flex {
    display: flex;
    justify-content: space-between;
  }

  &.height-md {
    height: 120px;
  }

  &.btn-center-m {
    @include media-breakpoint-for(mobile) {
      text-align: center;
      height: 120px;
    }

    button {
      @include media-breakpoint-for(mobile) {
        float: unset !important;
        margin: 0px 5px 20px;
      }
    }
  }

  .center {
    margin: 0 auto;
    float: none;
  }

  button {
    min-width: 120px;

    @include media-breakpoint-for(mobile) {
      line-height: 20px;
    }
  }
}

.reorder-modal, .subscription-modal, .fff-modal {
  .modal-content {
    .modal-header {
      box-shadow: none;
      border-bottom: 1px solid rgba($fff-brand-new-dark, 0.12);
      margin: 0 1rem;
      padding: 0.75rem 0;

      .modal-title {
        text-align: left;
        color: $fff-brand-new-dark;

        @include media-breakpoint-for(mobile) {
          width: 100%;
        }
      }

      .close {
        color: $fff-brand-new-primary;
        &::after {
          content: "\e904";
          font-family: 'Fff-Icons' !important;
          font-size: 20px;
          width: 28px;
          height: 28px;
          border-radius: 28px;
          display: block;
        }
        &:hover {
          &::after {
            background-color: $fff-brand-new-fill;
          }
        }
        span {
          display: none;
        }
      }
    }

    .modal-body {
      .radio-btn-default {
        padding: 0rem 1.75rem;
        width: auto;
        .checkmark {
          width: 18px;
          height: 18px;
          border: 1px solid $fff-brand-new-primary;
          &::after {
            width: 12px;
            height: 12px;
            position: absolute;
            inset: 2px 0;
            margin: 0 auto;
            background-color: $fff-brand-new-primary;
          }
        }
      }

      .btn-place-new-order {
        width: 100%;
      }

      .txt-or-separator {
        text-align: center;
        color: $fff-brand-new-primary;
      }

      .summer-detail-container  {
        background-color: rgba($fff-brand-new-primary, 0.05);
        padding: 1rem;

        h5 {
          text-align: left;
          border-bottom: 1px solid rgba($fff-brand-new-dark, 0.12);
          font-weight: 700;
          color: $fff-brand-new-dark;
          padding-top: 0;
        }

        .macro-cards-row {
          column-gap: 0.5rem;
          .macro-card {
            flex: 1;
            .macro-lable {
              color: $fff-brand-new-primary;
              font-weight: 700;
              margin-bottom: 0.25rem;
              text-align: center;
            }
            .macro-value {
              padding: 0.625rem 0.25rem;
              background-color: $fff-brand-new-fill;
              color: $fff-brand-new-primary;
              text-align: center;
              font-size: 18px;
            }
          }
        }

        .calorie-allowance-row {
          p {
            color: $fff-brand-new-primary;
            text-align: center;
            font-weight: 700;
            margin: 0.75rem 0 1rem;
          }
        }
        
        .order-detail-row {
          color: $fff-brand-new-dark;
          align-items: baseline;
          .label {
            font-size: 12px;
            font-weight: 700;
            padding-right: 1.25rem;
          }

          .value {
            line-height: 22px;
          }

          .payment-options-wrapper {
            display: flex;
            .radio-btn-default {
              padding: 1rem 1.75rem;
              width: auto;
              p {
                color: $fff-brand-new-dark;
                font-family: $fff-font-sans;
                font-weight: 700;
              }
              .checkmark {
                width: 18px;
                height: 18px;
                border: 1px solid $fff-brand-new-primary;
                &::after {
                  width: 12px;
                  height: 12px;
                  position: absolute;
                  inset: 2px 0;
                  margin: 0 auto;
                  background-color: $fff-brand-new-primary;
                }
              }
            }
          }

          .p-calendar {
            .p-inputtext {
              width: 100%;
              color: $fff-brand-new-accent;
              font-weight: 600;
              border: 1px solid rgba($fff-brand-new-dark, 0.125);
              cursor: auto;
              &:hover, &:focus {
                border-color: $fff-brand-new-secondary !important;
              }
            }

            .p-button {
              span {
                color: $fff-brand-new-primary;
              }
            }
          }

          #discountCode, #tokens {
            width: 100%;
            border: 1px solid rgba($fff-brand-new-dark, 0.125);
            &:hover, &:focus {
              border-color: $fff-brand-new-secondary;
            }
          }
        }

        .delivery-pattern-row {
          padding-top: 0.75rem;
          padding-bottom: 0.75rem;
          > div {
            width: 100%;
            background: none;
            border: none;
            padding: 0;
          }

          .delivery-days-wrapper {
            gap: 0.25rem;
            .delivery-day-item {
              padding: 0.5rem;
              p {
                color: $fff-brand-new-dark;
              }
              .total-count {
                display: flex;
                justify-content: center;
                .total {
                  width: 32px;
                  height: 32px;
                  max-width: 32px;
                  display: flex;
                  border-radius: 32px;
                  align-items: center;
                  justify-content: center;
                  background-color: $fff-brand-new-primary;
                  color: $fff-brand-new-light;
                }
              }
            }
          }
        }

        .order-summary {
          margin: 0 -15px -15px;
          padding: 1rem 0;
          background-color: $fff-brand-new-light;
          p {
            padding: 0 1rem;
          }
          .total-bill-row {
            &::before {
              border-color: rgba($fff-brand-new-dark, 0.12);
            }
            &::after {
              display: none;
            }
            .lable {
              font-size: 16px;
              width: 60%;
              text-align: right;
            }
            .value {
              width: 40%;
              text-align: right;
            }
          }
        }

        .custom-modal-footer {
          display: flex;
          align-items: center;
        }
      }

      .token-conversion-content-area {
        .available-tokens {
          font-weight: 700;
        }

        .token-conversion-content {
          padding: 0 1rem;
          display: flex;
          gap: 1rem;
          align-items: end;

          .token-input-field-col {
            padding: 0;
            flex: 1;
            max-width: 70%;

            @include breakpoint(mobile) {
              max-width: 100%;
            }

            .single-input-field {
              label {
                font-weight: normal;
                font-family: $fff-font-sans;
                margin-bottom: 1rem;
                &:not(.validation-error-label) {
                  color: $fff-brand-grey;
                }
              }

              .label {
                font-size: 14px;
                text-transform: none;
              }
            }
          }

          .operator-col {
            padding: 0;
            max-width: 10px;
            display: flex;
            align-items: center;
            justify-content: center;

            .operator {
              height: 48px;
              display: flex;
              align-items: center;
            }
          }

          .cash-value-col {
            padding: 0;
            min-width: fit-content;
            max-width: fit-content;

            .single-input-field {
              height: 48px;
              display: flex;
              align-items: center;
              flex-direction: column;
              text-align: center;
            }

            label {
              font-family: $fff-font-sans;
            }

            input {
              text-align: center;
              padding-left: 12px !important;
              &:disabled {
                background-color: rgba($fff-brand-new-primary, 0.04);
              }
            }

            .cash-amount {
              font-weight: 700;
              font-size: 18px;
            }
          }
        }

        .info-text {
          padding: 0.25rem 1rem;
          border-radius: 4px;

          p {
            font-size: 14px;
            color: rgba($fff-brand-grey, 0.72);
            margin: 0;
          }
        }
      }

      .cash-value-container {
        height: 48px;
        display: flex;
        align-items: center;
        background-color: rgba($fff-brand-new-primary, 0.04);
        padding: 0.5rem;
        border-radius: 4px;

        .cash-value {
          span {
            font-weight: 700;
          }
        }
      }
    }
  }

  .selectBox {
    .fff__control {
      border: 1px solid rgba($fff-brand-new-dark, 0.125);
      &:hover {
        border-color: $fff-brand-new-secondary;
      }
      &--is-focused {
        border-color: $fff-brand-new-secondary;
      }
    }
  }
}

.fff-modal {
  &.title-center {
    .modal-title {
      text-align: center !important;
    }
  }

  .modal-body {
    margin-bottom: 2rem !important;

    p {
      color: $fff-brand-new-dark;
    }

    .cal-col {
      color: $fff-brand-new-dark;
      .value {
        height: 100%;
        display: flex;
        align-items: center;
      }
    }

    .btn-wrapper {
      margin: 1rem 0 0;
    }
  }

  .modal-footer {
    border-top: 0;
    padding-top: 0 !important;

    .btn-wrapper {
      width: 100%;
    }
  }

  &.no-footer {
    .modal-body {
      margin-bottom: 0 !important;
      padding-bottom: 1rem;
    }
  }
}

.fff-date-input {
  .p-datepicker {
    position: relative;
    top: 0 !important;
  }

  .p-inputwrapper-filled {
    .p-inputtext:enabled:hover:not(.p-error) {
      border-width: 2px;
    }
  }
}

.fff-modal-admin-portal {
  .fff-input-inline {
    .p-datepicker {
      position: relative;
      top: 0 !important;
    }
  }
}