@import "./responsive";

.input-group {
  margin-bottom: 5px;

  /* removing arrow buttons of number input from Chrome, Safari, Edge, Opera and Firefox */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  /* ----- */
  &.inch-ft {
    margin-bottom: 0px;

    .inch-label {
      color: $fff-brand-new-primary;
      height: 18px;
      line-height: 18px;
      font-size: 14px;
      padding: 0px 10px 0px 0px;
      top: 50%;
      margin-top: -9px;
      width: auto;
      border-radius: 0;
      margin-right: 4px;
      border: 0;
      text-align: center;
      box-shadow: none;
      position: absolute;
      z-index: 1;
      right: 60%;
      cursor: text;
      display: block;
    }

    .form-control {
      &:first-of-type {
        border-radius: 5px 0px 0px 5px !important;
        border-right: 0px;
        width: 40%;

        &:hover {
          border-right: 2px solid $fff-brand-new-secondary;
        }
      }

      &:last-of-type {
        border-radius: 0px 5px 5px 0px !important;
        width: 60%;
      }

      &.validation-error-outline {
        &:first-of-type {
          border-right: 0px !important;
        }
      }
    }
  }

  .custom-addon {
    position: absolute;
    right: 3px;
    bottom: 6px;
    height: 32px;
    z-index: 3;

    &.vertical-center {
      bottom: -1px;
      right: 6px;
    }

    span {
      background: white;
      border: 0;
      height: 28px;
      font-size: 14px;
    }

    button {
      background: white;
      color: $fff-brand-new-primary;
      height: 34.5px;
      font-size: 14px;
      padding: 0px 10px 0px 0px;
      margin-top: 0.5px;
      width: auto;
      border-radius: 0px 3px 3px 0px !important;
      margin-right: -1px;
      border: 0;
      text-align: center;
      box-shadow: none;

      &:not(:disabled):not(.disabled):active,
      &:hover,
      &:active,
      &:focus {
        background: white;
        color: $fff-brand-new-primary;
        box-shadow: none;
      }

      i {
        margin-left: 5px;
        vertical-align: middle;
      }
    }

    .measurement-units {
      border: none;
      width: 70px;
      font-size: 14px;
      position: relative;
      top: -5px;

      .css-vj8t7z {
        min-height: 32px;
        border: none;
        background-color: transparent;

        &:hover {
          border: none;
        }
      }

      .css-2o5izw {
        border: none;
        box-shadow: none;
        background-color: transparent !important;

        &:hover {
          border: none;
        }
      }

      .css-1hwfws3 {
        padding: 2px 5px;
      }
    }
  }

  .form-control {
    padding: 0rem 0.75rem;

    &.StripeElement {
      padding-top: 0.8rem;
    }
  }
}

label {
  width: 100%;
  color: rgba($fff-brand-grey, 0.72);
  font-size: 12px;
  text-transform: capitalize;
  margin-bottom: 2px;
  font-weight: 400;
  font-family: "Soleil-Bold", sans-serif;

  &.required {
    color: red;
    margin-top: -10px;
    margin-bottom: 15px;
    font-size: 12px;
    font-weight: 400;
  }

  .error {
    color: red;
    font-size: 12px;
    font-weight: 400;
  }
}

.form-control {
  height: 40px;
  width: 100%;
  border: 2px solid rgba(#000000, 0.125);
  border-radius: 4px !important;
  font-size: 14px;
  padding: 5px 12px;

  &:focus {
    box-shadow: none;
    border: 2px solid $fff-brand-new-secondary;
  }

  &.error {
    border: 2px solid rgba(red, 0.8);
  }

  &::placeholder {
    color: rgba($fff-brand-grey, 0.36);
  }

  &:-ms-input-placeholder {
    color: rgba($fff-brand-grey, 0.36);
  }

  &::-ms-input-placeholder {
    color: rgba($fff-brand-grey, 0.36);
  }
}

input {
  &:focus {
    box-shadow: none;
    border: 2px solid $fff-brand-green;
  }

  &.error {
    border: 2px solid rgba(red, 0.8);
  }

  &::placeholder {
    color: rgba($fff-brand-grey, 0.36);
  }

  &:-ms-input-placeholder {
    color: rgba($fff-brand-grey, 0.36);
  }

  &::-ms-input-placeholder {
    color: rgba($fff-brand-grey, 0.36);
  }
}

.prompt-post-code {
  .form-auto-complete {

    .validation-error-outline,
    .multi-input-datepicker .validation-error-outline {
      border: 2px solid red !important;
    }
  }
}

.form-auto-complete,
.multi-input-datepicker {
  //added class multi-input-datepicker
  width: 100%;
  margin-bottom: 15px;

  // Added styles for the affiliate sign up form ( .css-bg1rzq-control, .css-1szy77t-control)
  .validation-error-outline {
    border-radius: 4px;
    border: 0 !important;

    .css-bg1rzq-control {
      border-color: red;
      border-width: 2px;

      &:hover {
        border-color: red;
      }
    }

    .css-1szy77t-control {
      border-color: red !important;
      border-width: 2px;
      box-shadow: 0 0 0 0.2px red;
    }
  }

  .css-bg1rzq-control {
    border-color: rgba(3, 110, 56, 0.25);
    border-width: 2px;
    min-height: 40px;

    &:hover {
      border-color: rgba(3, 110, 56, 0.25);
    }
  }

  .css-1szy77t-control {
    border-color: #036e38;
    border-width: 2px;
    box-shadow: 0 0 0 0.2px #036e38;
  }
}

.referral-section {
  padding: 15px 0px 0px;
  margin-bottom: 30px;
  background-color: $fff-brand-light-green;
  box-shadow: 0px 1px 4px rgba($fff-brand-grey, 0.08);

  .referral-button {
    background: white;
    padding: 15px;

    .button {
      margin: 3px;
      box-shadow: none;

      &.btn-icon {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        padding: 0px;
      }
    }
  }
}

.calendar {
  width: 100%;
  padding: 0.4rem 0.75rem !important;
  width: 100%;
  border: 2px solid rgba($fff-brand-green, 0.18) !important;
  border-radius: 5px !important;
  outline: 0px;

  &:focus,
  &:enabled:hover:not(.p-error) {
    box-shadow: none;
    border: 2px solid $fff-brand-green;
  }

  >div {
    @include media-breakpoint-for(mobile) {
      left: 0px !important;
      right: 0px;
      width: 100%;
      min-width: fit-content !important;
    }

    @include media-breakpoint-for(tab) {
      left: 0px !important;
      right: 0px;
      width: 100%;
      min-width: fit-content !important;
    }

    @include media-breakpoint-for(tab-landscape) {
      left: 0px !important;
      right: 0px;
      width: 100%;
      min-width: fit-content !important;
    }

    table {
      tbody {
        tr {
          td {
            @include media-breakpoint-for(mobile) {
              padding: 0.1rem;
            }

            @include media-breakpoint-for(tab) {
              padding: 0.1rem;
            }

            @include media-breakpoint-for(tab-landscape) {
              padding: 0.1rem;
            }

            a {
              &.p-highlight {
                background-color: $fff-brand-green;
              }
            }
          }
        }
      }
    }
  }
}

// check box styles
.mini-checkbox {
  p {
    font-size: 12px;
    text-transform: none;
  }
}

.checkbox-default {
  display: block;
  position: relative;
  padding-left: 30px;
  padding-right: 0px;
  margin-bottom: 6px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: left;
  padding-top: 1px !important;
  height: 30px;
  margin-bottom: 15px;

  @include media-breakpoint-for(mobile) {
    height: auto;
  }

  @include media-breakpoint-for(tab) {
    height: auto;
  }

  @include media-breakpoint-for(tab-landscape) {
    height: auto;
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.auto-width {
    width: auto;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    color: $fff-brand-grey;
    text-transform: none;
    margin-top: 7px;
    margin-bottom: 0rem !important;
    padding-left: 12px;
    font-family: $fff-font-sans;
  }

  input {
    position: absolute;
    opacity: 0;
    left: 0;
    top: 0;
    height: 30px;
    width: 30px;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    width: 30px;
    background-color: #fff;
    border: 2px solid rgba(#000000, 0.125);
    border-radius: 4px;
    margin-top: 2px;
    margin-right: 1px;
    margin-left: 0px;
  }

  &:hover input~.checkmark {
    background-color: #eee;
  }

  input:checked~.checkmark {
    background-color: white;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked~.checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 10px;
    top: 4px;
    width: 7px;
    height: 17px;
    border: 1px solid $fff-brand-new-primary;
    border-width: 0px 2px 2px 0px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

// radio box styles
.radio-btn-default {
  display: inline-block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: left;
  padding-top: 1px !important;
  float: left;
  padding-left: 38px;
  margin-bottom: 1rem;
  width: 100%;
  height: 28px;
  text-transform: none;

  &.disabled {
    opacity: 0.5;
  }

  p {
    text-align: left;
    font-weight: 500;
    font-size: 14px;
    display: block;
    margin-bottom: 0rem !important;
    color: $fff-brand-grey;
    margin-top: 0.2rem;
    font-family: "SoleilLt", sans-serif;
  }

  input {
    position: absolute;
    opacity: 0;
  }

  .checkmark {
    position: absolute;
    left: 0;
    height: 28px;
    width: 28px;
    background-color: white;
    border-radius: 50%;
    border: 2px solid rgba($fff-brand-green, 0.18);
    top: 50%;
    transform: translateY(-50%) !important;
    margin-left: 0px;
  }

  &:hover input~.checkmark {
    background-color: white;
  }

  input:checked~.checkmark {
    background-color: white;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked~.checkmark:after {
    display: block;
  }

  .checkmark:after {
    top: 7px;
    left: 7px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: $fff-brand-green;
  }
}

// hidden radio box styles
.radio-btn-box {
  display: inline-block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: center;
  float: left;
  margin-bottom: 15px;
  width: 100%;
  text-transform: none;
  border: 2px solid rgba($fff-brand-green, 0.16);
  padding: 15px;

  &.disabled {
    opacity: 0.5;
  }

  &.selected {
    border: 2px solid $fff-brand-green;
  }

  p {
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    display: block;
    margin-bottom: 0rem !important;
    color: $fff-brand-grey;
    margin-top: 0.2rem;
    font-family: "SoleilLt", sans-serif;
  }

  input {
    position: absolute;
    opacity: 0;
  }

  .checkmark {
    position: absolute;
    left: 0;
    height: 28px;
    width: 28px;
    background-color: white;
    border-radius: 50%;
    border: 2px solid rgba($fff-brand-green, 0.18);
    top: 50%;
    transform: translateY(-50%) !important;
    margin-left: 0px;
  }

  &:hover input~.checkmark {
    background-color: white;
  }

  input:checked~.checkmark {
    background-color: white;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked~.checkmark:after {
    display: block;
  }

  .checkmark:after {
    top: 7px;
    left: 7px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: $fff-brand-green;
  }
}

.selectBox {
  width: 100%;
  border: 0;
  margin-bottom: 0px;
  border-radius: 4px !important;

  &.tenant-switcher {
    .fff__control {
      border-radius: 20px;
    }
  }

  &:focus {
    outline: 0;
    box-shadow: none;
  }

  &.dropdown-top {
    .fff_ {
      &_menu {
        @include media-breakpoint-for(mobile) {
          margin-top: -300px;
          border-radius: 8px 8px 0px 0px;
        }

        @include media-breakpoint-for(tab) {
          margin-top: 0px;
          border-radius: 0px 0px 8px 8px;
        }

        @include media-breakpoint-for(tab-landscape) {
          margin-top: 0px;
          border-radius: 0px 0px 8px 8px;
        }
      }
    }
  }

  .fff_ {
    &_control {
      padding: 0px 0px 0px 12px;
      border: 1px solid rgba($fff-brand-new-dark, 0.125);
      min-height: 40px;

      &:hover {
        border: 1px solid rgba($fff-brand-green, 0.125);
      }

      &--is-focused {
        box-shadow: none;
        border: 1px solid $fff-brand-new-secondary !important;

        .fff__dropdown-indicator {
          color: $fff-brand-new-dark;
        }
      }
    }

    &_value-container {
      padding: 0px;

      .css-1g6gooi {
        margin: 0px;
      }
    }

    &_placeholder {
      margin: 0px;
    }

    &_multi-value {
      background-color: rgba($fff-brand-grey, 0.18);
      margin-right: 5px;

      &:last-of-type {
        margin-right: 0px;
      }
    }

    &_multi-value_ {
      &_remove {
        color: $fff-brand-green;
        cursor: pointer;

        &:hover {
          background: none;
        }
      }
    }

    &_single-value {
      padding-left: 0px;
      font-family: "SoleilLt", sans-serif;
      font-size: 14px;
    }

    &_indicators {
      color: rgba($fff-brand-green, 0.18);

      &:hover {
        color: $fff-brand-green;
      }
    }

    &_indicator {
      color: $fff-brand-new-dark;

      &:hover,
      &:focus {
        color: $fff-brand-new-dark;
      }
    }

    &_clear-indicator {
      color: rgba($fff-brand-green, 0.18);

      &:hover {
        color: $fff-brand-green;
      }
    }

    &_indicator-separator {
      display: none;
    }

    &_dropdown-indicator {
      padding: 0px 10px;
      color: rgba($fff-brand-new-dark, 0.125);
      cursor: pointer;

      &:focus {
        color: $fff-brand-new-dark;
      }
    }

    &_menu {
      margin: 0;
      border-radius: 0px 0px 4px 4px;
      background: none;
      border: 0;
      border-top-color: $fff-brand-grey;
      box-shadow: none;
      min-width: 100%;
      z-index: 10000;
      height: calc(100% + 280px);

      .css-z5z6cw {
        background: $fff-brand-green;
      }
    }

    &_menu-list {
      background-color: $fff-brand-new-light;
      box-shadow: 0px 0px 6px rgba(#000000, 0.16);
    }

    &_option {
      background-color: transparent;

      &--is-selected {
        background: $fff-brand-new-fill;
        color: $fff-brand-new-primary;
      }

      &:focus,
      &:active,
      &.isSelected {
        background: $fff-brand-new-primary !important;
        color: white;

        &:hover {
          background: $fff-brand-new-primary !important;
          color: white;
        }
      }

      &:hover {
        background: $fff-brand-new-fill;
        color: $fff-brand-new-primary;
      }
    }
  }
}

.error {
  border: 2px solid rgba(red, 0.8);
}

.toggle-wrapper {
  float: left;

  &.disabled {
    .toggle {
      opacity: 0.5;
    }
  }

  .toggle {
    display: inline-block;
    height: 0.51rem;
    outline: none !important;

    &.p-inputswitch-focus {
      outline: none !important;

      .p-inputswitch-slider {
        box-shadow: none;
      }
    }

    &.p-inputswitch-checked {
      outline: none !important;

      .p-inputswitch-slider {
        background-color: rgba($fff-brand-green, 0.18) !important;

        &::before {
          bottom: -0.3rem !important;
          background-color: $fff-brand-green !important;
        }
      }
    }

    .p-inputswitch-slider {
      background-color: rgba($fff-brand-grey, 0.18) !important;

      &::before {
        bottom: -0.3rem !important;
        background-color: #ccc;
      }
    }
  }

  p {
    display: inline-block;
    margin-left: 10px;
  }
}

.multi-datepicker-row {
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-flex;
  display: flex;
  width: 100%;

  .multi-input-datepicker {
    width: 31%;
    outline: 0;
    border: 0px;

    .css-bgvzuu-indicatorSeparator {
      display: none;
    }

    svg {
      color: rgba($fff-brand-green, 0.16);
    }

    >.css-vj8t7z {
      border: 2px solid rgba($fff-brand-green, 0.18);
      box-shadow: none;
      height: 20px;

      .css-1wy0on6 {
        .css-d8oujb {
          display: none;
        }
      }
    }

    .css-2o5izw {
      border-color: $fff-brand-new-secondary;
      border-width: 2px;
      box-shadow: none;
      height: 20px;

      &:hover,
      &:focus {
        border-color: $fff-brand-new-secondary;

        >div {
          >div {
            svg {
              color: $fff-brand-new-secondary;
            }
          }
        }
      }

      .css-1wy0on6 {
        .css-d8oujb {
          display: none;
        }
      }
    }

    .css-15k3avv {
      z-index: 10000;

      >div {
        z-index: 10000;

        >div {

          &:not(:disabled):not(.disabled):active,
          &:hover,
          &:active,
          &:focus {
            background: rgba($fff-brand-green, 0.16);
            color: black;
          }
        }
      }

      .css-z5z6cw {
        background: $fff-brand-new-secondary;
        color: white;
      }
    }
  }
}

.btn-group {
  .btn-rounded {
    width: 30px;
    height: 30px;
    margin: 5px 0px;
    font-size: 20px;
    line-height: 19px;
    box-shadow: none;
    position: relative;

    &.minus {
      &::after {
        content: " ";
        position: absolute;
        background-image: url("../../assets/img/icons/fff-minus.svg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center bottom;
        top: 3px;
        bottom: 2.5px;
        left: 3px;
        right: 3px;
      }
    }

    &.plus {
      &::after {
        content: " ";
        position: absolute;
        background-image: url("../../assets/img/icons/fff-plus.svg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        top: 3px;
        bottom: 3px;
        left: 3px;
        right: 3px;
      }
    }
  }
}

.genderButton {
  font-family: inherit;
  width: 50%;
  padding: 0;
  box-shadow: none !important;

  &.male {
    border-radius: 5px 0 0 5px;
  }

  &.female {
    border-radius: 0px 5px 5px 0px;
  }
}

.themed-checkbox {
  padding: 3px;
  position: relative;
  display: block;

  &:hover {
    input[type="checkbox"]+label:before {
      background-color: #eeeeee;
    }
  }

  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    margin-left: 0;
    margin-top: 0px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  label {
    cursor: pointer;
    margin-bottom: 0;
  }

  input[type="checkbox"]+label:before {
    border: 2px solid rgba($fff-brand-green, 0.18);
    content: "\00a0";
    display: inline-block;
    font: 14px/1em sans-serif;
    line-height: 16px;
    height: 20px;
    margin: 0 0.25em 0 0;
    padding: 0;
    width: 20px;
    cursor: pointer;
  }

  input[type="checkbox"]:checked+label:before {
    background: #ffffff;
    color: $fff-brand-green;
    content: "\2713";
    text-align: center;
  }

  input[type="checkbox"]:checked+label:after {
    font-weight: bold;
  }
}

.text-unset {
  text-transform: unset !important;
}

%form-message {
  display: block;
  padding-top: 4px;
  font-weight: 600;
}

.error-message {
  color: rgba(red, 0.8);
  @extend %form-message;
}

.success-message {
  color: $fff-brand-black-text;
  @extend %form-message;
}

.fff-field-group {
  padding: 0 1rem;
  column-gap: 1rem;

  .col {
    padding: 0;

    @include breakpoint(mobile) {
      flex: 0 0 100%;
    }
  }
}

.fff-col-md {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 50%;

  @include breakpoint(tab-down) {
    flex: 0 0 100%;
    max-width: 100%;

    &.col-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }

    &.col-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

.user-profile,
.fff-modal {
  .fff-input-inline {
    width: 100%;
    position: relative;
    margin-bottom: 1.5rem !important;

    .input-group {
      &.inch-ft {
        .inch-label {
          width: fit-content;
          margin: auto;
          margin-right: 2px;
          margin-top: -9px;
          color: $fff-brand-new-primary;
          font-weight: 600;
          z-index: 10;
          text-transform: lowercase;
        }

        .form-control {
          &:first-of-type {
            margin-right: -1px;

            &:hover {
              border-right: 0;
            }

            &:focus {
              border-right: 1px solid $fff-brand-new-secondary;
            }
          }
        }

        .validation-error-outline {
          &:first-of-type {
            &:focus {
              border-right: 1px solid $fff-brand-red !important;
            }
          }
        }
      }
    }

    label {
      width: fit-content;
      padding: 0 0.75rem 0 0.625rem;
      position: absolute;
      z-index: 1;
      margin: 0;
      top: -8px;
      background: white;
      left: 1.5rem;
      font-weight: normal;
      font-family: $fff-font-sans;
      border-radius: 20px;

      &::after {
        content: '';
        width: 100%;
        height: 2px;
        display: block;
        background-color: #fff;
      }
    }

    input,
    .input-element {
      height: 48px;
      min-height: 48px !important;
      width: 100%;
      display: flex;
      align-items: center;
      line-height: 1.5;
      padding: 5px 12px;
      padding-left: 2rem !important;
      color: $fff-brand-new-dark !important;
      border: 1px solid rgba($fff-brand-grey, 0.24);
      margin-bottom: 0;
      border-radius: 5px;

      &.disabled {
        background-color: #EFEFEF;
      }

      strong {
        line-height: 48px;
      }

      &:disabled {
        background-color: #fff;
      }

      &:focus {
        border: 1px solid $fff-brand-new-secondary;
      }
    }

    .p-calendar {
      width: 100%;
    }

    .p-inputtext {
      border: 1px solid rgba($fff-brand-grey, 0.24);
    }

    .selectBox {
      .fff {
        &__single-value {
          font-family: $fff-font-sans;
          padding-left: 2rem;
          margin: 0;
        }
      }

      .order-form {
        &__control {
          min-height: 48px;
          height: 48px;
          padding-left: 2rem;
          border: 1px solid rgba($fff-brand-grey, 0.24);

          &:focus {
            border: 1px solid $fff-brand-new-secondary;
          }

          &--is-focused {
            border: 1px solid $fff-brand-new-secondary;

            &:hover {
              border: 1px solid $fff-brand-new-secondary;
            }
          }
        }

        &__placeholder {
          margin: 0;
        }

        &__value-container {
          padding: 0;

          .css-1g6gooi {
            margin: 0;
          }
        }

        &__single-value {
          margin: 0;
          color: $fff-brand-new-dark;
          font-weight: normal;
        }
      }

      input {
        padding-left: 0 !important;
        min-height: 10px !important;
        height: 40px;
      }
    }

    .address-box {
      padding: 1rem 2rem;
    }

    .StripeElement {
      height: 48px;
      padding: 1.125rem 0.75rem 1rem 2rem;
      padding-left: 2rem;
      border: 1px solid rgba($fff-brand-grey, 0.24);

      &:focus {
        border: 1px solid $fff-brand-new-secondary;
      }
    }

    .StripeElement--focus {
      border: 1px solid $fff-brand-new-secondary;
    }

    .fff {
      &__control {
        padding: 0;
        border: 1px solid rgba($fff-brand-grey, 0.24) !important;
        cursor: unset;

        &--is-focused {
          border: 1px solid $fff-brand-new-secondary !important;
        }
      }

      &__placeholder {
        padding-left: 2rem;
      }

      &__input {
        padding-left: 2rem;
      }
    }

    .custom-addon {
      bottom: 10px;
    }

    .col {
      padding-left: 0;
      padding-right: 0;
    }

    textarea {
      padding: 1rem 1rem 1rem 2rem;
      border: 1px solid rgba($fff-brand-new-dark, 0.125);

      &:focus {
        border-width: 1px;
      }
    }

    .validation-error-outline {
      border-width: 1px !important;
    }

    .fff-phone-number-input {
      border: 1px solid rgba($fff-brand-new-dark, .125);
    }

    .p-datepicker {
      position: relative;
      top: 0 !important;
    }
  }

  .single-input-field {
    .fff-input-inline {
      margin-bottom: 0 !important;
    }
  }
}

.fff-input-file-upload {
  position: relative;

  label {
    width: fit-content;
    padding: 0 0.75rem 0 0.625rem;
    font-family: $fff-font-sans;
    position: absolute;
    z-index: 10;
    left: 1.5rem;
    top: -8px;
    background-color: $fff-brand-new-light;
  }

  >div {
    text-align: right;
    border: 1px solid rgba($fff-brand-grey, 0.12);
    border-radius: 4px;
    padding: 1.25rem 1rem 1rem;
    margin-top: 0;

    img {
      border-radius: 4px;
    }
  }

  .btn-close {
    outline: 0;
    border: none;
    box-shadow: none !important;
    background: none !important;
    position: absolute;
    top: 0;
    right: 0;
    padding: 8px;

    &::after {
      content: "\E904";
      font-family: 'Fff-Icons' !important;
      font-size: 20px;
      border-radius: 28px;
      display: block;
      color: $fff-brand-new-primary;
      font-weight: normal;
    }
  }
}

.btn-wrapper {
  display: flex;
  align-items: center;
  column-gap: 1rem;

  .full-width {
    width: 100%;
  }

  &.content-start {
    justify-content: start;
  }

  &.content-center {
    justify-content: center;
  }

  &.content-end {
    justify-content: end;
  }

  .btn {
    min-width: 110px;
  }
}

.fff-select-inline {
  .fff-inline {

    &__control,
    &__indicators,
    &__value-container {
      height: 48px;
      line-height: 1.5;

      &--is-focused {
        box-shadow: none;
        border-width: 2px;
        border-color: $fff-brand-new-secondary !important;
      }

      &:hover {
        border-color: rgba($fff-brand-grey, 0.24);
      }
    }

    &__value-container {
      padding-left: 0;
    }

    &__single-value {
      padding-left: 2rem;
      line-height: 2.5;
    }

    &__indicator-separator {
      display: none;
    }

    &__option {
      background-color: transparent;

      &--is-selected {
        background-color: $fff-brand-new-primary !important;
      }

      &:hover,
      &:focus {
        background-color: $fff-brand-new-fill;
      }
    }
  }
}

.fff-checkbox {
  .checkbox-default {
    height: auto;
    width: fit-content;
    padding-left: 2rem;
    margin-bottom: 0.625rem;

    &:hover input~.checkmark {
      background-color: $fff-brand-new-fill;
    }

    input:checked~.checkmark {
      background-color: white;
    }

    p {
      margin: 0;
      padding: 0;
    }

    .checkmark {
      height: 18px;
      width: 18px;
      border: 1px solid rgba($fff-brand-grey, 0.24);

      &::after {
        width: 6px;
        height: 10px;
        top: 2px;
        left: 5px;
      }
    }
  }
}

.fff-radio-btn {
  display: flex;
  flex-direction: column;

  .radio-btn-default {
    height: auto;
    width: fit-content;
    padding-left: 2rem;
    margin-bottom: 0.625rem;

    &:hover input~.checkmark {
      background-color: $fff-brand-new-fill;
    }

    input:checked~.checkmark {
      background-color: white;
    }

    p {
      margin: 0;
      padding: 0;
      font-family: "Poppins", sans-serif;
      font-weight: 400;
    }

    .checkmark {
      height: 18px;
      width: 18px;
      border: 1px solid rgba($fff-brand-grey, 0.24);

      &::after {
        height: 10px;
        top: 3px;
        left: 3px;
      }
    }
  }
}



.form-auto-complete {
  .customer-portal {
    &__control {
      border-color: rgba($fff-brand-new-dark, 0.125);
      border-width: 1px;
      box-shadow: none;
      min-height: 40px;
      cursor: text;

      &:hover {
        border-color: $fff-brand-new-secondary;
      }

      &--is-focused {
        border-color: $fff-brand-new-secondary;
      }
    }

    &__multi-value {
      background-color: rgba($fff-brand-new-primary, 0.04);

      &__label {
        color: $fff-brand-new-dark;
        font-weight: bold;
      }

      &__remove {
        &:hover {
          background-color: $fff-brand-new-fill;
          color: $fff-brand-new-dark;
          cursor: pointer;
        }
      }
    }

    &__indicators {
      display: none;
    }

    &__option {
      &--is-focused {
        background-color: $fff-brand-new-fill;
      }

      &:hover {
        background-color: $fff-brand-new-fill;
      }
    }
  }
}

.fff-phone-number-input {
  border: 2px solid rgba($fff-brand-new-dark, .125);
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out;

  &:hover,
  &:focus {
    border-color: $fff-brand-new-secondary;
  }

  &:focus-within {
    border-color: $fff-brand-new-secondary;
  }

  &.react-tel-input {
    .form-control {
      width: 100%;
      border: 0;
      padding-left: 4.5rem !important;
    }

    .flag-dropdown {
      height: 30px;
      margin: auto;
      margin-left: 2px;
      background-color: transparent;
      border: 0;
      border-right: 1px solid rgba($fff-brand-new-dark, .125);

      .selected-flag {
        padding: 0 14px;
        width: 50px;
      }
    }
  }

  &:has(.validation-error-outline) {
    border-color: $fff-brand-red !important;
  }

  .validation-error-outline {
    border: none !important;
  }
}