@font-face {
  font-family: 'Fff-Icons';
  src:  url('../fonts/icons/Fff-Icons.eot?mywvra');
  src:  url('../fonts/icons/Fff-Icons.eot?mywvra#iefix') format('embedded-opentype'),
    url('../fonts/icons/Fff-Icons.ttf?mywvra') format('truetype'),
    url('../fonts/icons/Fff-Icons.woff?mywvra') format('woff'),
    url('../fonts/icons/Fff-Icons.svg?mywvra#Fff-Icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Fff-Icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-fff-yt:before {
  content: "\e90f";
}
.icon-fff-ig:before {
  content: "\e910";
}
.icon-fff-user:before {
  content: "\e90e";
}

.icon-fff-affili:before {
  content: "\e90d";
}
.icon-fff-delivery:before {
  content: "\e901";
}
.icon-fff-list:before {
  content: "\e902";
}
.icon-fff-nutri:before {
  content: "\e905";
}
.icon-fff-ref:before {
  content: "\e90c";
}
.icon-fff-play:before {
  content: "\e914";
  color: #066d38;
}
.icon-fff-li:before {
  content: "\e90b";
}
.icon-fff-search:before {
  content: "\e90a";
  color: #066d38;
}
.icon-fff-top:before {
  content: "\e909";
}
.icon-fff-tw:before {
  content: "\e908";
}
.icon-fff-fb:before {
  content: "\e907";
}
.icon-fff-done:before {
  content: "\e906";
  color: #066d38;
}
.icon-fff-close:before {
  content: "\e904";
  color: #066d38;
}
.icon-fff-chevron:before {
  content: "\e903";
}
.icon-fff-arrow:before {
  content: "\e900";
}

// FFF Icon Font - V2

@font-face {
  font-family: 'fff-icons-v2';
  src:  url('../fonts/icons/fff-icons-v2.eot');
  src:  url('../fonts/icons/fff-icons-v2.eot?2jfajq#iefix') format('embedded-opentype'),
    url('../fonts/icons/fff-icons-v2.ttf?2jfajq') format('truetype'),
    url('../fonts/icons/fff-icons-v2.woff?2jfajq') format('woff'),
    url('../fonts/icons/fff-icons-v2.svg?2jfajq#fff-icons-v2') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="fff-icon-"], [class*=" fff-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'fff-icons-v2' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fff-icon {
  font-size: 16px;
}

.fff-nav-icon {
  width: 30px;
  height: 30px;
  font-size: 30px;
  margin: 0 auto;
  background-size: contain;
  background-repeat: no-repeat;
}

.fff-icon-offer-bold:before {
  content: "\e900";
}
.fff-icon-offer:before {
  content: "\e901";
}
.fff-icon-offers:before {
  content: "\e902";
}
.fff-icon-fresh-perks:before {
  content: '\e903';
}
.fff-icon-account-logs:before {
  content: '\e904';
}
.fff-icon-referrals:before {
  content: '\e905';
}
.fff-icon-account-info:before {
  content: '\e906';
}
.fff-icon-manage-nutrition:before {
  content: '\e907';
}
.fff-icon-daily-menu:before {
  content: '\e908';
}
.fff-icon-my-order:before {
  content: '\e909';
}
.fff-icon-manage-delivery:before {
  content: '\e90a';
}
.fff-icon-delete:before {
  content: '\e90b';
}
.fff-icon-pencil:before {
  content: '\e90c';
}
.fff-icon-book-call:before {
  content: "\e90d";
  color: #003a1f;
}