@import './responsive';
@import './variables';

html {
  font-size: 14px;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: $fff-font-sans;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.width-3 {
  min-width: 150px;
  max-width: 200px;
}

.width-4 {
  min-width: 200px;
  max-width: 250px;
}

.container {
  position: relative;

  @include media-breakpoint-for(tab) {
    max-width: 100%;
  }

  @include media-breakpoint-for(tab-landscape) {
    max-width: 100%;
  }

  @media (min-width: 1600px) {
    max-width: 1540px;
  }
}

h1 {
  font-size: 24px;
  font-weight: 600;
  color: black;
  margin-bottom: 1.5rem;
  margin-top: 0rem;
  font-family: 'DINPro-Bold', sans-serif;
}

h2.page-section-title {
  font-size: 42px;
  text-align: center;
  font-family: $fff-font-serif;
  margin-bottom: 40px;

  @include media-breakpoint-for(mobile) {
    font-size: 28px;
    line-height: 30px;
    margin-bottom: 30px;
  }

  @include media-breakpoint-for(tab) {
    font-size: 28px;
    line-height: 30px;
    margin-bottom: 30px;
  }

  @include media-breakpoint-for(tab-landscape) {
    font-size: 28px;
    line-height: 30px;
    margin-bottom: 30px;
  }
}

p {
  font-size: 14px;
  font-weight: 400;
  color: $fff-brand-grey;

  .material-icons {
    font-size: 16px;
    vertical-align: middle;
    font-weight: 600;
  }

  a {
    color: $fff-brand-new-primary;
    border-top: 2px solid transparent;
    border-bottom: 1px solid $fff-brand-new-primary;
    cursor: pointer;
    text-decoration: none;
    -webkit-transition: 0.2s;
    transition: 0.2s;

    &:hover {
      color: $fff-brand-new-secondary !important;
      border-bottom-color: $fff-brand-new-secondary;
    }
  }
}

h2 {
  font-size: 16px;
  font-weight: 400;
}

button {
  outline: 0px;
}

a {
  outline: 0px;
  text-decoration: none !important;
  color: $fff-brand-green;
  cursor: pointer;

  .material-icons {
    font-size: 16px;
    vertical-align: middle;
    font-weight: 600;
    margin-top: 3px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.v-center {
  margin: 0 auto;
}

.nowrap {
  white-space: nowrap;
}

.text-lg {
  font-size: 20px;
  font-family: 'DINPro-Bold', sans-serif;
}

.text-center-m {
  @include media-breakpoint-for(mobile) {
    text-align: center;
  }
}

.center-m {
  @include media-breakpoint-for(mobile) {
    margin: 0 auto;
    float: unset !important;
  }
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.inline-block {
  display: inline-block;
}

.inline-flex {
  display: flex;
}

.flex-start {
  display: inline-flex;
  justify-content: flex-start;
  flex-grow: 1;
}

.flex-end {
  display: inline-flex;
  justify-content: flex-end;
  flex-grow: 1;
}

.full-width {
  width: 100%;
}

label {
  font-weight: 600;
}

.top-row-margin {
  margin: 0px 0px 15px;
}

.margin-right-sm {
  margin-right: 10px;
}

.margin-right-md {
  margin-right: 20px;
}

.margin-right-lg {
  margin-right: 30px;
}

.margin-left-sm {
  margin-left: 10px;
}

.margin-left-md {
  margin-left: 20px;
}

.margin-left-lg {
  margin-left: 30px;
}

.margin-top-xsm {
  margin-top: 6px;
}

.margin-top-sm {
  margin-top: 10px;
}

.margin-top-md {
  margin-top: 20px;
}

.margin-top-lg {
  margin-top: 30px;
}

.margin-top-xlg {
  margin-top: 60px;
}

.margin-bottom-xs {
  margin-bottom: 5px;
}

.margin-bottom-sm {
  margin-bottom: 10px;
}

.margin-bottom-md {
  margin-bottom: 20px;
}

.margin-bottom-lg {
  margin-bottom: 30px;
}

.margin-bottom-xlg {
  margin-bottom: 60px;
}

.margin-bottom-xxlg {
  margin-bottom: 90px;
}

.padding-bottom-lg {
  padding-bottom: 30px;
}

.padding-bottom-md {
  padding-bottom: 20px;
}

.padding-top-lg {
  padding-top: 30px;
}

.padding-top-md {
  padding-top: 20px;
}

.padding-top-sm {
  padding-top: 10px;
}

.padding-top-xsm {
  padding-top: 5px;
}

.top-xsm {
  top: 5px;
}

.padding {
  padding: 15px;
}

.no-padding {
  padding: 0px;
}

.padding-right-none {
  padding-right: 0px !important;
}

.mobile-center {
  @include media-breakpoint-for(mobile) {
    text-align: center !important;
  }
}

.padding-left-none {
  padding-left: 0px !important;
}

.underline {
  text-decoration: underline;
}

.no-margin {
  margin: 0px;
}

.no-margin-m {
  @include media-breakpoint-for(mobile) {
    margin: 0px !important;
  }

  @include media-breakpoint-for(tab) {
    margin: 0px !important;
  }

  @include media-breakpoint-for(tab-landscape) {
    margin: 0px !important;
  }
}

.no-shadow {
  box-shadow: none !important;
}

.center {
  margin: 0 auto;
}

.relative {
  position: relative;
}

.color-red {
  color: $fff-brand-red;
}

.color-green {
  color: $fff-brand-green;
}

.color-racing-green {
  color: $fff-brand-new-primary;
}

.color-medium-green {
  color: $fff-brand-medium-green;
}

.color-yellow {
  color: yellow;
}

.color-blue {
  color: $fff-brand-blue;
}

.color-md-grey {
  color: rgba($fff-brand-grey, 0.72);
}

.color-light-grey {
  color: rgba($fff-brand-grey, 0.32);
}

.color-black {
  color: black;
}

.light-green-bg {
  background-color: $fff-brand-new-fill;
}

.light-gray-bg {
  background-color: $fff-brand-grey-light;
}

.remove-shadow {
  box-shadow: none !important;
}

.white-box {
  background-color: white;
  box-shadow: 0px 1px 4px rgba($fff-brand-grey, 0.08);
  padding: 12px 16px;

  &.no-padding {
    padding: 12px 0px;
  }
}

.light-grey-bg {
  background-color: $fff-brand-new-fill;
}

.lighter-grey-bg {
  background-color: $fff-brand-new-fill;
}

.footer {
  position: fixed;
  bottom: 0px;
  padding: 0.5rem 1rem;
  width: 100%;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.16);
  z-index: 3;

  .footer-container {
    display: block;
    width: 600px;
    margin: 0 auto;
    box-sizing: border-box;

    @include media-breakpoint-for(mobile) {
      width: 100%;
    }

    @include media-breakpoint-for(tab) {
      width: 100%;
    }

    @include media-breakpoint-for(tab-landscape) {
      width: 100%;
    }

    .progress {
      height: 0.5rem;
      margin-top: 1rem;
    }
  }
}

.footer-row {
  background-color: rgba($fff-brand-grey, 0.04);
  padding: 0px;

  @include media-breakpoint-for(mobile) {
    &.bottom-margin {
      margin-bottom: 100px;
    }
  }

  @include media-breakpoint-for(tab) {
    @include media-breakpoint-for(tab) {
      max-width: 100%;
      flex: 0 0 100%;
    }

    @include media-breakpoint-for(tab-landscape) {
      max-width: 100%;
      flex: 0 0 100%;
    }

    &.bottom-margin {
      margin-bottom: 100px;
    }
  }

  @include media-breakpoint-for(tab-landscape) {
    @include media-breakpoint-for(tab) {
      max-width: 100%;
      flex: 0 0 100%;
    }

    @include media-breakpoint-for(tab-landscape) {
      max-width: 100%;
      flex: 0 0 100%;
    }

    &.bottom-margin {
      margin-bottom: 100px;
    }
  }

  .custom-container {
    padding: 0;
    position: relative;

    .nav-row {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
      justify-content: center;
      padding: 30px 0px;
      border-bottom: 1px solid rgba($fff-brand-grey, 0.18);
      margin: 0px 0px 20px;
      position: relative;

      @include media-breakpoint-for(mobile) {
        margin: 0px 15px 60px;
      }

      @include media-breakpoint-for(tab) {
        margin: 0px 15px 60px;
      }

      @include media-breakpoint-for(tab-landscape) {
        margin: 0px 15px 60px;
      }

      a {
        font-family: $fff-font-sans;
        font-size: 14px;
        font-weight: bold;
        padding: 5px 20px;
        color: rgba($fff-brand-grey, 0.72);

        @include media-breakpoint-for(mobile) {
          display: none;
        }

        @include media-breakpoint-for(tab) {
          display: none;
        }

        @include media-breakpoint-for(tab-landscape) {
          display: none;
        }

        &:hover {
          color: $fff-brand-grey;
        }
      }

      .scroll-top {
        width: 40px;
        height: 40px;
        line-height: 40px;
        border-radius: 50%;
        border: 2px solid rgba($fff-brand-green, 0.18);
        background-color: white;
        display: block;
        position: absolute;
        right: 0px;
        bottom: -20px;
        text-align: center;
        color: $fff-brand-green;
        padding: 0px;
        box-shadow: -7px 0px 0px #f8f8f8;
        text-decoration: none;
        cursor: pointer;

        &:not(:disabled):not(.disabled):active,
        &:focus,
        &:hover {
          color: $fff-brand-green;
          border-color: #d1e5da;
          background: #d1e5da;
        }

        i {
          font-size: 20px;
          width: auto;
          height: auto;
          text-align: center;
          display: block;
          line-height: 40px;
        }
      }
    }
  }

  .footer-bg-row {
    width: 100%;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: bottom -1px center;

    @include media-breakpoint-for(mobile) {
      background-size: contain;
    }

    @include media-breakpoint-for(tab) {
      background-size: contain;
    }

    @include media-breakpoint-for(tab-landscape) {
      background-size: contain;
    }

    .logo-row {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
      justify-content: center;
      margin: 0px;
      padding: 30px 0px 0px;
      height: auto;

      @include media-breakpoint-for(mobile) {
        padding: 10px 0px 60px;
        height: auto;
      }

      @include media-breakpoint-for(tab) {
        padding: 10px 0px 60px;
        height: auto;
      }

      @include media-breakpoint-for(tab-landscape) {
        padding: 10px 0px 60px;
        height: auto;
      }

      .logo {
        width: 120px;
        height: 55px;
        border: 0px;
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    .icon-row {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
      justify-content: center;
      margin: 0px;
      position: relative;
      padding: 40px 0px 60px;

      @include media-breakpoint-for(mobile) {
        display: none;
      }

      @include media-breakpoint-for(tab) {
        display: none;
      }

      @include media-breakpoint-for(tab-landscape) {
        display: none;
      }

      a {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #fff;
        margin: 0px 5px;
        line-height: 40px;
        text-align: center;
        font-size: 25px;
        color: $fff-brand-grey;
        text-decoration: none;

        &:hover {
          box-shadow: 0px 4px 8px rgba($fff-brand-green, 0.16);
          color: $fff-brand-green;
        }
      }
    }
  }

  .footer-grey-row {
    background-color: $fff-brand-grey;
    margin-top: 0px;
    padding: 60px 0px 0px;
    position: relative;
    margin-bottom: -21px;

    @include media-breakpoint-for(mobile) {
      padding: 40px 0px 40px;
      height: auto;
      margin-bottom: 0;
    }

    @include media-breakpoint-for(tab) {
      padding: 40px 0px 40px;
      height: auto;
      margin-bottom: 0;
    }

    @include media-breakpoint-for(tab-landscape) {
      padding: 40px 0px 40px;
      height: auto;
    }

    .copyright-row {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
      justify-content: center;
      padding: 0 0 40px;
      margin: 0px;
      position: relative;

      @include media-breakpoint-for(mobile) {
        padding: 0px;
      }

      @include media-breakpoint-for(tab) {
        padding: 0px;
      }

      @include media-breakpoint-for(tab-landscape) {
        padding: 0px;
      }

      p {
        font-family: 'SoleilLt', sans-serif;
        font-size: 12px;
        color: white;

        @include media-breakpoint-for(mobile) {
          margin-bottom: 0px;
        }

        @include media-breakpoint-for(tab) {
          margin-bottom: 0px;
        }

        @include media-breakpoint-for(tab-landscape) {
          margin-bottom: 0px;
        }
      }
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.wrapper {
  background: #f9f9f9;
  padding-top: 65px;
  min-height: 100vh;
  transition: padding-top 0.24s ease-in-out;
  margin-bottom: -21px;
  position: relative;

  &.extend {
    @media screen and (min-width: 1024px) {
      padding-top: calc(65px + 46px);
    }
    .new-order-summary-side-bar {
      @media screen and (min-width: 1024px) {
        padding-top: calc(65px + 46px);
      }
    }
  }

  &.white {
    background: white;
  }
}

.fff-main-panel {
  padding: 10px 0;

  &.no-padding {
    padding: 0px;
  }
}

.tag-label {
  font-family: 'SoleilLt', sans-serif;
  font-weight: 600;
  font-size: 12px;
  border-radius: 20px;
  height: 22px;
  display: inline-block;
  line-height: 14px;
  padding: 4px 8px;
  color: $fff-brand-grey;

  &.yellow {
    background-color: $fff-brand-yellow;
    color: $fff-brand-grey;
  }

  &.white {
    background-color: white;
  }

  &.grey {
    background-color: rgba($fff-brand-grey, 0.1);
  }
}

.validation-error {
  color: red;
  font-size: smaller;
  position: relative;
  width: 100%;
  margin-top: 5px;
}

.validate-macros {
  color: red;
  text-align: center;
  width: 100%;
  font-size: smaller;
  padding-bottom: 10px;
}

.validation-error-label {
  color: $fff-brand-red;
}

.validation-error-outline {
  &:not(.selectBox) {
    border: 2px solid $fff-brand-red !important;
  }

  &.selectBox {
    div[class*="control"] {
      border-color: $fff-brand-red !important;

      &:hover {
        border-color: $fff-brand-red !important;
      }
    }
  }
}

.required label:after {
  color: $fff-brand-red;
  content: ' *';
  display: inline;
}

.no-boarder {
  border: 0;
}

.page-header {
  margin-top: 10px;
  margin-bottom: 30px;
}

.avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

@media (max-width: 720px) {
  .p-growl.p-growl-topright {
    width: calc(100% - 20px);
    left: 0;
    right: 0;
    top: 10px;
    margin: 0 10px;

    .p-growl-item {
      height: 60px;
      padding: 0.2em 1em;

      .p-growl-image {
        margin-top: 4px;
      }

      .p-growl-message {
        .p-growl-title {
          padding: 0;
          margin-top: -6px;
        }

        p {
          margin-bottom: 0;
        }
      }
    }
  }
}

.custom-two-col {
  @include media-breakpoint-for(mobile) {
    padding-right: 7.5px;

    &:last-of-type {
      padding-right: 15px;
      padding-left: 7.5px;
    }
  }

  @include media-breakpoint-for(tab) {
    padding-right: 7.5px;

    &:last-of-type {
      padding-right: 15px;
      padding-left: 7.5px;
    }
  }

  @include media-breakpoint-for(tab-landscape) {
    padding-right: 7.5px;

    &:last-of-type {
      padding-right: 15px;
      padding-left: 7.5px;
    }
  }
}

.hide-in-desktop {
  display: none !important;

  @include media-breakpoint-for(mobile) {
    display: inline-block !important;
  }

  @include media-breakpoint-for(tab) {
    display: inline-block !important;
  }

  @include media-breakpoint-for(tab-landscape) {
    display: inline-block !important;
  }
}

.hide-in-mobile {
  display: inline-block !important;

  @include media-breakpoint-for(mobile) {
    display: none !important;
  }

  @include media-breakpoint-for(tab) {
    display: none !important;
  }

  @include media-breakpoint-for(tab-landscape) {
    display: none !important;
  }
}

.hide-in-desktop {
  display: none !important;

  @include media-breakpoint-for(mobile) {
    display: block !important;
  }

  @include media-breakpoint-for(tab) {
    display: block !important;
  }

  @include media-breakpoint-for(tab-landscape) {
    display: block !important;
  }
}

.btn-on-mobile {
  padding: 7px 30px;
  font-size: 14px;
  float: right;
}

.foo-item-list {
  display: none;
}

.show {
  .foo-item-list {
    display: block !important;
  }
}

.inner-description {
  display: none;
}

.m-hidden {
  @include media-breakpoint-for(mobile) {
    display: none;
  }

  @include media-breakpoint-for(tab) {
    display: none;
  }

  @include media-breakpoint-for(tab-landscape) {
    display: none;
  }
}

.m-xpadding-sm {
  @include media-breakpoint-for(mobile) {
    padding: 0px 15px;
  }

  @include media-breakpoint-for(tab) {
    padding: 0px 15px;
  }

  @include media-breakpoint-for(tab-landscape) {
    padding: 0px 15px;
  }
}

//2019.06.13
body .p-slider.p-slider-horizontal {
  height: 0.48em !important;
  border-radius: 5px;
}

body .p-slider .p-slider-range {
  background-color: $fff-brand-dark-grey;
  border-radius: 5px;
}

body .p-slider.slider-circle.p-disabled.p-slider-horizontal .p-slider-handle {
  left: 0% !important;
}

body .p-slider.slider-circle.p-disabled.p-slider-horizontal .p-slider-range {
  width: 0% !important;
}

body .p-slider.slider-circle.p-slider-horizontal .p-slider-handle {
  width: 16px;
  height: 16px;
  margin-top: -0.6em;
  background-image: none;

  @include media-breakpoint-for(mobile) {
    width: 20px;
    height: 20px;
    margin-top: -0.7em;
  }
}

body .p-slider.p-slider-horizontal .p-slider-handle {
  width: 35px;
  height: 35px;
  margin-top: -1.3em;
  padding: 8px;
  background-image: url('../img/maximize-2.svg');
  background-color: $fff-brand-yellow;
  background-repeat: no-repeat;
  background-position: center center;
  box-shadow: 0px 2px 10px -2px rgba($fff-brand-grey, 0.08);
  border: none;

  &:hover {
    border: none;
  }

  @include media-breakpoint-for(mobile) {
    width: 30px;
    height: 30px;
    margin-top: -1.1em;
    background-size: 16px;
  }

  @include media-breakpoint-for(tab) {
    width: 30px;
    height: 30px;
    margin-top: -1.1em;
    background-size: 16px;
  }

  @include media-breakpoint-for(tab-landscape) {
    width: 30px;
    height: 30px;
    margin-top: -1.1em;
    background-size: 16px;
  }
}

ul {
  &.tick-list {
    padding-left: 0px;
    margin-top: 15px;

    li {
      list-style: none;
      font-family: $fff-font-sans;
      background-image: url('../../assets/img/fff-bullet-r-green.svg');
      background-repeat: no-repeat;
      background-position: left top;
      background-size: 22px;
      padding-left: 30px;
      font-size: 14px;
      font-weight: 400;
      color: $fff-brand-grey;
      margin-bottom: 8px;
      text-align: left;

      @include media-breakpoint-for(mobile) {
        background-position: left 0px top 0px;
      }

      @include media-breakpoint-for(tab) {
        background-position: left 0px top 0px;
      }

      @include media-breakpoint-for(tab-landscape) {
        background-position: left 0px top 0px;
      }

      &:last-of-type {
        margin-bottom: 0px;
      }
    }
  }
}

.badge {
  padding: 0px 8px;
  font-family: 'SoleilLt', sans-serif;
  font-size: 12px;
  font-weight: 300;
  height: 22px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: unset;

  &.badge-danger {
    color: $fff-brand-dark-red;
    background-color: $fff-brand-shadded-red;
    border: 2px solid $fff-brand-shadded-red;
  }

  &.badge-success {
    color: $fff-brand-green;
    border: 2px solid rgba($fff-brand-green, 0.18);
    background-color: #ffffff;
    font-family: 'Soleil-Bold', sans-serif;
  }

  &.badge-light {
    background-color: rgba(242, 255, 249, 1);
    color: $fff-brand-grey;
    font-family: 'Soleil-Bold', sans-serif;
    border: 2px solid rgba(242, 255, 249, 1);
  }
}

.half-width {
  width: 50% !important;
}

.trustpilot-widget-auto-height {
  margin: 30px 0px;

  .trustpilot-widget {
    iframe {
      height: auto !important;

      @include media-breakpoint-for(mobile) {
        height: 133px !important;
      }

      @include media-breakpoint-for(tab) {
        height: 133px !important;
      }

      @include media-breakpoint-for(tab-landscape) {
        height: 133px !important;
      }
    }
  }
}

.rh5v-DefaultPlayer_component {
  display: contents;
}

.nutrition-row {
  color: #54565c;

  span {
    margin-right: 10px;
  }
}

.cta-box {
  height: 260px;
  position: relative;
  margin-bottom: 60px;
  display: flex;

  // @media (min-width: 1600px) {
  //   padding-bottom: 20%;
  // }

  @include media-breakpoint-for(mobile) {
    padding: 0px;
    height: auto;
    margin-bottom: 30px;
    display: block;
    margin-top: -45px;
  }

  @include media-breakpoint-for(tab) {
    padding: 0px;
    height: auto;
    margin-bottom: 30px;
    margin-top: -45px;
  }

  @include media-breakpoint-for(tab-landscape) {
    padding: 0px;
    height: auto;
    margin-bottom: 30px;
    margin-top: -45px;
  }

  &::before {
    content: '';
    flex: 0 0 20%;
    max-width: 20%;
    width: 100%;
    height: 100%;
    display: block;
    background-image: url('../../assets/img/CTA-left.jpg');
    background-repeat: no-repeat;
    background-position: top left;
    background-size: contain;

    @include media-breakpoint-for(mobile) {
      background-image: url('../../assets/img/CTA-mobile.jpg');
      padding-top: 30.5%;
      max-width: none;
      flex: auto;
    }

    @include media-breakpoint-for(tab) {
      background-image: url('../../assets/img/CTA-mobile.jpg');
      padding-top: 30.5%;
      max-width: none;
      flex: auto;
    }

    @include media-breakpoint-for(tab-landscape) {
      background-image: url('../../assets/img/CTA-mobile.jpg');
      padding-top: 30.5%;
      max-width: none;
      flex: auto;
    }
  }

  &::after {
    content: '';
    flex: 0 0 20%;
    max-width: 20%;
    width: 100%;
    height: 100%;
    display: block;
    background-image: url('../../assets/img/CTA-right.jpg');
    background-repeat: no-repeat;
    background-position: top right;
    background-size: contain;

    @include media-breakpoint-for(mobile) {
      display: none;
    }

    @include media-breakpoint-for(tab) {
      display: none;
    }

    @include media-breakpoint-for(tab-landscape) {
      display: none;
    }
  }
  // &::before,
  // &::after {
  //   @include media-breakpoint-for(mobile) {
  //     max-width: 220px;
  //     width: 220px;
  //     height: 220px;
  //     flex: auto;
  //     margin: 0 auto;
  //   }
  //   @include media-breakpoint-for(tab-landscape) {
  //     max-width: 220px;
  //     width: 220px;
  //     height: 220px;
  //   }
  //   @include media-breakpoint-for(ipad-pro-portrait) {
  //     max-width: 220px;
  //     width: 220px;
  //     height: 220px;
  //   }
  // }

  // .cta-box-inside-title-box {
  //   background-color: #ffffffb3;
  //   max-width: max-content;
  //   margin: 0 auto;
  //   padding: 5px 15px;
  //   border-radius: 2px;
  // }

  .cta-box-inside {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    margin: 0px;
    padding: 15px;
    display: block;
    text-align: center;
    z-index: 1;

    @include media-breakpoint-for(mobile) {
      position: relative;
      left: unset;
      -webkit-transform: unset;
      transform: unset;
    }

    @include media-breakpoint-for(tab) {
      position: relative;
      left: unset;
      -webkit-transform: unset;
      transform: unset;
    }

    @include media-breakpoint-for(tab-landscape) {
      position: relative;
      left: unset;
      -webkit-transform: unset;
      transform: unset;
    }

    @include media-breakpoint-for(ipad-pro-portrait) {
      position: relative;
      left: unset;
      -webkit-transform: unset;
      transform: unset;
    }

    h2 {
      font-size: 42px;
      line-height: 45px;
      font-family: $fff-font-sans;
      font-weight: bold;
      margin-bottom: 10px;
      color: $fff-brand-grey;

      @include media-breakpoint-for(mobile) {
        font-size: 28px;
        line-height: 35px;
        text-align: center;
        position: relative;
        left: unset;
        transform: unset;
      }

      @include media-breakpoint-for(tab) {
        font-size: 28px;
        line-height: 35px;
        text-align: center;
        position: relative;
        left: unset;
        transform: unset;
      }

      @include media-breakpoint-for(tab-landscape) {
        font-size: 28px;
        line-height: 35px;
        text-align: center;
        position: relative;
        left: unset;
        transform: unset;
      }
    }

    h3 {
      font-size: 28px;
      font-family: $fff-font-sans;
      font-weight: 400;
      margin-bottom: 20px;
      color: $fff-brand-grey;

      @include media-breakpoint-for(mobile) {
        font-size: 22px;
        text-align: center;
      }

      @include media-breakpoint-for(tab) {
        font-size: 22px;
        text-align: center;
      }

      @include media-breakpoint-for(tab-landscape) {
        font-size: 22px;
        text-align: center;
      }
    }

    .btn-lg {
      @include media-breakpoint-for(mobile) {
        vertical-align: middle;
        height: 56px;
        height: 56px;
        font-size: 18px;
      }

      @include media-breakpoint-for(tab) {
        vertical-align: middle;
        height: 56px;
        height: 56px;
        font-size: 18px;
      }

      @include media-breakpoint-for(tab-landscape) {
        vertical-align: middle;
        height: 56px;
        height: 56px;
        font-size: 18px;
      }
    }

    a {
      box-shadow: 0px 4px 8px rgba($fff-brand-green, 0.18);
      height: 56px;
      background-color: $fff-brand-green;
      color: white;
      border-radius: 30px;
      font-size: 18px;
      padding: 16px 41px 16px;
      line-height: 18px;
      font-family: 'Soleil-Bold', sans-serif;
      font-weight: normal;
      text-decoration: none;

      &:not(:disabled):not(.disabled):active,
      &:hover,
      &:focus {
        background-color: $fff-brand-dark-green;
        border-color: $fff-brand-dark-green;
        box-shadow: 0px 4px 8px rgba($fff-brand-green, 0.18);
      }

      @include media-breakpoint-for(mobile) {
        vertical-align: middle;
        font-size: 14px;
        padding: 10px 36px 9px;
        line-height: 17px;
        height: 40px;
      }

      @include media-breakpoint-for(tab) {
        vertical-align: middle;
        font-size: 14px;
        padding: 10px 36px 9px;
        line-height: 17px;
        height: 40px;
      }

      @include media-breakpoint-for(tab-landscape) {
        vertical-align: middle;
        font-size: 14px;
        padding: 10px 36px 9px;
        line-height: 17px;
        height: 40px;
      }
    }
  }
}

.light-green-container {
  margin: 0px;
  border: 0px;
  background-color: $fff-brand-light-green;
  padding: 20px 16px;

  p {
    color: $fff-brand-grey;
    font-size: 20px;
    font-family: 'SoleilLt', sans-serif;
    font-weight: 400;
    margin-bottom: 0px;
    text-align: center;

    @include media-breakpoint-for(mobile) {
      font-size: 18px;
    }

    @include media-breakpoint-for(tab) {
      font-size: 18px;
    }

    @include media-breakpoint-for(tab-landscape) {
      font-size: 18px;
    }

    &.margin-bottom-lg {
      margin-bottom: 30px;

      @include media-breakpoint-for(mobile) {
        margin-bottom: 10px;
      }

      @include media-breakpoint-for(tab) {
        margin-bottom: 10px;
      }

      @include media-breakpoint-for(tab-landscape) {
        margin-bottom: 10px;
      }
    }

    strong {
      font-family: 'DINPro-Bold', sans-serif;
    }

    &.small {
      font-size: 14px;
    }
  }

  ul {
    margin: 20px 0px 0px;
    padding: 0px 100px;
    justify-content: space-between;
    flex-wrap: wrap;
    display: flex;
    width: auto;

    @include media-breakpoint-for(mobile) {
      padding: 0px;
    }

    @include media-breakpoint-for(tab) {
      padding: 0px;
    }

    @include media-breakpoint-for(tab-landscape) {
      padding: 0px;
    }

    li {
      width: auto;
      display: inline-block;
      text-align: center;
      margin: 0px;
      padding: 0px;
      color: $fff-brand-grey;
      font-size: 14px;
      font-family: $fff-font-sans;
      font-weight: 400;
      margin-bottom: 0px;
      text-align: center;

      strong {
        font-family: 'DINPro-Bold', sans-serif;
      }
    }
  }

  .btn {
    &.bottom {
      position: absolute;
      top: unset;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, 0%);
      bottom: 0px;

      @include media-breakpoint-for(mobile) {
        position: relative;
        top: unset;
        left: unset;
        -webkit-transform: unset;
        transform: unset;
        bottom: unset;
        margin-bottom: 15px;
      }

      @include media-breakpoint-for(tab) {
        position: relative;
        top: unset;
        left: unset;
        -webkit-transform: unset;
        transform: unset;
        bottom: unset;
        margin-bottom: 15px;
      }

      @include media-breakpoint-for(tab-landscape) {
        position: relative;
        top: unset;
        left: unset;
        -webkit-transform: unset;
        transform: unset;
        bottom: unset;
        margin-bottom: 15px;
      }
    }
  }
}

.light-yellow-container {
  margin: 0px;
  border: 0px;
  background-color: $fff-brand-yellow-light;
  padding: 20px 16px;

  p {
    color: $fff-brand-grey;
    font-size: 20px;
    font-family: 'SoleilLt', sans-serif;
    font-weight: 400;
    margin-bottom: 0px;
    text-align: center;

    @include media-breakpoint-for(mobile) {
      font-size: 18px;
    }

    @include media-breakpoint-for(tab) {
      font-size: 18px;
    }

    @include media-breakpoint-for(tab-landscape) {
      font-size: 18px;
    }

    strong {
      font-family: 'DINPro-Bold', sans-serif;
    }

    &.small {
      font-size: 14px;
    }
  }
}

.icon-row {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  margin: 0px;
  position: relative;
  padding: 16px 0px;

  @include media-breakpoint-for(mobile) {
    display: block;
    text-align: center;
  }

  @include media-breakpoint-for(tab) {
    display: block;
    text-align: center;
  }

  @include media-breakpoint-for(tab-landscape) {
    display: block;
    text-align: center;
  }

  p {
    font-size: 14px;
    font-weight: 300;
    margin: 0px 5px 0px 0px;
    color: $fff-brand-grey;
    font-family: 'SoleilLt', sans-serif;
    line-height: 30px;

    @include media-breakpoint-for(mobile) {
      text-align: center;
      margin-bottom: 10px;
      width: 100%;
    }

    @include media-breakpoint-for(tab) {
      text-align: center;
      margin-bottom: 10px;
      width: 100%;
    }

    @include media-breakpoint-for(tab-landscape) {
      text-align: center;
      margin-bottom: 10px;
      width: 100%;
    }
  }

  button {
    width: auto;
    height: 30px;
    border-radius: 20px;
    background: #fff;
    margin: 0px 10px;
    text-align: center;
    font-size: 14px;
    color: $fff-brand-green;
    padding: 0px 10px;
    box-shadow: none;

    @include media-breakpoint-for(mobile) {
      line-height: 30px;
    }

    @include media-breakpoint-for(tab) {
      line-height: 30px;
    }

    @include media-breakpoint-for(tab-landscape) {
      line-height: 30px;
    }
  }

  .btn-icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #fff;
    margin: 0px 5px;
    line-height: 30px;
    text-align: center;
    font-size: 20px;
    color: $fff-brand-green;
    border: 2px solid rgba($fff-brand-green, 0.18);
    padding: 0px;

    > div {
      outline: 0;
      line-height: 20px;

      @include media-breakpoint-for(mobile) {
        line-height: unset;
      }

      @include media-breakpoint-for(tab) {
        line-height: unset;
      }

      @include media-breakpoint-for(tab-landscape) {
        line-height: unset;
      }

      i {
        line-height: unset;
      }
    }
  }
}

.dv-star-rating {
  display: block;
  margin: 10px 0px;

  label {
    width: auto;

    &.dv-star-rating-empty-star {
      width: 15px;
      height: 15px;
      position: relative;
      margin: 0px 2px;

      &::before {
        content: '';
        position: absolute;
        background-image: url('../../assets/img/star-rating-empty.svg');
        background-repeat: no-repeat;
        width: 15px;
        height: 15px;
        background-size: contain;
      }
    }

    &.dv-star-rating-full-star {
      width: 15px;
      height: 15px;
      position: relative;
      margin: 0px 2px;

      &::before {
        content: '';
        position: absolute;
        background-image: url('../../assets/img/star-rating-full.svg');
        background-repeat: no-repeat;
        width: 15px;
        height: 15px;
        background-size: contain;
      }
    }

    i {
      display: none;
      font-size: 20px;
    }
  }
}

.white-overlay {
  position: absolute;
  background-color: #ffffff;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.5;
  overflow: hidden;
}

.loading-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: white;
  opacity: 1;
  z-index: 99999;
}

.text-black {
  color: #212529 !important;
}

#hubspot-messages-iframe-container {
  .shadow.active {
    display: none !important;
  }
}

.payment-plan-toggle {
  margin: 30px auto 30px;

  .payment-plan-btn {
    width: 100%;
  }
}

.tab-50 {
  @include media-breakpoint-for(tab) {
    max-width: 50%;
    flex: 0 0 50%;
  }

  @include media-breakpoint-for(tab-landscape) {
    max-width: 50%;
    flex: 0 0 50%;
  }
}

.custom-addon {
  span {
    background-color: transparent !important;
  }
}

.calender-for-user-profile {
  .p-datepicker table td > span.p-highlight {
    background-color: transparent;
    color: black;
  }
}

.link {
  color: $fff-brand-green;
  text-decoration: none;
}

.default-tooltip {
  font-size: 15px;
  color: $fff-brand-new-primary;
  position: relative;
  vertical-align: -0.2rem;
}

.tooltip-black {
  color: #0f0f0f !important;
}

.left {
  float: left !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.fff-dropdown-select {
  padding: 0rem;
  width: 100%;
  height: 40px;
  min-width: 220px;
  line-height: 38px;
  outline: 0px;
  box-shadow: none;
  &.p-dropdown {
    border-radius: 6px;
    border: 2px solid rgba($fff-brand-new-dark, 0.12);
    &:not(.p-disabled):hover, &:not(.p-disabled).p-focus {
      border-color: $fff-brand-new-secondary;
    }
    .p-dropdown-panel {
      .p-dropdown-items-wrapper {
        .p-dropdown-items {
          .p-dropdown-item {
            &.p-highlight {
              background-color: $fff-brand-new-primary;
            }
            &:not(.p-highlight):hover {
              background-color: $fff-brand-new-fill;
            }
          }
        }
      }
    }
  }
  &.p-focus {
    box-shadow: none !important;
  }

  .p-dropdown-label {
    height: 100%;
    min-height: 100%;
    color: $fff-brand-new-dark;
    font-family: $fff-font-sans;
  }

  .p-highlight {
    background-color: $fff-brand-new-primary;
  }

  .p-dropdown-trigger {
    padding: 18px 20px !important;
    span {
      color: $fff-brand-new-dark;
      font-size: 14px;
    }
  }
}

.fff-loading-spinner {
  width: 15px;
  height: 15px;
  display: block;
  margin-left: 0.25rem;
  background: url('../img/icons/spinner-solid.svg');
  animation-name: spin;
  animation-duration: 1500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
}

.fff-loading-spinner-red{
  width: 15px;
  height: 15px;
  display: block;
  margin-left: 0.25rem;
  background: url('../img/icons/spinner-solid-red.svg');
  animation-name: spin;
  animation-duration: 1500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
}

.fff-horizontal-select {
  display: flex;
  flex-direction: row;
  border-radius: 20px;
  border: 1px solid #F0F0F0;
  justify-content: space-around;
  height: 34px;

  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-width: 0px;
    border-style: none;
    background-color: transparent;

    .disabled {
      opacity: 0.3;
      cursor: default;
    }
  }

  .label-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-for(mobile) {
      max-width: 65%;
    }

    .label {
      font-weight: 500;
      font-size: 14px;
      font-family: $fff-font-sans;
      color: #212529;
      text-align: center;
      line-height: 14px;
      text-wrap: balance;
    }
  }
}

.fff-horizontal-date-select{
  @media screen and (max-width: 1068px) {
    border: none;
    width: 100%;
    gap: 15px;
    height: auto;
    border-radius: 0px;
    background-color: $fff-brand-new-light;

    .label-wrapper {
      flex: 1 1;
      padding: 5px 8px 0 8px;
      height: 40px;
      display: flex;
      align-items: center;

      .label {
        line-height: normal;
        margin: 0;
        color: $fff-brand-foodbox-primary;
        opacity: 40%;
        font-weight: 600;
      }

      .delivery-exclude-icon {
        padding-left: 8px;
      }
    }

    .mid-section{
      border-radius: 4px 4px 0 0;
      background-color: $fff_brand_grey_green;
      
      .label{
        opacity: 100%;
      }
    }
  }
}

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}