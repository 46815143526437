@import './variables';
@import './responsive';

.p-dialog-title {
  font-size: 1rem;
  font-weight: bold;
}

.p-dialog-content {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.p-datatable {
  .p-sortable-column {
    &.p-highlight {
      background-color: rgba($fff-brand-grey, 0.1) !important;
      color: black !important;
      a {
        color: black !important;
      }
    }
  }
  .p-datatable-tbody {
    & > tr {
      & > td {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow-x: hidden;
      }
    }
  }
}

.btn {
  .material-icons {
    font-size: 20px;
    line-height: unset;
  }
}

.p-checkbox { 
   width: 30px !important;
  height: 30px !important;
  // margin-right: 10px !important;
  .p-checkbox-box {
    background-color: #fff;
    border: 2px solid rgba($color: #000000, $alpha: .125) !important;
    width: 30px !important;
    height: 30px !important;
    border-radius: 4px !important;
    box-shadow: none !important;
    .p-checkbox-icon {
      color: $fff-brand-new-primary !important;
      font-size: 16px;
      font-weight: 900;
    }
    &.p-highlight {
      border-color: rgba($color: #000000, $alpha: .125) !important;
      background-color: white !important;
    }
  }
}
.p-checkbox-label {
  width: auto;
}
body .p-datepicker{
    padding: 5px 5px 10px 5px;
}
.p-calendar {
  display: block !important;
  outline: none;
}
.p-calendar .p-inputtext {
  width: 100%;
  &:hover {
    border-color: $fff-brand-new-secondary !important;
  }
}
.p-calendar .p-datepicker {
  z-index: 10000 !important;
}
body .p-datepicker table td{
  padding:0;
  border: 1px solid rgba($fff-brand-grey, 0.1);
  width: 14.2%;
  cursor: default;
  &:hover{
     background-color: transparent; 
  }
}
body .p-datepicker table td.p-datepicker-today a{
  background-color: $fff-brand-light-green;
}
body .p-datepicker table td.p-datepicker-today a.p-highlight{
  background-color: $fff-brand-light-green;
}
body .p-datepicker table td.p-datepicker-today > span.p-highlight{
  background: rgba($fff-brand-grey, 0.08);
  color: black;
  background: none;
}
body .p-datepicker table td > span{
  cursor: default;
}
body .p-datepicker table td > span.p-disabled{
  cursor: default !important;
  .green-bg{
    cursor: default !important;
  }
  .otherDays{
    cursor: default !important;
  }
}
body .p-datepicker table td > span.p-highlight{
  background-color: #d1e5da;
  color: black;
  border-radius: 0px;
  width: 100%;
}
body .p-datepicker table td > span {
  width: 100%;
}
body .p-datepicker table td > span.p-highlight .otherDays{
  position: absolute;
  top:0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 35px;
  padding: 5px;
  width: 100%;
}

body .p-datepicker:not(.p-disabled) table td a:not(.p-highlight):not(.p-highlight):hover{
  background-color:transparent;
}
body .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover{
  background-color:transparent;
}
body .p-datepicker table td.p-datepicker-today>span{
  border-radius: 0;
  background-color: rgba($fff-brand-grey, 0.18); 
  background: none;
}
body .p-datepicker table td>a, body .p-datepicker table td>span{
  text-align: right;
  position: relative; 
  font-size: 14px;
  padding:5px;
  height: 35px;

  @include media-breakpoint-for(mobile) {
    font-size: 12px;
    height: 35px;
  }
  @include media-breakpoint-for(tab) {
    font-size: 12px;
    height: 35px;
  }
  @include media-breakpoint-for(tab-landscape) {
    font-size: 12px;
    height: 35px;
  }
}


body .p-datepicker table td > a.p-highlight{
  background-color: rgba($fff-brand-grey, 0.18); 
  color:$fff-brand-grey;
  border-radius: 0px;
  padding: 5px;
}
body .p-disabled, body .p-component:disabled{
  padding-right: 3px;
  padding-top: 5px;
}
.calenderContainer{
  position: relative;
  i{    
    color: rgba(6, 109, 56, 0.72);
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;
  }
}

body .p-inputtext:enabled:focus{
  box-shadow: none;
}

.p-inputwrapper-filled{
  .form-control:disabled, .form-control[readonly]{
    border: 1px solid rgba($fff-brand-new-dark, 0.125);
    height: 40px;
  }
  .p-inputtext:enabled:hover:not(.p-error){
  border: 1px solid $fff-brand-new-secondary;
  }
  body .p-datepicker:not(.p-datepicker-inline){
    margin-bottom: 10px;
  }
 .p-datepicker:not(.p-datepicker-inline){
box-shadow: none;
  }}
  body .p-inputtext{
    width: 100%;
    border: 2px solid rgba($fff-brand-green, 0.18);
    border-radius: 5px !important;
    padding: 0rem 0.75rem;
    min-height: 40px;
    &:focus {
      box-shadow: none;
      border-color: $fff-brand-new-secondary !important;
    }
  }

  .p-datepicker .p-datepicker-next span{
    // border: 1px solid $fff-brand-green;
    // border-radius: 50%;
    font-size: 12px;
    padding: 0.3rem;
    right: 4%;
  }
  .p-datepicker .p-datepicker-prev span{
    // border: 1px solid $fff-brand-green;
    // border-radius: 50%;
    font-size: 12px;
    padding: 0.3rem;
    left: 4%;
  }
  body .p-datepicker .p-datepicker-header{
    padding-top: 0.6rem;
  }
  body .p-datepicker .p-datepicker-header .p-datepicker-title{
    font-weight: 600;
    font-size: 14px;
    color:$fff-brand-grey;
  }
  body .p-datepicker .p-datepicker-header .p-datepicker-title select{
    margin-top: -0.15em;
    border: 0;
  }
  body .p-datepicker table th{
    color:$fff-brand-grey;
    font-family: 'SoleilLt', sans-serif;
    font-weight: 400;
  }
  .p-datepicker.p-datepicker-inline{
    border: 0px;
  }
  .p-datepicker .p-datepicker-prev{
    left: 1em;
    @media (min-width: 1200px) {
      left: 2em;
      }

      @include media-breakpoint-for(mobile) {
        left: 0.5em;
      }
      @include media-breakpoint-for(tab) {
        left: 0.5em;
      }
      @include media-breakpoint-for(tab-landscape) {
        left: 0.5em;
      }
  }
  .p-datepicker .p-datepicker-next {
    right: 1em;
    @media (min-width: 1200px) {
      right: 2em;
      }

    @include media-breakpoint-for(mobile) {
      right: 0.5em;
    }
    @include media-breakpoint-for(tab) {
      right: 0.5em;
    }
    @include media-breakpoint-for(tab-landscape) {
      right: 0.5em;
    }
}
body .p-calendar.p-calendar-w-btn .p-datepicker-trigger.p-button{
  position: absolute;
  top: -3px;
  right: -3px;
  background-color: transparent;
  color: $fff-brand-new-primary;
  font-size: 22px; 
  box-shadow: none;
  border-color: transparent;
  padding-right: 20px;
}
body .p-calendar.p-calendar-w-btn .p-datepicker-trigger.p-button{
  span {
    font-size: 22px !important;
  }
}
body .p-calendar.p-calendar-w-btn .p-inputtext{
  border-right: 2px solid rgba($fff-brand-green, 0.16);
}
body .p-button.p-button-icon-only .p-button-text{
  padding: 0px;
}
body .p-link:focus{
  box-shadow: none;
}
body .p-datepicker:not(.p-disabled) .p-datepicker-header .p-datepicker-prev:hover, body .p-datepicker:not(.p-disabled) .p-datepicker-header .p-datepicker-next:hover{
  color: inherit;
}


//TODO: Remove this after fixing FFF-5414
.bespoke-order-form-container {
  @media screen and (max-width: 667px) {
    max-width: 100vw;
    overflow-x: hidden;
  }
}

.has-main-site-notification {
  .bespoke-order-form-container {
    padding-top: 45px !important;
  }
  @media screen and (min-width: 1024px) {
    .new-order-summary-side-bar {
      padding-top: 110px;
    }
  }
  .togo-sticker{
    top: 108px;
  }
}

.togo-extras-image{
  &>div{
    width: 55%;
    max-height: 250px;
    overflow: hidden;
    margin: 0 auto;
  }
}

//the text inside circular progress bar
.CircularProgressbar-text{
  font-weight: bold;
  font-family: 'SoleilLt', sans-serif;
  fill: $fff-brand-black-text;
  text-anchor: middle; //used to align svg text horizontally 
  dominant-baseline: middle; //used to vertically align svg text
}

.input-group > .form-control:not(:last-child) {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.allow-scrolling {
  max-width: 100%;
  overflow: auto;
}

.override-span-width {
  min-height: 110px;
  .img-container {
    vertical-align: top;
  }
  span:not(.img-container) {
    width: calc(100% - 70px) !important;
  }

  h2 {
    span:not(.img-container) {
      width: 100% !important;
      margin-bottom: 2px;
    }
  }
}

.CodeMirror {
  height: auto !important;
  padding-top: 16px;
  padding-bottom: 16px;
}

// Overwriting fff-table styles

.fff-table {
  &.fff-table-for-customer-portal {
    .p-datatable-wrapper {
      table {
        .p-datatable-thead {
          tr {
            .p-sortable-column {
              background-color: transparent !important;
            }

            th {
              &.min-width-25 {
                width: 25%;
              }
            }
            
            td {
              strong {
                font-weight: 600;
              }
            }
          } 
        }
        
        tbody {
          &.p-datatable-tbody {
            tr {
              &:hover {
                td {
                  background-color: transparent !important;
                }
              }
  
              td {
                strong {
                  font-weight: 600;
                }
              }
            } 
          }
        }
      } 
    }
  
    .p-paginator {
      &.p-paginator-bottom {
        .p-paginator-left-content {
          color: $fff-brand-new-dark !important;
        }
      }
  
      .p-dropdown {
        &.p-component {
          .p-dropdown-label {
            height: 36px !important;
            line-height: 36px !important;
            color: $fff-brand-new-dark !important;
          }
  
          .p-dropdown-trigger {
            padding-top: 6px !important;
  
            span {
              color: $fff-brand-new-dark !important;
            }
          }
  
          .p-dropdown-panel {
            ul {
              .p-highlight {
                background-color: $fff-brand-new-primary !important;
              }
            }
          }
        }
      }
    }
  }
}
